import axios from 'axios';
import * as AdminAuthService from '../../common/auth/AdminAuthService';

//-------------------------
// ADMIN SUBSCRIPTION Data Service
// - GetAll
// - GetByCompany
// - GetById
// - Add
// - Update
// - Delete
//-------------------------

export const GetAll = () => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/subscriptions`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByCompany = companyId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/subscriptions/company/${companyId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetById = subscriptionId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/subscriptions/${subscriptionId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Add = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/subscriptions`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Sync = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/subscriptions/sync`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Update = (subscriptionId, payload) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .put(`/api/subscriptions/${subscriptionId}`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Delete = subscriptionId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .delete(`/api/subscriptions/${subscriptionId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};
