import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, GridColumn, List, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as Validation from '../../../common/utils/Validation';

class BookingServiceCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Price
    const priceValue = !Validation.isEmpty(dataEntity.defaultPrice)
      ? dataEntity.defaultPrice
      : 0;
    const priceType = !Validation.isEmpty(dataEntity.defaultPriceType)
      ? Enums.BOOKING_PRICETYPE[dataEntity.defaultPriceType]
      : '';
    const priceText = `Price: ${priceValue} ${priceType}`;

    // Field Location
    const locationObject = dataEntity ? dataEntity.defaultLocation : {};
    const locationName = StringUtil.MaxLength(
      !Validation.isEmpty(locationObject.displayName)
        ? 'Name: ' + locationObject.displayName
        : '',
      lengthColumn,
      true
    );
    const locationType = StringUtil.MaxLength(
      !Validation.isEmpty(locationObject.locationType)
        ? 'Type: ' + Enums.BOOKING_LOCATIONTYPE[locationObject.locationType]
        : '',
      lengthColumn,
      true
    );

    // Field Address
    const addressObject = !Validation.isEmpty(locationObject)
      ? locationObject.address
      : {};

    // Field Settings
    const settingHidden = dataEntity ? dataEntity.isHiddenFromCustomers : false;
    const settingConfirmation =
      dataEntity && dataEntity.schedulingPolicy
        ? dataEntity.schedulingPolicy.sendConfirmationsToOwner
        : false;
    const settingStaff =
      dataEntity && dataEntity.schedulingPolicy
        ? dataEntity.schedulingPolicy.allowStaffSelection
        : false;

    const settingHiddenIcon = settingHidden
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const settingConfirmationIcon = settingConfirmation
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const settingStaffIcon = settingStaff
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;

    // Field Links
    const linksWeb = !Validation.isEmpty(dataEntity.ApplicationUrl)
      ? dataEntity.ApplicationUrl
      : '';

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Price</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(priceText)}</div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Staff Members</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.staffMemberIds) ? (
                    <List items={dataEntity.staffMemberIds} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Location</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(locationName)}</div>
                  {locationType && <div>{locationType}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Address</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {FieldUtil.IsValidPhysicalAddress(addressObject) ? (
                    <div className="app-itemcard-info">
                      <div>
                        {FieldUtil.GetStreetLineFromPhysicalAddress(
                          addressObject
                        )}
                      </div>
                      <div>
                        {FieldUtil.GetCityLineFromPhysicalAddress(
                          addressObject
                        )}
                      </div>
                      <div>
                        {FieldUtil.GetCountryLineFromPhysicalAddress(
                          addressObject
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Settings</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>
                    <Icon
                      name={settingHiddenIcon}
                      className="app-listitem-icon"
                    />
                    Hidden from Customer
                  </div>
                  <div>
                    <Icon
                      name={settingConfirmationIcon}
                      className="app-listitem-icon"
                    />
                    Send Confirmations to Owner
                  </div>
                  <div>
                    <Icon
                      name={settingStaffIcon}
                      className="app-listitem-icon"
                    />
                    Staff Selection enabled
                  </div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-website">
                  {linksWeb ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksWeb}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Weblink
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
BookingServiceCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

BookingServiceCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default BookingServiceCardForm;
