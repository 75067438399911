import moment from 'moment';
import * as Actions from '../types';
import * as Selectors from '../../reducers/rootReducer';
import * as ObjType from '../../../config/entities/Objects';
import * as ObjectParams from '../../../config/entities/ParameterOfObject';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// MANAGER
// - invalidateUser
// - loadUser
//-------------------------
// With Data Extension
//-------------------------

export const invalidateUser = () => (dispatch, getState) => {
  const managerContainer = Selectors.getMyManager(getState());
  return {
    type: Actions.MANAGER_INVALIDATE,
    payload: {
      entityId: managerContainer.userId
    }
  };
};

export const loadUser = auth => (dispatch, getState) => {
  if (shouldFetchEntity(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchUser(auth));
  } else {
    const managerContainer = Selectors.getMyManager(getState());
    const entityContainer = !Validation.isEmpty(managerContainer)
      ? Selectors.getUserEntityById(getState(), managerContainer.userId)
      : {};
    const extendedAvailable =
      entityContainer &&
      entityContainer.entity &&
      Validation.isDefined(entityContainer.entity, 'aboutMe')
        ? true
        : false;
    if (entityContainer && entityContainer.entity && !extendedAvailable) {
      return dispatch(fetchUserExtension(auth, managerContainer.userId));
    } else {
      // Let the calling code know there's nothing to wait for.
      return Promise.resolve();
    }
  }
};

//-------------------------
// Helpers
//-------------------------

const requestEntity = () => (dispatch, getState) => {
  const managerContainer = Selectors.getMyManager(getState());
  return {
    type: Actions.MANAGER_REQUEST,
    payload: {
      entityId: managerContainer.userId
    }
  };
};

const receiveEntity = data => {
  const type = !Validation.isEmpty(data) ? data['@odata.type'] : '';
  const apitype = ObjectParams.GetApiObjectType(ObjType.USER.code);
  if (!type || type === apitype) {
    return {
      type: Actions.MANAGER_RECEIVE,
      payload: {
        entityId: !Validation.isEmpty(data) ? data.id : '',
        entity: data,
        receivedAt: Date.now()
      }
    };
  }
};

const receiveError = err => {
  if (err.statusCode === 404) {
    return {
      type: Actions.MANAGER_RECEIVE,
      payload: {
        entityId: '',
        entity: {},
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const shouldFetchEntity = state => {
  const entityContainer = Selectors.getMyManager(state);
  const staleDate = moment().subtract(10, 'minutes');

  if (entityContainer) {
    if (!entityContainer.lastUpdated) {
      return true;
    } else if (
      entityContainer.lastUpdated &&
      entityContainer.lastUpdated < staleDate
    ) {
      return true;
    } else if (entityContainer.didInvalidate) {
      return true;
    } else if (entityContainer.isFetching) {
      return false;
    } else return false;
  } else return true;
};

const fetchUser = auth => dispatch => {
  dispatch(requestEntity());
  let basicEntity = {};
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api('/me/manager')
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(basic => {
          basicEntity = { ...basic };
          dispatch(receiveEntity(basicEntity));
          dispatch(
            fetchExtendedData(client, basicEntity ? basicEntity.id : '')
          );
        })
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};

//-------------------------
// Helpers - Extension
//-------------------------

const receiveEntityExtension = (id, extension) => {
  return {
    type: Actions.USER_EXTENSION_RECEIVE,
    payload: {
      entityId: id,
      entity: extension,
      receivedAt: Date.now()
    }
  };
};

const fetchUserExtension = (auth, id) => dispatch => {
  dispatch(requestEntity());
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      dispatch(fetchExtendedData(client, id));
    })
    .catch(err => console.log(err));
};

const fetchExtendedData = (client, id) => dispatch => {
  client
    .api(`/me/manager`)
    .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
    .select([
      'aboutMe',
      'birthday',
      'hireDate',
      'interests',
      'mySite',
      'pastProjects',
      'preferredName',
      'responsibilities',
      'schools',
      'skills'
    ])
    .get()
    .then(extension => {
      const extendedEntity = { ...extension };
      client
        .api(`/me/manager/photo`)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(imageParameter => {
          const imageBaseNode = { ...imageParameter };
          client
            .api(`/me/manager/photo/$value`)
            .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
            .responseType(MicrosoftGraph.ResponseType.ARRAYBUFFER)
            .get()
            .then(image => {
              try {
                const base64 = Buffer.from(image).toString('base64');
                const contentType = imageBaseNode['@odata.mediaContentType'];
                const imageUrl = `data:${contentType};base64,${base64}`;
                const imageNode = { ...imageBaseNode, url: imageUrl };
                const imageEntity = { ...extendedEntity, image: imageNode };
                dispatch(receiveEntityExtension(id, imageEntity));
              } catch (error) {
                console.error(error);
              }
            })
            .catch(err => dispatch(receiveEntityExtension(id, extendedEntity)));
        })
        .catch(err => dispatch(receiveEntityExtension(id, extendedEntity)));
    });
};
