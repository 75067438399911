import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';
import { Form, Message, Button } from 'semantic-ui-react';
import RelationSelect from '../../../common/components/controls/RelationLookup';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as ObjectConfig from '../../../app/config/entities/Objects';

class NodeRelation extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Helpers
  //-----------------------------------
  getEntityType = (dataNode, dataMe) => {
    if (dataNode && dataMe) {
      if (
        dataNode.objectType === ObjectConfig.USER.code &&
        dataNode.objectId === dataMe.userId
      ) {
        return ObjectConfig.ME.code;
      }
      return dataNode.objectType;
    } else return '';
  };
  getErrorList = formErrors => {
    let newArray = [];
    for (let prop in formErrors) {
      newArray.push(formErrors[prop]);
    }
    return newArray;
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { formErrors, formWarnings, formMessages } = this.props;
    const { valueRelation, dataNode, dataMe } = this.props;

    const entityType = this.getEntityType(dataNode, dataMe);
    const hasErrors = !(
      Object.keys(formErrors).length === 0 && formErrors.constructor === Object
    );
    const hasWarnings = formWarnings && formWarnings.length > 1;
    const hasMessages = formMessages && formMessages.length > 1;
    const errorList = this.getErrorList(formErrors);

    return (
      <Segment basic>
        <Header sub className="app-itemcard-subheader">
          Select the Relation to load
        </Header>
        <Form error={hasErrors} warning={hasWarnings} success={hasMessages}>
          <Form.Field>
            <label>Relation</label>
            <RelationSelect
              auth={auth}
              location={location}
              match={match}
              params={params}
              selectedValue={valueRelation}
              entityType={entityType}
              fieldName={'selectRelation'}
              placeholder={'Select Relation'}
              isLoading={false}
              isDisabled={false}
              searchMode={true}
              fluidMode={true}
              hasError={!!formErrors.selectRelation}
              onSelectionChange={this.props.onRelationChange}
            />
          </Form.Field>
          <Message error header="Validation errors:" list={errorList} />
          <Message warning header="Please note:" list={formWarnings} />
          <Message success header="Success:" list={formMessages} />
          <Button
            content="Add to Navigation Path"
            onClick={this.props.onExtendPathClick}
            primary
            icon={MenuConfig.ICONS_MENU_DEFAULTS.add}
            labelPosition="left"
          />
        </Form>
      </Segment>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
NodeRelation.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  valueRelation: PropTypes.string.isRequired,
  valueLoadAll: PropTypes.bool.isRequired,
  dataNode: PropTypes.object.isRequired,
  dataContext: PropTypes.object.isRequired,
  dataMe: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  formWarnings: PropTypes.array,
  formMessages: PropTypes.array,
  onRelationChange: PropTypes.func.isRequired,
  onLoadAllChange: PropTypes.func.isRequired,
  onExtendPathClick: PropTypes.func.isRequired
};

export default NodeRelation;
