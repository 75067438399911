import React from 'react';
import HomeCanvas from './Home.Canvas.Container';
import Callback from '../../../app/Callback';
import '../Single.css';

const handleAuthentication = auth => {
  const authenticated = auth ? auth.authenticated : false;
  if (!authenticated) {
    if (auth) {
      const scopes = ['user.read'];
      auth.login(scopes);
    } else {
      console.log('AuthService not defined');
    }
  }
};

class HomePage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { auth, location, match, params } = this.props;
    const authenticated = auth.authenticated;

    if (!authenticated) {
      handleAuthentication(auth);
      return <Callback {...this.props} />;
    }

    return (
      <div className="app-homepage">
        <HomeCanvas
          auth={auth}
          match={match}
          location={location}
          params={params}
        />
      </div>
    );
  }
}

export default HomePage;
