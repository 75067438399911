import { combineReducers } from 'redux';
import byIdReducer, * as byIdSelectors from './sites/byIdReducer';
import allIdsReducer from './sites/allIdsReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  byId: byIdReducer,
  allIds: allIdsReducer
});

//-------------------------
// Selectors
//-------------------------

export const getEntityById = (state, id) => {
  return byIdSelectors.getEntityById(state.byId, id);
};
export const getEntitiesByIds = (state, ids = []) => {
  return ids.map(id => state.byId[id]);
};
export const getAllEntities = state => {
  return state.allIds.map(id => state.byId[id]);
};
export const getFilteredEntities = (state, filter = 'all') => {
  const allEntities = state.allIds.map(id => state.byId[id]);
  switch (filter) {
    case 'all':
      return allEntities;
    default:
      throw new Error(`Unknown filter: ${filter}`);
  }
};
