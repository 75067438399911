import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Button } from 'semantic-ui-react';
import AccountHeader from './Account.Header';
import AccountCard from './Account.Card';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as AdminAuthService from '../../../common/auth/AdminAuthService';

class AccountCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeHeaderMenuItem: MenuConfig.CODE_MENU_DEFAULTS.none
    };
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleHeaderMenuItemClick = (e, { name }) => {
    this.setState({ activeHeaderMenuItem: name });

    if (name === MenuConfig.CODE_MENU_DEFAULTS.close) {
      const callingPath = this.props.match.url;
      const basePathArray = callingPath.split('/account', 1);
      const basePath = basePathArray.join();
      this.props.history.push(basePath);
    }
  };

  handleLogoutButtonClick = (e, data) => {
    e.preventDefault();
    this.props.auth.logout();
    AdminAuthService.ClearAccessToken();
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { activeHeaderMenuItem } = this.state;
    const { auth, location, match, history, params } = this.props;
    const { dataAccount, dataNode, dataContext, dataLoading } = this.props;
    const webLink = dataAccount ? dataAccount.mySite : '/';

    return (
      <div className="app-sidebar-content">
        <div className="app-sidebar-header-wrapper">
          <AccountHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            activeMenuItem={activeHeaderMenuItem}
            webLink={webLink}
            onMenuItemClick={this.handleHeaderMenuItemClick}
          />
        </div>
        <AccountCard
          auth={auth}
          location={location}
          match={match}
          history={history}
          params={params}
          dataEntity={dataAccount}
          dataNode={dataNode}
          dataContext={dataContext}
          dataLoading={dataLoading}
          loadAccount={this.props.loadAccount}
        />
        <Segment basic>
          <Button
            content="Logout"
            onClick={this.handleLogoutButtonClick}
            primary
            icon={MenuConfig.ICONS_MENU_DEFAULTS.signOut}
            labelPosition="right"
          />
        </Segment>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
AccountCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataAccount: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  dataContext: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  loadAccount: PropTypes.func.isRequired
};

export default AccountCanvas;
