import axios from 'axios';
import * as AdminAuthService from '../../common/auth/AdminAuthService';

//-------------------------
// ADMIN LICENSE Data Service
// - GetAll
// - GetByAccount
// - GetBySubscription
// - GetById
// - Add
// - Update
// - Delete
//-------------------------

export const GetAll = () => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/licenses`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByAccount = accountId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/licenses/account/${accountId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetBySubscription = subscriptionId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/licenses/subscription/${subscriptionId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetById = licenseId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/licenses/${licenseId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Add = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/licenses`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Sync = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/licenses/sync`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Update = (licenseId, payload) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .put(`/api/licenses/${licenseId}`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Delete = licenseId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .delete(`/api/licenses/${licenseId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};
