// Segments

export const DRIVES = {
  all: 'drivesAll',
  assigned: 'drivesAssigned',
  default: 'drivesDefault'
};

export const GROUPS = {
  all: 'groupsAll',
  acceptedSenders: 'groupsAcceptedSenders',
  assigned: 'groupsAssigned',
  joined: 'groupsJoined',
  memberOf: 'groupsMemberOf',
  members: 'groupsMembers',
  membersWithLicenseErrors: 'groupsMembersWithLicenseErrors',
  rejectedSenders: 'groupsRejectedSenders'
};

export const ORGANIZATIONS = {};

export const PLANS = {
  all: 'plansAll',
  assigned: 'plansAssigned',
  favorite: 'plansFavorite',
  recent: 'plansRecent'
};

export const SITES = {
  root: 'sitesRoot',
  children: 'sitesChildren'
};

export const USERS = {
  all: 'usersAll',
  acceptedSenders: 'usersAcceptedSenders',
  assigned: 'usersAssigned',
  directReports: 'usersDirectReports',
  impacted: 'usersImpacted',
  manager: 'usersManager',
  members: 'usersMembers',
  membersWithLicenseErrors: 'usersMembersWithLicenseErrors',
  owners: 'usersOwners',
  registeredOwners: 'usersRegisteredOwners',
  registeredUsers: 'usersRegisteredUsers',
  rejectedSenders: 'usersRejectedSenders',
  taskAssigned: 'usersTaskAssigned',
  taskOwners: 'usersTaskOwners'
};

// Entities

export const ACTIVITIES = {
  all: 'activitiesAll',
  open: 'activitiesOpen',
  subAll: 'activitiesSubAll',
  subOpen: 'activitiesSubOpen'
};

export const ACTIVITYFOLDERS = {
  all: 'activityfoldersAll',
  subAll: 'activityfoldersSubAll'
};

export const ACTIVITYGROUPS = {
  all: 'activitygroupsAll'
};

export const ADMINISTRATIVEUNITS = {
  memberOf: 'administrativeUnitsMemberOf'
};

export const AGREEMENTS = {
  all: 'agreementsAll'
};

export const AGREEMENTACCEPTANCE = {
  all: 'agreementAcceptancesAll'
};

export const APPLICATIONS = {
  assigned: 'applicationsAssigned'
};

export const ATTACHMENTS = {
  all: 'attachmentsAll'
};

export const BOOKINGAPPOINTMENTS = {
  all: 'bookingappointmentsAll',
  coming: 'bookingappointmentsComing',
  customerAll: 'bookingappointmentsCustomerAll',
  serviceAll: 'bookingappointmentsServiceAll'
};

export const BOOKINGBUSINESSES = {
  all: 'bookingbusinessesAll'
};

export const BOOKINGCUSTOMERS = {
  all: 'bookingcustomersAll'
};

export const BOOKINGSERVICES = {
  all: 'bookingservicesAll'
};

export const BOOKINGSTAFFMEMBERS = {
  all: 'bookingstaffmembersAll'
};

export const BUCKETS = {
  all: 'bucketsAll'
};

export const CALENDARS = {
  all: 'calendarsAll',
  assigned: 'calendarsAssigned',
  default: 'calendarsDefault',
  single: 'calendarsSingle'
};

export const CALENDARGROUPS = {
  all: 'calendargroupsAll'
};

export const CHANNELS = {
  all: 'channelsAll'
};

export const CHATMESSAGES = {
  all: 'chatmessagesAll',
  root: 'chatmessagesRoot'
};

export const CHATTHREADS = {
  all: 'chatthreadsAll'
};

export const CONTACTS = {
  all: 'contactsAll',
  assigned: 'contactsAssigned'
};

export const CONTACTFOLDERS = {
  all: 'contactfoldersAll',
  children: 'contactfoldersChildren'
};

export const CONTRACTS = {
  assigned: 'contractsAssigned'
};

export const CONVERSATIONS = {
  all: 'conversationsAll'
};

export const DEVICES = {
  assigned: 'devicesAssigned',
  owned: 'devicesOwned',
  registered: 'devicesRegistered'
};

export const DIRECTORYROLES = {
  memberOf: 'directoryrolesMemberOf'
};

export const DOMAINES = {
  assigned: 'domainsAssigned'
};

export const DRIVEITEMS = {
  appRoot: 'driveitemsApproot',
  cameraRoll: 'driveitemsCameraroll',
  children: 'driveitemsChildren',
  documents: 'driveitemsDocuments',
  following: 'driveitemsFollowing',
  music: 'driveitemsMusic',
  photos: 'driveitemsPhotos',
  root: 'driveitemsRoot'
};

export const EVENTS = {
  all: 'eventsAll',
  coming: 'eventsComing',
  instances: 'eventsInstances'
};

export const IDENTITYPROVIDERS = {
  assigned: 'identityprovidersAssigned'
};

export const INSIGHTS = {
  shared: 'insightsShared',
  trending: 'insightsTrending',
  used: 'insightsUsed'
};

export const LICENSES = {
  assigned: 'licensesAssigned',
  subscribed: 'licensesSubscribed'
};

export const LISTS = {
  all: 'listsAll'
};

export const LISTITEMS = {
  all: 'listitemsAll'
};

export const MAILFOLDERS = {
  all: 'mailfoldersAll',
  children: 'mailfoldersChildren'
};

export const MESSAGES = {
  all: 'messagesAll',
  unread: 'messagesUnread',
  inboxAll: 'messagesInboxAll',
  inboxUnread: 'messagesInboxUnread'
};

export const NOTEBOOKS = {
  all: 'notebooksAll',
  assigned: 'notebooksAssigned'
};

export const ORGCONTACTS = {
  assigned: 'orgcontactsAssigned',
  directReports: 'orgcontactsDirectReports',
  manager: 'orgcontactsManager',
  members: 'orgcontactsMembers',
  membersWithLicenseErrors: 'orgcontactsMembersWithLicenseErrors'
};

export const PAGES = {
  all: 'pagesAll'
};

export const PERSONS = {
  trending: 'personsTrending'
};

export const POSTS = {
  all: 'postsAll',
  inReplyTo: 'postsInReplyTo'
};

export const RISKEVENTS = {
  anonymousIp: 'riskeventsAnonymousIP',
  identity: 'riskeventsIdentity',
  impossibleTravel: 'riskeventsImpossibleTravel',
  leakedCredentials: 'riskeventsLeakedCredentials',
  malware: 'riskeventsMalware',
  suspiciousIp: 'riskeventsSuspiciousIP',
  unfamiliarLocation: 'riskeventsUnfamiliarLocation'
};

export const SECURITYALERTS = {
  all: 'securityalertsAll'
};

export const SERVICES = {
  all: 'servicesAll'
};

export const SERVICEPLANS = {
  assigned: 'serviceplansAssigned'
};

export const SERVICEPRINCIPALS = {
  assigned: 'serviceprincipalsAssigned'
};

export const TASKS = {
  all: 'tasksAll',
  assigned: 'tasksAssigned',
  open: 'tasksOpen',
  subAll: 'tasksSubAll',
  subAssigned: 'tasksSubAssigned',
  subOpen: 'tasksSubOpen'
};

export const TEAMS = {
  all: 'teamsAll',
  joined: 'teamsJoined'
};

export const TEAMSAPPINSTALLATIONS = {
  all: 'teamsappinstallationsAll'
};

export const TEAMSTABS = {
  all: 'teamstabsAll'
};

export const THREADS = {
  all: 'threadsAll'
};

export const VERSIONS = {
  all: 'versionsAll'
};
