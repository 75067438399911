import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, GridColumn, List, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class GroupCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Settings
    const hasSettings =
      !Validation.isEmpty(dataEntity.visibility) ||
      !Validation.isEmpty(dataEntity.classification) ||
      Validation.isValidDate(dataEntity.expirationDateTime) ||
      !Validation.isEmpty(dataEntity.preferredLanguage) ||
      !Validation.isEmpty(dataEntity.preferredDataLocation);
    const settingVisibility = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.visibility)
        ? 'Visibility: ' + dataEntity.visibility
        : '',
      lengthColumn,
      true
    );
    const settingClassify = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.classification)
        ? 'Classification: ' + dataEntity.classification
        : '',
      lengthColumn,
      true
    );
    const settingExpire = Validation.isValidDate(dataEntity.expirationDateTime)
      ? 'Expires: ' +
        DateUtil.formatMsGraphDateTime(dataEntity.expirationDateTime)
      : '';
    const settingLanguage = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.preferredLanguage)
        ? 'Language: ' + dataEntity.preferredLanguage
        : '',
      lengthColumn,
      true
    );
    const settingData = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.preferredDataLocation)
        ? 'Data Location: ' + dataEntity.preferredDataLocation
        : '',
      lengthColumn,
      true
    );

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );
    const systemDate = Validation.isValidDate(dataEntity.createdDateTime)
      ? 'Created: ' + DateUtil.formatMsGraphDateTime(dataEntity.createdDateTime)
      : '';
    const renewedDate = Validation.isValidDate(dataEntity.renewedDateTime)
      ? 'Renewed: ' + DateUtil.formatMsGraphDateTime(dataEntity.renewedDateTime)
      : '';

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Group Email</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {!Validation.isEmpty(dataEntity.mail) ? (
                    <div className="app-itemcard-email">
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                        className="app-listitem-icon"
                      />
                      <a href={'mailto:' + dataEntity.mail}>
                        {dataEntity.mail}
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Type</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.groupTypes) ? (
                    <List items={dataEntity.groupTypes} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Provisioned For</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(
                    dataEntity.resourceProvisioningOptions
                  ) ? (
                    <List items={dataEntity.resourceProvisioningOptions} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Settings</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {hasSettings ? (
                    <div>
                      {settingVisibility && <div>{settingVisibility}</div>}
                      {settingClassify && <div>{settingClassify}</div>}
                      {settingExpire && <div>{settingExpire}</div>}
                      {settingLanguage && <div>{settingLanguage}</div>}
                      {settingData && <div>{settingData}</div>}
                    </div>
                  ) : (
                    <div className="app-itemcard-info">
                      {StringUtil.NoEmptyString('')}
                    </div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.value) ? (
                    <List>
                      {dataEntity.value.map((weblink, index) => (
                        <List.Item key={index}>
                          <div className="app-itemcard-website">
                            <Icon
                              name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                              className="app-listitem-icon"
                            />
                            <a
                              href={weblink.uri}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {weblink.providerName}
                            </a>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                  {systemDate && <div>{systemDate}</div>}
                  {renewedDate && <div>{renewedDate}</div>}
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
GroupCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

GroupCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default GroupCardForm;
