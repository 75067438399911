import * as Actions from '../../../actions/types';
import * as ObjectConfig from '../../../../config/entities/Objects';

const initialState = {};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId } = payload;
    const entity = state[entityId];
    const updated = {
      ...entity,
      didInvalidate: true
    };
    return {
      ...state,
      [entityId]: updated
    };
  }
  return state;
}

function setFetching(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId } = payload;
    const entity = state[entityId];
    const updated = {
      ...entity,
      isFetching: true,
      didInvalidate: false
    };
    return {
      ...state,
      [entityId]: updated
    };
  }
  return state;
}

function upsertEntity(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId, entity, receivedAt } = payload;
    const entitycontainer = {
      id: entityId,
      entity: entity,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
    return {
      ...state,
      [entityId]: entitycontainer
    };
  }
  return state;
}

function upsertEntities(state, action) {
  const { payload } = action;
  if (payload && payload.entities) {
    const { entities, receivedAt } = payload;
    let newState = { ...state };
    entities.forEach(entity => {
      const entitycontainer = {
        id: entity.id,
        entity: entity,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: receivedAt
      };
      newState = {
        ...newState,
        [entity.id]: entitycontainer
      };
    });
    return newState;
  }
  return state;
}

function upsertEntitiesFromGraph(state, action) {
  const { payload } = action;
  if (payload && payload.queryParams) {
    const objectType = payload.queryParams.objectType;
    if (objectType === ObjectConfig.BOOKINGSERVICE.code) {
      const newAction = {
        payload: {
          entities: payload.entities,
          receivedAt: payload.receivedAt
        }
      };
      const newState = upsertEntities(state, newAction);
      return newState;
    }
  }
  return state;
}

function upsertEntitiesFromPathfinder(state, action) {
  const { payload } = action;
  if (payload && payload.queryParams) {
    const objectType = payload.queryParams.objectType;
    if (objectType === ObjectConfig.BOOKINGSERVICE.code) {
      const newAction = {
        payload: {
          entities: payload.entities,
          receivedAt: payload.receivedAt
        }
      };
      const newState = upsertEntities(state, newAction);
      return newState;
    }
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.BOOKINGSERVICE_INVALIDATE:
      return setInvalidate(state, action);
    case Actions.BOOKINGSERVICE_REQUEST:
      return setFetching(state, action);
    case Actions.BOOKINGSERVICE_RECEIVE:
      return upsertEntity(state, action);
    case Actions.BOOKINGSERVICES_RECEIVE:
    case Actions.BOOKINGSERVICES_APPEND:
      return upsertEntities(state, action);
    case Actions.GRAPH_QUERY_RECEIVE:
    case Actions.GRAPH_QUERY_APPEND:
      return upsertEntitiesFromGraph(state, action);
    case Actions.PATHFINDER_QUERY_RECEIVE:
    case Actions.PATHFINDER_QUERY_APPEND:
      return upsertEntitiesFromPathfinder(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getEntityById = (state, id) => {
  return state[id];
};
