import moment from 'moment';
import * as Actions from '../types';
import * as Selectors from '../../reducers/rootReducer';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as ObjectConfig from '../../../config/entities/Objects';
import * as NavigationUtils from '../../../../common/utils/NavigationUtils';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// ORGANIZATION
// - invalidateOrganization
// - loadOrganization
// - receiveEntity
// - receiveEntities
//-------------------------
// NO Data Extension
//-------------------------

export const invalidateOrganization = entityId => {
  return {
    type: Actions.ORGANIZATION_INVALIDATE,
    payload: {
      entityId: entityId
    }
  };
};

export const loadOrganization = (auth, id, parent = {}, segment = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchEntity(getState(), id)) {
    // Fetch the entity from data api
    return dispatch(fetchOrganization(auth, id, parent, segment));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const receiveEntity = data => {
  const entityList = !Validation.isEmpty(data) ? data.value : [];
  const entity = entityList && entityList.length > 0 ? data.value[0] : {};
  return {
    type: Actions.ORGANIZATION_RECEIVE,
    payload: {
      entityId: !Validation.isEmpty(entity) ? entity.id : '',
      entity: entity,
      receivedAt: Date.now()
    }
  };
};

export const receiveEntities = entityList => {
  let entityIds = [];
  let entities = [];
  entityList.forEach(entity => {
    if (entityIds.indexOf(entity.id) === -1) {
      entityIds = entityIds.concat(entity.id);
      entities = entities.concat(entity);
    }
  });
  return {
    type: Actions.ORGANIZATIONS_RECEIVE,
    payload: {
      entityIds: entityIds,
      entities: entities,
      receivedAt: Date.now()
    }
  };
};

//-------------------------
// Helpers
//-------------------------

const requestEntity = entityId => {
  return {
    type: Actions.ORGANIZATION_REQUEST,
    payload: {
      entityId: entityId
    }
  };
};

const receiveError = err => {
  if (err.statusCode === 404) {
    return {
      type: Actions.ORGANIZATION_RECEIVE,
      payload: {
        entityId: '',
        entity: {},
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const shouldFetchEntity = (state, id) => {
  const entityContainer = Selectors.getOrganizationEntityById(state, id);
  const staleDate = moment().subtract(10, 'minutes');

  if (entityContainer) {
    if (!entityContainer.lastUpdated) {
      return true;
    } else if (
      entityContainer.lastUpdated &&
      entityContainer.lastUpdated < staleDate
    ) {
      return true;
    } else if (entityContainer.didInvalidate) {
      return true;
    } else if (entityContainer.isFetching) {
      return false;
    } else return false;
  } else return true;
};

const fetchOrganization = (auth, id, parent, segment) => dispatch => {
  dispatch(requestEntity(id));

  const type = ObjectConfig.ORGANIZATION.code;
  const queryUrl = NavigationUtils.CreateNodeQuery(type, id, parent, segment);
  console.log('API Path: ' + queryUrl);

  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(queryUrl)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => dispatch(receiveEntity(res)))
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};
