import * as Validation from './Validation';
import * as ObjectConfig from '../../app/config/entities/Objects';

export const getPathPositionOfDataSet = (PathSteps, DataParams) => {
  let position = -1;
  if (!Validation.isEmpty(PathSteps) && !Validation.isEmpty(DataParams)) {
    PathSteps.forEach((entity, index) => {
      const step = entity.step;
      const header = step ? step.header : {};
      const context = header ? header.context : {};
      const query = step ? step.query : {};
      if (
        context.segmentType === DataParams.segmentType ||
        (context.segmentType === ObjectConfig.USER.code &&
          context.segmentType === ObjectConfig.ME.code) ||
        (context.segmentType === ObjectConfig.ME.code &&
          context.segmentType === ObjectConfig.USER.code)
      ) {
        if (
          context.segmentId === DataParams.segmentId &&
          context.parentType === DataParams.parentType &&
          context.segmentId === DataParams.segmentId &&
          header.entityType === DataParams.entityType &&
          header.entityId === DataParams.entityId &&
          query.relationType === DataParams.relationType
        ) {
          position = index + 1;
        }
      }
    });
  }
  return position;
};
