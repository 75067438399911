import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ManagerActions from '../../../../app/store/actions/dashboard/managerActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import * as Validation from '../../../../common/utils/Validation';
import BaseComponent from '../../../components/user/User.StickerList';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  const managerContainer = Selectors.getMyManager(state);
  if (!Validation.isEmpty(managerContainer)) {
    const userContainer = Selectors.getUserEntityById(
      state,
      managerContainer.userId
    );
    const userList = !Validation.isEmpty(userContainer) ? [userContainer] : [];
    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataEntities: userList,
      dataLoading: managerContainer.isFetching,
      dataMoreAvailable: false,
      dataMoreUrl: ''
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataEntities: [],
    dataLoading: false,
    dataMoreAvailable: false,
    dataMoreUrl: ''
  };
};

// Entity is Segment & ME
// > NO Segment, Parent or ID information needed to load the correct Entity (loadUsers)
const mapDispatchToProps = dispatch => ({
  loadUsers: auth => dispatch(ManagerActions.loadUser(auth)),
  invalidateUsers: () => dispatch(ManagerActions.invalidateUser()),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
