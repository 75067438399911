import axios from 'axios';
import * as AdminAuthService from '../../common/auth/AdminAuthService';

//-------------------------
// ADMIN ACCOUNT Data Service
// - GetAll
// - GetByCompany
// - GetByStatus
// - GetById
// - GetByExternalId
// - Add
// - Sync
// - Update
//-------------------------
// - Channel Add
// - Channel Update
// - Channel Delete
//-------------------------

export const GetAll = () => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/accounts`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByCompany = companyId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/accounts/company/${companyId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByStatus = status => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/accounts/status/${status}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetById = accountId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/accounts/${accountId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByExternalId = externalId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/accounts/extern/${externalId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Add = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/accounts`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Sync = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/accounts/sync`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Update = (accountId, payload) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .put(`/api/accounts/${accountId}`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const ChannelAdd = (accountId, payload) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/accounts/${accountId}/channel`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const ChannelUpdate = (accountId, channelId, payload) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .put(`/api/accounts/${accountId}/channel/${channelId}`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const ChannelDelete = (accountId, channelId) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .delete(`/api/accounts/${accountId}/channel/${channelId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};
