import * as AuthDataService from '../../data/admin/AuthDataService';

//-------------------------
// ADMIN AUTH Service
// - IsAuthenticated
// - GetAccessToken
// - ClearAccessToken
//-------------------------

export const IsAuthenticated = () => {
  return AuthDataService.TokenIsValid();
};

export const GetAccessToken = () => {
  return new Promise(function(resolve, reject) {
    if (IsAuthenticated()) {
      const storedToken = AuthDataService.GetToken();
      resolve(storedToken);
    } else {
      AuthDataService.Connect()
        .then(newToken => resolve(newToken))
        .catch(err => reject('Error acquiring token: ' + err));
    }
  });
};

export const ClearAccessToken = () => {
  AuthDataService.Disconnect();
};
