import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Grid, GridColumn, List, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class ContactCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Company
    let companyString = dataEntity.companyName;
    if (!Validation.isEmpty(dataEntity.officeLocation)) {
      companyString = !Validation.isEmpty(companyString)
        ? companyString + ', ' + dataEntity.officeLocation
        : dataEntity.officeLocation;
    }
    let departmentString = dataEntity.jobTitle;
    if (!Validation.isEmpty(dataEntity.department)) {
      departmentString = !Validation.isEmpty(departmentString)
        ? departmentString + ', ' + dataEntity.department
        : dataEntity.department;
    }
    const professionString = dataEntity.profession;

    // Field Team
    const managerString = !Validation.isEmpty(dataEntity.manager)
      ? 'Manager: ' + dataEntity.manager
      : '';
    const assistantString = !Validation.isEmpty(dataEntity.assistantName)
      ? 'Assistant: ' + dataEntity.assistantName
      : '';

    // Field Personal
    const titleString = !Validation.isEmpty(dataEntity.title)
      ? 'Title: ' + dataEntity.title
      : '';
    const birthdayString = Validation.isValidDate(dataEntity.birthday)
      ? 'Birthday: ' + moment(dataEntity.birthday).format('D. MMMM')
      : '';
    const genderString = !Validation.isEmpty(dataEntity.gender)
      ? 'Gender: ' + dataEntity.gender
      : '';
    const generationString = !Validation.isEmpty(dataEntity.generation)
      ? 'Generation: ' + dataEntity.generation
      : '';
    const spouseString = !Validation.isEmpty(dataEntity.spouseName)
      ? 'Partner: ' + dataEntity.spouseName
      : '';

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );
    const systemCreated = Validation.isValidDate(dataEntity.createdDateTime)
      ? 'Created: ' + DateUtil.formatMsGraphDateTime(dataEntity.createdDateTime)
      : '';
    const systemModified = Validation.isValidDate(
      dataEntity.lastModifiedDateTime
    )
      ? 'Modified: ' +
        DateUtil.formatMsGraphDateTime(dataEntity.lastModifiedDateTime)
      : '';

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Company</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(companyString)}</div>
                  {departmentString && <div>{departmentString}</div>}
                  {professionString && <div>{professionString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Address</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {FieldUtil.HasValidPhysicalAddress(
                    dataEntity.postalAddresses
                  ) ? (
                    <List>
                      {dataEntity.postalAddresses.map(
                        (postalAddress, index) => (
                          <div key={index}>
                            {FieldUtil.IsValidPhysicalAddress(
                              postalAddress
                            ) && (
                              <List.Item key={index}>
                                <div className="app-itemcard-info">
                                  <div>
                                    {FieldUtil.GetStreetLineFromPhysicalAddress(
                                      postalAddress
                                    )}
                                  </div>
                                  <div>
                                    {FieldUtil.GetCityLineFromPhysicalAddress(
                                      postalAddress
                                    )}
                                  </div>
                                  <div>
                                    {FieldUtil.GetCountryLineFromPhysicalAddress(
                                      postalAddress
                                    )}
                                  </div>
                                </div>
                              </List.Item>
                            )}
                          </div>
                        )
                      )}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>E-Mail</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {FieldUtil.HasValidEmailAddress(dataEntity.emailAddresses) ? (
                    <List>
                      {dataEntity.emailAddresses.map((emailAddress, index) => (
                        <div key={index}>
                          {FieldUtil.IsValidEmailAddress(emailAddress) && (
                            <List.Item key={index}>
                              <div className="app-itemcard-email">
                                <Icon
                                  name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                                  className="app-listitem-icon"
                                />
                                <a
                                  href={
                                    'mailto:' +
                                    FieldUtil.GetEmailFromEmailAddress(
                                      emailAddress
                                    )
                                  }
                                >
                                  {FieldUtil.GetEmailFromEmailAddress(
                                    emailAddress
                                  )}
                                </a>
                              </div>
                            </List.Item>
                          )}
                        </div>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Phone</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {FieldUtil.HasValidTypedPhone(dataEntity.phones) ? (
                    <List>
                      {dataEntity.phones.map((phoneNumber, index) => (
                        <div key={index}>
                          {FieldUtil.IsValidTypedPhone(phoneNumber) && (
                            <List.Item key={index}>
                              <div className="app-itemcard-info">
                                <Icon
                                  name={FieldUtil.GetIconFromTypedPhone(
                                    phoneNumber
                                  )}
                                  className="app-listitem-icon"
                                />
                                {FieldUtil.GetNumberFromTypedPhone(phoneNumber)}
                              </div>
                            </List.Item>
                          )}
                        </div>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Team</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(managerString)}</div>
                  {assistantString && <div>{assistantString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Personal</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(titleString)}</div>
                  {birthdayString && <div>{birthdayString}</div>}
                  {genderString && <div>{genderString}</div>}
                  {generationString && <div>{generationString}</div>}
                  {spouseString && <div>{spouseString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Categories</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.categories) ? (
                    <List items={dataEntity.categories} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Assigned Contacts</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.children) ? (
                    <List items={dataEntity.children} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {FieldUtil.HasValidWebsite(dataEntity.websites) ? (
                    <List>
                      {dataEntity.websites.map((website, index) => (
                        <div key={index}>
                          {FieldUtil.IsValidWebsite(website) && (
                            <List.Item key={index}>
                              <div className="app-itemcard-website">
                                <Icon
                                  name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                                  className="app-listitem-icon"
                                />
                                <a
                                  href={FieldUtil.GetAddressFromWebsite(
                                    website
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {FieldUtil.GetStringFromWebsite(website)}
                                </a>
                              </div>
                            </List.Item>
                          )}
                        </div>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                  {systemCreated && <div>{systemCreated}</div>}
                  {systemModified && <div>{systemModified}</div>}
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
ContactCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

ContactCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default ContactCardForm;
