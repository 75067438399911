import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Header, Icon, Button } from 'semantic-ui-react';
import * as AppConfig from '../../../app/config/settings/Application';
import '../Single.css';

class NotFoundPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { location } = this.props;

    return (
      <div className="app-single">
        <div className="app-content">
          <Container>
            <div className="app-single-content">
              <Header as="h1" icon textAlign="center">
                <Icon
                  name={AppConfig.SETTINGS_APP_ICONS.messageInfo}
                  circular
                />
                <Header.Content>
                  <div className="app-notfound-header">
                    404 - Page Not Found
                  </div>
                  <div className="app-notfound-subheader">
                    <Header.Subheader>
                      Sorry, but we coudn't find page
                    </Header.Subheader>
                    <Header.Subheader>{location.pathname}</Header.Subheader>
                  </div>
                </Header.Content>
              </Header>
              <div className="app-notfound-description">
                You tried to open a page not available. Please contact your
                administrator, if this problem continues.
              </div>
              <div className="app-notfound-button">
                <Button primary as={Link} to="/dashboard">
                  Return To Application
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
