import * as Actions from '../../actions/types';

const initialState = {
  app: {},
  fetching: {},
  input: []
};

//-------------------------
// Helpers
//-------------------------

function clearAppError(state) {
  return {
    ...state,
    app: {}
  };
}

function clearFetchingError(state) {
  return {
    ...state,
    fetching: {}
  };
}

function clearInputErrors(state) {
  return {
    ...state,
    input: []
  };
}

function clearError() {
  return initialState;
}

function setAppError(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      app: payload
    };
  }
  return state;
}

function setFetchingError(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      fetching: payload
    };
  }
  return state;
}

function setInputErrors(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      input: payload
    };
  }
  return state;
}

function setError(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      ...payload
    };
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.ERRORS_APP_CLEAR:
      return clearAppError();
    case Actions.ERRORS_FETCHING_CLEAR:
      return clearFetchingError();
    case Actions.ERRORS_INPUT_CLEAR:
      return clearInputErrors();
    case Actions.ERRORS_CLEAR:
      return clearError();
    case Actions.ERRORS_APP_GET:
      return setAppError(state, action);
    case Actions.ERRORS_FETCHING_GET:
      return setFetchingError(state, action);
    case Actions.ERRORS_INPUT_GET:
      return setInputErrors(state, action);
    case Actions.ERRORS_GET:
      return setError(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getError = state => {
  return state;
};

export const getAppError = state => {
  return state.app;
};

export const getFetchingError = state => {
  return state.fetching;
};

export const getInputErrors = state => {
  return state.input;
};
