// Admin
export const ADMIN_ACCOUNT_REQUEST = 'ADMIN_ACCOUNT_REQUEST';
export const ADMIN_ACCOUNT_RECEIVE = 'ADMIN_ACCOUNT_RECEIVE';
export const ADMIN_ACCOUNT_INVALIDATE = 'ADMIN_ACCOUNT_INVALIDATE';
export const ADMIN_LICENSES_REQUEST = 'ADMIN_LICENSES_REQUEST';
export const ADMIN_LICENSES_RECEIVE = 'ADMIN_LICENSES_RECEIVE';
export const ADMIN_LICENSES_INVALIDATE = 'ADMIN_LICENSES_INVALIDATE';
export const ADMIN_LOGINS_REQUEST = 'ADMIN_LOGINS_REQUEST';
export const ADMIN_LOGINS_RECEIVE = 'ADMIN_LOGINS_RECEIVE';
export const ADMIN_LOGINS_INVALIDATE = 'ADMIN_LOGINS_INVALIDATE';
export const ADMIN_STATES_REQUEST = 'ADMIN_STATES_REQUEST';
export const ADMIN_STATES_RECEIVE = 'ADMIN_STATES_RECEIVE';
export const ADMIN_STATES_INVALIDATE = 'ADMIN_STATES_INVALIDATE';

export const ADMIN_COMPANY_REQUEST = 'ADMIN_COMPANY_REQUEST';
export const ADMIN_COMPANY_RECEIVE = 'ADMIN_COMPANY_RECEIVE';
export const ADMIN_COMPANY_INVALIDATE = 'ADMIN_COMPANY_INVALIDATE';
export const ADMIN_SUBSCRIPTIONS_REQUEST = 'ADMIN_SUBSCRIPTIONS_REQUEST';
export const ADMIN_SUBSCRIPTIONS_RECEIVE = 'ADMIN_SUBSCRIPTIONS_RECEIVE';
export const ADMIN_SUBSCRIPTIONS_INVALIDATE = 'ADMIN_SUBSCRIPTIONS_INVALIDATE';

// App
export const AUTH_USER_RECEIVE = 'AUTH_USER_RECEIVE';
export const ONBOARDING_NEEDED = 'ONBOARDING_NEEDED';
export const ONBOARDING_RUNNING = 'ONBOARDING_RUNNING';
export const ONBOARDING_STEP = 'ONBOARDING_STEP';
export const ONBOARDING_FINISHED = 'ONBOARDING_FINISHED';
export const ONBOARDING_RECEIVE = 'ONBOARDING_RECEIVE';
export const ERRORS_GET = 'ERRORS_GET';
export const ERRORS_CLEAR = 'ERRORS_CLEAR';
export const ERRORS_APP_GET = 'ERRORS_APP_GET';
export const ERRORS_APP_CLEAR = 'ERRORS_APP_CLEAR';
export const ERRORS_FETCHING_GET = 'ERRORS_FETCHING_GET';
export const ERRORS_FETCHING_CLEAR = 'ERRORS_FETCHING_CLEAR';
export const ERRORS_INPUT_GET = 'ERRORS_INPUT_GET';
export const ERRORS_INPUT_CLEAR = 'ERRORS_INPUT_CLEAR';
export const CURRENTNODE_RECEIVE = 'CURRENTNODE_RECEIVE';
export const CURRENTNODE_RELATION = 'CURRENTNODE_RELATION';
export const CURRENTCONTEXT_RECEIVE = 'CURRENTCONTEXT_RECEIVE';
export const CURRENTCONTEXT_SEGMENT = 'CURRENTCONTEXT_SEGMENT';
export const CURRENTCONTEXT_PARENT = 'CURRENTCONTEXT_PARENT';

// Context
export const DIRECTREPORTS_REQUEST = 'DIRECTREPORTS_REQUEST';
export const DIRECTREPORTS_RECEIVE = 'DIRECTREPORTS_RECEIVE';
export const DIRECTREPORTS_APPEND = 'DIRECTREPORTS_APPEND';
export const DIRECTREPORTS_INVALIDATE = 'DIRECTREPORTS_INVALIDATE';
export const GRAPH_REQUEST = 'GRAPH_REQUEST';
export const GRAPH_RECEIVE = 'GRAPH_RECEIVE';
export const GRAPH_INVALIDATE = 'GRAPH_INVALIDATE';
export const GRAPH_QUERY_RECEIVE = 'GRAPH_QUERY_RECEIVE';
export const GRAPH_QUERY_APPEND = 'GRAPH_QUERY_APPEND';
export const GRAPHS_STEP_RECEIVE = 'GRAPHS_RECEIVE';
export const INITIALGROUPS_REQUEST = 'INITIALGROUPS_REQUEST';
export const INITIALGROUPS_RECEIVE = 'INITIALGROUPS_RECEIVE';
export const INITIALGROUPS_APPEND = 'INITIALGROUPS_APPEND';
export const INITIALGROUPS_INVALIDATE = 'INITIALGROUPS_INVALIDATE';
export const INITIALPLANS_REQUEST = 'INITIALPLANS_REQUEST';
export const INITIALPLANS_RECEIVE = 'INITIALPLANS_RECEIVE';
export const INITIALPLANS_APPEND = 'INITIALPLANS_APPEND';
export const INITIALPLANS_INVALIDATE = 'INITIALPLANS_INVALIDATE';
export const INITIALUSERS_REQUEST = 'INITIALUSERS_REQUEST';
export const INITIALUSERS_RECEIVE = 'INITIALUSERS_RECEIVE';
export const INITIALUSERS_APPEND = 'INITIALUSERS_APPEND';
export const INITIALUSERS_INVALIDATE = 'INITIALUSERS_INVALIDATE';
export const JOINEDGROUPS_REQUEST = 'JOINEDGROUPS_REQUEST';
export const JOINEDGROUPS_RECEIVE = 'JOINEDGROUPS_RECEIVE';
export const JOINEDGROUPS_APPEND = 'JOINEDGROUPS_APPEND';
export const JOINEDGROUPS_INVALIDATE = 'JOINEDGROUPS_INVALIDATE';
export const MANAGER_REQUEST = 'MANAGER_REQUEST';
export const MANAGER_RECEIVE = 'MANAGER_RECEIVE';
export const MANAGER_INVALIDATE = 'MANAGER_INVALIDATE';
export const ME_REQUEST = 'ME_REQUEST';
export const ME_RECEIVE = 'ME_RECEIVE';
export const ME_RECEIVE_EXTENSION = 'ME_RECEIVE_EXTENSION';
export const ME_RECEIVE_IMAGE = 'ME_RECEIVE_IMAGE';
export const ME_INVALIDATE = 'ME_INVALIDATE';
export const PATHFINDER_CLEAR = 'PATHFINDER_CLEAR';
export const PATHFINDER_PATH_STEPCURRENT = 'PATHFINDER_PATH_STEPCURRENT';
export const PATHFINDER_PATH_RECEIVE = 'PATHFINDER_PATH_RECEIVE';
export const PATHFINDER_STEP_REQUEST = 'PATHFINDER_STEP_REQUEST';
export const PATHFINDER_STEP_RECEIVE = 'PATHFINDER_STEP_RECEIVE';
export const PATHFINDER_STEP_INVALIDATE = 'PATHFINDER_STEP_INVALIDATE';
export const PATHFINDER_STEP_REMOVE = 'PATHFINDER_STEP_REMOVE';
export const PATHFINDER_QUERY_RECEIVE = 'PATHFINDER_QUERY_RECEIVE';
export const PATHFINDER_QUERY_APPEND = 'PATHFINDER_QUERY_APPEND';
export const PATHFINDERS_STEP_RECEIVE = 'PATHFINDERS_STEP_RECEIVE';
export const QUERY_REQUEST = 'QUERY_REQUEST';
export const QUERY_INVALIDATE = 'QUERY_INVALIDATE';
export const QUERY_RECEIVE = 'QUERY_RECEIVE';
export const QUERY_APPEND = 'QUERY_APPEND';
export const STATISTICS_ACTIVITIES_OPEN = 'STATISTICS_ACTIVITIES_OPEN';
export const STATISTICS_BOOKINGS_COMING = 'STATISTICS_BOOKINGS_COMING';
export const STATISTICS_EVENTS_COMING = 'STATISTICS_EVENTS_COMING';
export const STATISTICS_TASKS_OPEN = 'STATISTICS_TASKS_OPEN';
export const STATISTICS_MESSAGES_UNREAD = 'STATISTICS_MESSAGES_UNREAD';
export const TENANT_REQUEST = 'TENANT_REQUEST';
export const TENANT_RECEIVE = 'TENANT_RECEIVE';
export const TENANT_INVALIDATE = 'TENANT_INVALIDATE';

// Segments
export const GROUP_REQUEST = 'GROUP_REQUEST';
export const GROUP_RECEIVE = 'GROUP_RECEIVE';
export const GROUP_EXTENSION_RECEIVE = 'GROUP_EXTENSION_RECEIVE';
export const GROUP_INVALIDATE = 'GROUP_INVALIDATE';
export const GROUPS_RECEIVE = 'GROUPS_RECEIVE';
export const GROUPS_APPEND = 'GROUPS_APPEND';
export const ORGANIZATION_REQUEST = 'ORGANIZATION_REQUEST';
export const ORGANIZATION_RECEIVE = 'ORGANIZATION_RECEIVE';
export const ORGANIZATION_INVALIDATE = 'ORGANIZATION_INVALIDATE';
export const ORGANIZATIONS_RECEIVE = 'ORGANIZATIONS_RECEIVE';
export const ORGANIZATIONS_APPEND = 'ORGANIZATIONS_APPEND';
export const PLAN_REQUEST = 'PLAN_REQUEST';
export const PLAN_RECEIVE = 'PLAN_RECEIVE';
export const PLAN_INVALIDATE = 'PLAN_INVALIDATE';
export const PLANS_RECEIVE = 'PLANS_RECEIVE';
export const PLANS_APPEND = 'PLANS_APPEND';
export const USER_REQUEST = 'USER_REQUEST';
export const USER_RECEIVE = 'USER_RECEIVE';
export const USER_EXTENSION_RECEIVE = 'USER_EXTENSION_RECEIVE';
export const USER_INVALIDATE = 'USER_INVALIDATE';
export const USERS_RECEIVE = 'USERS_RECEIVE';
export const USERS_APPEND = 'USERS_APPEND';

// Entitiies
export const ACTIVITY_REQUEST = 'ACTIVITY_REQUEST';
export const ACTIVITY_RECEIVE = 'ACTIVITY_RECEIVE';
export const ACTIVITY_INVALIDATE = 'ACTIVITY_INVALIDATE';
export const ACTIVITIES_RECEIVE = 'ACTIVITIES_RECEIVE';
export const ACTIVITIES_APPEND = 'ACTIVITIES_APPEND';
export const EVENT_REQUEST = 'EVENT_REQUEST';
export const EVENT_RECEIVE = 'EVENT_RECEIVE';
export const EVENT_INVALIDATE = 'EVENT_INVALIDATE';
export const EVENTS_RECEIVE = 'EVENTS_RECEIVE';
export const EVENTS_APPEND = 'EVENTS_APPEND';
export const MESSAGE_REQUEST = 'MESSAGE_REQUEST';
export const MESSAGE_RECEIVE = 'MESSAGE_RECEIVE';
export const MESSAGE_INVALIDATE = 'MESSAGE_INVALIDATE';
export const MESSAGES_RECEIVE = 'MESSAGES_RECEIVE';
export const MESSAGES_APPEND = 'MESSAGES_APPEND';
export const TASK_REQUEST = 'TASK_REQUEST';
export const TASK_RECEIVE = 'TASK_RECEIVE';
export const TASK_INVALIDATE = 'TASK_INVALIDATE';
export const TASKS_RECEIVE = 'TASKS_RECEIVE';
export const TASKS_APPEND = 'TASKS_APPEND';

// Segments - NEU
export const DRIVE_REQUEST = 'DRIVE_REQUEST';
export const DRIVE_RECEIVE = 'DRIVE_RECEIVE';
export const DRIVE_INVALIDATE = 'DRIVE_INVALIDATE';
export const DRIVES_RECEIVE = 'DRIVES_RECEIVE';
export const DRIVES_APPEND = 'DRIVES_APPEND';
export const SITE_REQUEST = 'SITE_REQUEST';
export const SITE_RECEIVE = 'SITE_RECEIVE';
export const SITE_INVALIDATE = 'SITE_INVALIDATE';
export const SITES_RECEIVE = 'SITES_RECEIVE';
export const SITES_APPEND = 'SITES_APPEND';

// Entities - NEU
export const ACTIVITYFOLDER_REQUEST = 'ACTIVITYFOLDER_REQUEST';
export const ACTIVITYFOLDER_RECEIVE = 'ACTIVITYFOLDER_RECEIVE';
export const ACTIVITYFOLDER_INVALIDATE = 'ACTIVITYFOLDER_INVALIDATE';
export const ACTIVITYFOLDERS_RECEIVE = 'ACTIVITYFOLDERS_RECEIVE';
export const ACTIVITYFOLDERS_APPEND = 'ACTIVITYFOLDERS_APPEND';

export const ACTIVITYGROUP_REQUEST = 'ACTIVITYGROUP_REQUEST';
export const ACTIVITYGROUP_RECEIVE = 'ACTIVITYGROUP_RECEIVE';
export const ACTIVITYGROUP_INVALIDATE = 'ACTIVITYGROUP_INVALIDATE';
export const ACTIVITYGROUPS_RECEIVE = 'ACTIVITYGROUPS_RECEIVE';
export const ACTIVITYGROUPS_APPEND = 'ACTIVITYGROUPS_APPEND';

export const ADMINISTRATIVEUNIT_REQUEST = 'ADMINISTRATIVEUNIT_REQUEST';
export const ADMINISTRATIVEUNIT_RECEIVE = 'ADMINISTRATIVEUNIT_RECEIVE';
export const ADMINISTRATIVEUNIT_INVALIDATE = 'ADMINISTRATIVEUNIT_INVALIDATE';
export const ADMINISTRATIVEUNITS_RECEIVE = 'ADMINISTRATIVEUNITS_RECEIVE';
export const ADMINISTRATIVEUNITS_APPEND = 'ADMINISTRATIVEUNITS_APPEND';

export const AGREEMENT_REQUEST = 'AGREEMENT_REQUEST';
export const AGREEMENT_RECEIVE = 'AGREEMENT_RECEIVE';
export const AGREEMENT_INVALIDATE = 'AGREEMENT_INVALIDATE';
export const AGREEMENTS_RECEIVE = 'AGREEMENTS_RECEIVE';
export const AGREEMENTS_APPEND = 'AGREEMENTS_APPEND';

export const AGREEMENTACCEPTANCE_REQUEST = 'AGREEMENTACCEPTANCE_REQUEST';
export const AGREEMENTACCEPTANCE_RECEIVE = 'AGREEMENTACCEPTANCE_RECEIVE';
export const AGREEMENTACCEPTANCE_INVALIDATE = 'AGREEMENTACCEPTANCE_INVALIDATE';
export const AGREEMENTACCEPTANCES_RECEIVE = 'AGREEMENTACCEPTANCES_RECEIVE';
export const AGREEMENTACCEPTANCES_APPEND = 'AGREEMENTACCEPTANCES_APPEND';

export const APPLICATION_REQUEST = 'APPLICATION_REQUEST';
export const APPLICATION_RECEIVE = 'APPLICATION_RECEIVE';
export const APPLICATION_INVALIDATE = 'APPLICATION_INVALIDATE';
export const APPLICATIONS_RECEIVE = 'APPLICATIONS_RECEIVE';
export const APPLICATIONS_APPEND = 'APPLICATIONS_APPEND';

export const ATTACHMENT_REQUEST = 'ATTACHMENT_REQUEST';
export const ATTACHMENT_RECEIVE = 'ATTACHMENT_RECEIVE';
export const ATTACHMENT_INVALIDATE = 'ATTACHMENT_INVALIDATE';
export const ATTACHMENTS_RECEIVE = 'ATTACHMENTS_RECEIVE';
export const ATTACHMENTS_APPEND = 'ATTACHMENTS_APPEND';

export const BOOKINGAPPOINTMENT_REQUEST = 'BOOKINGAPPOINTMENT_REQUEST';
export const BOOKINGAPPOINTMENT_RECEIVE = 'BOOKINGAPPOINTMENT_RECEIVE';
export const BOOKINGAPPOINTMENT_INVALIDATE = 'BOOKINGAPPOINTMENT_INVALIDATE';
export const BOOKINGAPPOINTMENTS_RECEIVE = 'BOOKINGAPPOINTMENTS_RECEIVE';
export const BOOKINGAPPOINTMENTS_APPEND = 'BOOKINGAPPOINTMENTS_APPEND';

export const BOOKINGBUSINESS_REQUEST = 'BOOKINGBUSINESS_REQUEST';
export const BOOKINGBUSINESS_RECEIVE = 'BOOKINGBUSINESS_RECEIVE';
export const BOOKINGBUSINESS_INVALIDATE = 'BOOKINGBUSINESS_INVALIDATE';
export const BOOKINGBUSINESSES_RECEIVE = 'BOOKINGBUSINESSES_RECEIVE';
export const BOOKINGBUSINESSES_APPEND = 'BOOKINGBUSINESSES_APPEND';

export const BOOKINGCUSTOMER_REQUEST = 'BOOKINGCUSTOMER_REQUEST';
export const BOOKINGCUSTOMER_RECEIVE = 'BOOKINGCUSTOMER_RECEIVE';
export const BOOKINGCUSTOMER_INVALIDATE = 'BOOKINGCUSTOMER_INVALIDATE';
export const BOOKINGCUSTOMERS_RECEIVE = 'BOOKINGCUSTOMERS_RECEIVE';
export const BOOKINGCUSTOMERS_APPEND = 'BOOKINGCUSTOMERS_APPEND';

export const BOOKINGSERVICE_REQUEST = 'BOOKINGSERVICE_REQUEST';
export const BOOKINGSERVICE_RECEIVE = 'BOOKINGSERVICE_RECEIVE';
export const BOOKINGSERVICE_INVALIDATE = 'BOOKINGSERVICE_INVALIDATE';
export const BOOKINGSERVICES_RECEIVE = 'BOOKINGSERVICES_RECEIVE';
export const BOOKINGSERVICES_APPEND = 'BOOKINGSERVICES_APPEND';

export const BOOKINGSTAFFMEMBER_REQUEST = 'BOOKINGSTAFFMEMBER_REQUEST';
export const BOOKINGSTAFFMEMBER_RECEIVE = 'BOOKINGSTAFFMEMBER_RECEIVE';
export const BOOKINGSTAFFMEMBER_INVALIDATE = 'BOOKINGSTAFFMEMBER_INVALIDATE';
export const BOOKINGSTAFFMEMBERS_RECEIVE = 'BOOKINGSTAFFMEMBERS_RECEIVE';
export const BOOKINGSTAFFMEMBERS_APPEND = 'BOOKINGSTAFFMEMBERS_APPEND';

export const BUCKET_REQUEST = 'BUCKET_REQUEST';
export const BUCKET_RECEIVE = 'BUCKET_RECEIVE';
export const BUCKET_INVALIDATE = 'BUCKET_INVALIDATE';
export const BUCKETS_RECEIVE = 'BUCKETS_RECEIVE';
export const BUCKETS_APPEND = 'BUCKETS_APPEND';

export const CALENDAR_REQUEST = 'CALENDAR_REQUEST';
export const CALENDAR_RECEIVE = 'CALENDAR_RECEIVE';
export const CALENDAR_INVALIDATE = 'CALENDAR_INVALIDATE';
export const CALENDARS_RECEIVE = 'CALENDARS_RECEIVE';
export const CALENDARS_APPEND = 'CALENDARS_APPEND';

export const CALENDARGROUP_REQUEST = 'CALENDARGROUP_REQUEST';
export const CALENDARGROUP_RECEIVE = 'CALENDARGROUP_RECEIVE';
export const CALENDARGROUP_INVALIDATE = 'CALENDARGROUP_INVALIDATE';
export const CALENDARGROUPS_RECEIVE = 'CALENDARGROUPS_RECEIVE';
export const CALENDARGROUPS_APPEND = 'CALENDARGROUPS_APPEND';

export const CHANNEL_REQUEST = 'CHANNEL_REQUEST';
export const CHANNEL_RECEIVE = 'CHANNEL_RECEIVE';
export const CHANNEL_INVALIDATE = 'CHANNEL_INVALIDATE';
export const CHANNELS_RECEIVE = 'CHANNELS_RECEIVE';
export const CHANNELS_APPEND = 'CHANNELS_APPEND';

export const CHATMESSAGE_REQUEST = 'CHATMESSAGE_REQUEST';
export const CHATMESSAGE_RECEIVE = 'CHATMESSAGE_RECEIVE';
export const CHATMESSAGE_INVALIDATE = 'CHATMESSAGE_INVALIDATE';
export const CHATMESSAGES_RECEIVE = 'CHATMESSAGES_RECEIVE';
export const CHATMESSAGES_APPEND = 'CHATMESSAGES_APPEND';

export const CHATTHREAD_REQUEST = 'CHATTHREAD_REQUEST';
export const CHATTHREAD_RECEIVE = 'CHATTHREAD_RECEIVE';
export const CHATTHREAD_INVALIDATE = 'CHATTHREAD_INVALIDATE';
export const CHATTHREADS_RECEIVE = 'CHATTHREADS_RECEIVE';
export const CHATTHREADS_APPEND = 'CHATTHREADS_APPEND';

export const CONTACT_REQUEST = 'CONTACT_REQUEST';
export const CONTACT_RECEIVE = 'CONTACT_RECEIVE';
export const CONTACT_EXTENSION_RECEIVE = 'CONTACT_EXTENSION_RECEIVE';
export const CONTACT_INVALIDATE = 'CONTACT_INVALIDATE';
export const CONTACTS_RECEIVE = 'CONTACTS_RECEIVE';
export const CONTACTS_APPEND = 'CONTACTS_APPEND';

export const CONTACTFOLDER_REQUEST = 'CONTACTFOLDER_REQUEST';
export const CONTACTFOLDER_RECEIVE = 'CONTACTFOLDER_RECEIVE';
export const CONTACTFOLDER_INVALIDATE = 'CONTACTFOLDER_INVALIDATE';
export const CONTACTFOLDERS_RECEIVE = 'CONTACTFOLDERS_RECEIVE';
export const CONTACTFOLDERS_APPEND = 'CONTACTFOLDERS_APPEND';

export const CONTRACT_REQUEST = 'CONTRACT_REQUEST';
export const CONTRACT_RECEIVE = 'CONTRACT_RECEIVE';
export const CONTRACT_INVALIDATE = 'CONTRACT_INVALIDATE';
export const CONTRACTS_RECEIVE = 'CONTRACTS_RECEIVE';
export const CONTRACTS_APPEND = 'CONTRACTS_APPEND';

export const CONVERSATION_REQUEST = 'CONVERSATION_REQUEST';
export const CONVERSATION_RECEIVE = 'CONVERSATION_RECEIVE';
export const CONVERSATION_INVALIDATE = 'CONVERSATION_INVALIDATE';
export const CONVERSATIONS_RECEIVE = 'CONVERSATIONS_RECEIVE';
export const CONVERSATIONS_APPEND = 'CONVERSATIONS_APPEND';

export const DEVICE_REQUEST = 'DEVICE_REQUEST';
export const DEVICE_RECEIVE = 'DEVICE_RECEIVE';
export const DEVICE_INVALIDATE = 'DEVICE_INVALIDATE';
export const DEVICES_RECEIVE = 'DEVICES_RECEIVE';
export const DEVICES_APPEND = 'DEVICES_APPEND';

export const DIRECTORYROLE_REQUEST = 'DIRECTORYROLE_REQUEST';
export const DIRECTORYROLE_RECEIVE = 'DIRECTORYROLE_RECEIVE';
export const DIRECTORYROLE_INVALIDATE = 'DIRECTORYROLE_INVALIDATE';
export const DIRECTORYROLES_RECEIVE = 'DIRECTORYROLES_RECEIVE';
export const DIRECTORYROLES_APPEND = 'DIRECTORYROLES_APPEND';

export const DOMAIN_REQUEST = 'DOMAIN_REQUEST';
export const DOMAIN_RECEIVE = 'DOMAIN_RECEIVE';
export const DOMAIN_INVALIDATE = 'DOMAIN_INVALIDATE';
export const DOMAINS_RECEIVE = 'DOMAINS_RECEIVE';
export const DOMAINS_APPEND = 'DOMAINS_APPEND';

export const DRIVEITEM_REQUEST = 'DRIVEITEM_REQUEST';
export const DRIVEITEM_RECEIVE = 'DRIVEITEM_RECEIVE';
export const DRIVEITEM_INVALIDATE = 'DRIVEITEM_INVALIDATE';
export const DRIVEITEMS_RECEIVE = 'DRIVEITEMS_RECEIVE';
export const DRIVEITEMS_APPEND = 'DRIVEITEMS_APPEND';

export const IDENTITYPROVIDER_REQUEST = 'IDENTITYPROVIDER_REQUEST';
export const IDENTITYPROVIDER_RECEIVE = 'IDENTITYPROVIDER_RECEIVE';
export const IDENTITYPROVIDER_INVALIDATE = 'IDENTITYPROVIDER_INVALIDATE';
export const IDENTITYPROVIDERS_RECEIVE = 'IDENTITYPROVIDERS_RECEIVE';
export const IDENTITYPROVIDERS_APPEND = 'IDENTITYPROVIDERS_APPEND';

export const INSIGHT_REQUEST = 'INSIGHT_REQUEST';
export const INSIGHT_RECEIVE = 'INSIGHT_RECEIVE';
export const INSIGHT_INVALIDATE = 'INSIGHT_INVALIDATE';
export const INSIGHTS_RECEIVE = 'INSIGHTS_RECEIVE';
export const INSIGHTS_APPEND = 'INSIGHTS_APPEND';

export const LICENSE_REQUEST = 'LICENSE_REQUEST';
export const LICENSE_RECEIVE = 'LICENSE_RECEIVE';
export const LICENSE_INVALIDATE = 'LICENSE_INVALIDATE';
export const LICENSES_RECEIVE = 'LICENSES_RECEIVE';
export const LICENSES_APPEND = 'LICENSES_APPEND';

export const LIST_REQUEST = 'LIST_REQUEST';
export const LIST_RECEIVE = 'LIST_RECEIVE';
export const LIST_INVALIDATE = 'LIST_INVALIDATE';
export const LISTS_RECEIVE = 'LISTS_RECEIVE';
export const LISTS_APPEND = 'LISTS_APPEND';

export const LISTITEM_REQUEST = 'LISTITEM_REQUEST';
export const LISTITEM_RECEIVE = 'LISTITEM_RECEIVE';
export const LISTITEM_INVALIDATE = 'LISTITEM_INVALIDATE';
export const LISTITEMS_RECEIVE = 'LISTITEMS_RECEIVE';
export const LISTITEMS_APPEND = 'LISTITEMS_APPEND';

export const MAILFOLDER_REQUEST = 'MAILFOLDER_REQUEST';
export const MAILFOLDER_RECEIVE = 'MAILFOLDER_RECEIVE';
export const MAILFOLDER_INVALIDATE = 'MAILFOLDER_INVALIDATE';
export const MAILFOLDERS_RECEIVE = 'MAILFOLDERS_RECEIVE';
export const MAILFOLDERS_APPEND = 'MAILFOLDERS_APPEND';

export const NOTEBOOK_REQUEST = 'NOTEBOOK_REQUEST';
export const NOTEBOOK_RECEIVE = 'NOTEBOOK_RECEIVE';
export const NOTEBOOK_INVALIDATE = 'NOTEBOOK_INVALIDATE';
export const NOTEBOOKS_RECEIVE = 'NOTEBOOKS_RECEIVE';
export const NOTEBOOKS_APPEND = 'NOTEBOOKS_APPEND';

export const ORGCONTACT_REQUEST = 'ORGCONTACT_REQUEST';
export const ORGCONTACT_RECEIVE = 'ORGCONTACT_RECEIVE';
export const ORGCONTACT_INVALIDATE = 'ORGCONTACT_INVALIDATE';
export const ORGCONTACTS_RECEIVE = 'ORGCONTACTS_RECEIVE';
export const ORGCONTACTS_APPEND = 'ORGCONTACTS_APPEND';

export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_RECEIVE = 'PAGE_RECEIVE';
export const PAGE_INVALIDATE = 'PAGE_INVALIDATE';
export const PAGES_RECEIVE = 'PAGES_RECEIVE';
export const PAGES_APPEND = 'PAGES_APPEND';

export const PERSON_REQUEST = 'PERSON_REQUEST';
export const PERSON_RECEIVE = 'PERSON_RECEIVE';
export const PERSON_INVALIDATE = 'PERSON_INVALIDATE';
export const PERSONS_RECEIVE = 'PERSONS_RECEIVE';
export const PERSONS_APPEND = 'PERSONS_APPEND';

export const POST_REQUEST = 'POST_REQUEST';
export const POST_RECEIVE = 'POST_RECEIVE';
export const POST_INVALIDATE = 'POST_INVALIDATE';
export const POSTS_RECEIVE = 'POSTS_RECEIVE';
export const POSTS_APPEND = 'POSTS_APPEND';

export const RISKEVENT_REQUEST = 'RISKEVENT_REQUEST';
export const RISKEVENT_RECEIVE = 'RISKEVENT_RECEIVE';
export const RISKEVENT_INVALIDATE = 'RISKEVENT_INVALIDATE';
export const RISKEVENTS_RECEIVE = 'RISKEVENTS_RECEIVE';
export const RISKEVENTS_APPEND = 'RISKEVENTS_APPEND';

export const SECURITYALERT_REQUEST = 'SECURITYALERT_REQUEST';
export const SECURITYALERT_RECEIVE = 'SECURITYALERT_RECEIVE';
export const SECURITYALERT_INVALIDATE = 'SECURITYALERT_INVALIDATE';
export const SECURITYALERTS_RECEIVE = 'SECURITYALERTS_RECEIVE';
export const SECURITYALERTS_APPEND = 'SECURITYALERTS_APPEND';

export const SERVICE_REQUEST = 'SERVICE_REQUEST';
export const SERVICE_RECEIVE = 'SERVICE_RECEIVE';
export const SERVICE_INVALIDATE = 'SERVICE_INVALIDATE';
export const SERVICES_RECEIVE = 'SERVICES_RECEIVE';
export const SERVICES_APPEND = 'SERVICES_APPEND';

export const SERVICEPLAN_REQUEST = 'SERVICEPLAN_REQUEST';
export const SERVICEPLAN_RECEIVE = 'SERVICEPLAN_RECEIVE';
export const SERVICEPLAN_INVALIDATE = 'SERVICEPLAN_INVALIDATE';
export const SERVICEPLANS_RECEIVE = 'SERVICEPLANS_RECEIVE';
export const SERVICEPLANS_APPEND = 'SERVICEPLANS_APPEND';

export const SERVICEPRINCIPAL_REQUEST = 'SERVICEPRINCIPAL_REQUEST';
export const SERVICEPRINCIPAL_RECEIVE = 'SERVICEPRINCIPAL_RECEIVE';
export const SERVICEPRINCIPAL_INVALIDATE = 'SERVICEPRINCIPAL_INVALIDATE';
export const SERVICEPRINCIPALS_RECEIVE = 'SERVICEPRINCIPALS_RECEIVE';
export const SERVICEPRINCIPALS_APPEND = 'SERVICEPRINCIPALS_APPEND';

export const TEAM_REQUEST = 'TEAM_REQUEST';
export const TEAM_RECEIVE = 'TEAM_RECEIVE';
export const TEAM_INVALIDATE = 'TEAM_INVALIDATE';

export const TEAMSAPPINSTALLATION_REQUEST = 'TEAMSAPPINSTALLATION_REQUEST';
export const TEAMSAPPINSTALLATION_RECEIVE = 'TEAMSAPPINSTALLATION_RECEIVE';
export const TEAMSAPPINSTALLATION_INVALIDATE =
  'TEAMSAPPINSTALLATION_INVALIDATE';
export const TEAMSAPPINSTALLATIONS_RECEIVE = 'TEAMSAPPINSTALLATIONS_RECEIVE';
export const TEAMSAPPINSTALLATIONS_APPEND = 'TEAMSAPPINSTALLATIONS_APPEND';

export const TEAMSTAB_REQUEST = 'TEAMSTAB_REQUEST';
export const TEAMSTAB_RECEIVE = 'TEAMSTAB_RECEIVE';
export const TEAMSTAB_INVALIDATE = 'TEAMSTAB_INVALIDATE';
export const TEAMSTABS_RECEIVE = 'TEAMSTABS_RECEIVE';
export const TEAMSTABS_APPEND = 'TEAMSTABS_APPEND';

export const THREAD_REQUEST = 'THREAD_REQUEST';
export const THREAD_RECEIVE = 'THREAD_RECEIVE';
export const THREAD_INVALIDATE = 'THREAD_INVALIDATE';
export const THREADS_RECEIVE = 'THREADS_RECEIVE';
export const THREADS_APPEND = 'THREADS_APPEND';

export const VERSION_REQUEST = 'VERSION_REQUEST';
export const VERSION_RECEIVE = 'VERSION_RECEIVE';
export const VERSION_INVALIDATE = 'VERSION_INVALIDATE';
export const VERSIONS_RECEIVE = 'VERSIONS_RECEIVE';
export const VERSIONS_APPEND = 'VERSIONS_APPEND';
