import * as Actions from '../../../actions/types';

const initialState = {};

//-------------------------
// Helpers
//-------------------------

function upsertEntity(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId, entity, receivedAt } = payload;
    const entitycontainer = {
      id: entityId,
      entity: entity,
      lastUpdated: receivedAt
    };
    return {
      ...state,
      [entityId]: entitycontainer
    };
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.CONTACT_EXTENSION_RECEIVE:
      return upsertEntity(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getExtensionById = (state, id) => {
  return state[id];
};
