import React from 'react';
import PropTypes from 'prop-types';
import { Label, Image } from 'semantic-ui-react';
import DefaultImage from '../../../app/assets/images/defaults/img-default-user.png';
import * as Validation from '../../utils/Validation';

class NodeSticker extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleNodeClick = (e, key, url) => {
    e.preventDefault();
    this.props.onStickerClick(key, url);
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { itemKey, itemName, itemImage, externalUrl } = this.props;

    if (Validation.isEmpty(itemKey) || Validation.isEmpty(itemName)) {
      return (
        <Label basic image className="app-personas-item">
          {Validation.isEmpty(itemImage) && (
            <Image src={DefaultImage} alt="Default" />
          )}
          {!Validation.isEmpty(itemImage) && (
            <Image src={itemImage} alt={itemName} />
          )}
          {'--'}
        </Label>
      );
    }

    return (
      <Label
        as="a"
        basic
        image
        onClick={e => this.handleNodeClick(e, itemKey, externalUrl)}
        className="app-personas-item"
      >
        {Validation.isEmpty(itemImage) && (
          <Image src={DefaultImage} alt="Default" />
        )}
        {!Validation.isEmpty(itemImage) && (
          <Image src={itemImage} alt={itemName} />
        )}
        {itemName}
      </Label>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------

NodeSticker.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  itemKey: PropTypes.string,
  itemName: PropTypes.string,
  itemImage: PropTypes.string,
  externalUrl: PropTypes.string,
  onStickerClick: PropTypes.func.isRequired
};

export default NodeSticker;
