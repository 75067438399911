import React from 'react';
import LandingPageAnonym from './Landing.Anonym';
import LandingPageLoggedin from './Landing.Loggedin.Container';
import '../Single.css';

class LandingPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { auth, location, match, params } = this.props;
    const authenticated = auth.authenticated;

    return (
      <div className="app-homepage">
        {authenticated && (
          <LandingPageLoggedin
            auth={auth}
            match={match}
            location={location}
            params={params}
          />
        )}
        {!authenticated && (
          <LandingPageAnonym
            auth={auth}
            match={match}
            location={location}
            params={params}
          />
        )}
      </div>
    );
  }
}

export default LandingPage;
