import * as Actions from '../../actions/types';

const initialState = {
  groups: [],
  moreAvailable: false,
  moreUrl: '',
  isFetching: false,
  didInvalidate: false
};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state) {
  return {
    ...state,
    didInvalidate: true
  };
}

function setFetching(state) {
  return {
    ...state,
    isFetching: true,
    didInvalidate: false
  };
}

function setJoinedGroups(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds) {
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;

    return {
      ...state,
      groups: entityIds,
      moreAvailable: moreAvailable,
      moreUrl: moreUrl,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    groups: [],
    moreAvailable: false,
    moreUrl: '',
    isFetching: false,
    didInvalidate: false
  };
}

function appendJoinedGroups(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds) {
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;
    const newGroups =
      state && state.groups ? state.groups.concat(entityIds) : entityIds;
    return {
      ...state,
      groups: newGroups,
      moreAvailable: moreAvailable,
      moreUrl: moreUrl,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    moreAvailable: false,
    moreUrl: '',
    isFetching: false,
    didInvalidate: false
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.JOINEDGROUPS_INVALIDATE:
      return setInvalidate(state);
    case Actions.JOINEDGROUPS_REQUEST:
      return setFetching(state);
    case Actions.JOINEDGROUPS_RECEIVE:
      return setJoinedGroups(state, action);
    case Actions.JOINEDGROUPS_APPEND:
      return appendJoinedGroups(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getMyJoinedGroups = state => {
  return state;
};
