import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid } from 'semantic-ui-react';
import { Popup, Icon } from 'semantic-ui-react';
import DataLoader from '../../../../common/components/controls/DataLoader';
import * as ApplicationConfig from '../../../../app/config/constants/Application';
import * as ObjectConfig from '../../../../app/config/entities/Objects';
import * as MenuConfig from '../../../../app/config/constants/Menu';

class TenantCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { accordionIndex: -1 };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    this.props.loadOrganization(this.props.auth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleOrganizationClick = (e, key, url) => {
    e.preventDefault();
    this.props.setCurrentContext(ObjectConfig.ORGANIZATION.code, key);
    this.props.setCurrentNode(
      this.props.area,
      ObjectConfig.ORGANIZATION.code,
      key,
      url
    );
    this.props.history.push(`${this.props.match.url}/node`);
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    //const { auth, location, match, params } = this.props;
    const { dataEntity, dataLoading } = this.props;
    const { showObjectLink } = this.props;

    if (dataLoading) {
      return <DataLoader />;
    }

    return (
      <div className="app-itemcard-top">
        <Segment basic>
          <Grid>
            <Grid.Column width={16}>
              {showObjectLink ? (
                <div>
                  <div
                    onClick={e =>
                      this.handleOrganizationClick(e, dataEntity.id, '')
                    }
                    className="app-itemcard-top-titlelink app-itemcard-header-title"
                  >
                    {dataEntity.displayName}
                  </div>
                  <div className="app-itemcard-header-icon">
                    <Popup
                      trigger={
                        <Icon
                          link
                          circular
                          color={ApplicationConfig.COLOR_CODES.grey}
                          name={MenuConfig.ICONS_MENU_DEFAULTS.view}
                          onClick={e =>
                            this.handleOrganizationClick(e, dataEntity.id, '')
                          }
                        />
                      }
                      content="Open in Navigator"
                    />
                  </div>
                </div>
              ) : (
                <div className="app-itemcard-top-title">
                  {dataEntity.displayName}
                </div>
              )}
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
TenantCard.defaultProps = {
  area: ApplicationConfig.NAVIGATION_AREA.dashboard,
  smallContainer: true,
  showExtended: false,
  showObjectLink: false
};

TenantCard.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  area: PropTypes.string,
  smallContainer: PropTypes.bool,
  labelContext: PropTypes.string,
  showExtended: PropTypes.bool,
  showObjectLink: PropTypes.bool,
  loadOrganization: PropTypes.func.isRequired,
  invalidateOrganization: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired
};

export default TenantCard;
