import * as Actions from '../../actions/types';

const initialState = {
  needed: false,
  finished: false,
  step: 0
};

//-------------------------
// Helpers
//-------------------------

function setNeeded(state, action) {
  const { payload } = action;
  const needed = payload ? payload.needed : true;
  if (needed) {
    return {
      ...state,
      needed: true
    };
  }
  return initialState;
}

function setRunning(state, action) {
  const { payload } = action;
  const step = payload ? payload.step : 0;
  return {
    ...state,
    needed: true,
    finished: false,
    step: step
  };
}

function setStep(state, action) {
  const { payload } = action;
  const step = payload ? payload.step : 0;
  return {
    ...state,
    needed: true,
    finished: false,
    step: step
  };
}

function setFinished(state) {
  return {
    ...state,
    needed: true,
    finished: true,
    step: 10
  };
}

function setOnboarding(state, action) {
  const { payload } = action;
  if (payload) {
    const { needed, finished, step } = payload;
    return {
      ...state,
      needed: needed,
      finished: finished,
      step: step
    };
  }
  return initialState;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.ONBOARDING_NEEDED:
      return setNeeded(state, action);
    case Actions.ONBOARDING_RUNNING:
      return setRunning(state, action);
    case Actions.ONBOARDING_STEP:
      return setStep(state, action);
    case Actions.ONBOARDING_FINISHED:
      return setFinished(state);
    case Actions.ONBOARDING_RECEIVE:
      return setOnboarding(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getOnboarding = state => {
  return state;
};
