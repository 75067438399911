import { combineReducers } from 'redux';
import nodeReducer, * as nodeSelectors from './current/nodeReducer';
import contextReducer, * as contextSelectors from './current/contextReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  node: nodeReducer,
  context: contextReducer
});

//-------------------------
// Selectors
//-------------------------

export const getCurrentNode = state => {
  return nodeSelectors.getCurrentNode(state.node);
};

export const getCurrentContext = state => {
  return contextSelectors.getCurrentContext(state.context);
};
