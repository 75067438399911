import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, GridColumn } from 'semantic-ui-react';
import { List, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class ActivityCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Start Date
    const startDate = FieldUtil.GetStringFromDateTimeTimezone(
      dataEntity.startDateTime
    );

    // Field Due Date
    const dueDate = FieldUtil.GetStringFromDateTimeTimezone(
      dataEntity.dueDateTime
    );

    // Field Completed Date
    const completedDate = FieldUtil.GetStringFromDateTimeTimezone(
      dataEntity.completedDateTime
    );

    // Field Settings
    const hasSettings =
      dataEntity.importance !== 'normal' ||
      dataEntity.hasAttachments ||
      dataEntity.isReminderOn;
    let reminderSetting = '';
    if (dataEntity.isReminderOn) {
      const reminderDate = FieldUtil.GetStringFromDateTimeTimezone(
        dataEntity.reminderDateTime
      );
      reminderSetting = 'Reminder set on ' + reminderDate;
    }

    // Field Links
    const linksWeb = !Validation.isEmpty(dataEntity.todoUrl)
      ? dataEntity.todoUrl
      : '';

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );
    const systemCreated = Validation.isValidDate(dataEntity.createdDateTime)
      ? 'Created: ' + DateUtil.formatMsGraphDateTime(dataEntity.createdDateTime)
      : '';
    const systemModified = Validation.isValidDate(
      dataEntity.lastModifiedDateTime
    )
      ? 'Modified: ' +
        DateUtil.formatMsGraphDateTime(dataEntity.lastModifiedDateTime)
      : '';

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Subject</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(dataEntity.subject)}</div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Assigned To</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(dataEntity.assignedTo)}</div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Owner</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(dataEntity.owner)}</div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Start</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(startDate) ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.date}
                        className="app-listitem-icon"
                      />
                      {startDate}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Due Date</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dueDate) ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.date}
                        className="app-listitem-icon"
                      />
                      {dueDate}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Completed Date</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(completedDate) ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.date}
                        className="app-listitem-icon"
                      />
                      {completedDate}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Sensitivity</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(dataEntity.sensitivity)}</div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Settings</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {hasSettings ? (
                    <div>
                      {dataEntity.importance === 'high' && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.priorityhigh}
                            color="red"
                            className="app-listitem-icon"
                          />
                          High Priority
                        </div>
                      )}
                      {dataEntity.importance === 'low' && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.prioritylow}
                            color="blue"
                            className="app-listitem-icon"
                          />
                          Low Priority
                        </div>
                      )}
                      {dataEntity.hasAttachments && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.attachment}
                            className="app-listitem-icon"
                          />
                          has Attachments
                        </div>
                      )}
                      {dataEntity.isReminderOn && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.alarm}
                            className="app-listitem-icon"
                          />
                          {reminderSetting}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="app-itemcard-info">
                      {StringUtil.NoEmptyString('')}
                    </div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Categories</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.categories) ? (
                    <List items={dataEntity.categories} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-website">
                  {linksWeb ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksWeb}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Weblink
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                  {systemCreated && <div>{systemCreated}</div>}
                  {systemModified && <div>{systemModified}</div>}
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
ActivityCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

ActivityCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default ActivityCardForm;
