import * as MenuConfig from '../../app/config/constants/Menu';
import * as Validation from './Validation';
import * as DateUtil from './DateUtils';

//-------------------------------
// DateTimeTimezone
//-------------------------------

export const HasMondayBookingWorkHours = BookingWorkHours => {
  let result = false;
  if (!Validation.isEmpty(BookingWorkHours)) {
    BookingWorkHours.forEach(weekday => {
      if (weekday.day === 'monday') {
        if (weekday.timeSlots && weekday.timeSlots.length > 0) {
          result = true;
        }
      }
    });
  }
  return result;
};

export const HasTuesdayBookingWorkHours = BookingWorkHours => {
  let result = false;
  if (!Validation.isEmpty(BookingWorkHours)) {
    BookingWorkHours.forEach(weekday => {
      if (weekday.day === 'tuesday') {
        if (weekday.timeSlots && weekday.timeSlots.length > 0) {
          result = true;
        }
      }
    });
  }
  return result;
};

export const HasWednesdayBookingWorkHours = BookingWorkHours => {
  let result = false;
  if (!Validation.isEmpty(BookingWorkHours)) {
    BookingWorkHours.forEach(weekday => {
      if (weekday.day === 'wednesday') {
        if (weekday.timeSlots && weekday.timeSlots.length > 0) {
          result = true;
        }
      }
    });
  }
  return result;
};

export const HasThursdayBookingWorkHours = BookingWorkHours => {
  let result = false;
  if (!Validation.isEmpty(BookingWorkHours)) {
    BookingWorkHours.forEach(weekday => {
      if (weekday.day === 'thursday') {
        if (weekday.timeSlots && weekday.timeSlots.length > 0) {
          result = true;
        }
      }
    });
  }
  return result;
};

export const HasFridayBookingWorkHours = BookingWorkHours => {
  let result = false;
  if (!Validation.isEmpty(BookingWorkHours)) {
    BookingWorkHours.forEach(weekday => {
      if (weekday.day === 'friday') {
        if (weekday.timeSlots && weekday.timeSlots.length > 0) {
          result = true;
        }
      }
    });
  }
  return result;
};

export const HasSaturdayBookingWorkHours = BookingWorkHours => {
  let result = false;
  if (!Validation.isEmpty(BookingWorkHours)) {
    BookingWorkHours.forEach(weekday => {
      if (weekday.day === 'saturday') {
        if (weekday.timeSlots && weekday.timeSlots.length > 0) {
          result = true;
        }
      }
    });
  }
  return result;
};

export const HasSundayBookingWorkHours = BookingWorkHours => {
  let result = false;
  if (!Validation.isEmpty(BookingWorkHours)) {
    BookingWorkHours.forEach(weekday => {
      if (weekday.day === 'sunday') {
        if (weekday.timeSlots && weekday.timeSlots.length > 0) {
          result = true;
        }
      }
    });
  }
  return result;
};

//-------------------------------
// DateTimeTimezone
//-------------------------------

export const GetStringFromDateTimeTimezone = (
  DateTimeTimezone,
  Format = 'D. MMM YYYY H:mm'
) => {
  let resultString = '';
  if (!Validation.isEmpty(DateTimeTimezone)) {
    const { dateTime, timeZone } = DateTimeTimezone;
    const usedTimezone = !Validation.isEmpty(timeZone) ? timeZone : 'UTC';
    resultString = 'Jetzt';
    resultString = DateUtil.formatMsGraphDateTimeWithTimeZone(
      dateTime,
      usedTimezone,
      Format
    );
  }
  return resultString;
};

//-------------------------------
// EmailAddress
//-------------------------------

export const GetStringFromEmailAddress = EmailAddress => {
  let resultString = '';
  if (!Validation.isEmpty(EmailAddress)) {
    const { name, address } = EmailAddress;
    if (
      !Validation.isEmpty(name) &&
      !Validation.isEmpty(address) &&
      name !== address
    ) {
      resultString = name + ' (' + address + ')';
    } else {
      resultString = address;
    }
  }
  return resultString;
};

export const GetNameFromEmailAddress = EmailAddress => {
  let resultString = '';
  if (!Validation.isEmpty(EmailAddress)) {
    const { name, address } = EmailAddress;
    if (!Validation.isEmpty(name)) {
      resultString = name;
    } else {
      resultString = address;
    }
  }
  return resultString;
};

export const GetEmailFromEmailAddress = EmailAddress => {
  let resultString = '';
  if (!Validation.isEmpty(EmailAddress)) {
    resultString = EmailAddress.address;
  }
  return resultString;
};

export const IsValidEmailAddress = EmailAddress => {
  let result = false;
  if (!Validation.isEmpty(EmailAddress)) {
    const emailFound = GetEmailFromEmailAddress(EmailAddress);
    if (!Validation.isEmpty(emailFound)) {
      result = true;
    }
  }
  return result;
};

export const HasValidEmailAddress = EmailAddresses => {
  let result = false;
  if (!Validation.isEmpty(EmailAddresses)) {
    EmailAddresses.forEach(emailAddress => {
      if (IsValidEmailAddress(emailAddress)) {
        result = true;
      }
    });
  }
  return result;
};

//-------------------------------
// FollowupFlag
//-------------------------------

export const GetStringFromFollowupFlag = FollowupFlag => {
  let resultString = '';
  if (!Validation.isEmpty(FollowupFlag)) {
    const { flagStatus } = FollowupFlag;
    resultString = flagStatus;
  }
  return resultString;
};

//-------------------------------
// Group
//-------------------------------

export const GetNameFromGroup = Group => {
  let resultString = '';
  if (!Validation.isEmpty(Group)) {
    const { displayName } = Group;
    resultString = displayName;
  }
  return resultString;
};

//-------------------------------
// IdentitySetGroup
//-------------------------------

export const GetNameFromIdentitySetGroup = IdentitySetGroup => {
  let resultString = '';
  if (!Validation.isEmpty(IdentitySetGroup)) {
    resultString = IdentitySetGroup.displayName;
  }
  return resultString;
};

//-------------------------------
// IdentitySetUser
//-------------------------------

export const GetNameFromIdentitySetUser = IdentitySetUser => {
  let resultString = '';
  if (!Validation.isEmpty(IdentitySetUser)) {
    resultString = IdentitySetUser.displayName;
  }
  return resultString;
};

export const GetEmailFromIdentitySetUser = IdentitySetUser => {
  let resultString = '';
  if (!Validation.isEmpty(IdentitySetUser)) {
    if (!Validation.isDefined(IdentitySetUser, 'emailAddress')) {
      resultString = IdentitySetUser.emailAddress;
    }
    if (!Validation.isDefined(IdentitySetUser, 'email')) {
      resultString = IdentitySetUser.email;
    }
  }
  return resultString;
};

//-------------------------------
// PhysicalAddress
//-------------------------------

export const GetStreetLineFromPhysicalAddress = PhysicalAddress => {
  let resultString = '';
  if (!Validation.isEmpty(PhysicalAddress)) {
    const { street, postOfficeBox } = PhysicalAddress;
    if (!Validation.isEmpty(street)) {
      resultString = street;
    } else {
      resultString = postOfficeBox;
    }
  }
  return resultString;
};

export const GetCityLineFromPhysicalAddress = PhysicalAddress => {
  let resultString = '';
  let spaceLiteral = '';
  if (!Validation.isEmpty(PhysicalAddress)) {
    const { postalCode, city, state } = PhysicalAddress;
    // Postal Code
    resultString = postalCode ? postalCode : '';
    // City
    spaceLiteral = resultString && city ? ' ' : '';
    resultString = city ? resultString + spaceLiteral + city : resultString;
    // State
    spaceLiteral = resultString && city ? ', ' : '';
    resultString = state ? resultString + spaceLiteral + state : resultString;
  }
  return resultString;
};

export const GetCountryLineFromPhysicalAddress = PhysicalAddress => {
  let resultString = '';
  if (!Validation.isEmpty(PhysicalAddress)) {
    const { countryOrRegion } = PhysicalAddress;
    resultString = countryOrRegion ? countryOrRegion : '';
  }
  return resultString;
};

export const IsValidPhysicalAddress = PhysicalAddress => {
  let result = false;
  if (!Validation.isEmpty(PhysicalAddress)) {
    const addressStreet = GetStreetLineFromPhysicalAddress(PhysicalAddress);
    const addressCity = GetCityLineFromPhysicalAddress(PhysicalAddress);
    const addressCountry = GetCountryLineFromPhysicalAddress(PhysicalAddress);
    if (addressStreet || addressCity || addressCountry) {
      result = true;
    }
  }
  return result;
};

export const HasValidPhysicalAddress = PhysicalAddresses => {
  let result = false;
  if (!Validation.isEmpty(PhysicalAddresses)) {
    PhysicalAddresses.forEach(physicalAddress => {
      if (IsValidPhysicalAddress(physicalAddress)) {
        result = true;
      }
    });
  }
  return result;
};

//-------------------------------
// PlannerAssignment
//-------------------------------

export const GetNameFromPlannerAssignment = PlannerAssignment => {
  let resultString = '';
  if (!Validation.isEmpty(PlannerAssignment)) {
    resultString = PlannerAssignment.displayName;
  }
  return resultString;
};

export const GetEmailFromPlannerAssignment = PlannerAssignment => {
  let resultString = '';
  if (!Validation.isEmpty(PlannerAssignment)) {
    resultString = PlannerAssignment.emailAddress;
  }
  return resultString;
};

//-------------------------------
// PlannerPlan
//-------------------------------

export const GetTitleFromPlannerPlan = PlannerPlan => {
  let resultString = '';
  if (!Validation.isEmpty(PlannerPlan)) {
    const { title } = PlannerPlan;
    resultString = title;
  }
  return resultString;
};

//-------------------------------
// TypedPhone
//-------------------------------

export const GetNumberFromTypedPhone = TypedPhone => {
  let resultString = '';
  if (!Validation.isEmpty(TypedPhone)) {
    resultString = TypedPhone.number;
  }
  return resultString;
};

export const GetTypeFromTypedPhone = TypedPhone => {
  let resultString = '';
  if (!Validation.isEmpty(TypedPhone)) {
    resultString = TypedPhone.type;
  }
  return resultString;
};

export const GetIconFromTypedPhone = TypedPhone => {
  let resultString = MenuConfig.ICONS_MENU_DEFAULTS.phone;
  if (!Validation.isEmpty(TypedPhone)) {
    if (TypedPhone.type === 'mobile') {
      resultString = MenuConfig.ICONS_MENU_DEFAULTS.mobile;
    }
    if (
      TypedPhone.type === 'homeFax' ||
      TypedPhone.type === 'businessFax' ||
      TypedPhone.type === 'otherFax'
    ) {
      resultString = MenuConfig.ICONS_MENU_DEFAULTS.fax;
    }
  }
  return resultString;
};

export const IsValidTypedPhone = TypedPhone => {
  let result = false;
  if (!Validation.isEmpty(TypedPhone)) {
    const phoneFound = GetNumberFromTypedPhone(TypedPhone);
    if (!Validation.isEmpty(phoneFound)) {
      result = true;
    }
  }
  return result;
};

export const HasValidTypedPhone = TypedPhones => {
  let result = false;
  if (!Validation.isEmpty(TypedPhones)) {
    TypedPhones.forEach(typedPhone => {
      if (IsValidTypedPhone(typedPhone)) {
        result = true;
      }
    });
  }
  return result;
};

//-------------------------------
// Website
//-------------------------------

export const GetStringFromWebsite = Website => {
  let resultString = '';
  if (!Validation.isEmpty(Website)) {
    const { displayName, address } = Website;
    resultString = displayName ? displayName : address;
  }
  return resultString;
};

export const GetNameFromWebsite = Website => {
  let resultString = '';
  if (!Validation.isEmpty(Website)) {
    resultString = Website.displayName;
  }
  return resultString;
};

export const GetAddressFromWebsite = Website => {
  let resultString = '';
  if (!Validation.isEmpty(Website)) {
    resultString = Website.address;
    if (
      resultString &&
      !(resultString.startsWith('http') || resultString.startsWith('https'))
    ) {
      resultString = 'https://' + resultString;
    }
  }
  return resultString;
};

export const IsValidWebsite = Website => {
  let result = false;
  if (!Validation.isEmpty(Website)) {
    const addressFound = GetAddressFromWebsite(Website);
    if (!Validation.isEmpty(addressFound)) {
      result = true;
    }
  }
  return result;
};

export const HasValidWebsite = Websites => {
  let result = false;
  if (!Validation.isEmpty(Websites)) {
    Websites.forEach(webAddress => {
      if (IsValidEmailAddress(webAddress)) {
        result = true;
      }
    });
  }
  return result;
};
