import React from 'react';
import PropTypes from 'prop-types';
import NodeHeader from './Node.Header';
import NodeContent from './Node.Content';
import NodeRelation from './Node.Relation';
import * as ApplicationConfig from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as DataUtils from '../../../common/utils/DataUtils';
import * as Validation from '../../../common/utils/Validation';

class NodeCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeHeaderMenuItem: MenuConfig.CODE_MENU_DEFAULTS.none,
      activeRelation: '',
      activeLoadAll: false,
      formErrors: {},
      formWarnings: [],
      formMessages: []
    };
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleHeaderMenuItemClick = (e, { name }) => {
    this.setState({ activeHeaderMenuItem: name });
    if (name === MenuConfig.CODE_MENU_DEFAULTS.graph) {
      e.preventDefault();
      if (!this.formIsValid()) {
        return;
      }
      if (
        window.confirm('Existing Steps in the Path will be removed. Continue?')
      ) {
        this.props.clearPathfinder();
        this.addNewStepToPath();
        this.props.history.push('/graph');
      }
    }
    if (name === MenuConfig.CODE_MENU_DEFAULTS.pathfinder) {
      e.preventDefault();
      if (!this.formIsValid()) {
        return;
      }
      if (
        window.confirm('Existing Steps in the Path will be removed. Continue?')
      ) {
        this.props.clearPathfinder();
        this.addNewStepToPath();
        this.props.history.push('/pathfinder');
      }
    }
    if (name === MenuConfig.CODE_MENU_DEFAULTS.close) {
      const area = this.props.dataNode ? this.props.dataNode.source : '';
      if (area === ApplicationConfig.NAVIGATION_AREA.dashboard) {
        this.props.history.push('/dashboard');
      } else if (area === ApplicationConfig.NAVIGATION_AREA.graph) {
        this.props.history.push('/graph');
      } else if (area === ApplicationConfig.NAVIGATION_AREA.pathfinder) {
        this.props.history.push('/pathfinder');
      }
    }
  };

  handleRelationChange = (e, { value }) => {
    this.setState({ activeRelation: value, formErrors: {} });
  };

  handleLoadAllChange = () => {
    this.setState({ activeLoadAll: !this.state.activeLoadAll, formErrors: {} });
  };

  handleExtendPathClick = (e, data) => {
    e.preventDefault();
    if (!this.formIsValid()) {
      return;
    }
    // update selected Relation in current node
    const { activeRelation, activeLoadAll } = this.state;
    this.props.setRelation(activeRelation, activeLoadAll);

    // check Object & Relations if already available as Step
    const datasetParams = {
      segmentType: this.props.dataContext
        ? this.props.dataContext.segmentType
        : '',
      segmentId: this.props.dataContext ? this.props.dataContext.segmentId : '',
      parentType: this.props.dataContext
        ? this.props.dataContext.parentType
        : '',
      parentId: this.props.dataContext ? this.props.dataContext.parentId : '',
      entityType: this.props.dataNode ? this.props.dataNode.objectType : '',
      entityId: this.props.dataNode ? this.props.dataNode.objectId : '',
      relationType: activeRelation
    };
    const newPosition = DataUtils.getPathPositionOfDataSet(
      this.props.dataSteps,
      datasetParams
    );
    if (newPosition > 0) {
      // navigate to already open Step in Path
      this.props.setCurrentStepPosition(newPosition);
    } else {
      // create new Step in Path
      this.props.addNewPathfinderStep(this.props.auth);
      this.props.setCurrentStepPositionToLast();
    }
    // open the Path
    const area = this.props.dataNode ? this.props.dataNode.source : '';
    if (area === ApplicationConfig.NAVIGATION_AREA.dashboard) {
      this.props.history.push('/pathfinder');
    } else if (area === ApplicationConfig.NAVIGATION_AREA.graph) {
      this.props.history.push('/graph');
    } else if (area === ApplicationConfig.NAVIGATION_AREA.pathfinder) {
      this.props.history.push('/pathfinder');
    }
  };

  addNewStepToPath = () => {
    // update selected Relation in current node
    const { activeRelation, activeLoadAll } = this.state;
    this.props.setRelation(activeRelation, activeLoadAll);

    // add new Step to Path
    this.props.addNewPathfinderStep(this.props.auth);

    // navigate to the new Step
    this.props.setCurrentStepPositionToLast();
  };

  //-----------------------------------
  // Validation
  //-----------------------------------
  formIsValid = () => {
    let formIsValid = true;
    let errors = {};

    const relationValue = this.state.activeRelation;
    if (!relationValue) {
      errors.selectRelation = 'Please select a Relation first.';
      formIsValid = false;
    }

    this.setState({ formErrors: errors });
    return formIsValid;
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { activeHeaderMenuItem, activeRelation, activeLoadAll } = this.state;
    const { formErrors, formWarnings, formMessages } = this.state;
    const { auth, location, match, params } = this.props;
    const { dataNode, dataContext, dataMe } = this.props;

    const headerMenuExternalLink = dataNode ? dataNode.externalUrl : '';
    const showHeaderMenuExternalLink = !Validation.isEmpty(
      headerMenuExternalLink
    );

    return (
      <div className="app-sidebar-content">
        <div className="app-sidebar-header-wrapper">
          <NodeHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            activeMenuItem={activeHeaderMenuItem}
            showExternalLink={showHeaderMenuExternalLink}
            externalLink={headerMenuExternalLink}
            onMenuItemClick={this.handleHeaderMenuItemClick}
          />
        </div>
        <div className="app-sidebar-body">
          <NodeContent
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataNode={dataNode}
            smallContainer={true}
            showExtended={true}
            showObjectLink={false}
          />
          <NodeRelation
            auth={auth}
            location={location}
            match={match}
            params={params}
            valueRelation={activeRelation}
            valueLoadAll={activeLoadAll}
            dataNode={dataNode}
            dataContext={dataContext}
            dataMe={dataMe}
            formErrors={formErrors}
            formWarnings={formWarnings}
            formMessages={formMessages}
            onRelationChange={this.handleRelationChange}
            onLoadAllChange={this.handleLoadAllChange}
            onExtendPathClick={this.handleExtendPathClick}
          />
        </div>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
NodeCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  dataContext: PropTypes.object.isRequired,
  dataSteps: PropTypes.array.isRequired,
  dataMe: PropTypes.object.isRequired,
  setCurrentStepPosition: PropTypes.func.isRequired,
  setCurrentStepPositionToLast: PropTypes.func.isRequired,
  clearPathfinder: PropTypes.func.isRequired,
  setRelation: PropTypes.func.isRequired,
  addNewPathfinderStep: PropTypes.func.isRequired
};

export default NodeCanvas;
