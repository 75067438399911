import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu, Icon } from 'semantic-ui-react';
import * as MenuConfig from '../../../app/config/constants/Menu';

class SettingsHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    //const { location, match, params } = this.props;
    const { basePath } = this.props;

    return (
      <div className="app-nav-chart-main">
        <Menu secondary pointing>
          <Menu.Menu position="right">
            <Menu.Item
              name={MenuConfig.CODE_MENU_DEFAULTS.close}
              as={NavLink}
              to={basePath}
              active={false}
              onClick={this.props.onMenuItemClick}
            >
              <Icon
                name={MenuConfig.ICONS_MENU_DEFAULTS.close}
                size="large"
                color="grey"
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
SettingsHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  activeMenuItem: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  onMenuItemClick: PropTypes.func.isRequired
};

export default SettingsHeader;
