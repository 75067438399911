import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function RoutePrivateRedirect(props) {
  const { component: Component, auth: Auth, params: Params, ...rest } = props;
  const authenticated = Auth.authenticated;

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component auth={Auth} params={Params} {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
}

export default RoutePrivateRedirect;
