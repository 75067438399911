import * as Actions from '../types';
import * as NavUtil from '../../../../common/utils/NavigationUtils';

//-------------------------
// CURRENT CONTEXT
// - setSegment
// - setParent
// - setContext
// - receiveEntity
//-------------------------

export const setSegment = (type, id) => {
  return {
    type: Actions.CURRENTCONTEXT_SEGMENT,
    payload: {
      segmentType: type,
      segmentId: id
    }
  };
};

export const setParent = (type, id) => {
  return {
    type: Actions.CURRENTCONTEXT_PARENT,
    payload: {
      parentType: type,
      parentId: id
    }
  };
};

export const setContext = (type, id, parent, segment) => (
  dispatch,
  getState
) => {
  const state = getState();
  const context = NavUtil.CreateContext(state, type, id, parent, segment);
  dispatch(
    receiveEntity(
      context.segmentType,
      context.segmentId,
      context.parentType,
      context.parentId
    )
  );
};

export const receiveEntity = (sType, sId, pType, pId) => {
  return {
    type: Actions.CURRENTCONTEXT_RECEIVE,
    payload: {
      segmentType: sType,
      segmentId: sId,
      parentType: pType,
      parentId: pId,
      receivedAt: Date.now()
    }
  };
};

export const clearEntity = () => {
  return {
    type: Actions.CURRENTCONTEXT_RECEIVE,
    payload: {}
  };
};

//-------------------------
// Helpers
//-------------------------
