import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'semantic-ui-react';
import CardListCard from './Group.CardList.Card';
import * as ApplicationConst from '../../../app/config/constants/Application';

class GroupCardListList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { dataEntities, dataMoreAvailable, dataMoreUrl } = this.props;

    // Device size
    let cardColumns = 4;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.mobile) {
      cardColumns = 2;
    }

    return (
      <div>
        <Card.Group itemsPerRow={cardColumns}>
          {dataEntities.map((itemData, index) => (
            <CardListCard
              key={
                itemData && itemData.entity
                  ? itemData.entity.id
                  : index.toString()
              }
              auth={auth}
              location={location}
              match={match}
              params={params}
              dataEntity={itemData && itemData.entity ? itemData.entity : {}}
              dataKey={
                itemData && itemData.entity
                  ? itemData.entity.id
                  : index.toString()
              }
              onEntityClick={this.props.onEntityClick}
            />
          ))}
        </Card.Group>
        {dataMoreAvailable && (
          <div className="app-itemlist-loadmore">
            <Button
              size="mini"
              onClick={e => this.props.onLoadMoreClick(e, dataMoreUrl)}
            >
              Load more ...
            </Button>
          </div>
        )}
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
GroupCardListList.defaultProps = {
  dataMoreAvailable: false
};

GroupCardListList.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntities: PropTypes.array.isRequired,
  dataMoreAvailable: PropTypes.bool,
  dataMoreUrl: PropTypes.string,
  onEntityClick: PropTypes.func.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired
};

export default GroupCardListList;
