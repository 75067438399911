import * as Actions from '../../actions/types';

const initialState = {
  entityIds: [],
  moreAvailable: false,
  moreUrl: '',
  isFetching: false,
  didInvalidate: false
};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state) {
  return {
    ...state,
    didInvalidate: true
  };
}

function setFetching(state) {
  return {
    ...state,
    isFetching: true,
    didInvalidate: false
  };
}

function setInitialGroups(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds) {
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;

    return {
      ...state,
      entityIds: entityIds,
      moreAvailable: moreAvailable,
      moreUrl: moreUrl,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    entityIds: [],
    moreAvailable: false,
    moreUrl: '',
    isFetching: false,
    didInvalidate: false
  };
}

function appendInitialGroups(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds) {
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;
    const newEntityIds =
      state && state.entityIds ? state.entityIds.concat(entityIds) : entityIds;
    return {
      ...state,
      entityIds: newEntityIds,
      moreAvailable: moreAvailable,
      moreUrl: moreUrl,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    moreAvailable: false,
    moreUrl: '',
    isFetching: false,
    didInvalidate: false
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.INITIALGROUPS_INVALIDATE:
      return setInvalidate(state);
    case Actions.INITIALGROUPS_REQUEST:
      return setFetching(state);
    case Actions.INITIALGROUPS_RECEIVE:
      return setInitialGroups(state, action);
    case Actions.INITIALGROUPS_APPEND:
      return appendInitialGroups(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getMyInitialGroups = state => {
  return state;
};
