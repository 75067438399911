import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Grid, GridColumn, List, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class UserCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 8;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Company
    let companyString = dataEntity.companyName;
    if (!Validation.isEmpty(dataEntity.officeLocation)) {
      companyString = !Validation.isEmpty(companyString)
        ? companyString + ', ' + dataEntity.officeLocation
        : dataEntity.officeLocation;
    }
    if (!Validation.isEmpty(dataEntity.usageLocation)) {
      companyString = !Validation.isEmpty(companyString)
        ? companyString + ', ' + dataEntity.usageLocation
        : dataEntity.usageLocation;
    }
    let departmentString = dataEntity.jobTitle;
    if (!Validation.isEmpty(dataEntity.department)) {
      departmentString = !Validation.isEmpty(departmentString)
        ? departmentString + ', ' + dataEntity.department
        : dataEntity.department;
    }

    // Field Employee Data
    const employeeIdString = !Validation.isEmpty(dataEntity.employeeId)
      ? 'Nr: ' + dataEntity.employeeId
      : '';
    const employeeStartString = Validation.isValidDate(dataEntity.hireDate)
      ? 'Start: ' + DateUtil.formatMsGraphDateTime(dataEntity.hireDate)
      : '';

    // Field Address
    const addressStreetString = dataEntity.streetAddress;
    let addressCityString = dataEntity.postalCode;
    if (!Validation.isEmpty(dataEntity.city)) {
      addressCityString = !Validation.isEmpty(addressCityString)
        ? addressCityString + ' ' + dataEntity.city
        : dataEntity.city;
    }
    if (!Validation.isEmpty(dataEntity.state)) {
      addressCityString = !Validation.isEmpty(addressCityString)
        ? addressCityString + ', ' + dataEntity.state
        : dataEntity.state;
    }
    if (!Validation.isEmpty(dataEntity.country)) {
      addressCityString = !Validation.isEmpty(addressCityString)
        ? addressCityString + ', ' + dataEntity.country
        : dataEntity.country;
    }

    // Field Phone
    const faxNumberString = dataEntity.faxNumber;
    const mobileNumberString = dataEntity.mobilePhone;

    // Field Personal
    const persLanguageString = !Validation.isEmpty(dataEntity.preferredLanguage)
      ? 'Language: ' + dataEntity.preferredLanguage
      : '';
    const persBirthdayString = Validation.isValidDate(dataEntity.birthday)
      ? 'Birthday: ' + moment(dataEntity.birthday).format('D. MMMM')
      : '';
    const persAgeGroupString = !Validation.isEmpty(dataEntity.ageGroup)
      ? 'Age Group: ' + dataEntity.ageGroup
      : '';

    // Field Links
    const linksWeb = !Validation.isEmpty(dataEntity.mySite)
      ? dataEntity.mySite
      : '';

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );
    const systemDate = Validation.isValidDate(dataEntity.createdDateTime)
      ? 'Created: ' + DateUtil.formatMsGraphDateTime(dataEntity.createdDateTime)
      : '';

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Company</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(companyString)}</div>
                  {departmentString && <div>{departmentString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Employee Data</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(employeeIdString)}</div>
                  {employeeStartString && <div>{employeeStartString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Responsibilities</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.responsibilities) ? (
                    <List items={dataEntity.responsibilities} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Projects</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.pastProjects) ? (
                    <List items={dataEntity.pastProjects} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Skills</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.skills) ? (
                    <List items={dataEntity.skills} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Education</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.schools) ? (
                    <List items={dataEntity.schools} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
          </GridColumn>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Address</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(addressStreetString)}</div>
                  {addressCityString && <div>{addressCityString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Contact</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {dataEntity.mail && (
                    <div className="app-itemcard-email">
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                        className="app-listitem-icon"
                      />
                      <a href={'mailto:' + dataEntity.mail}>
                        {dataEntity.mail}
                      </a>
                    </div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Phone</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.businessPhones) ? (
                    <List>
                      {dataEntity.businessPhones.map((phoneNumber, index) => (
                        <List.Item key={index}>
                          <div>
                            <Icon
                              name={MenuConfig.ICONS_MENU_DEFAULTS.phone}
                              className="app-listitem-icon"
                            />
                            {phoneNumber}
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                  {faxNumberString && (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.fax}
                        className="app-listitem-icon"
                      />
                      {faxNumberString}
                    </div>
                  )}
                  {mobileNumberString && (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.mobile}
                        className="app-listitem-icon"
                      />
                      {mobileNumberString}
                    </div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Personal</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(persLanguageString)}</div>
                  {persBirthdayString && <div>{persBirthdayString}</div>}
                  {persAgeGroupString && <div>{persAgeGroupString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Interests</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.interests) ? (
                    <List items={dataEntity.interests} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-website">
                  {linksWeb ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksWeb}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Weblink
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                  {systemDate && <div>{systemDate}</div>}
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
UserCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

UserCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default UserCardForm;
