export const SETTINGS_APP_DEFAULTS = {
  pagerCount: 10,
  featuresCount: 4,
  teaserCharCount: 300,
  labelCharCount: 30,
  ratingMaxStars: 5,
  dateTimeFormat: 'D. MMM YYYY HH:mm',
  dateFormat: 'D. MMM YYYY',
  timeFormat: 'HH:mm'
};

export const SETTINGS_APP_COLORS = {
  menuaccount: 'blue',
  menudashboard: 'blue',
  menugraph: 'blue',
  menupathfinder: 'blue',
  menusettings: 'blue'
};

export const SETTINGS_APP_ICONS = {
  account: 'user outline',
  construction: 'wrench',
  dashboard: 'home',
  graph: 'arrows alternate',
  home: 'home',
  messageError: 'warning sign',
  messageInfo: 'info',
  messageWarning: 'warning',
  pathfinder: 'exchange',
  setting: 'wrench',
  team: 'users',
  user: 'user',
  email: 'mail outline',
  emailopen: 'envelope open outline',
  task: 'check square outline',
  activity: 'tasks',
  appointment: 'calendar outline'
};
