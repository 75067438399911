import { combineReducers } from 'redux';
import adminReducers, * as adminSelectors from './adminReducers';
import appReducers, * as appSelectors from './appReducers';
import currentReducers, * as currentSelectors from './currentReducers';
import dashboardReducers, * as dashboardSelectors from './dashboardReducers';
//import graphReducers, * as graphSelectors from "./graphReducers";
import pathfinderReducers, * as pathfinderSelectors from './pathfinderReducers';
import queriesReducers, * as queriesSelectors from './queriesReducers';
import entitiesReducers, * as entitiesSelectors from './entitiesReducers';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  admin: adminReducers,
  app: appReducers,
  current: currentReducers,
  dashboard: dashboardReducers,
  //graph: graphReducers,
  pathfinder: pathfinderReducers,
  queries: queriesReducers,
  entities: entitiesReducers
});

//-------------------------
// Selectors
//-------------------------

// Admin
export const getAdminAccount = state => {
  return adminSelectors.getAdminAccount(state.admin);
};
export const getAdminCompany = state => {
  return adminSelectors.getAdminCompany(state.admin);
};
export const getAdminLicenses = state => {
  return adminSelectors.getAdminLicenses(state.admin);
};
export const getAdminLogins = state => {
  return adminSelectors.getAdminLogins(state.admin);
};
export const getAdminStates = state => {
  return adminSelectors.getAdminStates(state.admin);
};
export const getAdminSubscriptions = state => {
  return adminSelectors.getAdminSubscriptions(state.admin);
};

// App
export const getAuth = state => {
  return appSelectors.getAuth(state.app);
};
export const getOnboarding = state => {
  return appSelectors.getOnboarding(state.app);
};
export const getCurrentError = state => {
  return appSelectors.getError(state.app);
};
export const getCurrentAppError = state => {
  return appSelectors.getAppError(state.app);
};
export const getCurrentFetchingError = state => {
  return appSelectors.getFetchingError(state.app);
};
export const getCurrentInputErrors = state => {
  return appSelectors.getInputErrors(state.app);
};

// Current
export const getCurrentNode = state => {
  return currentSelectors.getCurrentNode(state.current);
};
export const getCurrentContext = state => {
  return currentSelectors.getCurrentContext(state.current);
};

// Dashboard
export const getMyUser = state => {
  return dashboardSelectors.getMyUser(state.dashboard);
};
export const getMyTenant = state => {
  return dashboardSelectors.getMyTenant(state.dashboard);
};
export const getMyManager = state => {
  return dashboardSelectors.getMyManager(state.dashboard);
};
export const getMyDirectReports = state => {
  return dashboardSelectors.getMyDirectReports(state.dashboard);
};
export const getMyJoinedGroups = state => {
  return dashboardSelectors.getMyJoinedGroups(state.dashboard);
};
export const getMyStatistics = state => {
  return dashboardSelectors.getMyStatistics(state.dashboard);
};
export const getMyInitialGroups = state => {
  return dashboardSelectors.getMyInitialGroups(state.dashboard);
};
export const getMyInitialPlans = state => {
  return dashboardSelectors.getMyInitialPlans(state.dashboard);
};
export const getMyInitialUsers = state => {
  return dashboardSelectors.getMyInitialUsers(state.dashboard);
};

// Pathfinder
export const getPathfinderPath = state => {
  return pathfinderSelectors.getPath(state.pathfinder);
};
export const getPathfinderCurrentStepPosition = state => {
  return pathfinderSelectors.getCurrentStepPosition(state.pathfinder);
};
export const getPathfinderStepIndexByPosition = (state, position) => {
  return pathfinderSelectors.getStepIndexByPosition(state.pathfinder, position);
};
export const getPathfinderStepByIndex = (state, index) => {
  return pathfinderSelectors.getStepByIndex(state.pathfinder, index);
};
export const getPathfinderStepsByIndices = (state, indices) => {
  return pathfinderSelectors.getStepsByIndices(state.pathfinder, indices);
};
export const getAllPathfinderSteps = state => {
  return pathfinderSelectors.getAllSteps(state.pathfinder);
};
export const getAllPathfinderStepsCount = state => {
  return pathfinderSelectors.getAllStepsCount(state.pathfinder);
};
export const getFilteredPathfinderSteps = (state, filter) => {
  return pathfinderSelectors.getFilteredSteps(state.pathfinder, filter);
};

// Queries
export const getQueryByIndex = (state, index) => {
  return queriesSelectors.getQueryByIndex(state.queries, index);
};
export const getQueriesByIndices = (state, indices) => {
  return queriesSelectors.getQueriesByIndices(state.queries, indices);
};
export const getAllQueries = state => {
  return queriesSelectors.getAllQueries(state.queries);
};
export const getFilteredQueries = (state, filter) => {
  return queriesSelectors.getFilteredQueries(state.queries, filter);
};

// Entities
export const getEntityById = (state, type, id) => {
  return entitiesSelectors.getEntityById(state.entities, type, id);
};
export const getEntitiesByIds = (state, type, ids) => {
  return entitiesSelectors.getEntitiesByIds(state.entities, type, ids);
};

// Entities - Activity
export const getActivityEntityById = (state, id) => {
  return entitiesSelectors.getActivityEntityById(state.entities, id);
};
export const getActivityEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getActivityEntitiesByIds(state.entities, ids);
};
export const getAllActivityEntities = state => {
  return entitiesSelectors.getAllActivityEntities(state.entities);
};
export const getFilteredActivityEntities = (state, filter) => {
  return entitiesSelectors.getFilteredActivityEntities(state.entities, filter);
};

// Entities - ActivityFolder
export const getActivityFolderEntityById = (state, id) => {
  return entitiesSelectors.getActivityFolderEntityById(state.entities, id);
};
export const getActivityFolderEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getActivityFolderEntitiesByIds(state.entities, ids);
};
export const getAllActivityFolderEntities = state => {
  return entitiesSelectors.getAllActivityFolderEntities(state.entities);
};
export const getFilteredActivityFolderEntities = (state, filter) => {
  return entitiesSelectors.getFilteredActivityFolderEntities(
    state.entities,
    filter
  );
};

// Entities - ActivityGroup
export const getActivityGroupEntityById = (state, id) => {
  return entitiesSelectors.getActivityGroupEntityById(state.entities, id);
};
export const getActivityGroupEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getActivityGroupEntitiesByIds(state.entities, ids);
};
export const getAllActivityGroupEntities = state => {
  return entitiesSelectors.getAllActivityGroupEntities(state.entities);
};
export const getFilteredActivityGroupEntities = (state, filter) => {
  return entitiesSelectors.getFilteredActivityGroupEntities(
    state.entities,
    filter
  );
};

// Entities - AdministrativeUnit
export const getAdministrativeUnitEntityById = (state, id) => {
  return entitiesSelectors.getAdministrativeUnitEntityById(state.entities, id);
};
export const getAdministrativeUnitEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getAdministrativeUnitEntitiesByIds(
    state.entities,
    ids
  );
};
export const getAllAdministrativeUnitEntities = state => {
  return entitiesSelectors.getAllAdministrativeUnitEntities(state.entities);
};
export const getFilteredAdministrativeUnitEntities = (state, filter) => {
  return entitiesSelectors.getFilteredAdministrativeUnitEntities(
    state.entities,
    filter
  );
};

// Entities - AgreementAcceptance
export const getAgreementAcceptanceEntityById = (state, id) => {
  return entitiesSelectors.getAgreementAcceptanceEntityById(state.entities, id);
};
export const getAgreementAcceptanceEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getAgreementAcceptanceEntitiesByIds(
    state.entities,
    ids
  );
};
export const getAllAgreementAcceptanceEntities = state => {
  return entitiesSelectors.getAllAgreementAcceptanceEntities(state.entities);
};
export const getFilteredAgreementAcceptanceEntities = (state, filter) => {
  return entitiesSelectors.getFilteredAgreementAcceptanceEntities(
    state.entities,
    filter
  );
};

// Entities - Agreement
export const getAgreementEntityById = (state, id) => {
  return entitiesSelectors.getAgreementEntityById(state.entities, id);
};
export const getAgreementEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getAgreementEntitiesByIds(state.entities, ids);
};
export const getAllAgreementEntities = state => {
  return entitiesSelectors.getAllAgreementEntities(state.entities);
};
export const getFilteredAgreementEntities = (state, filter) => {
  return entitiesSelectors.getFilteredAgreementEntities(state.entities, filter);
};

// Entities - Application
export const getApplicationEntityById = (state, id) => {
  return entitiesSelectors.getApplicationEntityById(state.entities, id);
};
export const getApplicationEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getApplicationEntitiesByIds(state.entities, ids);
};
export const getAllApplicationEntities = state => {
  return entitiesSelectors.getAllApplicationEntities(state.entities);
};
export const getFilteredApplicationEntities = (state, filter) => {
  return entitiesSelectors.getFilteredApplicationEntities(
    state.entities,
    filter
  );
};

// Entities - Attachment
export const getAttachmentEntityById = (state, id) => {
  return entitiesSelectors.getAttachmentEntityById(state.entities, id);
};
export const getAttachmentEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getAttachmentEntitiesByIds(state.entities, ids);
};
export const getAllAttachmentEntities = state => {
  return entitiesSelectors.getAllAttachmentEntities(state.entities);
};
export const getFilteredAttachmentEntities = (state, filter) => {
  return entitiesSelectors.getFilteredAttachmentEntities(
    state.entities,
    filter
  );
};

// Entities - Booking Appointment
export const getBookingAppointmentEntityById = (state, id) => {
  return entitiesSelectors.getBookingAppointmentEntityById(state.entities, id);
};
export const getBookingAppointmentEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getBookingAppointmentEntitiesByIds(
    state.entities,
    ids
  );
};
export const getAllBookingAppointmentEntities = state => {
  return entitiesSelectors.getAllBookingAppointmentEntities(state.entities);
};
export const getFilteredBookingAppointmentEntities = (state, filter) => {
  return entitiesSelectors.getFilteredBookingAppointmentEntities(
    state.entities,
    filter
  );
};

// Entities - BookingBusiness
export const getBookingBusinessEntityById = (state, id) => {
  return entitiesSelectors.getBookingBusinessEntityById(state.entities, id);
};
export const getBookingBusinessEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getBookingBusinessEntitiesByIds(state.entities, ids);
};
export const getAllBookingBusinessEntities = state => {
  return entitiesSelectors.getAllBookingBusinessEntities(state.entities);
};
export const getFilteredBookingBusinessEntities = (state, filter) => {
  return entitiesSelectors.getFilteredBookingBusinessEntities(
    state.entities,
    filter
  );
};

// Entities - BookingCustomer
export const getBookingCustomerEntityById = (state, id) => {
  return entitiesSelectors.getBookingCustomerEntityById(state.entities, id);
};
export const getBookingCustomerEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getBookingCustomerEntitiesByIds(state.entities, ids);
};
export const getAllBookingCustomerEntities = state => {
  return entitiesSelectors.getAllBookingCustomerEntities(state.entities);
};
export const getFilteredBookingCustomerEntities = (state, filter) => {
  return entitiesSelectors.getFilteredBookingCustomerEntities(
    state.entities,
    filter
  );
};

// Entities - BookingService
export const getBookingServiceEntityById = (state, id) => {
  return entitiesSelectors.getBookingServiceEntityById(state.entities, id);
};
export const getBookingServiceEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getBookingServiceEntitiesByIds(state.entities, ids);
};
export const getAllBookingServiceEntities = state => {
  return entitiesSelectors.getAllBookingServiceEntities(state.entities);
};
export const getFilteredBookingServiceEntities = (state, filter) => {
  return entitiesSelectors.getFilteredBookingServiceEntities(
    state.entities,
    filter
  );
};

// Entities - BookingStaffMember
export const getBookingStaffMemberEntityById = (state, id) => {
  return entitiesSelectors.getBookingStaffMemberEntityById(state.entities, id);
};
export const getBookingStaffMemberEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getBookingStaffMemberEntitiesByIds(
    state.entities,
    ids
  );
};
export const getAllBookingStaffMemberEntities = state => {
  return entitiesSelectors.getAllBookingStaffMemberEntities(state.entities);
};
export const getFilteredBookingStaffMemberEntities = (state, filter) => {
  return entitiesSelectors.getFilteredBookingStaffMemberEntities(
    state.entities,
    filter
  );
};

// Entities - Bucket
export const getBucketEntityById = (state, id) => {
  return entitiesSelectors.getBucketEntityById(state.entities, id);
};
export const getBucketEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getBucketEntitiesByIds(state.entities, ids);
};
export const getAllBucketEntities = state => {
  return entitiesSelectors.getAllBucketEntities(state.entities);
};
export const getFilteredBucketEntities = (state, filter) => {
  return entitiesSelectors.getFilteredBucketEntities(state.entities, filter);
};

// Entities - CalendarGroup
export const getCalendarGroupEntityById = (state, id) => {
  return entitiesSelectors.getCalendarGroupEntityById(state.entities, id);
};
export const getCalendarGroupEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getCalendarGroupEntitiesByIds(state.entities, ids);
};
export const getAllCalendarGroupEntities = state => {
  return entitiesSelectors.getAllCalendarGroupEntities(state.entities);
};
export const getFilteredCalendarGroupEntities = (state, filter) => {
  return entitiesSelectors.getFilteredCalendarGroupEntities(
    state.entities,
    filter
  );
};

// Entities - Calendar
export const getCalendarEntityById = (state, id) => {
  return entitiesSelectors.getCalendarEntityById(state.entities, id);
};
export const getCalendarEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getCalendarEntitiesByIds(state.entities, ids);
};
export const getAllCalendarEntities = state => {
  return entitiesSelectors.getAllCalendarEntities(state.entities);
};
export const getFilteredCalendarEntities = (state, filter) => {
  return entitiesSelectors.getFilteredCalendarEntities(state.entities, filter);
};

// Entities - Channel
export const getChannelEntityById = (state, id) => {
  return entitiesSelectors.getChannelEntityById(state.entities, id);
};
export const getChannelEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getChannelEntitiesByIds(state.entities, ids);
};
export const getAllChannelEntities = state => {
  return entitiesSelectors.getAllChannelEntities(state.entities);
};
export const getFilteredChannelEntities = (state, filter) => {
  return entitiesSelectors.getFilteredChannelEntities(state.entities, filter);
};

// Entities - ChatMessage
export const getChatMessageEntityById = (state, id) => {
  return entitiesSelectors.getChatMessageEntityById(state.entities, id);
};
export const getChatMessageEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getChatMessageEntitiesByIds(state.entities, ids);
};
export const getAllChatMessageEntities = state => {
  return entitiesSelectors.getAllChatMessageEntities(state.entities);
};
export const getFilteredChatMessageEntities = (state, filter) => {
  return entitiesSelectors.getFilteredChatMessageEntities(
    state.entities,
    filter
  );
};

// Entities - ChatThread
export const getChatThreadEntityById = (state, id) => {
  return entitiesSelectors.getChatThreadEntityById(state.entities, id);
};
export const getChatThreadEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getChatThreadEntitiesByIds(state.entities, ids);
};
export const getAllChatThreadEntities = state => {
  return entitiesSelectors.getAllChatThreadEntities(state.entities);
};
export const getFilteredChatThreadEntities = (state, filter) => {
  return entitiesSelectors.getFilteredChatThreadEntities(
    state.entities,
    filter
  );
};

// Entities - ContactFolder
export const getContactFolderEntityById = (state, id) => {
  return entitiesSelectors.getContactFolderEntityById(state.entities, id);
};
export const getContactFolderEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getContactFolderEntitiesByIds(state.entities, ids);
};
export const getAllContactFolderEntities = state => {
  return entitiesSelectors.getAllContactFolderEntities(state.entities);
};
export const getFilteredContactFolderEntities = (state, filter) => {
  return entitiesSelectors.getFilteredContactFolderEntities(
    state.entities,
    filter
  );
};

// Entities - Contact
export const getContactEntityById = (state, id) => {
  return entitiesSelectors.getContactEntityById(state.entities, id);
};
export const getContactEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getContactEntitiesByIds(state.entities, ids);
};
export const getAllContactEntities = state => {
  return entitiesSelectors.getAllContactEntities(state.entities);
};
export const getFilteredContactEntities = (state, filter) => {
  return entitiesSelectors.getFilteredContactEntities(state.entities, filter);
};

// Entities - Contract
export const getContractEntityById = (state, id) => {
  return entitiesSelectors.getContractEntityById(state.entities, id);
};
export const getContractEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getContractEntitiesByIds(state.entities, ids);
};
export const getAllContractEntities = state => {
  return entitiesSelectors.getAllContractEntities(state.entities);
};
export const getFilteredContractEntities = (state, filter) => {
  return entitiesSelectors.getFilteredContractEntities(state.entities, filter);
};

// Entities - Conversation
export const getConversationEntityById = (state, id) => {
  return entitiesSelectors.getConversationEntityById(state.entities, id);
};
export const getConversationEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getConversationEntitiesByIds(state.entities, ids);
};
export const getAllConversationEntities = state => {
  return entitiesSelectors.getAllConversationEntities(state.entities);
};
export const getFilteredConversationEntities = (state, filter) => {
  return entitiesSelectors.getFilteredConversationEntities(
    state.entities,
    filter
  );
};

// Entities - Device
export const getDeviceEntityById = (state, id) => {
  return entitiesSelectors.getDeviceEntityById(state.entities, id);
};
export const getDeviceEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getDeviceEntitiesByIds(state.entities, ids);
};
export const getAllDeviceEntities = state => {
  return entitiesSelectors.getAllDeviceEntities(state.entities);
};
export const getFilteredDeviceEntities = (state, filter) => {
  return entitiesSelectors.getFilteredDeviceEntities(state.entities, filter);
};

// Entities - DirectoryRole
export const getDirectoryRoleEntityById = (state, id) => {
  return entitiesSelectors.getDirectoryRoleEntityById(state.entities, id);
};
export const getDirectoryRoleEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getDirectoryRoleEntitiesByIds(state.entities, ids);
};
export const getAllDirectoryRoleEntities = state => {
  return entitiesSelectors.getAllDirectoryRoleEntities(state.entities);
};
export const getFilteredDirectoryRoleEntities = (state, filter) => {
  return entitiesSelectors.getFilteredDirectoryRoleEntities(
    state.entities,
    filter
  );
};

// Entities - Domain
export const getDomainEntityById = (state, id) => {
  return entitiesSelectors.getDomainEntityById(state.entities, id);
};
export const getDomainEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getDomainEntitiesByIds(state.entities, ids);
};
export const getAllDomainEntities = state => {
  return entitiesSelectors.getAllDomainEntities(state.entities);
};
export const getFilteredDomainEntities = (state, filter) => {
  return entitiesSelectors.getFilteredDomainEntities(state.entities, filter);
};

// Entities - DriveItem
export const getDriveItemEntityById = (state, id) => {
  return entitiesSelectors.getDriveItemEntityById(state.entities, id);
};
export const getDriveItemEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getDriveItemEntitiesByIds(state.entities, ids);
};
export const getAllDriveItemEntities = state => {
  return entitiesSelectors.getAllDriveItemEntities(state.entities);
};
export const getFilteredDriveItemEntities = (state, filter) => {
  return entitiesSelectors.getFilteredDriveItemEntities(state.entities, filter);
};

// Entities - Drive
export const getDriveEntityById = (state, id) => {
  return entitiesSelectors.getDriveEntityById(state.entities, id);
};
export const getDriveEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getDriveEntitiesByIds(state.entities, ids);
};
export const getAllDriveEntities = state => {
  return entitiesSelectors.getAllDriveEntities(state.entities);
};
export const getFilteredDriveEntities = (state, filter) => {
  return entitiesSelectors.getFilteredDriveEntities(state.entities, filter);
};

// Entities - Event
export const getEventEntityById = (state, id) => {
  return entitiesSelectors.getEventEntityById(state.entities, id);
};
export const getEventEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getEventEntitiesByIds(state.entities, ids);
};
export const getAllEventEntities = state => {
  return entitiesSelectors.getAllEventEntities(state.entities);
};
export const getFilteredEventEntities = (state, filter) => {
  return entitiesSelectors.getFilteredEventEntities(state.entities, filter);
};

// Entities - Group
export const getGroupEntityById = (state, id) => {
  return entitiesSelectors.getGroupEntityById(state.entities, id);
};
export const getGroupEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getGroupEntitiesByIds(state.entities, ids);
};
export const getAllGroupEntities = state => {
  return entitiesSelectors.getAllGroupEntities(state.entities);
};
export const getFilteredGroupEntities = (state, filter) => {
  return entitiesSelectors.getFilteredGroupEntities(state.entities, filter);
};

// Entities - IdentityProvider
export const getIdentityProviderEntityById = (state, id) => {
  return entitiesSelectors.getIdentityProviderEntityById(state.entities, id);
};
export const getIdentityProviderEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getIdentityProviderEntitiesByIds(
    state.entities,
    ids
  );
};
export const getAllIdentityProviderEntities = state => {
  return entitiesSelectors.getAllIdentityProviderEntities(state.entities);
};
export const getFilteredIdentityProviderEntities = (state, filter) => {
  return entitiesSelectors.getFilteredIdentityProviderEntities(
    state.entities,
    filter
  );
};

// Entities - Insight
export const getInsightEntityById = (state, id) => {
  return entitiesSelectors.getInsightEntityById(state.entities, id);
};
export const getInsightEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getInsightEntitiesByIds(state.entities, ids);
};
export const getAllInsightEntities = state => {
  return entitiesSelectors.getAllInsightEntities(state.entities);
};
export const getFilteredInsightEntities = (state, filter) => {
  return entitiesSelectors.getFilteredInsightEntities(state.entities, filter);
};

// Entities - License
export const getLicenseEntityById = (state, id) => {
  return entitiesSelectors.getLicenseEntityById(state.entities, id);
};
export const getLicenseEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getLicenseEntitiesByIds(state.entities, ids);
};
export const getAllLicenseEntities = state => {
  return entitiesSelectors.getAllLicenseEntities(state.entities);
};
export const getFilteredLicenseEntities = (state, filter) => {
  return entitiesSelectors.getFilteredLicenseEntities(state.entities, filter);
};

// Entities - ListItem
export const getListItemEntityById = (state, id) => {
  return entitiesSelectors.getListItemEntityById(state.entities, id);
};
export const getListItemEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getListItemEntitiesByIds(state.entities, ids);
};
export const getAllListItemEntities = state => {
  return entitiesSelectors.getAllListItemEntities(state.entities);
};
export const getFilteredListItemEntities = (state, filter) => {
  return entitiesSelectors.getFilteredListItemEntities(state.entities, filter);
};

// Entities - List
export const getListEntityById = (state, id) => {
  return entitiesSelectors.getListEntityById(state.entities, id);
};
export const getListEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getListEntitiesByIds(state.entities, ids);
};
export const getAllListEntities = state => {
  return entitiesSelectors.getAllListEntities(state.entities);
};
export const getFilteredListEntities = (state, filter) => {
  return entitiesSelectors.getFilteredListEntities(state.entities, filter);
};

// Entities - MailFolder
export const getMailFolderEntityById = (state, id) => {
  return entitiesSelectors.getMailFolderEntityById(state.entities, id);
};
export const getMailFolderEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getMailFolderEntitiesByIds(state.entities, ids);
};
export const getAllMailFolderEntities = state => {
  return entitiesSelectors.getAllMailFolderEntities(state.entities);
};
export const getFilteredMailFolderEntities = (state, filter) => {
  return entitiesSelectors.getFilteredMailFolderEntities(
    state.entities,
    filter
  );
};

// Entities - Message
export const getMessageEntityById = (state, id) => {
  return entitiesSelectors.getMessageEntityById(state.entities, id);
};
export const getMessageEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getMessageEntitiesByIds(state.entities, ids);
};
export const getAllMessageEntities = state => {
  return entitiesSelectors.getAllMessageEntities(state.entities);
};
export const getFilteredMessageEntities = (state, filter) => {
  return entitiesSelectors.getFilteredMessageEntities(state.entities, filter);
};

// Entities - Notebook
export const getNotebookEntityById = (state, id) => {
  return entitiesSelectors.getNotebookEntityById(state.entities, id);
};
export const getNotebookEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getNotebookEntitiesByIds(state.entities, ids);
};
export const getAllNotebookEntities = state => {
  return entitiesSelectors.getAllNotebookEntities(state.entities);
};
export const getFilteredNotebookEntities = (state, filter) => {
  return entitiesSelectors.getFilteredNotebookEntities(state.entities, filter);
};

// Entities - Organization
export const getOrganizationEntityById = (state, id) => {
  return entitiesSelectors.getOrganizationEntityById(state.entities, id);
};
export const getOrganizationEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getOrganizationEntitiesByIds(state.entities, ids);
};
export const getAllOrganizationEntities = state => {
  return entitiesSelectors.getAllOrganizationEntities(state.entities);
};
export const getFilteredOrganizationEntities = (state, filter) => {
  return entitiesSelectors.getFilteredOrganizationEntities(
    state.entities,
    filter
  );
};

// Entities - OrgContact
export const getOrgContactEntityById = (state, id) => {
  return entitiesSelectors.getOrgContactEntityById(state.entities, id);
};
export const getOrgContactEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getOrgContactEntitiesByIds(state.entities, ids);
};
export const getAllOrgContactEntities = state => {
  return entitiesSelectors.getAllOrgContactEntities(state.entities);
};
export const getFilteredOrgContactEntities = (state, filter) => {
  return entitiesSelectors.getFilteredOrgContactEntities(
    state.entities,
    filter
  );
};

// Entities - Page
export const getPageEntityById = (state, id) => {
  return entitiesSelectors.getPageEntityById(state.entities, id);
};
export const getPageEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getPageEntitiesByIds(state.entities, ids);
};
export const getAllPageEntities = state => {
  return entitiesSelectors.getAllPageEntities(state.entities);
};
export const getFilteredPageEntities = (state, filter) => {
  return entitiesSelectors.getFilteredPageEntities(state.entities, filter);
};

// Entities - Person
export const getPersonEntityById = (state, id) => {
  return entitiesSelectors.getPersonEntityById(state.entities, id);
};
export const getPersonEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getPersonEntitiesByIds(state.entities, ids);
};
export const getAllPersonEntities = state => {
  return entitiesSelectors.getAllPersonEntities(state.entities);
};
export const getFilteredPersonEntities = (state, filter) => {
  return entitiesSelectors.getFilteredPersonEntities(state.entities, filter);
};

// Entities - Plan
export const getPlanEntityById = (state, id) => {
  return entitiesSelectors.getPlanEntityById(state.entities, id);
};
export const getPlanEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getPlanEntitiesByIds(state.entities, ids);
};
export const getAllPlanEntities = state => {
  return entitiesSelectors.getAllPlanEntities(state.entities);
};
export const getFilteredPlanEntities = (state, filter) => {
  return entitiesSelectors.getFilteredPlanEntities(state.entities, filter);
};

// Entities - Post
export const getPostEntityById = (state, id) => {
  return entitiesSelectors.getPostEntityById(state.entities, id);
};
export const getPostEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getPostEntitiesByIds(state.entities, ids);
};
export const getAllPostEntities = state => {
  return entitiesSelectors.getAllPostEntities(state.entities);
};
export const getFilteredPostEntities = (state, filter) => {
  return entitiesSelectors.getFilteredPostEntities(state.entities, filter);
};

// Entities - RiskEvent
export const getRiskEventEntityById = (state, id) => {
  return entitiesSelectors.getRiskEventEntityById(state.entities, id);
};
export const getRiskEventEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getRiskEventEntitiesByIds(state.entities, ids);
};
export const getAllRiskEventEntities = state => {
  return entitiesSelectors.getAllRiskEventEntities(state.entities);
};
export const getFilteredRiskEventEntities = (state, filter) => {
  return entitiesSelectors.getFilteredRiskEventEntities(state.entities, filter);
};

// Entities - SecurityAlert
export const getSecurityAlertEntityById = (state, id) => {
  return entitiesSelectors.getSecurityAlertEntityById(state.entities, id);
};
export const getSecurityAlertEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getSecurityAlertEntitiesByIds(state.entities, ids);
};
export const getAllSecurityAlertEntities = state => {
  return entitiesSelectors.getAllSecurityAlertEntities(state.entities);
};
export const getFilteredSecurityAlertEntities = (state, filter) => {
  return entitiesSelectors.getFilteredSecurityAlertEntities(
    state.entities,
    filter
  );
};

// Entities - ServicePlan
export const getServicePlanEntityById = (state, id) => {
  return entitiesSelectors.getServicePlanEntityById(state.entities, id);
};
export const getServicePlanEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getServicePlanEntitiesByIds(state.entities, ids);
};
export const getAllServicePlanEntities = state => {
  return entitiesSelectors.getAllServicePlanEntities(state.entities);
};
export const getFilteredServicePlanEntities = (state, filter) => {
  return entitiesSelectors.getFilteredServicePlanEntities(
    state.entities,
    filter
  );
};

// Entities - ServicePrincipal
export const getServicePrincipalEntityById = (state, id) => {
  return entitiesSelectors.getServicePrincipalEntityById(state.entities, id);
};
export const getServicePrincipalEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getServicePrincipalEntitiesByIds(
    state.entities,
    ids
  );
};
export const getAllServicePrincipalEntities = state => {
  return entitiesSelectors.getAllServicePrincipalEntities(state.entities);
};
export const getFilteredServicePrincipalEntities = (state, filter) => {
  return entitiesSelectors.getFilteredServicePrincipalEntities(
    state.entities,
    filter
  );
};

// Entities - Service
export const getServiceEntityById = (state, id) => {
  return entitiesSelectors.getServiceEntityById(state.entities, id);
};
export const getServiceEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getServiceEntitiesByIds(state.entities, ids);
};
export const getAllServiceEntities = state => {
  return entitiesSelectors.getAllServiceEntities(state.entities);
};
export const getFilteredServiceEntities = (state, filter) => {
  return entitiesSelectors.getFilteredServiceEntities(state.entities, filter);
};

// Entities - Site
export const getSiteEntityById = (state, id) => {
  return entitiesSelectors.getSiteEntityById(state.entities, id);
};
export const getSiteEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getSiteEntitiesByIds(state.entities, ids);
};
export const getAllSiteEntities = state => {
  return entitiesSelectors.getAllSiteEntities(state.entities);
};
export const getFilteredSiteEntities = (state, filter) => {
  return entitiesSelectors.getFilteredSiteEntities(state.entities, filter);
};

// Entities - Task
export const getTaskEntityById = (state, id) => {
  return entitiesSelectors.getTaskEntityById(state.entities, id);
};
export const getTaskEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getTaskEntitiesByIds(state.entities, ids);
};
export const getAllTaskEntities = state => {
  return entitiesSelectors.getAllTaskEntities(state.entities);
};
export const getFilteredTaskEntities = (state, filter) => {
  return entitiesSelectors.getFilteredTaskEntities(state.entities, filter);
};

// Entities - Team
export const getTeamEntityById = (state, id) => {
  return entitiesSelectors.getTeamEntityById(state.entities, id);
};
export const getTeamEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getTeamEntitiesByIds(state.entities, ids);
};
export const getAllTeamEntities = state => {
  return entitiesSelectors.getAllTeamEntities(state.entities);
};
export const getFilteredTeamEntities = (state, filter) => {
  return entitiesSelectors.getFilteredTeamEntities(state.entities, filter);
};

// Entities - TeamsAppInstallation
export const getTeamsAppInstallationEntityById = (state, id) => {
  return entitiesSelectors.getTeamsAppInstallationEntityById(
    state.entities,
    id
  );
};
export const getTeamsAppInstallationEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getTeamsAppInstallationEntitiesByIds(
    state.entities,
    ids
  );
};
export const getAllTeamsAppInstallationEntities = state => {
  return entitiesSelectors.getAllTeamsAppInstallationEntities(state.entities);
};
export const getFilteredTeamsAppInstallationEntities = (state, filter) => {
  return entitiesSelectors.getFilteredTeamsAppInstallationEntities(
    state.entities,
    filter
  );
};

// Entities - TeamsTab
export const getTeamsTabEntityById = (state, id) => {
  return entitiesSelectors.getTeamsTabEntityById(state.entities, id);
};
export const getTeamsTabEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getTeamsTabEntitiesByIds(state.entities, ids);
};
export const getAllTeamsTabEntities = state => {
  return entitiesSelectors.getAllTeamsTabEntities(state.entities);
};
export const getFilteredTeamsTabEntities = (state, filter) => {
  return entitiesSelectors.getFilteredTeamsTabEntities(state.entities, filter);
};

// Entities - Thread
export const getThreadEntityById = (state, id) => {
  return entitiesSelectors.getThreadEntityById(state.entities, id);
};
export const getThreadEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getThreadEntitiesByIds(state.entities, ids);
};
export const getAllThreadEntities = state => {
  return entitiesSelectors.getAllThreadEntities(state.entities);
};
export const getFilteredThreadEntities = (state, filter) => {
  return entitiesSelectors.getFilteredThreadEntities(state.entities, filter);
};

// Entities - User
export const getUserEntityById = (state, id) => {
  return entitiesSelectors.getUserEntityById(state.entities, id);
};
export const getUserEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getUserEntitiesByIds(state.entities, ids);
};
export const getAllUserEntities = state => {
  return entitiesSelectors.getAllUserEntities(state.entities);
};
export const getFilteredUserEntities = (state, filter) => {
  return entitiesSelectors.getFilteredUserEntities(state.entities, filter);
};

// Entities - Version
export const getVersionEntityById = (state, id) => {
  return entitiesSelectors.getVersionEntityById(state.entities, id);
};
export const getVersionEntitiesByIds = (state, ids) => {
  return entitiesSelectors.getVersionEntitiesByIds(state.entities, ids);
};
export const getAllVersionEntities = state => {
  return entitiesSelectors.getAllVersionEntities(state.entities);
};
export const getFilteredVersionEntities = (state, filter) => {
  return entitiesSelectors.getFilteredVersionEntities(state.entities, filter);
};
