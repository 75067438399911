import React from 'react';
import PropTypes from 'prop-types';
import { List, Image, Label } from 'semantic-ui-react';
import DefaultImage from '../../../app/assets/images/defaults/img-default-task.png';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as Colors from '../../../app/config/settings/ColorOfState';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as Validation from '../../../common/utils/Validation';

class TaskItemlistItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataKey } = this.props;

    // Device size
    let lengthMeta = 52;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      lengthMeta = 30;
    }

    // Image
    const entityImage =
      dataEntity && dataEntity.image ? dataEntity.image.url : DefaultImage;

    // Title
    const titleString = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.title) ? dataEntity.title : '--',
      lengthMeta,
      true
    );

    // Label
    const percentComplete = !Validation.isEmpty(dataEntity)
      ? dataEntity.percentComplete
      : 0;
    let labelValue = '';
    if (!isNaN(percentComplete) && percentComplete >= 100) {
      labelValue = Enums.TASK_STATUS.completed;
    } else if (!isNaN(percentComplete) && percentComplete > 0) {
      labelValue = Enums.TASK_STATUS.inProgress;
    } else {
      labelValue = Enums.TASK_STATUS.notStarted;
    }
    const labelColor = Colors.GetColorOfTaskState(labelValue);

    // Meta
    const metaProject = StringUtil.MaxLength(
      FieldUtil.GetTitleFromPlannerPlan(dataEntity.plan),
      lengthMeta,
      true
    );
    const checklistItems = !Validation.isEmpty(dataEntity)
      ? dataEntity.checklistItemCount
      : 0;
    const checklistItemsOpen = !Validation.isEmpty(dataEntity)
      ? dataEntity.activeChecklistItemCount
      : 0;
    const checklistItemsClosed = checklistItems - checklistItemsOpen;

    const metaChecklist =
      checklistItems > 0
        ? checklistItemsClosed + '/' + checklistItems + ' Subtasks completed'
        : 'No Subtasks defined';

    const categoriesObject = dataEntity ? dataEntity.appliedCategories : {};
    let metaCategories = {};
    for (const key in categoriesObject) {
      let value = categoriesObject[key];
      if (key === 'category1' && value) {
        metaCategories.cat1 = true;
      }
      if (key === 'category2' && value) {
        metaCategories.cat2 = true;
      }
      if (key === 'category3' && value) {
        metaCategories.cat3 = true;
      }
      if (key === 'category4' && value) {
        metaCategories.cat4 = true;
      }
      if (key === 'category5' && value) {
        metaCategories.cat5 = true;
      }
      if (key === 'category6' && value) {
        metaCategories.cat6 = true;
      }
    }

    // WebLink
    let webLink = '';

    return (
      <List.Item
        key={dataKey}
        onClick={e => this.props.onEntityClick(e, dataEntity.id, webLink)}
      >
        <List.Content floated="right">
          <Label
            color={labelColor}
            circular
            empty
            className="app-itemlist-label"
          />
          <br />
        </List.Content>
        <Image size="mini" src={entityImage} alt={titleString} />
        <List.Content className="app-meta-item-content">
          <List.Header>{titleString}</List.Header>
          <List.Description>{metaProject}</List.Description>
          <List.Description>
            <div className="app-meta-item-description-categories">
              {metaCategories && metaCategories.cat1 && (
                <span className="app-label-item">
                  <Label color="purple" />
                </span>
              )}
              {metaCategories && metaCategories.cat2 && (
                <span className="app-label-item">
                  <Label color="red" />
                </span>
              )}
              {metaCategories && metaCategories.cat3 && (
                <span className="app-label-item">
                  <Label color="yellow" />
                </span>
              )}
              {metaCategories && metaCategories.cat4 && (
                <span className="app-label-item">
                  <Label color="olive" />
                </span>
              )}
              {metaCategories && metaCategories.cat5 && (
                <span className="app-label-item">
                  <Label color="green" />
                </span>
              )}
              {metaCategories && metaCategories.cat6 && (
                <span className="app-label-item">
                  <Label color="blue" />
                </span>
              )}
            </div>
            <div>{metaChecklist}</div>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
TaskItemlistItem.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  onEntityClick: PropTypes.func.isRequired
};

export default TaskItemlistItem;
