export const AUTHORISATION_CONFIG = {
  clientID: 'c90bb3e3-e451-4109-b125-206cec2c2dcf',
  appID: '682cb10b-f080-4aff-a337-c021257d2334',
  appKey: '3d917f9e-d73a-4d06-9165-562f5f7bf8e1',
  graphScopes: [
    'Agreement.Read.All',
    'AgreementAcceptance.Read.All',
    'Bookings.Read.All',
    'Calendars.Read.Shared',
    'Contacts.Read.Shared',
    'Directory.Read.All',
    'Group.Read.All',
    'IdentityProvider.Read.All',
    'IdentityRiskEvent.Read.All',
    'Mail.Read.Shared',
    'Notes.Read.All',
    'People.Read.All',
    'SecurityEvents.Read.All',
    'Sites.Read.All',
    'Tasks.Read.Shared',
    'User.Read.All'
  ],
  graphEndpoint: 'https://graph.microsoft.com/v1.0/me',

  /* SELECTED VERSION */
  loginRedirectUri: 'https://app.appsolut-graph.com/callback',
  logoutRedirectUri: "https://app.appsolut-graph.com/landing"

  /* Operational
  loginRedirectUri: 'https://app.appsolut-graph.com/callback',
  logoutRedirectUri: "https://app.appsolut-graph.com/landing"
  */
  /* Development
  loginRedirectUri: 'http://localhost:3000/callback',
  logoutRedirectUri: 'http://localhost:3000/landing'
  */
};
