import React from 'react';
import PropTypes from 'prop-types';
import { Message, Icon } from 'semantic-ui-react';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Validation from '../../utils/Validation';

class SystemMessage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleClearAppErrorClick = e => {
    e.preventDefault();
    //window.alert("Clear App Error");
    this.props.clearAppError();
  };

  handleClearFetchingErrorClick = e => {
    e.preventDefault();
    //window.alert("Clear Fetching Error");
    this.props.clearFetchingError();
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    //const { auth, location, match, params } = this.props;
    const { hasAppError, dataAppError } = this.props;
    const { hasFetchingError, dataFetchingError } = this.props;

    const errorAppHeader =
      dataAppError && !Validation.isEmpty(dataAppError.code)
        ? 'Error: ' + dataAppError.code
        : 'Error';
    const errorAppText =
      dataAppError && !Validation.isEmpty(dataAppError.message)
        ? dataAppError.message
        : 'An internal Error occurred';

    const errorFetchingStatus =
      dataFetchingError && !Validation.isEmpty(dataFetchingError.statusCode)
        ? dataFetchingError.statusCode
        : '';
    const errorFetchingCode =
      dataFetchingError && !Validation.isEmpty(dataFetchingError.code)
        ? dataFetchingError.code
        : '';
    let errorFetchingHeader = 'API Status:';
    if (!Validation.isEmpty(errorFetchingStatus)) {
      errorFetchingHeader += ' ' + errorFetchingStatus;
    }
    if (!Validation.isEmpty(errorFetchingCode)) {
      errorFetchingHeader += ' (' + errorFetchingCode + ')';
    }
    const errorFetchingText =
      dataFetchingError && !Validation.isEmpty(dataFetchingError.message)
        ? dataFetchingError.message
        : 'An Error occurred during the last data fetch';

    return (
      <div>
        {hasAppError && (
          <Message negative>
            <Message.Header>
              {errorAppHeader}
              <Icon
                link
                name={MenuConfig.ICONS_MENU_DEFAULTS.close}
                color="brown"
                onClick={e => this.handleClearAppErrorClick(e)}
              />
            </Message.Header>
            <p>{errorAppText}</p>
          </Message>
        )}
        {hasFetchingError && (
          <Message negative>
            <Message.Header>
              {errorFetchingHeader}
              <Icon
                link
                name={MenuConfig.ICONS_MENU_DEFAULTS.close}
                color="brown"
                onClick={e => this.handleClearFetchingErrorClick(e)}
              />
            </Message.Header>
            <p>{errorFetchingText}</p>
          </Message>
        )}
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------

SystemMessage.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  hasAppError: PropTypes.bool.isRequired,
  dataAppError: PropTypes.object,
  hasFetchingError: PropTypes.bool.isRequired,
  dataFetchingError: PropTypes.object,
  clearAppError: PropTypes.func.isRequired,
  clearFetchingError: PropTypes.func.isRequired
};

export default SystemMessage;
