import moment from 'moment';
import * as Actions from '../types';
import * as Selectors from '../../reducers/rootReducer';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as ObjectConfig from '../../../config/entities/Objects';
import * as NavigationUtils from '../../../../common/utils/NavigationUtils';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// CONTACT
// - invalidateContact
// - loadContact
// - receiveEntity
// - receiveEntities
//-------------------------
// With Data Extension
//-------------------------

export const invalidateContact = entityId => {
  return {
    type: Actions.CONTACT_INVALIDATE,
    payload: {
      entityId: entityId
    }
  };
};

export const loadContact = (auth, id, parent = {}, segment = {}) => (
  dispatch,
  getState
) => {
  if (shouldFetchEntity(getState(), id)) {
    // Fetch the entity from data api
    return dispatch(fetchContact(auth, id, parent, segment));
  } else {
    const entityContainer = Selectors.getContactEntityById(getState(), id);
    const extendedAvailable =
      entityContainer &&
      entityContainer.entity &&
      Validation.isDefined(entityContainer.entity, 'image')
        ? true
        : false;
    if (entityContainer && entityContainer.entity && !extendedAvailable) {
      // NOT ACTIVATED
      // only few images assigned, but every not assigned contact produces an error message in the console
      //return dispatch(fetchContactExtension(auth, id, parent, segment));
    } else {
      // Let the calling code know there's nothing to wait for.
      return Promise.resolve();
    }
  }
};

export const receiveEntity = data => {
  return {
    type: Actions.CONTACT_RECEIVE,
    payload: {
      entityId: !Validation.isEmpty(data) ? data.id : '',
      entity: data,
      receivedAt: Date.now()
    }
  };
};

export const receiveEntities = entityList => {
  let entityIds = [];
  let entities = [];
  entityList.forEach(entity => {
    if (entityIds.indexOf(entity.id) === -1) {
      entityIds = entityIds.concat(entity.id);
      entities = entities.concat(entity);
    }
  });
  return {
    type: Actions.CONTACTS_RECEIVE,
    payload: {
      entityIds: entityIds,
      entities: entities,
      receivedAt: Date.now()
    }
  };
};

//-------------------------
// Helpers
//-------------------------

const requestEntity = entityId => {
  return {
    type: Actions.CONTACT_REQUEST,
    payload: {
      entityId: entityId
    }
  };
};

const receiveError = err => {
  if (err.statusCode === 404) {
    return {
      type: Actions.CONTACT_RECEIVE,
      payload: {
        entityId: '',
        entity: {},
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const shouldFetchEntity = (state, id) => {
  const entityContainer = Selectors.getContactEntityById(state, id);
  const staleDate = moment().subtract(10, 'minutes');

  if (entityContainer) {
    if (!entityContainer.lastUpdated) {
      return true;
    } else if (
      entityContainer.lastUpdated &&
      entityContainer.lastUpdated < staleDate
    ) {
      return true;
    } else if (entityContainer.didInvalidate) {
      return true;
    } else if (entityContainer.isFetching) {
      return false;
    } else return false;
  } else return true;
};

const fetchContact = (auth, id, parent, segment) => dispatch => {
  dispatch(requestEntity(id));

  const type = ObjectConfig.CONTACT.code;
  const queryUrl = NavigationUtils.CreateNodeQuery(type, id, parent, segment);
  console.log('API Path: ' + queryUrl);

  let basicEntity = {};
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(queryUrl)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(basic => {
          basicEntity = { ...basic };
          dispatch(receiveEntity(basicEntity));
          // NOT ACTIVATED
          // only few images assigned, but every not assigned contact produces an error message in the console
          //dispatch(fetchExtendedData(client, id, parent, segment));
        })
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};

//-------------------------
// Helpers - Extension
//-------------------------

const receiveEntityExtension = (id, extension) => {
  return {
    type: Actions.CONTACT_EXTENSION_RECEIVE,
    payload: {
      entityId: id,
      entity: extension,
      receivedAt: Date.now()
    }
  };
};

// const fetchContactExtension = (auth, id, parent, segment) => dispatch => {
//   auth
//     .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
//     .then(accessToken => {
//       const client = MicrosoftGraph.Client.init({
//         authProvider: done => done(null, accessToken)
//       });
//       dispatch(fetchExtendedData(client, id, parent, segment));
//     })
//     .catch(err => console.log(err));
// };

export const fetchExtendedData = (client, id, parent, segment) => dispatch => {
  const extendedEntity = { image: {} };
  const type = ObjectConfig.CONTACT.code;
  const baseUrl = NavigationUtils.CreateNodeQuery(type, id, parent, segment);
  const photoUrl = `${baseUrl}/photo`;
  //console.log("API Photo Path: " + photoUrl);

  client
    .api(photoUrl)
    .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
    .get()
    .then(imageParameter => {
      const imageBaseNode = { ...imageParameter };
      client
        .api(`${photoUrl}/$value`)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .responseType(MicrosoftGraph.ResponseType.ARRAYBUFFER)
        .get()
        .then(image => {
          try {
            const base64 = Buffer.from(image).toString('base64');
            const contentType = imageBaseNode['@odata.mediaContentType'];
            const imageUrl = `data:${contentType};base64,${base64}`;
            const imageNode = { ...imageBaseNode, url: imageUrl };
            const imageEntity = { ...extendedEntity, image: imageNode };
            dispatch(receiveEntityExtension(id, imageEntity));
          } catch (error) {
            console.error(error);
          }
        });
    });
};
