import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Accordion, Icon } from 'semantic-ui-react';
import CardHeader from './Version.Card.Header';
import CardForm from './Version.Card.Form';
import CardBody from './Version.Card.Body';
import DataLoader from '../../../common/components/controls/DataLoader';
import * as ApplicationConfig from '../../../app/config/constants/Application';
import * as ObjectConfig from '../../../app/config/entities/Objects';
import * as Enums from '../../../app/config/constants/Enumerations';

class VersionCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { accordionIndex: -1 };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    const versionId = this.props.dataEntity ? this.props.dataEntity.id : '';
    const segment = {
      type: this.props.dataContext.segmentType,
      id: this.props.dataContext.segmentId
    };
    const parent = {
      type: this.props.dataContext.parentType,
      id: this.props.dataContext.parentId
    };
    this.props.loadVersion(this.props.auth, versionId, parent, segment);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleVersionClick = (e, key, url) => {
    e.preventDefault();
    this.props.setCurrentContext(ObjectConfig.VERSION.code, key);
    this.props.setCurrentNode(
      this.props.area,
      ObjectConfig.VERSION.code,
      key,
      url
    );
    this.props.history.push(`${this.props.match.url}/node`);
  };

  handleAccordionClick = (e, { index }) => {
    const { accordionIndex } = this.state;
    const newIndex = accordionIndex === index ? -1 : index;
    this.setState({ accordionIndex: newIndex });
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { accordionIndex } = this.state;
    const { auth, location, match, params } = this.props;
    const { dataEntity, dataLoading, showObjectLink } = this.props;
    const { smallContainer, labelContext, showExtended } = this.props;

    if (dataLoading) {
      return <DataLoader />;
    }

    return (
      <Segment basic className="app-itemcard">
        <CardHeader
          auth={auth}
          location={location}
          match={match}
          params={params}
          dataEntity={dataEntity}
          smallContainer={smallContainer}
          labelContext={labelContext}
          showObjectLink={showObjectLink}
          onEntityClick={this.handleVersionClick}
        />
        {showExtended && (
          <div className="app-itemcard-details-wrapper">
            <Accordion fluid styled>
              <Accordion.Title
                active={accordionIndex === 0}
                index={0}
                onClick={this.handleAccordionClick}
              >
                <Icon name="dropdown" />
                Details
              </Accordion.Title>
              <Accordion.Content active={accordionIndex === 0}>
                <CardForm
                  auth={auth}
                  location={location}
                  match={match}
                  params={params}
                  dataEntity={dataEntity}
                  smallContainer={smallContainer}
                  mode={Enums.FORM_MODE.view}
                />
              </Accordion.Content>
              <Accordion.Title
                active={accordionIndex === 1}
                index={1}
                onClick={this.handleAccordionClick}
              >
                <Icon name="dropdown" />
                Body
              </Accordion.Title>
              <Accordion.Content active={accordionIndex === 1}>
                <CardBody
                  auth={auth}
                  location={location}
                  match={match}
                  params={params}
                  dataEntity={dataEntity}
                  mode={Enums.FORM_MODE.view}
                />
              </Accordion.Content>
            </Accordion>
          </div>
        )}
      </Segment>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
VersionCard.defaultProps = {
  area: ApplicationConfig.NAVIGATION_AREA.dashboard,
  smallContainer: true,
  showExtended: false,
  showObjectLink: false
};

VersionCard.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  dataContext: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  area: PropTypes.string,
  smallContainer: PropTypes.bool,
  labelContext: PropTypes.string,
  showExtended: PropTypes.bool,
  showObjectLink: PropTypes.bool,
  loadVersion: PropTypes.func.isRequired,
  invalidateVersion: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired
};

export default VersionCard;
