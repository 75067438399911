import * as Actions from '../../actions/types';
import * as Validation from '../../../../common/utils/Validation';

const initialState = {
  isAuthenticated: false,
  userId: '',
  user: {}
};

//-------------------------
// Helpers
//-------------------------

function setUser(state, action) {
  const { payload } = action;
  if (payload && payload.receivedAt) {
    const { entityId, entity, receivedAt } = payload;
    return {
      ...state,
      isAuthenticated: !Validation.isEmpty(entity),
      userId: entityId,
      user: entity,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    isAuthenticated: false,
    userId: '',
    user: {}
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.AUTH_USER_RECEIVE:
      return setUser(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getAuth = state => {
  return state;
};
