import * as Validation from './Validation';

export const MaxLength = (StringValue, MaxLength, WithElipsis) => {
  if (StringValue && MaxLength) {
    if (!isNaN(MaxLength)) {
      if (StringValue.length > MaxLength) {
        const charCount = WithElipsis ? MaxLength - 3 : MaxLength;
        let newString = StringValue.substr(0, charCount);
        if (WithElipsis) {
          newString = newString + '...';
        }
        return newString;
      }
    }
  }
  return StringValue;
};

export const NoEmptyString = (StringValue, Replacement = '--') => {
  if (Validation.isEmpty(StringValue)) {
    return Replacement;
  }
  return StringValue;
};

export const HtmlLinebreaks = StringValue => {
  if (StringValue) {
    const newString = StringValue.replace(/\n/g, '<br />');
    return newString;
  }
  return StringValue;
};
