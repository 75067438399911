import * as Actions from '../../actions/types';

const initialState = [];

//-------------------------
// Helpers
//-------------------------

function addQueryIndex(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;
    if (state.indexOf(index) === -1) {
      return state.concat(index);
    }
    return state;
  }
  return state;
}

function addQueryIndexFromNavigation(state, action) {
  const { payload } = action;
  if (payload && payload.queryIndex) {
    const { queryIndex } = payload;
    const newAction = {
      payload: {
        index: queryIndex
      }
    };
    const newState = addQueryIndex(state, newAction);
    return newState;
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.GRAPH_QUERY_RECEIVE:
    case Actions.PATHFINDER_QUERY_RECEIVE:
      return addQueryIndexFromNavigation(state, action);
    default:
      return state;
  }
}
