import React from 'react';
import { Container, Image, Divider } from 'semantic-ui-react';
import ImageHome from '../../../../app/assets/images/content/Screenshot-Home.png';
import ImageDashboard from '../../../../app/assets/images/content/Screenshot-Dashboard.png';
import ImageNavigator from '../../../../app/assets/images/content/Screenshot-Navigator.png';
import ImagePathfinder from '../../../../app/assets/images/content/Screenshot-Pathfinder.png';
import ImageGraph from '../../../../app/assets/images/content/Screenshot-Graph.png';

class AboutPage extends React.Component {
  render() {
    return (
      <Container>
        <h1 id="top" className="app-info-title-h1">
          appsolut graph
        </h1>
        <p className="app-info-teaser">
          Your personal Navigator to explore and utilize the full power of
          Office 365
        </p>
        <p>
          Office 365 is the leading and most comprehensive business suite of
          software and services. You can create all kind of documents,
          collaborate with your teams (and the world) and organize your
          projects.
        </p>
        <p>
          appsolut graph can help you to keep control in this universe of
          information. Based on the extensive capabilities of Microsoft Graph,
          it provides you with a single point of access to Office 365 and an
          easy to use navigator. Surf the relations between documents, persons,
          teams and many other components of Office 365, discover the relevant
          details at your fingertips - and you can easily get the whole picture.
        </p>
        <p>
          At any point you can click on the weblinks provided and start working
          with the appropriate Office 365 service immediately. No hassle, just
          click and work.
        </p>
        <p>
          The integration of Office 365 is a major step in our journey to a
          master dashboard for businesses. The next steps will enable the
          integration of Dynamics 365, Shopify and Zendesk. All integrated
          within one comprehensive business graph.
        </p>

        <Divider clearing />

        <Image
          src={ImageHome}
          floated="left"
          size="large"
          className="app-info-image-left"
        />
        <h2 id="top" className="app-info-title-h2">
          Getting Started
        </h2>
        <p>The Installation of appsolut graph is as easy as pie:</p>
        <ul className="app-list-inside">
          <li>Log on to your Office 365 account as usually</li>
          <li>Click on the App-Launcher and open the AppSource Marketplace</li>
          <li>From the Web-Apps section browse for appsolut graph</li>
          <li>
            On clicking the appsolut graph app, you will be taken to the Welcome
            page
          </li>
          <li>
            Accept the terms and conditions and provide the Consent for the
            application (Please note: you need to have administrator privilege
            in your Office 365 account or need your administrator to consent to
            the application)
          </li>
          <li>
            That’s all: Your account is now enabled to use the appsolut graph
            application. Just sign in with your Microsoft Work or School account
            and start surfing.
          </li>
        </ul>

        <Divider clearing />

        <Image
          src={ImageDashboard}
          floated="right"
          size="big"
          className="app-info-image-right"
        />
        <h2 id="top" className="app-info-title-h2">
          Dashboard
        </h2>
        <p>
          Get direct access to your data in Office 365. Your Teams, Tasks,
          Appointments or Activities are just a click away.
        </p>

        <Divider clearing />

        <Image
          src={ImageNavigator}
          floated="left"
          size="big"
          className="app-info-image-left"
        />
        <h2 id="top" className="app-info-title-h2">
          Navigator
        </h2>
        <p>
          Click on any Entity of Office 365 to open the Navigator. View detail
          information, click on provided web-links to edit the entity with the
          appropriate Office 365 service or select the relation to navigate.
        </p>

        <Divider clearing />

        <Image
          src={ImagePathfinder}
          floated="right"
          size="big"
          className="app-info-image-right"
        />
        <h2 id="top" className="app-info-title-h2">
          Pathfinder
        </h2>
        <p>
          All selected Relations are presented in the Pathfinder. You can travel
          through the history of navigation and explore the complete network of
          Office 365 artefacts.
        </p>

        <Divider clearing />

        <Image
          src={ImageGraph}
          floated="left"
          size="big"
          className="app-info-image-left"
        />
        <h2 id="top" className="app-info-title-h2">
          Graph
        </h2>
        <p>
          The next step: the complete Navigation Path can be presented as visual
          Graph. With no additional effort. Click on any Node or Relation to
          open the Navigator and continue surfing.
        </p>
      </Container>
    );
  }
}

export default AboutPage;
