import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as NodeActions from '../../../app/store/actions/current/nodeActions';
import * as StepActions from '../../../app/store/actions/pathfinder/stepActions';
import * as PathActions from '../../../app/store/actions/pathfinder/pathActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import BaseComponent from './Node.Canvas';

const mapStateToProps = state => {
  const nodeContainer = Selectors.getCurrentNode(state);
  const contextContainer = Selectors.getCurrentContext(state);
  const meContainer = Selectors.getMyUser(state);
  const dataSteps = Selectors.getAllPathfinderSteps(state);
  return {
    dataNode: nodeContainer,
    dataContext: contextContainer,
    dataSteps: dataSteps,
    dataMe: meContainer
  };
};

const mapDispatchToProps = dispatch => ({
  clearPathfinder: () => dispatch(PathActions.clearPathfinder()),
  addNewPathfinderStep: auth => dispatch(StepActions.addNewStep(auth)),
  setRelation: (relation, loadAll) =>
    dispatch(NodeActions.setRelation(relation, loadAll)),
  setCurrentStepPositionToLast: () =>
    dispatch(PathActions.setCurrentStepPositionToLast()),
  setCurrentStepPosition: position =>
    dispatch(PathActions.setCurrentStepPosition(position))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
