import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as onboardingActions from '../../../app/store/actions/app/onboardingActions';
import * as initialGroupActions from '../../../app/store/actions/dashboard/initialGroupActions';
import * as initialPlanActions from '../../../app/store/actions/dashboard/initialPlanActions';
import * as initialUserActions from '../../../app/store/actions/dashboard/initialUserActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import BaseComponent from './Home.Onboarding';

const mapStateToProps = state => {
  const authContainer = Selectors.getAuth(state);
  const onboardingStatus = Selectors.getOnboarding(state);

  return {
    dataAuth: authContainer,
    onboardingStatus: onboardingStatus
  };
};

const mapDispatchToProps = dispatch => ({
  runOnboarding: aadUser => dispatch(onboardingActions.runOnboarding(aadUser)),
  loadInitialGroups: auth =>
    dispatch(initialGroupActions.loadInitialGroups(auth)),
  loadInitialPlans: auth => dispatch(initialPlanActions.loadInitialPlans(auth)),
  loadInitialUsers: auth => dispatch(initialUserActions.loadInitialUsers(auth))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
