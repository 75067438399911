import * as Actions from '../types';
import * as SubscriptionDataService from '../../../../data/admin/SubscriptionDataService';
import * as Validation from '../../../../common/utils/Validation';

//-------------------------
// ADMIN SUBSCRIPTIONS
// - invalidateSubscriptions
// - loadSubscriptionsByCompanyId
//-------------------------

export const invalidateSubscriptions = () => {
  return {
    type: Actions.ADMIN_SUBSCRIPTIONS_INVALIDATE
  };
};

export const loadSubscriptionsByCompanyId = companyId => (
  dispatch,
  getState
) => {
  if (shouldFetchEntities(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchSubscriptionsByCompanyId(companyId));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

//-------------------------
// Helpers
//-------------------------

const requestEntities = () => {
  return {
    type: Actions.ADMIN_SUBSCRIPTIONS_REQUEST
  };
};

const receiveEntities = data => {
  const entityList = !Validation.isEmpty(data) ? data : [];
  let entityIds = [];
  entityList.forEach(entity => {
    if (entityIds.indexOf(entity._id) === -1) {
      entityIds = entityIds.concat(entity._id);
    }
  });
  return {
    type: Actions.ADMIN_SUBSCRIPTIONS_RECEIVE,
    payload: {
      entityIds: entityIds,
      entities: entityList,
      receivedAt: Date.now()
    }
  };
};

const receiveError = err => {
  if (err.response.status === 404) {
    return {
      type: Actions.ADMIN_SUBSCRIPTIONS_RECEIVE,
      payload: {
        entityIds: [],
        entities: [],
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err.response
    }
  };
};

const shouldFetchEntities = state => {
  // Allways load from Database
  return true;
};

const fetchSubscriptionsByCompanyId = companyId => dispatch => {
  dispatch(requestEntities());
  SubscriptionDataService.GetByCompany(companyId)
    .then(res => dispatch(receiveEntities(res)))
    .catch(err => dispatch(receiveError(err)));
};
