import * as Actions from '../../actions/types';

const initialState = {
  openActivities: 0,
  comingEvents: 0,
  unreadMessages: 0,
  openTasks: 0
};

//-------------------------
// Helpers
//-------------------------

function setOpenActivities(state, action) {
  const { payload } = action;
  const value = payload && !isNaN(payload.value) ? payload.value : 0;
  return {
    ...state,
    openActivities: value
  };
}

function setComingEvents(state, action) {
  const { payload } = action;
  const value = payload && !isNaN(payload.value) ? payload.value : 0;
  return {
    ...state,
    comingEvents: value
  };
}

function setUnreadMessages(state, action) {
  const { payload } = action;
  const value = payload && !isNaN(payload.value) ? payload.value : 0;
  return {
    ...state,
    unreadMessages: value
  };
}

function setOpenTasks(state, action) {
  const { payload } = action;
  const value = payload && !isNaN(payload.value) ? payload.value : 0;
  return {
    ...state,
    openTasks: value
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.STATISTICS_ACTIVITIES_OPEN:
      return setOpenActivities(state, action);
    case Actions.STATISTICS_EVENTS_COMING:
      return setComingEvents(state, action);
    case Actions.STATISTICS_MESSAGES_UNREAD:
      return setUnreadMessages(state, action);
    case Actions.STATISTICS_TASKS_OPEN:
      return setOpenTasks(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getMyStatistics = state => {
  return state;
};
