import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as initialGroupActions from '../../../app/store/actions/dashboard/initialGroupActions';
import * as initialPlanActions from '../../../app/store/actions/dashboard/initialPlanActions';
import * as initialUserActions from '../../../app/store/actions/dashboard/initialUserActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import * as Validation from '../../../common/utils/Validation';
import BaseComponent from './Dashboard.Canvas';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  const initialGroupsContainer = Selectors.getMyInitialGroups(state);
  const initialPlansContainer = Selectors.getMyInitialPlans(state);
  const initialUsersContainer = Selectors.getMyInitialUsers(state);

  // Hydrate the lists
  let dataInitialGroups = [];
  let dataInitialPlans = [];
  let dataInitialUsers = [];
  if (
    !Validation.isEmpty(initialGroupsContainer) &&
    !Validation.isEmpty(initialGroupsContainer.entityIds)
  ) {
    dataInitialGroups = Selectors.getGroupEntitiesByIds(
      state,
      initialGroupsContainer.entityIds
    );
  }
  if (
    !Validation.isEmpty(initialPlansContainer) &&
    !Validation.isEmpty(initialPlansContainer.entityIds)
  ) {
    dataInitialPlans = Selectors.getPlanEntitiesByIds(
      state,
      initialPlansContainer.entityIds
    );
  }
  if (
    !Validation.isEmpty(initialUsersContainer) &&
    !Validation.isEmpty(initialUsersContainer.entityIds)
  ) {
    dataInitialUsers = Selectors.getUserEntitiesByIds(
      state,
      initialUsersContainer.entityIds
    );
  }

  return {
    dataNode: nodeContainer,
    dataContext: contextContainer,
    dataInitialGroups: dataInitialGroups,
    dataInitialPlans: dataInitialPlans,
    dataInitialUsers: dataInitialUsers
  };
};

const mapDispatchToProps = dispatch => ({
  loadInitialGroups: auth =>
    dispatch(initialGroupActions.loadInitialGroups(auth)),
  loadInitialPlans: auth => dispatch(initialPlanActions.loadInitialPlans(auth)),
  loadInitialUsers: auth => dispatch(initialUserActions.loadInitialUsers(auth))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
