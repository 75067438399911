import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as StatisticsActions from '../../../../app/store/actions/dashboard/statisticsActions';
import * as StepActions from '../../../../app/store/actions/pathfinder/stepActions';
import * as PathActions from '../../../../app/store/actions/pathfinder/pathActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import BaseComponent from './Statistics.Canvas';

const mapStateToProps = state => {
  const meContainer = Selectors.getMyUser(state);
  const contextTenant = Selectors.getMyTenant(state);
  const dataSteps = Selectors.getAllPathfinderSteps(state);
  const dataStatistics = Selectors.getMyStatistics(state);

  return {
    dataStatistics: dataStatistics,
    dataSteps: dataSteps,
    dataMe: meContainer,
    dataTenant: contextTenant
  };
};

const mapDispatchToProps = dispatch => ({
  loadStatistics: auth => dispatch(StatisticsActions.loadStatistics(auth)),
  addNewPathfinderStep: auth => dispatch(StepActions.addNewStep(auth)),
  setCurrentStepPositionToLast: () =>
    dispatch(PathActions.setCurrentStepPositionToLast()),
  setCurrentStepPosition: position =>
    dispatch(PathActions.setCurrentStepPosition(position)),
  setRelation: (relation, loadAll) =>
    dispatch(CurNodeActions.setRelation(relation, loadAll)),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
