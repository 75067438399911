import moment from 'moment';
import * as Actions from '../types';
import * as BasicActions from '../entities/userActions';
import * as Selectors from '../../reducers/rootReducer';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// INITIALUSER
// - invalidateInitialUsers
// - loadInitialUsers
// - loadMoreInitialUsers
//-------------------------

export const invalidateInitialUsers = () => {
  return {
    type: Actions.INITIALUSERS_INVALIDATE
  };
};

export const loadInitialUsers = auth => (dispatch, getState) => {
  if (shouldFetchEntities(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchInitialUsers(auth));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const loadMoreInitialUsers = auth => (dispatch, getState) => {
  const currentState = getState();
  const entityContainer = Selectors.getMyInitialUsers(currentState);
  if (entityContainer && entityContainer.moreAvailable) {
    // Fetch the next entities from data api and append to current list
    return dispatch(fetchInitialUsersToAttach(auth, entityContainer.moreUrl));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

//-------------------------
// Helpers
//-------------------------

const requestEntities = () => {
  return {
    type: Actions.INITIALUSERS_REQUEST
  };
};

const receiveEntities = (data, attach = false) => {
  const entityList = !Validation.isEmpty(data) ? data.value : [];
  const moreLink = !Validation.isEmpty(data) ? data['@odata.nextLink'] : '';
  const actionType = attach
    ? Actions.INITIALUSERS_APPEND
    : Actions.INITIALUSERS_RECEIVE;

  let entityIds = [];
  let entities = [];
  entityList.forEach(entity => {
    if (entityIds.indexOf(entity.id) === -1) {
      entityIds = entityIds.concat(entity.id);
      entities = entities.concat(entity);
    }
  });
  return {
    type: actionType,
    payload: {
      entityIds: entityIds,
      entities: entities,
      moreAvailable: !Validation.isEmpty(moreLink),
      moreUrl: moreLink,
      receivedAt: Date.now()
    }
  };
};

const receiveError = err => {
  if (err.statusCode === 404) {
    return {
      type: Actions.INITIALUSERS_RECEIVE,
      payload: {
        entities: [],
        moreAvailable: false,
        moreUrl: '',
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const receiveErrorFromAttach = err => {
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const shouldFetchEntities = state => {
  const entityContainer = Selectors.getMyInitialUsers(state);
  const staleDate = moment().subtract(35, 'minutes');
  if (entityContainer) {
    if (!entityContainer.lastUpdated) {
      return true;
    } else if (
      entityContainer.lastUpdated &&
      entityContainer.lastUpdated < staleDate
    ) {
      return true;
    } else if (entityContainer.didInvalidate) {
      return true;
    } else if (entityContainer.isFetching) {
      return false;
    } else {
      return false;
    }
  } else return true;
};

const fetchInitialUsers = auth => dispatch => {
  dispatch(requestEntities());
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api('/users')
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => {
          dispatch(receiveEntities(res, false));
          const moreLink = !Validation.isEmpty(res)
            ? res['@odata.nextLink']
            : '';
          const moreAvailable = !Validation.isEmpty(moreLink);
          if (moreAvailable) {
            console.log('More Users available!');
            dispatch(fetchInitialUsersToAttach(auth, moreLink));
          } else {
            dispatch(loadExtendedData(client));
          }
        })
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};

const fetchInitialUsersToAttach = (auth, url) => dispatch => {
  dispatch(requestEntities());
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(url)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => {
          dispatch(receiveEntities(res, true));
          const moreLink = !Validation.isEmpty(res)
            ? res['@odata.nextLink']
            : '';
          const moreAvailable = !Validation.isEmpty(moreLink);
          if (moreAvailable) {
            console.log('More Users available!');
            dispatch(fetchInitialUsersToAttach(auth, moreLink));
          } else {
            dispatch(loadExtendedData(client));
          }
        })
        .catch(err => dispatch(receiveErrorFromAttach(err)));
    })
    .catch(err => console.log(err));
};

//-------------------------
// Helpers - Extension
//-------------------------

const loadExtendedData = client => (dispatch, getState) => {
  const currentState = getState();
  const allEntities = Selectors.getAllUserEntities(currentState);
  allEntities.forEach(entityCont => {
    const entityId = entityCont ? entityCont.id : '';
    const extendedAvailable =
      entityCont &&
      entityCont.entity &&
      Validation.isDefined(entityCont.entity, 'aboutMe')
        ? true
        : false;
    if (entityCont && entityCont.entity && !extendedAvailable) {
      return dispatch(BasicActions.fetchExtendedData(client, entityId, {}, {}));
    }
  });
};
