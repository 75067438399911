import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import ElementHeader from './Settings.Header';
import Placeholder from '../../../common/components/controls/Placeholder';
import * as AppConfig from '../../../app/config/settings/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';

class SettingsCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeHeaderMenuItem: MenuConfig.CODE_MENU_DEFAULTS.none
    };
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleHeaderMenuItemClick = (e, { name }) => {
    this.setState({ activeHeaderMenuItem: name });
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { activeHeaderMenuItem } = this.state;
    const { auth, location, match, params } = this.props;

    const callingPath = match.url;
    const basePathArray = callingPath.split('/settings', 1);
    const basePath = basePathArray.join();

    return (
      <div className="app-sidebar-content">
        <div className="app-sidebar-header-wrapper">
          <ElementHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            activeMenuItem={activeHeaderMenuItem}
            basePath={basePath}
            onMenuItemClick={this.handleHeaderMenuItemClick}
          />
        </div>
        <Segment basic>
          <Placeholder
            icon={AppConfig.SETTINGS_APP_ICONS.setting}
            header="Settings"
            subHeader="Work in progress. Application settings are coming soon."
          />
        </Segment>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
SettingsCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default SettingsCanvas;
