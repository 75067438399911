import * as Actions from '../../actions/types';

const initialState = {};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;
    const query = state[index];
    const updated = {
      ...query,
      didInvalidate: true
    };
    return {
      ...state,
      [index]: updated
    };
  }
  return state;
}

function setFetching(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;
    const query = state[index];
    const updated = {
      ...query,
      isFetching: true,
      didInvalidate: false
    };
    return {
      ...state,
      [index]: updated
    };
  }
  return state;
}

function upsertQuery(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index, query, receivedAt } = payload;
    const querycontainer = {
      index: index,
      query: query,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
    return {
      ...state,
      [index]: querycontainer
    };
  }
  return state;
}

function setQueryFromNavigation(state, action) {
  const { payload } = action;
  if (payload && payload.queryIndex) {
    const { queryIndex, queryParams } = payload;
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;
    const newAction = {
      payload: {
        index: queryIndex,
        query: {
          index: queryIndex,
          url: queryIndex,
          objectType: queryParams.objectType,
          entities: entityIds,
          moreAvailable: moreAvailable,
          moreUrl: moreUrl
        },
        receivedAt: receivedAt
      }
    };
    const newState = upsertQuery(state, newAction);
    return newState;
  }
  return state;
}

function appendQueryFromNavigation(state, action) {
  const { payload } = action;
  if (payload && payload.queryIndex) {
    const { queryIndex, queryParams } = payload;
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;

    const queryContainer = state[queryIndex];
    if (queryContainer && queryContainer.query) {
      const query = queryContainer.query;
      const newEntities =
        query && query.entities ? query.entities.concat(entityIds) : entityIds;
      const newAction = {
        payload: {
          index: queryIndex,
          query: {
            index: queryIndex,
            url: queryIndex,
            objectType: queryParams.objectType,
            entities: newEntities,
            moreAvailable: moreAvailable,
            moreUrl: moreUrl
          },
          receivedAt: receivedAt
        }
      };
      const newState = upsertQuery(state, newAction);
      return newState;
    }
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.QUERY_INVALIDATE:
      return setInvalidate(state, action);
    case Actions.QUERY_REQUEST:
      return setFetching(state, action);
    case Actions.QUERY_RECEIVE:
      return upsertQuery(state, action);
    case Actions.GRAPH_QUERY_RECEIVE:
    case Actions.PATHFINDER_QUERY_RECEIVE:
      return setQueryFromNavigation(state, action);
    case Actions.GRAPH_QUERY_APPEND:
    case Actions.PATHFINDER_QUERY_APPEND:
      return appendQueryFromNavigation(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getQueryByIndex = (state, index) => {
  return state[index];
};
