import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Logger, LogLevel } from 'msal';
import store from './store/store';
import AuthService from '../common/auth/AuthService';
import RoutePrivate from '../common/components/controls/RoutePrivateRedirect';
import HomePage from '../features/single/home/Home.Page';
import LandingPage from '../features/single/landing/Landing.Page';
import GraphVisualizerPage from '../features/pages/graph/Graph.Page';
import PathfinderPage from '../features/pages/pathfinder/Pathfinder.Page';
import InfoPage from '../features/pages/info/Info.Page';
import DashboardPage from '../features/pages/dashboard/Dashboard.Page';
import PageNotFound from '../features/single/error/NotFound.Page';
import Callback from './Callback';
import SyncUserLogin from './functions/SyncUserLogin';
import * as AuthConfig from './config/settings/Authorization';
import { LOGGING_AUTH_CONFIG } from './config/settings/Logging';
import './App.css';

// Logger for AAD Authorization
const loggerCallback = (logLevel, message, piiLoggingEnabled) => {
  if (logLevel === LogLevel.Error) {
    console.error(message);
  } else if (logLevel === LogLevel.Warning) {
    console.warn(message);
  }
};
const logger = new Logger(loggerCallback, {
  level: LOGGING_AUTH_CONFIG.logLevel,
  piiLoggingEnabled: LOGGING_AUTH_CONFIG.piiLoggingEnabled
});

// AAD Authorization Client
const authCallback = (errorDesc, token, error, tokenType) => {
  if (token) {
    if (tokenType === 'access_token') {
      console.log('Access Token received');
    }
    if (tokenType === 'id_token') {
      console.log('ID Token received');
      SyncUserLogin(token);
    }
  } else {
    console.warn(error + ':' + errorDesc);
  }
};
const auth = new AuthService(
  AuthConfig.AUTHORISATION_CONFIG.clientID,
  null,
  authCallback,
  {
    redirectUri: AuthConfig.AUTHORISATION_CONFIG.loginRedirectUri,
    postLogoutRedirectUri: AuthConfig.AUTHORISATION_CONFIG.logoutRedirectUri,
    cacheLocation: LOGGING_AUTH_CONFIG.cacheLocation,
    storeAuthStateInCookie: true,
    logger: logger
  }
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="app">
            <Switch>
              <Route
                exact
                path="/"
                render={props => <HomePage auth={auth} {...props} />}
              />
              <RoutePrivate
                path="/dashboard"
                auth={auth}
                component={DashboardPage}
              />
              <RoutePrivate
                path="/graph"
                auth={auth}
                component={GraphVisualizerPage}
              />
              <RoutePrivate
                path="/pathfinder"
                auth={auth}
                component={PathfinderPage}
              />
              <Route
                path="/about"
                render={props => <InfoPage auth={auth} {...props} />}
              />
              <Route
                path="/terms"
                render={props => <InfoPage auth={auth} {...props} />}
              />
              <Route
                path="/privacy"
                render={props => <InfoPage auth={auth} {...props} />}
              />
              <Route
                path="/landing"
                render={props => <LandingPage auth={auth} {...props} />}
              />
              <Route
                path="/callback"
                render={props => <Callback {...props} />}
              />
              <Route
                render={props => <PageNotFound auth={auth} {...props} />}
              />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
