import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as UserActions from '../../../app/store/actions/dashboard/meActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import * as Validation from '../../../common/utils/Validation';
import BaseComponent from './Account.Canvas';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  const meContainer = Selectors.getMyUser(state);
  if (!Validation.isEmpty(meContainer)) {
    const userContainer = Selectors.getUserEntityById(
      state,
      meContainer.userId
    );
    const user = !Validation.isEmpty(userContainer) ? userContainer.entity : {};
    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataAccount: user,
      dataLoading: meContainer.isFetching
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataAccount: {},
    dataLoading: false
  };
};

const mapDispatchToProps = dispatch => ({
  loadAccount: auth => dispatch(UserActions.loadUser(auth))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
