import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as initialGroupActions from '../../../app/store/actions/dashboard/initialGroupActions';
import * as initialPlanActions from '../../../app/store/actions/dashboard/initialPlanActions';
import * as initialUserActions from '../../../app/store/actions/dashboard/initialUserActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import BaseComponent from './Landing.Loggedin';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);

  return {
    dataNode: nodeContainer,
    dataContext: contextContainer
  };
};

const mapDispatchToProps = dispatch => ({
  loadInitialGroups: auth =>
    dispatch(initialGroupActions.loadInitialGroups(auth)),
  loadInitialPlans: auth => dispatch(initialPlanActions.loadInitialPlans(auth)),
  loadInitialUsers: auth => dispatch(initialUserActions.loadInitialUsers(auth))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
