import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Popup, Icon, Image } from 'semantic-ui-react';
import LogoMain from '../../../app/assets/images/logo-main.png';
import LogoNavigator from '../../../app/assets/images/logo-sub.png';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as AppConfig from '../../../app/config/settings/Application';
import './Header.css';

class Header extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { match } = this.props;

    return (
      <header className="app-header">
        <Menu fixed="top" pointing icon size="small" className="app-navigation">
          <Menu.Item className="app-nav-mainlogowrapper">
            <Image
              src={LogoMain}
              className="app-nav-mainlogo"
              alt="Logo appsolut"
              as={Link}
              to="/"
            />
          </Menu.Item>
          <Menu.Item className="app-nav-sublogowrapper">
            <Image
              src={LogoNavigator}
              className="app-nav-sublogo"
              alt="Logo Graph.Navigator"
              as={Link}
              to="/"
            />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item as={NavLink} to="/dashboard">
              <Popup
                trigger={
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.dashboard}
                    circular
                    inverted
                    color={AppConfig.SETTINGS_APP_COLORS.menudashboard}
                    size="large"
                  />
                }
                content="Dashboard"
              />
            </Menu.Item>
            <Menu.Item as={NavLink} to="/pathfinder">
              <Popup
                trigger={
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.pathfinder}
                    circular
                    inverted
                    color={AppConfig.SETTINGS_APP_COLORS.menupathfinder}
                    size="large"
                  />
                }
                content="Pathfinder"
              />
            </Menu.Item>
            <Menu.Item as={NavLink} to="/graph">
              <Popup
                trigger={
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.graph}
                    circular
                    inverted
                    color={AppConfig.SETTINGS_APP_COLORS.menugraph}
                    size="large"
                  />
                }
                content="Graph"
              />
            </Menu.Item>
            <Menu.Item as={Link} to={`${match.url}/account`}>
              <Popup
                trigger={
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.account}
                    circular
                    inverted
                    color={AppConfig.SETTINGS_APP_COLORS.menuaccount}
                    size="large"
                  />
                }
                content="Account"
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </header>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
Header.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Header;
