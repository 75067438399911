import * as Actions from '../../../actions/types';

const initialState = [];

//-------------------------
// Helpers
//-------------------------

function addEntityIdsFromGroups(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds && payload.entities) {
    const { entities } = payload;
    let newState = [...state];
    entities.forEach(entity => {
      const isTeam = groupIsTeam(entity);
      if (isTeam) {
        if (newState.indexOf(entity.id) === -1) {
          newState = newState.concat(entity.id);
        }
      }
    });
    return newState;
  }
  return state;
}

function groupIsTeam(groupEntity) {
  const typeArray = groupEntity.resourceProvisioningOptions;
  const isTeam = typeArray ? typeArray.includes('Team') : false;
  return isTeam;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.INITIALGROUPS_RECEIVE:
    case Actions.INITIALGROUPS_APPEND:
    case Actions.GROUPS_RECEIVE:
    case Actions.GROUPS_APPEND:
      return addEntityIdsFromGroups(state, action);
    case Actions.GRAPH_QUERY_RECEIVE:
    case Actions.GRAPH_QUERY_APPEND:
      // NO action here - all data already loaded in Initial Queries
      return state;
    case Actions.PATHFINDER_QUERY_RECEIVE:
    case Actions.PATHFINDER_QUERY_APPEND:
      // NO action here - all data already loaded in Initial Queries
      return state;
    default:
      return state;
  }
}
