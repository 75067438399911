import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, GridColumn } from 'semantic-ui-react';
import { List, Icon, Label } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class TaskCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Project
    const projectName = FieldUtil.GetTitleFromPlannerPlan(dataEntity.plan);

    // Field Checklist
    const checklistObject =
      dataEntity && dataEntity.details ? dataEntity.details.checklist : {};
    const checklistArray = Object.values(checklistObject);

    // Field AssignedTo
    const assignmentsObject = dataEntity ? dataEntity.assignments : {};
    const assignementsArray = Object.values(assignmentsObject);

    // Field Owner
    const ownerIdentityUser =
      dataEntity && dataEntity.createdBy ? dataEntity.createdBy.user : {};
    const ownerIdentityUserEmail = FieldUtil.GetEmailFromIdentitySetUser(
      ownerIdentityUser
    );
    const ownerIdentityUserName = FieldUtil.GetNameFromIdentitySetUser(
      ownerIdentityUser
    );

    // Field Start Date
    const startDate = Validation.isValidDate(dataEntity.startDateTime)
      ? DateUtil.formatMsGraphDateTime(dataEntity.startDateTime)
      : '';

    // Field Due Date
    const dueDate = Validation.isValidDate(dataEntity.dueDateTime)
      ? DateUtil.formatMsGraphDateTime(dataEntity.dueDateTime)
      : '';

    // Field Completed Date
    const completedDate = Validation.isValidDate(dataEntity.completedDateTime)
      ? DateUtil.formatMsGraphDateTime(dataEntity.completedDateTime)
      : '';

    // Field Categories
    const categoriesObject = dataEntity ? dataEntity.appliedCategories : {};
    let categories = {};
    let hasCategory = false;
    for (const key in categoriesObject) {
      let value = categoriesObject[key];
      if (key === 'category1' && value) {
        categories.cat1 = true;
        hasCategory = true;
      }
      if (key === 'category2' && value) {
        categories.cat2 = true;
        hasCategory = true;
      }
      if (key === 'category3' && value) {
        categories.cat3 = true;
        hasCategory = true;
      }
      if (key === 'category4' && value) {
        categories.cat4 = true;
        hasCategory = true;
      }
      if (key === 'category5' && value) {
        categories.cat5 = true;
        hasCategory = true;
      }
      if (key === 'category6' && value) {
        categories.cat6 = true;
        hasCategory = true;
      }
    }

    // Field Links
    const linksWeb = !Validation.isEmpty(dataEntity.webLink)
      ? dataEntity.webLink
      : '';

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );
    const systemCreated = Validation.isValidDate(dataEntity.createdDateTime)
      ? 'Created: ' + DateUtil.formatMsGraphDateTime(dataEntity.createdDateTime)
      : '';

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Project</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(projectName)}</div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Checklist</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(checklistArray) ? (
                    <List>
                      {checklistArray.map((subtask, index) => (
                        <List.Item key={index}>
                          <div className="app-itemcard-email">
                            {subtask.isChecked ? (
                              <Icon
                                name={MenuConfig.ICONS_MENU_DEFAULTS.checked}
                                className="app-listitem-icon"
                              />
                            ) : (
                              <Icon
                                name={
                                  MenuConfig.ICONS_MENU_DEFAULTS
                                    .checklistUnchecked
                                }
                                className="app-listitem-icon"
                              />
                            )}
                            {subtask.title}
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Categories</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {hasCategory ? (
                    <div>
                      {categories && categories.cat1 && (
                        <Label color="purple" content="Purple" />
                      )}
                      {categories && categories.cat2 && (
                        <Label color="red" content="Red" />
                      )}
                      {categories && categories.cat3 && (
                        <Label color="yellow" content="Yellow" />
                      )}
                      {categories && categories.cat4 && (
                        <Label color="olive" content="Olive" />
                      )}
                      {categories && categories.cat5 && (
                        <Label color="green" content="Green" />
                      )}
                      {categories && categories.cat6 && (
                        <Label color="blue" content="Blue" />
                      )}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Assigned To</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(assignementsArray) ? (
                    <List>
                      {assignementsArray.map((assignment, index) => (
                        <List.Item key={index}>
                          <div className="app-itemcard-email">
                            <Icon
                              name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                              className="app-listitem-icon"
                            />
                            <a
                              href={
                                'mailto:' +
                                FieldUtil.GetEmailFromPlannerAssignment(
                                  assignment
                                )
                              }
                            >
                              {FieldUtil.GetNameFromPlannerAssignment(
                                assignment
                              )}
                            </a>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Owner</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {!Validation.isEmpty(ownerIdentityUserEmail) ? (
                    <div className="app-itemcard-email">
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                        className="app-listitem-icon"
                      />
                      <a href={'mailto:' + ownerIdentityUserEmail}>
                        {ownerIdentityUserName}
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Start</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(startDate) ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.date}
                        className="app-listitem-icon"
                      />
                      {startDate}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Due Date</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dueDate) ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.date}
                        className="app-listitem-icon"
                      />
                      {dueDate}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Completed Date</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(completedDate) ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.date}
                        className="app-listitem-icon"
                      />
                      {completedDate}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-website">
                  {linksWeb ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksWeb}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Weblink
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                  {systemCreated && <div>{systemCreated}</div>}
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
TaskCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

TaskCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default TaskCardForm;
