import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as TenantActions from '../../../../app/store/actions/dashboard/tenantActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import * as Validation from '../../../../common/utils/Validation';
import BaseComponent from './Tenant.Card';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  const tenantContainer = Selectors.getMyTenant(state);
  if (!Validation.isEmpty(tenantContainer)) {
    const orgContainer = Selectors.getOrganizationEntityById(
      state,
      tenantContainer.organizationId
    );
    const organization = orgContainer ? orgContainer.entity : {};
    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataEntity: organization,
      dataLoading: tenantContainer.isFetching
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataEntity: {},
    dataLoading: false
  };
};

// Entity is Segment & Tenant
// > NO Segment, Parent or ID information needed to load the correct Entity (loadOrganization)
const mapDispatchToProps = dispatch => ({
  loadOrganization: auth => dispatch(TenantActions.loadOrganization(auth)),
  invalidateOrganization: () =>
    dispatch(TenantActions.invalidateOrganization()),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
