import * as Actions from '../types';
import * as LoginDataService from '../../../../data/admin/LoginDataService';
import * as Validation from '../../../../common/utils/Validation';

//-------------------------
// ADMIN LOGINS
// - invalidateLogins
// - loadLoginsByAccountId
//-------------------------

export const invalidateLogins = () => {
  return {
    type: Actions.ADMIN_LOGINS_INVALIDATE
  };
};

export const loadLoginsByAccountId = accountId => (dispatch, getState) => {
  if (shouldFetchEntities(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchLoginsByAccountId(accountId));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

//-------------------------
// Helpers
//-------------------------

const requestEntities = () => {
  return {
    type: Actions.ADMIN_LOGINS_REQUEST
  };
};

const receiveEntities = data => {
  const entityList = !Validation.isEmpty(data) ? data : [];
  let entityIds = [];
  entityList.forEach(entity => {
    if (entityIds.indexOf(entity._id) === -1) {
      entityIds = entityIds.concat(entity._id);
    }
  });
  return {
    type: Actions.ADMIN_LOGINS_RECEIVE,
    payload: {
      entityIds: entityIds,
      entities: entityList,
      receivedAt: Date.now()
    }
  };
};

const receiveError = err => {
  if (err.response.status === 404) {
    return {
      type: Actions.ADMIN_LOGINS_RECEIVE,
      payload: {
        entityIds: [],
        entities: [],
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err.response
    }
  };
};

const shouldFetchEntities = state => {
  // Allways load from Database
  return true;
};

const fetchLoginsByAccountId = accountId => dispatch => {
  dispatch(requestEntities());
  LoginDataService.GetByAccount(accountId)
    .then(res => dispatch(receiveEntities(res)))
    .catch(err => dispatch(receiveError(err)));
};
