import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, GridColumn, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as Validation from '../../../common/utils/Validation';

class BookingBusinessCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Business
    const businessType = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.businessType)
        ? 'Type: ' + dataEntity.businessType
        : '',
      lengthColumn,
      true
    );
    const businessCurrency = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.defaultCurrencyIso)
        ? 'Currency: ' + dataEntity.defaultCurrencyIso
        : '',
      lengthColumn,
      true
    );
    const hasBusiness = businessType || businessCurrency;

    // Field Contact
    const contactPhone = !Validation.isEmpty(dataEntity.phone)
      ? dataEntity.phone
      : '';
    const contactMail = !Validation.isEmpty(dataEntity.email)
      ? dataEntity.email
      : '';
    const hasContact = contactPhone || contactMail;

    // Field Availability
    const schedule = dataEntity.businessHours;
    const hasMonday = FieldUtil.HasMondayBookingWorkHours(schedule);
    const hasTuesday = FieldUtil.HasTuesdayBookingWorkHours(schedule);
    const hasWednesday = FieldUtil.HasWednesdayBookingWorkHours(schedule);
    const hasThursday = FieldUtil.HasThursdayBookingWorkHours(schedule);
    const hasFriday = FieldUtil.HasFridayBookingWorkHours(schedule);
    const hasSaturday = FieldUtil.HasSaturdayBookingWorkHours(schedule);
    const hasSunday = FieldUtil.HasSundayBookingWorkHours(schedule);

    const mondayIcon = hasMonday
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const tuesdayIcon = hasTuesday
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const wednesdayIcon = hasWednesday
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const thursdayIcon = hasThursday
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const fridayIcon = hasFriday
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const saturdayIcon = hasSaturday
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const sundayIcon = hasSunday
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;

    // Field Settings
    const settingConfirmation =
      dataEntity && dataEntity.schedulingPolicy
        ? dataEntity.schedulingPolicy.sendConfirmationsToOwner
        : false;
    const settingStaff =
      dataEntity && dataEntity.schedulingPolicy
        ? dataEntity.schedulingPolicy.allowStaffSelection
        : false;

    const settingConfirmationIcon = settingConfirmation
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;
    const settingStaffIcon = settingStaff
      ? MenuConfig.ICONS_MENU_DEFAULTS.checklistChecked
      : MenuConfig.ICONS_MENU_DEFAULTS.checklistUnchecked;

    // Field Links
    const linkApplication = !Validation.isEmpty(dataEntity.ApplicationUrl)
      ? dataEntity.ApplicationUrl
      : '';
    const linkPublic = !Validation.isEmpty(dataEntity.publicUrl)
      ? dataEntity.publicUrl
      : '';
    const linkWebsite = !Validation.isEmpty(dataEntity.webSiteUrl)
      ? dataEntity.webSiteUrl
      : '';
    const hasLinks = linkApplication || linkPublic || linkWebsite;

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Company</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {hasBusiness ? (
                    <div className="app-itemcard-info">
                      {businessType && <div>{businessType}</div>}
                      {businessCurrency && <div>{businessCurrency}</div>}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Contact</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {hasContact ? (
                    <div>
                      {contactPhone && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.phone}
                            className="app-listitem-icon"
                          />
                          {contactPhone}
                        </div>
                      )}
                      {contactMail && (
                        <div className="app-itemcard-email">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                            className="app-listitem-icon"
                          />
                          <a href={'mailto:' + contactMail}>{contactMail}</a>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Address</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {FieldUtil.IsValidPhysicalAddress(dataEntity.address) ? (
                    <div className="app-itemcard-info">
                      <div>
                        {FieldUtil.GetStreetLineFromPhysicalAddress(
                          dataEntity.address
                        )}
                      </div>
                      <div>
                        {FieldUtil.GetCityLineFromPhysicalAddress(
                          dataEntity.address
                        )}
                      </div>
                      <div>
                        {FieldUtil.GetCountryLineFromPhysicalAddress(
                          dataEntity.address
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Availability</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>
                    <Icon name={mondayIcon} className="app-listitem-icon" />
                    Monday
                  </div>
                  <div>
                    <Icon name={tuesdayIcon} className="app-listitem-icon" />
                    Tuesday
                  </div>
                  <div>
                    <Icon name={wednesdayIcon} className="app-listitem-icon" />
                    Wednesday
                  </div>
                  <div>
                    <Icon name={thursdayIcon} className="app-listitem-icon" />
                    Thursday
                  </div>
                  <div>
                    <Icon name={fridayIcon} className="app-listitem-icon" />
                    Friday
                  </div>
                  <div>
                    <Icon name={saturdayIcon} className="app-listitem-icon" />
                    Saturday
                  </div>
                  <div>
                    <Icon name={sundayIcon} className="app-listitem-icon" />
                    Sunday
                  </div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Settings</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>
                    <Icon
                      name={settingConfirmationIcon}
                      className="app-listitem-icon"
                    />
                    Send Confirmations to Owner
                  </div>
                  <div>
                    <Icon
                      name={settingStaffIcon}
                      className="app-listitem-icon"
                    />
                    Staff Selection enabled
                  </div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-website">
                  {hasLinks ? (
                    <div>
                      {linkApplication && (
                        <div>
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                            className="app-listitem-icon"
                          />
                          <a
                            href={linkApplication}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Administration
                          </a>
                        </div>
                      )}
                      {linkPublic && (
                        <div>
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                            className="app-listitem-icon"
                          />
                          <a
                            href={linkPublic}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Public Landing Page
                          </a>
                        </div>
                      )}
                      {linkWebsite && (
                        <div>
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                            className="app-listitem-icon"
                          />
                          <a
                            href={linkWebsite}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Website
                          </a>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
BookingBusinessCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

BookingBusinessCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default BookingBusinessCardForm;
