import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';

import ActivityList from '../../components/activity/Activity.ItemList.List';
import ActivityFolderList from '../../components/activityfolder/ActivityFolder.ItemList.List';
import ActivityGroupList from '../../components/activitygroup/ActivityGroup.ItemList.List';
import AdministrativeUnitList from '../../components/administrativeunit/AdministrativeUnit.ItemList.List';
import AgreementAcceptanceList from '../../components/agreementacceptance/AgreementAcceptance.ItemList.List';
import AgreementList from '../../components/agreement/Agreement.ItemList.List';
import ApplicationList from '../../components/application/Application.ItemList.List';
import AttachmentList from '../../components/attachment/Attachment.ItemList.List';
import BookingAppointmentList from '../../components/bookingappointment/BookingAppointment.ItemList.List';
import BookingBusinessList from '../../components/bookingbusiness/BookingBusiness.ItemList.List';
import BookingCustomerList from '../../components/bookingcustomer/BookingCustomer.ItemList.List';
import BookingServiceList from '../../components/bookingservice/BookingService.ItemList.List';
import BookingStaffMemberList from '../../components/bookingstaffmember/BookingStaffMember.ItemList.List';
import BucketList from '../../components/bucket/Bucket.ItemList.List';
import CalendarGroupList from '../../components/calendargroup/CalendarGroup.ItemList.List';
import CalendarList from '../../components/calendar/Calendar.ItemList.List';
import ChannelList from '../../components/channel/Channel.ItemList.List';
import ChatMessageList from '../../components/chatmessage/ChatMessage.ItemList.List';
import ChatThreadList from '../../components/chatthread/ChatThread.ItemList.List';
import ContactFolderList from '../../components/contactfolder/ContactFolder.ItemList.List';
import ContactList from '../../components/contact/Contact.ItemList.List';
import ContractList from '../../components/contract/Contract.ItemList.List';
import ConversationList from '../../components/conversation/Conversation.ItemList.List';
import DeviceList from '../../components/device/Device.ItemList.List';
import DirectoryRoleList from '../../components/directoryrole/DirectoryRole.ItemList.List';
import DomainList from '../../components/domain/Domain.ItemList.List';
import DriveItemList from '../../components/driveitem/DriveItem.ItemList.List';
import DriveList from '../../components/drive/Drive.ItemList.List';
import EventList from '../../components/event/Event.ItemList.List';
import GroupList from '../../components/group/Group.ItemList.List';
import IdentityProviderList from '../../components/identityprovider/IdentityProvider.ItemList.List';
import InsightList from '../../components/insight/Insight.ItemList.List';
import LicenseList from '../../components/license/License.ItemList.List';
import ListItemList from '../../components/listitem/ListItem.ItemList.List';
import ListList from '../../components/list/List.ItemList.List';
import MailFolderList from '../../components/mailfolder/MailFolder.ItemList.List';
import MessageList from '../../components/message/Message.ItemList.List';
import NotebookList from '../../components/notebook/Notebook.ItemList.List';
import OrganizationList from '../../components/organization/Organization.ItemList.List';
import OrgContactList from '../../components/orgcontact/OrgContact.ItemList.List';
import PageList from '../../components/page/Page.ItemList.List';
import PersonList from '../../components/person/Person.ItemList.List';
import PlanList from '../../components/plan/Plan.ItemList.List';
import PostList from '../../components/post/Post.ItemList.List';
import RiskEventList from '../../components/riskevent/RiskEvent.ItemList.List';
import SecurityAlertList from '../../components/securityalert/SecurityAlert.ItemList.List';
import ServicePlanList from '../../components/serviceplan/ServicePlan.ItemList.List';
import ServicePrincipalList from '../../components/serviceprincipal/ServicePrincipal.ItemList.List';
import ServiceList from '../../components/service/Service.ItemList.List';
import SiteList from '../../components/site/Site.ItemList.List';
import TaskList from '../../components/task/Task.ItemList.List';
import TeamList from '../../components/team/Team.ItemList.List';
import TeamsAppInstallationList from '../../components/teamsappinstallation/TeamsAppInstallation.ItemList.List';
import TeamsTabList from '../../components/teamstab/TeamsTab.ItemList.List';
import ThreadList from '../../components/thread/Thread.ItemList.List';
import UserList from '../../components/user/User.ItemList.List';
import VersionList from '../../components/version/Version.ItemList.List';

import DataLoader from '../../../common/components/controls/DataLoader';
import * as RelationTypes from '../../../app/config/entities/RelationTypes';
import * as RelationParams from '../../../app/config/entities/ParameterOfRelation';
import * as ObjectConfig from '../../../app/config/entities/Objects';
import * as ApplicationConfig from '../../../app/config/constants/Application';

class PathfinderRelations extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleEntityClick = (e, key, url) => {
    e.preventDefault();
    const dataStep = this.props.dataStepContainer
      ? this.props.dataStepContainer.step
      : {};
    const dataHeader = dataStep ? dataStep.header : {};
    const dataContext = dataHeader ? dataHeader.context : {};
    const dataQuery = dataStep ? dataStep.query : {};
    const area = ApplicationConfig.NAVIGATION_AREA.pathfinder;
    const type = dataQuery ? dataQuery.objectType : '';

    //console.log("Pathfinder List Click");
    //console.log(dataHeader);
    //console.log(dataContext);
    //console.log(dataQuery);

    let parent = {};
    let segment = {};
    if (dataContext) {
      // Assign Segment
      segment.type = dataContext.segmentType;
      segment.id = dataContext.segmentId;

      // Assign Parent
      if (
        dataContext.segmentType !== dataHeader.entityType &&
        !(
          dataContext.segmentType === ObjectConfig.ME.code &&
          dataHeader.entityType === ObjectConfig.USER.code
        )
      ) {
        const relationType = dataQuery ? dataQuery.relationType : '';
        if (
          relationType === RelationTypes.BOOKINGAPPOINTMENTS.customerAll ||
          relationType === RelationTypes.BOOKINGAPPOINTMENTS.serviceAll
        ) {
          parent.type = dataContext.parentType;
          parent.id = dataContext.parentId;
        } else {
          parent.type = dataHeader.entityType;
          parent.id = dataHeader.entityId;
        }
      }
    }

    //console.log(segment);
    //console.log(parent);
    //console.log({ type: type, id: key });

    this.props.setCurrentContext(type, key, parent, segment);
    this.props.setCurrentNode(area, type, key, url);
    this.props.history.push(`${this.props.match.url}/node`);
  };
  handleLoadMoreClick = (e, url) => {
    this.props.loadMoreStepQuery(this.props.auth, this.props.dataPosition);
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { dataStepContainer, dataLoading } = this.props;

    const dataStep = dataStepContainer ? dataStepContainer.step : {};
    const dataQuery = dataStep ? dataStep.query : {};
    const dataEntities = dataQuery ? dataQuery.entities : [];
    const dataMoreAvailable = dataQuery ? dataQuery.moreAvailable : false;
    const dataRelationType = dataQuery ? dataQuery.relationType : '';
    const dataMoreUrl = dataQuery ? dataQuery.moreUrl : '';

    if (dataLoading) {
      return <DataLoader />;
    }

    if (dataEntities && dataEntities.length > 0) {
      if (dataQuery.objectType === ObjectConfig.ACTIVITY.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ActivityList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.ACTIVITYFOLDER.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ActivityFolderList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.ACTIVITYGROUP.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ActivityGroupList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.ADMINISTRATIVEUNIT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <AdministrativeUnitList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.AGREEMENT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <AgreementList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.AGREEMENTACCEPTANCE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <AgreementAcceptanceList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.APPLICATION.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ApplicationList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.ATTACHMENT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <AttachmentList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.BOOKINGAPPOINTMENT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <BookingAppointmentList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.BOOKINGBUSINESS.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <BookingBusinessList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.BOOKINGCUSTOMER.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <BookingCustomerList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.BOOKINGSERVICE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <BookingServiceList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.BOOKINGSTAFFMEMBER.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <BookingStaffMemberList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.BUCKET.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <BucketList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CALENDAR.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <CalendarList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CALENDARGROUP.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <CalendarGroupList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CHANNEL.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ChannelList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CHATMESSAGE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ChatMessageList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CHATTHREAD.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ChatThreadList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CONTACT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ContactList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CONTACTFOLDER.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ContactFolderList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CONTRACT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ContractList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.CONVERSATION.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ConversationList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.DEVICE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <DeviceList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.DIRECTORYROLE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <DirectoryRoleList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.DOMAIN.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <DomainList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.DRIVE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <DriveList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.DRIVEITEM.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <DriveItemList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.EVENT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <EventList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.GROUP.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <GroupList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.IDENTITYPROVIDER.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <IdentityProviderList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.INSIGHT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <InsightList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.LICENSE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <LicenseList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.LIST.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ListList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.LISTITEM.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ListItemList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.MAILFOLDER.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <MailFolderList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.MESSAGE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <MessageList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.NOTEBOOK.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <NotebookList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.ORGANIZATION.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <OrganizationList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.ORGCONTACT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <OrgContactList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.PAGE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <PageList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.PERSON.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <PersonList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.PLAN.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <PlanList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.POST.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <PostList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.RISKEVENT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <RiskEventList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.SECURITYALERT.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <SecurityAlertList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.SERVICE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ServiceList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.SERVICEPLAN.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ServicePlanList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.SERVICEPRINCIPAL.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ServicePrincipalList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.SITE.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <SiteList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.TASK.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <TaskList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.TEAM.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <TeamList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.TEAMSAPPINSTALLATION.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <TeamsAppInstallationList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.TEAMSTAB.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <TeamsTabList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.THREAD.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <ThreadList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.USER.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <UserList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }
      if (dataQuery.objectType === ObjectConfig.VERSION.code) {
        return (
          <div className="app-pathfinder-relations">
            <Segment.Group>
              <Segment
                secondary
                textAlign="center"
                className="app-itemlist-header"
                content={RelationParams.GetLabel(dataRelationType)}
              />
              <Segment>
                <div className="app-itemlist">
                  <VersionList
                    auth={auth}
                    location={location}
                    match={match}
                    params={params}
                    dataEntities={dataEntities}
                    dataMoreAvailable={dataMoreAvailable}
                    dataMoreUrl={dataMoreUrl}
                    onEntityClick={this.handleEntityClick}
                    onLoadMoreClick={this.handleLoadMoreClick}
                  />
                </div>
              </Segment>
            </Segment.Group>
          </div>
        );
      }

      return (
        <div className="app-pathfinder-relations">
          <Segment.Group>
            <Segment
              secondary
              textAlign="center"
              className="app-itemlist-header"
              content={RelationParams.GetLabel(dataRelationType)}
            />
            <Segment>
              <div className="app-itemlist">
                <Header as="h4" textAlign="center">
                  No data view available
                </Header>
              </div>
            </Segment>
          </Segment.Group>
        </div>
      );
    }
    return (
      <div className="app-pathfinder-relations">
        <Segment.Group>
          <Segment
            secondary
            textAlign="center"
            className="app-itemlist-header"
            content={RelationParams.GetLabel(dataRelationType)}
          />
          <Segment>
            <div className="app-itemlist">
              <Header as="h4" textAlign="center">
                No data to display
              </Header>
            </div>
          </Segment>
        </Segment.Group>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
PathfinderRelations.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataStepContainer: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  dataPosition: PropTypes.number.isRequired,
  loadMoreStepQuery: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired
};

export default PathfinderRelations;
