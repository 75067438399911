import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Icon, Popup } from 'semantic-ui-react';
import * as MenuConfig from '../../../app/config/constants/Menu';

class AccountHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    //const { location, match, params } = this.props;
    const { webLink } = this.props;

    return (
      <div className="app-nav-chart-main">
        <Menu secondary pointing>
          <Menu.Item
            name={MenuConfig.CODE_MENU_DEFAULTS.linkextern}
            as="a"
            href={webLink}
            target="_blank"
            active={false}
            onClick={this.props.onMenuItemClick}
          >
            <Popup
              trigger={
                <Icon
                  name={MenuConfig.ICONS_MENU_DEFAULTS.linkextern}
                  size="large"
                  color="grey"
                />
              }
              content="Weblink"
            />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item
              name={MenuConfig.CODE_MENU_DEFAULTS.close}
              active={false}
              onClick={this.props.onMenuItemClick}
            >
              <Icon
                name={MenuConfig.ICONS_MENU_DEFAULTS.close}
                size="large"
                color="grey"
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
AccountHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  activeMenuItem: PropTypes.string.isRequired,
  webLink: PropTypes.string.isRequired,
  onMenuItemClick: PropTypes.func.isRequired
};

export default AccountHeader;
