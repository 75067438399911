import * as ObjType from './Objects';

export const GetApiObjectType = Obj => {
  let result = '';

  // Segments
  if (Obj === ObjType.DRIVE.code) result = ObjType.DRIVE.apicode;
  if (Obj === ObjType.GROUP.code) result = ObjType.GROUP.apicode;
  if (Obj === ObjType.ME.code) result = ObjType.ME.apicode;
  if (Obj === ObjType.ORGANIZATION.code) result = ObjType.ORGANIZATION.apicode;
  if (Obj === ObjType.PLAN.code) result = ObjType.PLAN.apicode;
  if (Obj === ObjType.SITE.code) result = ObjType.SITE.apicode;
  if (Obj === ObjType.USER.code) result = ObjType.USER.apicode;

  // Entities
  if (Obj === ObjType.ACTIVITY.code) result = ObjType.ACTIVITY.apicode;
  if (Obj === ObjType.ACTIVITYFOLDER.code)
    result = ObjType.ACTIVITYFOLDER.apicode;
  if (Obj === ObjType.ACTIVITYGROUP.code)
    result = ObjType.ACTIVITYGROUP.apicode;
  if (Obj === ObjType.ADMINISTRATIVEUNIT.code)
    result = ObjType.ADMINISTRATIVEUNIT.apicode;
  if (Obj === ObjType.AGREEMENT.code) result = ObjType.AGREEMENT.apicode;
  if (Obj === ObjType.AGREEMENTACCEPTANCE.code)
    result = ObjType.AGREEMENTACCEPTANCE.apicode;
  if (Obj === ObjType.APPLICATION.code) result = ObjType.APPLICATION.apicode;
  if (Obj === ObjType.ATTACHMENT.code) result = ObjType.ATTACHMENT.apicode;
  if (Obj === ObjType.BOOKINGAPPOINTMENT.code)
    result = ObjType.BOOKINGAPPOINTMENT.apicode;
  if (Obj === ObjType.BOOKINGBUSINESS.code)
    result = ObjType.BOOKINGBUSINESS.apicode;
  if (Obj === ObjType.BOOKINGCUSTOMER.code)
    result = ObjType.BOOKINGCUSTOMER.apicode;
  if (Obj === ObjType.BOOKINGSERVICE.code)
    result = ObjType.BOOKINGSERVICE.apicode;
  if (Obj === ObjType.BOOKINGSTAFFMEMBER.code)
    result = ObjType.BOOKINGSTAFFMEMBER.apicode;
  if (Obj === ObjType.BUCKET.code) result = ObjType.BUCKET.apicode;
  if (Obj === ObjType.CALENDAR.code) result = ObjType.CALENDAR.apicode;
  if (Obj === ObjType.CALENDARGROUP.code)
    result = ObjType.CALENDARGROUP.apicode;
  if (Obj === ObjType.CHANNEL.code) result = ObjType.CHANNEL.apicode;
  if (Obj === ObjType.CHATMESSAGE.code) result = ObjType.CHATMESSAGE.apicode;
  if (Obj === ObjType.CHATTHREAD.code) result = ObjType.CHATTHREAD.apicode;
  if (Obj === ObjType.CONTACT.code) result = ObjType.CONTACT.apicode;
  if (Obj === ObjType.CONTACTFOLDER.code)
    result = ObjType.CONTACTFOLDER.apicode;
  if (Obj === ObjType.CONTRACT.code) result = ObjType.CONTRACT.apicode;
  if (Obj === ObjType.CONVERSATION.code) result = ObjType.CONVERSATION.apicode;
  if (Obj === ObjType.DEVICE.code) result = ObjType.DEVICE.apicode;
  if (Obj === ObjType.DIRECTORYROLE.code)
    result = ObjType.DIRECTORYROLE.apicode;
  if (Obj === ObjType.DRIVEITEM.code) result = ObjType.DRIVEITEM.apicode;
  if (Obj === ObjType.DOMAIN.code) result = ObjType.DOMAIN.apicode;
  if (Obj === ObjType.EVENT.code) result = ObjType.EVENT.apicode;
  if (Obj === ObjType.IDENTITYPROVIDER.code)
    result = ObjType.IDENTITYPROVIDER.apicode;
  if (Obj === ObjType.INSIGHT.code) result = ObjType.INSIGHT.apicode;
  if (Obj === ObjType.LICENSE.code) result = ObjType.LICENSE.apicode;
  if (Obj === ObjType.LIST.code) result = ObjType.LIST.apicode;
  if (Obj === ObjType.LISTITEM.code) result = ObjType.LISTITEM.apicode;
  if (Obj === ObjType.MAILFOLDER.code) result = ObjType.MAILFOLDER.apicode;
  if (Obj === ObjType.MESSAGE.code) result = ObjType.MESSAGE.apicode;
  if (Obj === ObjType.NOTEBOOK.code) result = ObjType.NOTEBOOK.apicode;
  if (Obj === ObjType.ORGCONTACT.code) result = ObjType.ORGCONTACT.apicode;
  if (Obj === ObjType.PAGE.code) result = ObjType.PAGE.apicode;
  if (Obj === ObjType.PERSON.code) result = ObjType.PERSON.apicode;
  if (Obj === ObjType.POST.code) result = ObjType.POST.apicode;
  if (Obj === ObjType.RISKEVENT.code) result = ObjType.RISKEVENT.apicode;
  if (Obj === ObjType.SECURITYALERT.code)
    result = ObjType.SECURITYALERT.apicode;
  if (Obj === ObjType.SERVICE.code) result = ObjType.SERVICE.apicode;
  if (Obj === ObjType.SERVICEPLAN.code) result = ObjType.SERVICEPLAN.apicode;
  if (Obj === ObjType.SERVICEPRINCIPAL.code)
    result = ObjType.SERVICEPRINCIPAL.apicode;
  if (Obj === ObjType.TASK.code) result = ObjType.TASK.apicode;
  if (Obj === ObjType.TEAM.code) result = ObjType.TEAM.apicode;
  if (Obj === ObjType.TEAMSAPPINSTALLATION.code)
    result = ObjType.TEAMSAPPINSTALLATION.apicode;
  if (Obj === ObjType.TEAMSTAB.code) result = ObjType.TEAMSTAB.apicode;
  if (Obj === ObjType.THREAD.code) result = ObjType.THREAD.apicode;
  if (Obj === ObjType.VERSION.code) result = ObjType.VERSION.apicode;

  return result;
};

export const GetHandle = Obj => {
  let result = '';

  // Segments
  if (Obj === ObjType.DRIVE.code) result = ObjType.DRIVE.handle;
  if (Obj === ObjType.GROUP.code) result = ObjType.GROUP.handle;
  if (Obj === ObjType.ME.code) result = ObjType.ME.handle;
  if (Obj === ObjType.ORGANIZATION.code) result = ObjType.ORGANIZATION.handle;
  if (Obj === ObjType.PLAN.code) result = ObjType.PLAN.handle;
  if (Obj === ObjType.SITE.code) result = ObjType.SITE.handle;
  if (Obj === ObjType.USER.code) result = ObjType.USER.handle;

  // Entities
  if (Obj === ObjType.ACTIVITY.code) result = ObjType.ACTIVITY.handle;
  if (Obj === ObjType.ACTIVITYFOLDER.code)
    result = ObjType.ACTIVITYFOLDER.handle;
  if (Obj === ObjType.ACTIVITYGROUP.code) result = ObjType.ACTIVITYGROUP.handle;
  if (Obj === ObjType.ADMINISTRATIVEUNIT.code)
    result = ObjType.ADMINISTRATIVEUNIT.handle;
  if (Obj === ObjType.AGREEMENT.code) result = ObjType.AGREEMENT.handle;
  if (Obj === ObjType.AGREEMENTACCEPTANCE.code)
    result = ObjType.AGREEMENTACCEPTANCE.handle;
  if (Obj === ObjType.APPLICATION.code) result = ObjType.APPLICATION.handle;
  if (Obj === ObjType.ATTACHMENT.code) result = ObjType.ATTACHMENT.handle;
  if (Obj === ObjType.BOOKINGAPPOINTMENT.code)
    result = ObjType.BOOKINGAPPOINTMENT.handle;
  if (Obj === ObjType.BOOKINGBUSINESS.code)
    result = ObjType.BOOKINGBUSINESS.handle;
  if (Obj === ObjType.BOOKINGCUSTOMER.code)
    result = ObjType.BOOKINGCUSTOMER.handle;
  if (Obj === ObjType.BOOKINGSERVICE.code)
    result = ObjType.BOOKINGSERVICE.handle;
  if (Obj === ObjType.BOOKINGSTAFFMEMBER.code)
    result = ObjType.BOOKINGSTAFFMEMBER.handle;
  if (Obj === ObjType.BUCKET.code) result = ObjType.BUCKET.handle;
  if (Obj === ObjType.CALENDAR.code) result = ObjType.CALENDAR.handle;
  if (Obj === ObjType.CALENDARGROUP.code) result = ObjType.CALENDARGROUP.handle;
  if (Obj === ObjType.CHANNEL.code) result = ObjType.CHANNEL.handle;
  if (Obj === ObjType.CHATMESSAGE.code) result = ObjType.CHATMESSAGE.handle;
  if (Obj === ObjType.CHATTHREAD.code) result = ObjType.CHATTHREAD.handle;
  if (Obj === ObjType.CONTACT.code) result = ObjType.CONTACT.handle;
  if (Obj === ObjType.CONTACTFOLDER.code) result = ObjType.CONTACTFOLDER.handle;
  if (Obj === ObjType.CONTRACT.code) result = ObjType.CONTRACT.handle;
  if (Obj === ObjType.CONVERSATION.code) result = ObjType.CONVERSATION.handle;
  if (Obj === ObjType.DEVICE.code) result = ObjType.DEVICE.handle;
  if (Obj === ObjType.DIRECTORYROLE.code) result = ObjType.DIRECTORYROLE.handle;
  if (Obj === ObjType.DRIVEITEM.code) result = ObjType.DRIVEITEM.handle;
  if (Obj === ObjType.DOMAIN.code) result = ObjType.DOMAIN.handle;
  if (Obj === ObjType.EVENT.code) result = ObjType.EVENT.handle;
  if (Obj === ObjType.IDENTITYPROVIDER.code)
    result = ObjType.IDENTITYPROVIDER.handle;
  if (Obj === ObjType.INSIGHT.code) result = ObjType.INSIGHT.handle;
  if (Obj === ObjType.LICENSE.code) result = ObjType.LICENSE.handle;
  if (Obj === ObjType.LIST.code) result = ObjType.LIST.handle;
  if (Obj === ObjType.LISTITEM.code) result = ObjType.LISTITEM.handle;
  if (Obj === ObjType.MAILFOLDER.code) result = ObjType.MAILFOLDER.handle;
  if (Obj === ObjType.MESSAGE.code) result = ObjType.MESSAGE.handle;
  if (Obj === ObjType.NOTEBOOK.code) result = ObjType.NOTEBOOK.handle;
  if (Obj === ObjType.ORGCONTACT.code) result = ObjType.ORGCONTACT.handle;
  if (Obj === ObjType.PAGE.code) result = ObjType.PAGE.handle;
  if (Obj === ObjType.PERSON.code) result = ObjType.PERSON.handle;
  if (Obj === ObjType.POST.code) result = ObjType.POST.handle;
  if (Obj === ObjType.RISKEVENT.code) result = ObjType.RISKEVENT.handle;
  if (Obj === ObjType.SECURITYALERT.code) result = ObjType.SECURITYALERT.handle;
  if (Obj === ObjType.SERVICE.code) result = ObjType.SERVICE.handle;
  if (Obj === ObjType.SERVICEPLAN.code) result = ObjType.SERVICEPLAN.handle;
  if (Obj === ObjType.SERVICEPRINCIPAL.code)
    result = ObjType.SERVICEPRINCIPAL.handle;
  if (Obj === ObjType.TASK.code) result = ObjType.TASK.handle;
  if (Obj === ObjType.TEAM.code) result = ObjType.TEAM.handle;
  if (Obj === ObjType.TEAMSAPPINSTALLATION.code)
    result = ObjType.TEAMSAPPINSTALLATION.handle;
  if (Obj === ObjType.TEAMSTAB.code) result = ObjType.TEAMSTAB.handle;
  if (Obj === ObjType.THREAD.code) result = ObjType.THREAD.handle;
  if (Obj === ObjType.VERSION.code) result = ObjType.VERSION.handle;

  return result;
};

export const GetUrlExtension = Obj => {
  let result = '';

  // Segments
  if (Obj === ObjType.DRIVE.code) result = ObjType.DRIVE.urlextension;
  if (Obj === ObjType.GROUP.code) result = ObjType.GROUP.urlextension;
  if (Obj === ObjType.ME.code) result = ObjType.ME.urlextension;
  if (Obj === ObjType.ORGANIZATION.code)
    result = ObjType.ORGANIZATION.urlextension;
  if (Obj === ObjType.PLAN.code) result = ObjType.PLAN.urlextension;
  if (Obj === ObjType.SITE.code) result = ObjType.SITE.urlextension;
  if (Obj === ObjType.USER.code) result = ObjType.USER.urlextension;

  // Entities
  if (Obj === ObjType.ACTIVITY.code) result = ObjType.ACTIVITY.urlextension;
  if (Obj === ObjType.ACTIVITYFOLDER.code)
    result = ObjType.ACTIVITYFOLDER.urlextension;
  if (Obj === ObjType.ACTIVITYGROUP.code)
    result = ObjType.ACTIVITYGROUP.urlextension;
  if (Obj === ObjType.ADMINISTRATIVEUNIT.code)
    result = ObjType.ADMINISTRATIVEUNIT.urlextension;
  if (Obj === ObjType.AGREEMENT.code) result = ObjType.AGREEMENT.urlextension;
  if (Obj === ObjType.AGREEMENTACCEPTANCE.code)
    result = ObjType.AGREEMENTACCEPTANCE.urlextension;
  if (Obj === ObjType.APPLICATION.code)
    result = ObjType.APPLICATION.urlextension;
  if (Obj === ObjType.ATTACHMENT.code) result = ObjType.ATTACHMENT.urlextension;
  if (Obj === ObjType.BOOKINGAPPOINTMENT.code)
    result = ObjType.BOOKINGAPPOINTMENT.urlextension;
  if (Obj === ObjType.BOOKINGBUSINESS.code)
    result = ObjType.BOOKINGBUSINESS.urlextension;
  if (Obj === ObjType.BOOKINGCUSTOMER.code)
    result = ObjType.BOOKINGCUSTOMER.urlextension;
  if (Obj === ObjType.BOOKINGSERVICE.code)
    result = ObjType.BOOKINGSERVICE.urlextension;
  if (Obj === ObjType.BOOKINGSTAFFMEMBER.code)
    result = ObjType.BOOKINGSTAFFMEMBER.urlextension;
  if (Obj === ObjType.BUCKET.code) result = ObjType.BUCKET.urlextension;
  if (Obj === ObjType.CALENDAR.code) result = ObjType.CALENDAR.urlextension;
  if (Obj === ObjType.CALENDARGROUP.code)
    result = ObjType.CALENDARGROUP.urlextension;
  if (Obj === ObjType.CHANNEL.code) result = ObjType.CHANNEL.urlextension;
  if (Obj === ObjType.CHATMESSAGE.code)
    result = ObjType.CHATMESSAGE.urlextension;
  if (Obj === ObjType.CHATTHREAD.code) result = ObjType.CHATTHREAD.urlextension;
  if (Obj === ObjType.CONTACT.code) result = ObjType.CONTACT.urlextension;
  if (Obj === ObjType.CONTACTFOLDER.code)
    result = ObjType.CONTACTFOLDER.urlextension;
  if (Obj === ObjType.CONTRACT.code) result = ObjType.CONTRACT.urlextension;
  if (Obj === ObjType.CONVERSATION.code)
    result = ObjType.CONVERSATION.urlextension;
  if (Obj === ObjType.DEVICE.code) result = ObjType.DEVICE.urlextension;
  if (Obj === ObjType.DIRECTORYROLE.code)
    result = ObjType.DIRECTORYROLE.urlextension;
  if (Obj === ObjType.DRIVEITEM.code) result = ObjType.DRIVEITEM.urlextension;
  if (Obj === ObjType.DOMAIN.code) result = ObjType.DOMAIN.urlextension;
  if (Obj === ObjType.EVENT.code) result = ObjType.EVENT.urlextension;
  if (Obj === ObjType.IDENTITYPROVIDER.code)
    result = ObjType.IDENTITYPROVIDER.urlextension;
  if (Obj === ObjType.INSIGHT.code) result = ObjType.INSIGHT.urlextension;
  if (Obj === ObjType.LICENSE.code) result = ObjType.LICENSE.urlextension;
  if (Obj === ObjType.LIST.code) result = ObjType.LIST.urlextension;
  if (Obj === ObjType.LISTITEM.code) result = ObjType.LISTITEM.urlextension;
  if (Obj === ObjType.MAILFOLDER.code) result = ObjType.MAILFOLDER.urlextension;
  if (Obj === ObjType.MESSAGE.code) result = ObjType.MESSAGE.urlextension;
  if (Obj === ObjType.NOTEBOOK.code) result = ObjType.NOTEBOOK.urlextension;
  if (Obj === ObjType.ORGCONTACT.code) result = ObjType.ORGCONTACT.urlextension;
  if (Obj === ObjType.PAGE.code) result = ObjType.PAGE.urlextension;
  if (Obj === ObjType.PERSON.code) result = ObjType.PERSON.urlextension;
  if (Obj === ObjType.POST.code) result = ObjType.POST.urlextension;
  if (Obj === ObjType.RISKEVENT.code) result = ObjType.RISKEVENT.urlextension;
  if (Obj === ObjType.SECURITYALERT.code)
    result = ObjType.SECURITYALERT.urlextension;
  if (Obj === ObjType.SERVICE.code) result = ObjType.SERVICE.urlextension;
  if (Obj === ObjType.SERVICEPLAN.code)
    result = ObjType.SERVICEPLAN.urlextension;
  if (Obj === ObjType.SERVICEPRINCIPAL.code)
    result = ObjType.SERVICEPRINCIPAL.urlextension;
  if (Obj === ObjType.TASK.code) result = ObjType.TASK.urlextension;
  if (Obj === ObjType.TEAM.code) result = ObjType.TEAM.urlextension;
  if (Obj === ObjType.TEAMSAPPINSTALLATION.code)
    result = ObjType.TEAMSAPPINSTALLATION.urlextension;
  if (Obj === ObjType.TEAMSTAB.code) result = ObjType.TEAMSTAB.urlextension;
  if (Obj === ObjType.THREAD.code) result = ObjType.THREAD.urlextension;
  if (Obj === ObjType.VERSION.code) result = ObjType.VERSION.urlextension;

  return result;
};
