import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';
import CardHeader from './Account.Card.Header';
import DataLoader from '../../../common/components/controls/DataLoader';
import * as AdminAuthService from '../../../common/auth/AdminAuthService';

class UserCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleLinkItemClick = (e, { name }) => {
    e.preventDefault();
    if (name === 'logout') {
      this.props.auth.logout();
      AdminAuthService.ClearAccessToken();
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { dataEntity, dataLoading } = this.props;
    const { smallContainer, showExtended, showLinks } = this.props;

    if (dataLoading) {
      return <DataLoader />;
    }

    return (
      <Segment basic>
        <div className="app-account">
          <div className="app-account-header-wrapper">
            <Header as="h3">MY ACCOUNT</Header>
          </div>
          <CardHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataEntity}
            smallContainer={smallContainer}
            showLinks={showLinks}
            onLinkItemClick={this.handleLinkItemClick}
          />
          {showExtended && <div className="app-account-details-wrapper" />}
        </div>
      </Segment>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
UserCard.defaultProps = {
  smallContainer: true,
  showLinks: true,
  showExtended: false
};

UserCard.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  dataContext: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  smallContainer: PropTypes.bool,
  showLinks: PropTypes.bool,
  showExtended: PropTypes.bool,
  loadAccount: PropTypes.func.isRequired
};

export default UserCard;
