import * as Actions from '../../actions/types';
import * as ApplicationConfig from '../../../config/constants/Application';

const initialState = {
  source: ApplicationConfig.NAVIGATION_AREA.dashboard,
  objectType: '',
  objectId: '',
  externalUrl: '',
  relationType: '',
  loadAll: false
};

//-------------------------
// Helpers
//-------------------------

function setRelation(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      relationType: payload.relationType,
      loadAll: payload.loadAll
    };
  }
  return {
    ...state,
    relationType: '',
    loadAll: false
  };
}

function setCurrentNode(state, action) {
  const { payload } = action;
  const area = ApplicationConfig.NAVIGATION_AREA.dashboard;
  if (payload && payload.objectType && payload.objectId) {
    const { objectId, objectType } = payload;
    const { source, externalUrl, receivedAt } = payload;
    return {
      ...state,
      source: source ? source : area,
      objectType: objectType,
      objectId: objectId,
      externalUrl: externalUrl,
      relationType: '',
      loadAll: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    source: area,
    objectType: '',
    objectId: '',
    externalUrl: '',
    relationType: '',
    loadAll: false
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.CURRENTNODE_RELATION:
      return setRelation(state, action);
    case Actions.CURRENTNODE_RECEIVE:
      return setCurrentNode(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getCurrentNode = state => {
  return state;
};
