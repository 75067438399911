import { combineReducers } from 'redux';
import meReducer, * as meSelectors from './dashboard/meReducer';
import tenantReducer, * as tenantSelectors from './dashboard/tenantReducer';
import managerReducer, * as managerSelectors from './dashboard/managerReducer';
import directReportsReducer, * as directReportsSelectors from './dashboard/directReportsReducer';
import joinedGroupsReducer, * as joinedGroupsSelectors from './dashboard/joinedGroupsReducer';
import statisticsReducer, * as statisticsSelectors from './dashboard/statisticsReducer';
import initialGroupsReducer, * as initialGroupsSelectors from './dashboard/initialGroupsReducer';
import initialPlansReducer, * as initialPlansSelectors from './dashboard/initialPlansReducer';
import initialUsersReducer, * as initialUsersSelectors from './dashboard/initialUsersReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  me: meReducer,
  tenant: tenantReducer,
  manager: managerReducer,
  directReports: directReportsReducer,
  joinedGroups: joinedGroupsReducer,
  statistics: statisticsReducer,
  initialGroups: initialGroupsReducer,
  initialPlans: initialPlansReducer,
  initialUsers: initialUsersReducer
});

//-------------------------
// Selectors
//-------------------------

export const getMyUser = state => {
  return meSelectors.getMyUser(state.me);
};

export const getMyTenant = state => {
  return tenantSelectors.getMyTenant(state.tenant);
};

export const getMyManager = state => {
  return managerSelectors.getMyManager(state.manager);
};

export const getMyDirectReports = state => {
  return directReportsSelectors.getMyDirectReports(state.directReports);
};

export const getMyJoinedGroups = state => {
  return joinedGroupsSelectors.getMyJoinedGroups(state.joinedGroups);
};

export const getMyStatistics = state => {
  return statisticsSelectors.getMyStatistics(state.statistics);
};

export const getMyInitialGroups = state => {
  return initialGroupsSelectors.getMyInitialGroups(state.initialGroups);
};

export const getMyInitialPlans = state => {
  return initialPlansSelectors.getMyInitialPlans(state.initialPlans);
};

export const getMyInitialUsers = state => {
  return initialUsersSelectors.getMyInitialUsers(state.initialUsers);
};
