import { combineReducers } from 'redux';
import accountReducer, * as accountSelectors from './admin/accountReducer';
import companyReducer, * as companySelectors from './admin/companyReducer';
import licensesReducer, * as licensesSelectors from './admin/licensesReducer';
import loginsReducer, * as loginsSelectors from './admin/loginsReducer';
import statesReducer, * as statesSelectors from './admin/statesReducer';
import subscriptionsReducer, * as subscriptionsSelectors from './admin/subscriptionsReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  account: accountReducer,
  company: companyReducer,
  licenses: licensesReducer,
  logins: loginsReducer,
  states: statesReducer,
  subscriptions: subscriptionsReducer
});

//-------------------------
// Selectors
//-------------------------

export const getAdminAccount = state => {
  return accountSelectors.getAdminAccount(state.account);
};

export const getAdminCompany = state => {
  return companySelectors.getAdminCompany(state.company);
};

export const getAdminLicenses = state => {
  return licensesSelectors.getAdminLicenses(state.licenses);
};

export const getAdminLogins = state => {
  return loginsSelectors.getAdminLogins(state.logins);
};

export const getAdminStates = state => {
  return statesSelectors.getAdminStates(state.states);
};

export const getAdminSubscriptions = state => {
  return subscriptionsSelectors.getAdminSubscriptions(state.subscriptions);
};
