import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Video, Transformation } from 'cloudinary-react';
import { Button, Progress } from 'semantic-ui-react';
import logo from '../../../app/assets/images/logo-appsolut.png';

class HomeOnboarding extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.actualWidth = 0;
    this.state = {
      videoSize: 'large'
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    this.runOnboarding(this.props.auth);
    this.initialDataLoad(this.props.auth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  //-----------------------------------
  // Event Handler
  //-----------------------------------
  updateDimensions = () => {
    const previousSize = this.state.videoSize;
    this.actualWidth = window.innerWidth;
    const newDeviceSize = this.getDeviceSize(this.actualWidth);
    const newVideoSize = this.getVideoSize(newDeviceSize);
    if (newVideoSize !== previousSize) {
      this.setState({ videoSize: newVideoSize });
    }
  };
  runOnboarding = authService => {
    const onboardingStatus = this.props.onboardingStatus;
    if (!onboardingStatus || !onboardingStatus.finished) {
      const authUser = authService.getUser();
      this.props.runOnboarding(authUser);
    }
  };
  initialDataLoad = auth => {
    this.props.loadInitialUsers(auth);
    this.props.loadInitialGroups(auth);
    this.props.loadInitialPlans(auth);
  };

  //-----------------------------------
  // Functions & Helpers
  //-----------------------------------
  getDeviceSize = width => {
    if (width < 768) return 'mobile';
    if (width < 992) return 'tablett';
    if (width < 1201) return 'desktop';
    return 'widescreen';
  };
  getVideoSize = deviceSize => {
    if (deviceSize === 'mobile') return 'small';
    if (deviceSize === 'tablett') return 'small';
    if (deviceSize === 'desktop') return 'middle';
    if (deviceSize === 'widescreen') return 'large';
    return 'middle';
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, onboardingStatus } = this.props;
    const { videoSize } = this.state;
    const transformation = ['video-' + videoSize];
    const actualUser = auth.getUser();
    const authenticated = auth.authenticated;
    const userName = authenticated ? actualUser.name : '';

    const step = onboardingStatus ? onboardingStatus.step : 1;
    const value = 10 * step;
    const percentage = value <= 100 ? value : 100;
    const inProgress = percentage < 100;

    return (
      <div className="app-pagewrapper">
        <div className="app-welcome">
          <div className="app-logo">
            <img src={logo} alt="appsolut 365" />
          </div>

          <div className="app-welcome-header">
            <p>appsolut graph</p>
          </div>
          <div className="app-welcome-info">Welcome {userName}!</div>

          <div className="app-video">
            <Video
              cloudName="appsolut"
              publicId="website-home/template/social-network-852x480"
              loop
              autoPlay
              muted
            >
              <Transformation transformation={transformation} />
            </Video>
          </div>

          <div className="app-welcome-progress">
            <Progress percent={percentage} indicating />
          </div>
          {inProgress ? (
            <div className="app-welcome-progress-label">
              Provisioning Environment ... Please Wait.
            </div>
          ) : (
            <div className="app-welcome-buttons">
              <div className="app-button-main">
                <Button
                  className="app-hp-hero-button"
                  as={Link}
                  to="/dashboard"
                >
                  Environment Ready - Click to Continue
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
HomeOnboarding.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataAuth: PropTypes.object.isRequired,
  onboardingStatus: PropTypes.object.isRequired,
  runOnboarding: PropTypes.func.isRequired,
  loadInitialGroups: PropTypes.func.isRequired,
  loadInitialPlans: PropTypes.func.isRequired,
  loadInitialUsers: PropTypes.func.isRequired
};

export default HomeOnboarding;
