import * as Actions from '../../../actions/types';

const initialState = {};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId } = payload;
    const entity = state[entityId];
    const updated = {
      ...entity,
      didInvalidate: true
    };
    return {
      ...state,
      [entityId]: updated
    };
  }
  return state;
}

function setFetching(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId } = payload;
    const entity = state[entityId];
    const updated = {
      ...entity,
      isFetching: true,
      didInvalidate: false
    };
    return {
      ...state,
      [entityId]: updated
    };
  }
  return state;
}

function upsertEntity(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId, entity, receivedAt } = payload;
    const entitycontainer = {
      id: entityId,
      entity: entity,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
    return {
      ...state,
      [entityId]: entitycontainer
    };
  }
  return state;
}

function upsertEntitiesFromGroups(state, action) {
  const { payload } = action;
  if (payload && payload.entities) {
    const { entities, receivedAt } = payload;
    let newState = { ...state };
    entities.forEach(entity => {
      const isTeam = groupIsTeam(entity);
      if (isTeam) {
        const newTeam = {
          id: entity.id
        };
        const entitycontainer = {
          id: entity.id,
          entity: newTeam,
          isFetching: false,
          didInvalidate: false,
          lastUpdated: receivedAt
        };
        newState = {
          ...newState,
          [entity.id]: entitycontainer
        };
      }
    });
    return newState;
  }
  return state;
}

function groupIsTeam(groupEntity) {
  const typeArray = groupEntity.resourceProvisioningOptions;
  const isTeam = typeArray ? typeArray.includes('Team') : false;
  return isTeam;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.TEAM_INVALIDATE:
      return setInvalidate(state, action);
    case Actions.TEAM_REQUEST:
      return setFetching(state, action);
    case Actions.TEAM_RECEIVE:
      return upsertEntity(state, action);
    case Actions.INITIALGROUPS_RECEIVE:
    case Actions.INITIALGROUPS_APPEND:
    case Actions.GROUPS_RECEIVE:
    case Actions.GROUPS_APPEND:
      return upsertEntitiesFromGroups(state, action);
    case Actions.GRAPH_QUERY_RECEIVE:
    case Actions.GRAPH_QUERY_APPEND:
      // NO action here - all data already loaded in Initial Queries
      return state;
    case Actions.PATHFINDER_QUERY_RECEIVE:
    case Actions.PATHFINDER_QUERY_APPEND:
      // NO action here - all data already loaded in Initial Queries
      return state;
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getTeamById = (state, id) => {
  return state[id];
};
