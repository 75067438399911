/*eslint eqeqeq:0*/
/*eslint no-useless-escape:0*/

import moment from 'moment';

export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0);

export const isDefined = (entity, key) => {
  if (entity && key && entity.hasOwnProperty(key)) {
    return true;
  }
  return false;
};

export const isValidDate = date => {
  var d = moment(date);
  if (d == null || !d.isValid()) {
    return false;
  }
  return true;
};

export const isEmailAddress = Mail => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Mail)) {
    return true;
  }
  return false;
};
