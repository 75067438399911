import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as ErrorActions from '../../../app/store/actions/app/errorActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import * as Validation from '../../utils/Validation';
import BaseComponent from './SystemMessage';

const mapStateToProps = state => {
  const appError = Selectors.getCurrentAppError(state);
  const hasAppError = !Validation.isEmpty(appError) ? true : false;

  const fetchingError = Selectors.getCurrentFetchingError(state);
  const hasFetchingError = !Validation.isEmpty(fetchingError) ? true : false;

  return {
    hasAppError: hasAppError,
    dataAppError: appError,
    hasFetchingError: hasFetchingError,
    dataFetchingError: fetchingError
  };
};

const mapDispatchToProps = dispatch => ({
  clearAppError: () => dispatch(ErrorActions.clearAppError()),
  clearFetchingError: () => dispatch(ErrorActions.clearFetchingError())
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
