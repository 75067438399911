import * as Actions from '../../../actions/types';

const initialState = {};

//-------------------------
// Helpers
//-------------------------

function clearSteps() {
  return initialState;
}

function setInvalidate(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;
    const step = state[index];
    const updated = {
      ...step,
      didInvalidate: true
    };
    return {
      ...state,
      [index]: updated
    };
  }
  return state;
}

function setFetching(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;
    const step = state[index];
    const updated = {
      ...step,
      isFetching: true,
      didInvalidate: false
    };
    return {
      ...state,
      [index]: updated
    };
  }
  return state;
}

function upsertStep(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index, step, receivedAt } = payload;
    const stepcontainer = {
      index: index,
      step: step,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
    return {
      ...state,
      [index]: stepcontainer
    };
  }
  return state;
}

function upsertSteps(state, action) {
  const { payload } = action;
  if (payload && payload.steps) {
    const { steps, receivedAt } = payload;
    let newState = { ...state };
    steps.forEach(step => {
      const stepcontainer = {
        index: step.index,
        step: step,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: receivedAt
      };
      newState = {
        ...newState,
        [step.index]: stepcontainer
      };
    });
    return newState;
  }
  return state;
}

function removeStep(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;

    // remove property from object (not array)
    let newState = { ...state };
    delete newState[index];

    return { ...newState };
  }
  return state;
}

function setQueryResults(state, action) {
  const { payload } = action;
  if (payload && payload.stepIndex) {
    const { stepIndex, queryIndex, queryParams } = payload;
    const { moreAvailable, moreUrl, receivedAt } = payload;

    const stepContainer = state[stepIndex];
    if (stepContainer && stepContainer.step) {
      const step = stepContainer.step;
      const query = step && step.query ? { ...step.query } : {};
      const updateQuery = {
        index: queryIndex,
        url: queryIndex,
        objectType: queryParams.objectType,
        relationType: query.relationType,
        loadAll: query.loadAll,
        moreAvailable: moreAvailable,
        moreUrl: moreUrl
      };
      const updateStep = {
        ...step,
        query: updateQuery
      };
      const updateStepContainer = {
        ...stepContainer,
        step: updateStep,
        isFetching: false,
        didInvalidate: false,
        lastUpdated: receivedAt
      };
      return {
        ...state,
        [stepIndex]: updateStepContainer
      };
    }
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.PATHFINDER_CLEAR:
      return clearSteps();
    case Actions.PATHFINDER_STEP_INVALIDATE:
      return setInvalidate(state, action);
    case Actions.PATHFINDER_STEP_REQUEST:
      return setFetching(state, action);
    case Actions.PATHFINDER_STEP_RECEIVE:
      return upsertStep(state, action);
    case Actions.PATHFINDERS_STEP_RECEIVE:
      return upsertSteps(state, action);
    case Actions.PATHFINDER_STEP_REMOVE:
      return removeStep(state, action);
    case Actions.PATHFINDER_QUERY_RECEIVE:
    case Actions.PATHFINDER_QUERY_APPEND:
      return setQueryResults(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getStepByIndex = (state, index) => {
  return state[index];
};
