import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Label, Header } from 'semantic-ui-react';
import Sticker from '../../../common/components/controls/NodeSticker';
import DataLoader from '../../../common/components/controls/DataLoader';
import * as ApplicationConfig from '../../../app/config/constants/Application';
import * as ObjectConfig from '../../../app/config/entities/Objects';

class UserStickerlist extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    this.props.loadUsers(this.props.auth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleStickerClick = (key, url) => {
    this.props.setCurrentContext(ObjectConfig.USER.code, key);
    this.props.setCurrentNode(
      this.props.area,
      ObjectConfig.USER.code,
      key,
      url
    );
    this.props.history.push(`${this.props.match.url}/node`);
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { dataEntities, dataLoading, headerText } = this.props;
    //const { dataMoreAvailable, dataMoreUrl } = this.props;
    //console.log(dataEntities);

    if (dataLoading) {
      return <DataLoader />;
    }

    return (
      <Segment basic>
        {headerText && (
          <Header sub className="app-itemcard-subheader">
            {headerText}
          </Header>
        )}
        <div className="app-personas-list">
          <Label.Group>
            {dataEntities.map(itemData => (
              <Sticker
                key={itemData && itemData.entity ? itemData.entity.id : '1'}
                auth={auth}
                location={location}
                match={match}
                params={params}
                itemKey={itemData && itemData.entity ? itemData.entity.id : ''}
                itemName={
                  itemData && itemData.entity ? itemData.entity.displayName : ''
                }
                itemImage={
                  itemData && itemData.entity && itemData.entity.image
                    ? itemData.entity.image.url
                    : ''
                }
                externalUrl={
                  itemData && itemData.entity ? itemData.entity.mySite : ''
                }
                onStickerClick={this.handleStickerClick}
              />
            ))}
          </Label.Group>
        </div>
      </Segment>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
UserStickerlist.defaultProps = {
  area: ApplicationConfig.NAVIGATION_AREA.dashboard
};

UserStickerlist.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataEntities: PropTypes.array.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  area: PropTypes.string,
  dataMoreAvailable: PropTypes.bool.isRequired,
  dataMoreUrl: PropTypes.string,
  headerText: PropTypes.string,
  loadUsers: PropTypes.func.isRequired,
  loadMoreUsers: PropTypes.func,
  invalidateUsers: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired
};

export default UserStickerlist;
