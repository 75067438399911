import moment from 'moment';
import * as Selectors from '../../app/store/reducers/rootReducer';
import * as ObjectConfig from '../../app/config/entities/Objects';
import * as ObjectParams from '../../app/config/entities/ParameterOfObject';
import * as RelationParams from '../../app/config/entities/ParameterOfRelation';
import * as RelationTypes from '../../app/config/entities/RelationTypes';

export const CreateNodeQuery = (Type, Id, Parent = {}, Segment = {}) => {
  //------------------------------------------------------------
  // Used in Entity Actions for query of single Entities
  //------------------------------------------------------------
  let queryStatement = CreateBaseQuery(Type, Id, Parent, Segment, true);
  return queryStatement;
};

export const CreateRelationQuery = (QueryHeader, QueryParams) => {
  //------------------------------------------------------------
  // Used in stepActions to select Relations (Lists of Entities)
  //------------------------------------------------------------
  const { relation, objectType, loadMore, moreUrl } = QueryParams;

  if (loadMore) {
    return {
      url: moreUrl,
      page: 'next',
      objectType: objectType
    };
  } else {
    let queryStatement = '';
    let queryObjectType = '';

    if (QueryHeader && QueryHeader.context && relation) {
      const queryContext = QueryHeader.context;
      const nType = QueryHeader.entityType;
      const nId = QueryHeader.entityId;
      const segment = {
        type: queryContext.segmentType,
        id: queryContext.segmentId
      };
      const parent = {
        type: queryContext.parentType,
        id: queryContext.parentId
      };

      // Step 1: Get Node Query string
      let queryNode = '';
      if (
        relation === RelationTypes.BOOKINGAPPOINTMENTS.customerAll ||
        relation === RelationTypes.BOOKINGAPPOINTMENTS.serviceAll
      ) {
        queryNode = CreateQueryForManualBookingAppointments(parent);
      } else {
        queryNode = CreateBaseQuery(nType, nId, parent, segment, false);
      }

      // Step 2: Add the Relation segment
      const queryRelation = RelationParams.GetHandle(relation);
      queryStatement = queryNode + '/' + queryRelation;

      // Step 3: adjustments for Relation
      const startDateISO = moment().toISOString();
      const endDateISO = moment()
        .add(1, 'months')
        .toISOString();
      if (
        relation === RelationTypes.EVENTS.coming ||
        relation === RelationTypes.BOOKINGAPPOINTMENTS.coming
      ) {
        const queryExtension =
          '?startDateTime=' +
          startDateISO +
          '&endDateTime=' +
          endDateISO +
          '&$count=true';
        queryStatement = queryStatement + queryExtension;
      }
      if (
        relation === RelationTypes.BOOKINGAPPOINTMENTS.customerAll ||
        relation === RelationTypes.BOOKINGAPPOINTMENTS.serviceAll
      ) {
        queryStatement = `${queryStatement} '${nId}'`;
      }

      // Step 4: get the Type of Relation objects
      queryObjectType = RelationParams.GetObjectType(relation);
    }
    return {
      url: queryStatement,
      page: 'first',
      objectType: queryObjectType
    };
  }
};

export const CreateContext = (State, Type, Id, Parent = {}, Segment = {}) => {
  let newContext = {};
  if (Type && Id) {
    if (EntityIsSegmentType(Type)) {
      if (EntityIsMe(State, Type, Id)) {
        return {
          segmentType: ObjectConfig.ME.code,
          segmentId: Id,
          parentType: '',
          parentId: ''
        };
      } else {
        return {
          segmentType: Type,
          segmentId: Id,
          parentType: '',
          parentId: ''
        };
      }
    } else {
      const currentContext = Selectors.getCurrentContext(State);
      const sType = Segment ? Segment.type : currentContext.segmentType;
      const sId = Segment ? Segment.id : currentContext.segmentId;
      const pType = Parent ? Parent.type : '';
      const pId = Parent ? Parent.id : '';
      return {
        segmentType: sType,
        segmentId: sId,
        parentType: pType,
        parentId: pId
      };
    }
  }
  return newContext;
};

//-------------------------
// Helpers
//-------------------------

const CreateBaseQuery = (Type, Id, Parent, Segment, IsSingle) => {
  let queryStatement = '';

  if (Type && Id) {
    const queryExtension = IsSingle ? ObjectParams.GetUrlExtension(Type) : '';
    const segmentType = Segment ? Segment.type : '';
    const segmentId = Segment ? Segment.id : '';
    const parentType = Parent ? Parent.type : '';
    const parentId = Parent ? Parent.id : '';

    let withParent = false;
    if (
      (segmentType === ObjectConfig.GROUP.code &&
        parentType === ObjectConfig.THREAD.code &&
        Type === ObjectConfig.POST.code) ||
      Type === ObjectConfig.ATTACHMENT.code ||
      Type === ObjectConfig.BOOKINGAPPOINTMENT.code
    ) {
      withParent = true;
    }

    if (EntityIsSegmentType(Type)) {
      //----------------------------------------------------------
      // Node Is Segment ?
      //----------------------------------------------------------
      // [node/[id]] + params
      //----------------------------------------------------------
      // SEGMENT: --
      // PARENT: --
      // NODE:
      if (!EntityTypeIsOrganization(Type)) {
        const nodeHandle =
          HandlePrefixForNode('', '', Type, withParent) +
          ObjectParams.GetHandle(Type);
        queryStatement += '/' + nodeHandle;
        if (!EntityTypeIsMe(Type)) {
          queryStatement += '/' + Id;
        }
      }
      // PARAMS:
      queryStatement += queryExtension;
    } else if (EntityHasRootHandle(Type)) {
      //----------------------------------------------------------
      // Node Has Root-Handle ?
      //----------------------------------------------------------
      // node/id + params
      //----------------------------------------------------------
      // SEGMENT: --
      // PARENT: --
      // NODE:
      const queryNode = CreateNodeHandle('', '', Type, Id, withParent);
      queryStatement += queryNode;

      // PARAMS:
      queryStatement += queryExtension;
    } else if (IsSingle && EntityHasSingleRootHandle(Type)) {
      //----------------------------------------------------------
      // Node Has Root-Handle ?
      //----------------------------------------------------------
      // node/id + params
      //----------------------------------------------------------
      // SEGMENT: --
      // PARENT: --
      // NODE:
      const queryNode = CreateNodeHandle('', '', Type, Id, withParent);
      queryStatement += queryNode;

      // PARAMS:
      queryStatement += queryExtension;
    } else if (EntityTypeIsOrganization(segmentType)) {
      //----------------------------------------------------------
      // Segment Is Organization ?
      //----------------------------------------------------------
      // [parent/id/] node/id + params
      //----------------------------------------------------------
      // SEGMENT: --
      // PARENT:
      if (parentType && parentId) {
        const parentHandle =
          HandlePrefixForParent('', parentType) +
          ObjectParams.GetHandle(parentType);
        queryStatement += '/' + parentHandle;
        queryStatement += '/' + Parent.id;
      }
      // NODE:
      const queryNode = CreateNodeHandle('', parentType, Type, Id, withParent);
      queryStatement += queryNode;

      // PARAMS:
      queryStatement += queryExtension;
    } else if (EntityHasRootHandle(parentType)) {
      //----------------------------------------------------------
      // Parent Has Root Handle ?
      //----------------------------------------------------------
      // parent/id/ node/id + params
      //----------------------------------------------------------
      // SEGMENT: --
      // PARENT:
      const parentHandle =
        HandlePrefixForParent('', parentType) +
        ObjectParams.GetHandle(parentType);
      queryStatement += '/' + parentHandle;
      queryStatement += '/' + Parent.id;
      // NODE:
      const queryNode = CreateNodeHandle('', parentType, Type, Id, withParent);
      queryStatement += queryNode;

      // PARAMS:
      queryStatement += queryExtension;
    } else {
      //----------------------------------------------------------
      // Standard
      //----------------------------------------------------------
      // segment/[id] [parent/id/] node/id + params
      //----------------------------------------------------------
      // SEGMENT:
      const segmentHandle =
        HandlePrefixForSegment(segmentType) +
        ObjectParams.GetHandle(segmentType);
      queryStatement += '/' + segmentHandle;
      if (!EntityTypeIsMe(segmentType)) {
        queryStatement += '/' + segmentId;
      }
      // PARENT:
      if (withParent && parentType && parentId) {
        const parentHandle =
          HandlePrefixForParent(segmentType, parentType) +
          ObjectParams.GetHandle(parentType);
        queryStatement += '/' + parentHandle;
        queryStatement += '/' + Parent.id;
      }
      // NODE:
      const queryNode = CreateNodeHandle(
        segmentType,
        parentType,
        Type,
        Id,
        withParent
      );
      queryStatement += queryNode;

      // PARAMS:
      queryStatement += queryExtension;
    }
  }
  return queryStatement;
};

const CreateQueryForManualBookingAppointments = Parent => {
  //----------------------------------------------------------
  // SPECIAL CASE for BOOKINGAPPOINTMENTS.customerAll
  //----------------------------------------------------------
  let queryStatement = '';

  const parentType = Parent ? Parent.type : '';
  const parentId = Parent ? Parent.id : '';

  //----------------------------------------------------------
  // Segment Is Organization
  //----------------------------------------------------------
  // parent/id/
  //----------------------------------------------------------
  // SEGMENT: --
  // PARENT:
  if (parentType && parentId) {
    const parentHandle =
      HandlePrefixForParent('', parentType) +
      ObjectParams.GetHandle(parentType);
    queryStatement += '/' + parentHandle;
    queryStatement += '/' + Parent.id;
  }
  // NODE: --

  return queryStatement;
};

const CreateNodeHandle = (sType, pType, Type, Id, pInclude) => {
  let result = '';

  const nodeHandlePrefix = HandlePrefixForNode(sType, pType, Type, pInclude);
  let nodeHandleBase = ObjectParams.GetHandle(Type);

  //----------------------------
  // Check Special Cases
  //----------------------------
  if (
    sType === ObjectConfig.GROUP.code &&
    Type === ObjectConfig.CALENDAR.code
  ) {
    const nodeHandle = nodeHandlePrefix + 'calendar';
    result += '/' + nodeHandle;
  } else {
    //----------------------------
    // Standard Case
    //----------------------------
    const nodeHandle = nodeHandlePrefix + nodeHandleBase;
    result += '/' + nodeHandle;
    result += '/' + Id;
  }
  return result;
};

const HandlePrefixForSegment = EntityType => {
  let result = '';
  if (EntityIsPlannerElement(EntityType)) {
    result = 'planner/';
  }
  if (EntityIsOutlookElement(EntityType)) {
    result = 'outlook/';
  }
  if (EntityIsOneNoteElement(EntityType)) {
    result = 'onenote/';
  }
  return result;
};

const HandlePrefixForParent = (SegmentType, EntityType) => {
  let result = '';

  const segmentIsPlanner = EntityIsPlannerElement(SegmentType);
  const segmentIsOutlook = EntityIsOutlookElement(SegmentType);
  const segmentIsOneNote = EntityIsOneNoteElement(SegmentType);

  if (EntityIsPlannerElement(EntityType) && !segmentIsPlanner) {
    result = 'planner/';
  }
  if (EntityIsOutlookElement(EntityType) && !segmentIsOutlook) {
    result = 'outlook/';
  }
  if (EntityIsOneNoteElement(EntityType) && !segmentIsOneNote) {
    result = 'onenote/';
  }
  return result;
};

const HandlePrefixForNode = (SegmentType, ParentType, EntityType, pInclude) => {
  let result = '';

  const segmentIsPlanner = EntityIsPlannerElement(SegmentType);
  const segmentIsOutlook = EntityIsOutlookElement(SegmentType);
  const segmentIsOneNote = EntityIsOneNoteElement(SegmentType);
  const parentIsPlanner = EntityIsPlannerElement(ParentType) && pInclude;
  const parentIsOutlook = EntityIsOutlookElement(ParentType) && pInclude;
  const parentIsOneNote = EntityIsOneNoteElement(ParentType) && pInclude;
  const isPlanner = segmentIsPlanner || parentIsPlanner;
  const isOutlook = segmentIsOutlook || parentIsOutlook;
  const isOneNote = segmentIsOneNote || parentIsOneNote;

  if (EntityIsPlannerElement(EntityType) && !isPlanner) {
    result = 'planner/';
  }
  if (EntityIsOutlookElement(EntityType) && !isOutlook) {
    result = 'outlook/';
  }
  if (EntityIsOneNoteElement(EntityType) && !isOneNote) {
    result = 'onenote/';
  }
  return result;
};

const EntityIsPlannerElement = EntityType => {
  if (EntityType) {
    if (
      EntityType === ObjectConfig.PLAN.code ||
      EntityType === ObjectConfig.BUCKET.code ||
      EntityType === ObjectConfig.TASK.code
    ) {
      return true;
    }
  }
  return false;
};

const EntityIsOutlookElement = EntityType => {
  if (EntityType) {
    if (
      EntityType === ObjectConfig.ACTIVITY.code ||
      EntityType === ObjectConfig.ACTIVITYFOLDER.code ||
      EntityType === ObjectConfig.ACTIVITYGROUP.code
    ) {
      return true;
    }
  }
  return false;
};

const EntityIsOneNoteElement = EntityType => {
  if (EntityType) {
    if (EntityType === ObjectConfig.NOTEBOOK.code) {
      return true;
    }
  }
  return false;
};

const EntityIsSegmentType = EntityType => {
  if (EntityType) {
    if (
      EntityType === ObjectConfig.DRIVE.code ||
      EntityType === ObjectConfig.GROUP.code ||
      EntityType === ObjectConfig.ME.code ||
      EntityType === ObjectConfig.ORGANIZATION.code ||
      EntityType === ObjectConfig.PLAN.code ||
      EntityType === ObjectConfig.SITE.code ||
      EntityType === ObjectConfig.USER.code
    ) {
      return true;
    }
  }
  return false;
};

const EntityHasRootHandle = EntityType => {
  if (EntityType) {
    if (
      EntityType === ObjectConfig.AGREEMENT.code ||
      EntityType === ObjectConfig.APPLICATION.code ||
      EntityType === ObjectConfig.BOOKINGBUSINESS.code ||
      EntityType === ObjectConfig.BUCKET.code ||
      EntityType === ObjectConfig.CONTRACT.code ||
      EntityType === ObjectConfig.DEVICE.code ||
      EntityType === ObjectConfig.DIRECTORYROLE.code ||
      EntityType === ObjectConfig.DOMAIN.code ||
      EntityType === ObjectConfig.IDENTITYPROVIDER.code ||
      EntityType === ObjectConfig.LICENSE.code ||
      EntityType === ObjectConfig.ORGCONTACT.code ||
      EntityType === ObjectConfig.RISKEVENT.code ||
      EntityType === ObjectConfig.SECURITYALERT.code ||
      EntityType === ObjectConfig.SERVICEPRINCIPAL.code ||
      EntityType === ObjectConfig.TEAM.code
    ) {
      return true;
    }
  }
  return false;
};

const EntityHasSingleRootHandle = EntityType => {
  if (EntityType) {
    if (EntityType === ObjectConfig.TASK.code) {
      return true;
    }
  }
  return false;
};

const EntityIsMe = (State, EntityType, EntityId) => {
  if (EntityType && EntityId) {
    const me = Selectors.getMyUser(State);
    if (me && EntityType === ObjectConfig.USER.code && EntityId === me.userId) {
      return true;
    }
  }
  return false;
};

const EntityTypeIsOrganization = EntityType => {
  if (EntityType && EntityType === ObjectConfig.ORGANIZATION.code) {
    return true;
  }
  return false;
};

const EntityTypeIsMe = EntityType => {
  if (EntityType && EntityType === ObjectConfig.ME.code) {
    return true;
  }
  return false;
};
