import * as RelType from './RelationTypes';
import * as ObjType from './Objects';

export const GetLabel = Rel => {
  let result = '--';

  //------------------------
  // Segments
  //------------------------

  // DRIVE
  if (Rel === RelType.DRIVES.all) result = 'Drives';
  if (Rel === RelType.DRIVES.assigned) result = 'Drives';
  if (Rel === RelType.DRIVES.default) result = 'Drive | default';

  // GROUP
  if (Rel === RelType.GROUPS.acceptedSenders)
    result = 'Groups | accepted Senders';
  if (Rel === RelType.GROUPS.all) result = 'Groups';
  if (Rel === RelType.GROUPS.assigned) result = 'Groups';
  if (Rel === RelType.GROUPS.joined) result = 'Groups | joined';
  if (Rel === RelType.GROUPS.memberOf) result = 'Groups | member of';
  if (Rel === RelType.GROUPS.members) result = 'Groups | members';
  if (Rel === RelType.GROUPS.membersWithLicenseErrors)
    result = 'Groups | Licensing issues';
  if (Rel === RelType.GROUPS.rejectedSenders)
    result = 'Groups | rejected Senders';

  // ORGANIZATION

  // PLAN
  if (Rel === RelType.PLANS.all) result = 'Plans';
  if (Rel === RelType.PLANS.assigned) result = 'Plans';
  if (Rel === RelType.PLANS.favorite) result = 'Plans | favorite';
  if (Rel === RelType.PLANS.recent) result = 'Plans | recent';

  // SITE
  if (Rel === RelType.SITES.children) result = 'Subsites';
  if (Rel === RelType.SITES.root) result = 'Site';

  // USER
  if (Rel === RelType.USERS.acceptedSenders)
    result = 'Users | accepted Senders';
  if (Rel === RelType.USERS.all) result = 'Users';
  if (Rel === RelType.USERS.assigned) result = 'Users';
  if (Rel === RelType.USERS.directReports) result = 'Users | Direct Reports';
  if (Rel === RelType.USERS.impacted) result = 'Users | impacted';
  if (Rel === RelType.USERS.manager) result = 'User | Manager';
  if (Rel === RelType.USERS.members) result = 'Users | members';
  if (Rel === RelType.USERS.membersWithLicenseErrors)
    result = 'Users | Licensing issues';
  if (Rel === RelType.USERS.owners) result = 'Users | owners';
  if (Rel === RelType.USERS.registeredOwners) result = 'Users | owners';
  if (Rel === RelType.USERS.registeredUsers) result = 'Users';
  if (Rel === RelType.USERS.rejectedSenders)
    result = 'Users | rejected Senders';
  if (Rel === RelType.USERS.taskAssigned) result = 'Users | assigned to';
  if (Rel === RelType.USERS.taskOwners) result = 'Users | owners';

  //------------------------
  // Entities
  //------------------------

  if (Rel === RelType.ACTIVITIES.all) result = 'Activities';
  if (Rel === RelType.ACTIVITIES.open) result = 'Activities | open';
  if (Rel === RelType.ACTIVITIES.subAll) result = 'Activities';
  if (Rel === RelType.ACTIVITIES.subOpen) result = 'Activities | open';
  if (Rel === RelType.ACTIVITYFOLDERS.all) result = 'Activity Folders';
  if (Rel === RelType.ACTIVITYFOLDERS.subAll) result = 'Activity Folders';
  if (Rel === RelType.ACTIVITYGROUPS.all) result = 'Activity Groups';
  if (Rel === RelType.ADMINISTRATIVEUNITS.memberOf)
    result = 'Administrative Units';
  if (Rel === RelType.AGREEMENTS.all) result = 'Agreements';
  if (Rel === RelType.AGREEMENTACCEPTANCE.all) result = 'Agreements';
  if (Rel === RelType.APPLICATIONS.assigned) result = 'Applications';
  if (Rel === RelType.ATTACHMENTS.all) result = 'Attachments';

  if (Rel === RelType.BOOKINGAPPOINTMENTS.all) result = 'Appointments';
  if (Rel === RelType.BOOKINGAPPOINTMENTS.coming)
    result = 'Appointments | coming';
  if (Rel === RelType.BOOKINGAPPOINTMENTS.customerAll) result = 'Appointments';
  if (Rel === RelType.BOOKINGAPPOINTMENTS.serviceAll) result = 'Appointments';
  if (Rel === RelType.BOOKINGBUSINESSES.all) result = 'Booking Businesses';
  if (Rel === RelType.BOOKINGCUSTOMERS.all) result = 'Customers';
  if (Rel === RelType.BOOKINGSERVICES.all) result = 'Services';
  if (Rel === RelType.BOOKINGSTAFFMEMBERS.all) result = 'Staff Members';
  if (Rel === RelType.BUCKETS.all) result = 'Buckets';

  if (Rel === RelType.CALENDARGROUPS.all) result = 'Calendar Groups';
  if (Rel === RelType.CALENDARS.all) result = 'Calendars';
  if (Rel === RelType.CALENDARS.assigned) result = 'Calendars';
  if (Rel === RelType.CALENDARS.default) result = 'Calendar | default';
  if (Rel === RelType.CALENDARS.single) result = 'Calendar';
  if (Rel === RelType.CHANNELS.all) result = 'Channels';
  if (Rel === RelType.CHATMESSAGES.all) result = 'Chat Messages';
  if (Rel === RelType.CHATMESSAGES.root) result = 'Chat Messages | root';
  if (Rel === RelType.CHATTHREADS.all) result = 'Chat Threads';
  if (Rel === RelType.CONTACTFOLDERS.all) result = 'Contact Folders';
  if (Rel === RelType.CONTACTFOLDERS.children) result = 'Subfolders';
  if (Rel === RelType.CONTACTS.all) result = 'Contacts';
  if (Rel === RelType.CONTACTS.assigned) result = 'Contacts';
  if (Rel === RelType.CONTRACTS.assigned) result = 'Contracts';
  if (Rel === RelType.CONVERSATIONS.all) result = 'Conversations';

  if (Rel === RelType.DEVICES.assigned) result = 'Devices';
  if (Rel === RelType.DEVICES.owned) result = 'Devices | owned';
  if (Rel === RelType.DEVICES.registered) result = 'Devices | registered';
  if (Rel === RelType.DIRECTORYROLES.memberOf)
    result = 'Directory Roles | member of';
  if (Rel === RelType.DOMAINES.assigned) result = 'Domains';
  if (Rel === RelType.DRIVEITEMS.appRoot) result = 'Item | app root';
  if (Rel === RelType.DRIVEITEMS.cameraRoll) result = 'Item | camera';
  if (Rel === RelType.DRIVEITEMS.children) result = 'Subitems';
  if (Rel === RelType.DRIVEITEMS.documents) result = 'Item | documents';
  if (Rel === RelType.DRIVEITEMS.following) result = 'Items | following';
  if (Rel === RelType.DRIVEITEMS.music) result = 'Item | music';
  if (Rel === RelType.DRIVEITEMS.photos) result = 'Item | photos';
  if (Rel === RelType.DRIVEITEMS.root) result = 'Item | root';

  if (Rel === RelType.EVENTS.all) result = 'Events';
  if (Rel === RelType.EVENTS.coming) result = 'Events | coming';
  if (Rel === RelType.EVENTS.instances) result = 'Instances';

  if (Rel === RelType.IDENTITYPROVIDERS.assigned) result = 'Identity Providers';
  if (Rel === RelType.INSIGHTS.shared) result = 'Items | shared';
  if (Rel === RelType.INSIGHTS.trending) result = 'Items | trending';
  if (Rel === RelType.INSIGHTS.used) result = 'Items | used';

  if (Rel === RelType.LICENSES.assigned) result = 'Licenses';
  if (Rel === RelType.LICENSES.subscribed) result = 'Licenses | subscribed';
  if (Rel === RelType.LISTITEMS.all) result = 'Listitems';
  if (Rel === RelType.LISTS.all) result = 'Lists';

  if (Rel === RelType.MAILFOLDERS.all) result = 'Mail Folders';
  if (Rel === RelType.MAILFOLDERS.children) result = 'Subfolders';
  if (Rel === RelType.MESSAGES.all) result = 'Messages';
  if (Rel === RelType.MESSAGES.unread) result = 'Messages | unread';
  if (Rel === RelType.MESSAGES.inboxAll) result = 'Messages';
  if (Rel === RelType.MESSAGES.inboxUnread) result = 'Messages | unread';

  if (Rel === RelType.NOTEBOOKS.all) result = 'Notebooks';
  if (Rel === RelType.NOTEBOOKS.assigned) result = 'Notebooks';

  if (Rel === RelType.ORGCONTACTS.assigned) result = 'Contacts';
  if (Rel === RelType.ORGCONTACTS.directReports)
    result = 'Contacts | Direct Reports';
  if (Rel === RelType.ORGCONTACTS.manager) result = 'Contact | Manager';
  if (Rel === RelType.ORGCONTACTS.members) result = 'Contacts | members';
  if (Rel === RelType.ORGCONTACTS.membersWithLicenseErrors)
    result = 'Contacts | Licensing issues';

  if (Rel === RelType.PAGES.all) result = 'Pages';
  if (Rel === RelType.PERSONS.trending) result = 'Persons | trending';
  if (Rel === RelType.POSTS.all) result = 'Posts';
  if (Rel === RelType.POSTS.inReplyTo) result = 'Posts | in reply to';

  if (Rel === RelType.RISKEVENTS.anonymousIp) result = 'Risks | anonymous IP';
  if (Rel === RelType.RISKEVENTS.identity) result = 'Risks | identity';
  if (Rel === RelType.RISKEVENTS.impossibleTravel)
    result = 'Risks | impossible Travel';
  if (Rel === RelType.RISKEVENTS.leakedCredentials)
    result = 'Risks | leaked Credentials';
  if (Rel === RelType.RISKEVENTS.malware) result = 'Risks | malware';
  if (Rel === RelType.RISKEVENTS.suspiciousIp) result = 'Risks | suspicious IP';
  if (Rel === RelType.RISKEVENTS.unfamiliarLocation)
    result = 'Risks | unfamiliar Location';

  if (Rel === RelType.SECURITYALERTS.all) result = 'Security Alerts';
  if (Rel === RelType.SERVICES.all) result = 'Services';
  if (Rel === RelType.SERVICEPLANS.assigned) result = 'Service Plans';
  if (Rel === RelType.SERVICEPRINCIPALS.assigned) result = 'Service Principals';

  if (Rel === RelType.TASKS.all) result = 'Tasks';
  if (Rel === RelType.TASKS.assigned) result = 'Tasks';
  if (Rel === RelType.TASKS.open) result = 'Tasks | open';
  if (Rel === RelType.TASKS.subAll) result = 'Tasks';
  if (Rel === RelType.TASKS.subAssigned) result = 'Tasks';
  if (Rel === RelType.TASKS.subOpen) result = 'Tasks | open';
  if (Rel === RelType.TEAMS.all) result = 'Teams';
  if (Rel === RelType.TEAMS.joined) result = 'Teams | joined';
  if (Rel === RelType.TEAMSAPPINSTALLATIONS.all) result = 'Apps';
  if (Rel === RelType.TEAMSTABS.all) result = 'Teams Tabs';
  if (Rel === RelType.THREADS.all) result = 'Conversation Threads';

  if (Rel === RelType.VERSIONS.all) result = 'Versions';

  return result;
};

export const GetObjectType = Rel => {
  let result = '';

  //------------------------
  // Segments
  //------------------------

  // DRIVE
  if (Rel === RelType.DRIVES.all) result = ObjType.DRIVE.code;
  if (Rel === RelType.DRIVES.assigned) result = ObjType.DRIVE.code;
  if (Rel === RelType.DRIVES.default) result = ObjType.DRIVE.code;

  // GROUP
  if (Rel === RelType.GROUPS.acceptedSenders) result = ObjType.GROUP.code;
  if (Rel === RelType.GROUPS.all) result = ObjType.GROUP.code;
  if (Rel === RelType.GROUPS.assigned) result = ObjType.GROUP.code;
  if (Rel === RelType.GROUPS.joined) result = ObjType.GROUP.code;
  if (Rel === RelType.GROUPS.memberOf) result = ObjType.GROUP.code;
  if (Rel === RelType.GROUPS.members) result = ObjType.GROUP.code;
  if (Rel === RelType.GROUPS.membersWithLicenseErrors)
    result = ObjType.GROUP.code;
  if (Rel === RelType.GROUPS.rejectedSenders) result = ObjType.GROUP.code;

  // ORGANIZATION

  // PLAN
  if (Rel === RelType.PLANS.all) result = ObjType.PLAN.code;
  if (Rel === RelType.PLANS.assigned) result = ObjType.PLAN.code;
  if (Rel === RelType.PLANS.favorite) result = ObjType.PLAN.code;
  if (Rel === RelType.PLANS.recent) result = ObjType.PLAN.code;

  // SITE
  if (Rel === RelType.SITES.children) result = ObjType.SITE.code;
  if (Rel === RelType.SITES.root) result = ObjType.SITE.code;

  // USER
  if (Rel === RelType.USERS.acceptedSenders) result = ObjType.USER.code;
  if (Rel === RelType.USERS.all) result = ObjType.USER.code;
  if (Rel === RelType.USERS.assigned) result = ObjType.USER.code;
  if (Rel === RelType.USERS.directReports) result = ObjType.USER.code;
  if (Rel === RelType.USERS.impacted) result = ObjType.USER.code;
  if (Rel === RelType.USERS.manager) result = ObjType.USER.code;
  if (Rel === RelType.USERS.members) result = ObjType.USER.code;
  if (Rel === RelType.USERS.membersWithLicenseErrors)
    result = ObjType.USER.code;
  if (Rel === RelType.USERS.owners) result = ObjType.USER.code;
  if (Rel === RelType.USERS.registeredOwners) result = ObjType.USER.code;
  if (Rel === RelType.USERS.registeredUsers) result = ObjType.USER.code;
  if (Rel === RelType.USERS.rejectedSenders) result = ObjType.USER.code;
  if (Rel === RelType.USERS.taskAssigned) result = ObjType.USER.code;
  if (Rel === RelType.USERS.taskOwners) result = ObjType.USER.code;

  //------------------------
  // Entities
  //------------------------

  if (Rel === RelType.ACTIVITIES.all) result = ObjType.ACTIVITY.code;
  if (Rel === RelType.ACTIVITIES.open) result = ObjType.ACTIVITY.code;
  if (Rel === RelType.ACTIVITIES.subAll) result = ObjType.ACTIVITY.code;
  if (Rel === RelType.ACTIVITIES.subOpen) result = ObjType.ACTIVITY.code;
  if (Rel === RelType.ACTIVITYFOLDERS.all) result = ObjType.ACTIVITYFOLDER.code;
  if (Rel === RelType.ACTIVITYFOLDERS.subAll)
    result = ObjType.ACTIVITYFOLDER.code;
  if (Rel === RelType.ACTIVITYGROUPS.all) result = ObjType.ACTIVITYGROUP.code;
  if (Rel === RelType.ADMINISTRATIVEUNITS.memberOf)
    result = ObjType.ADMINISTRATIVEUNIT.code;
  if (Rel === RelType.AGREEMENTS.all) result = ObjType.AGREEMENT.code;
  if (Rel === RelType.AGREEMENTACCEPTANCE.all)
    result = ObjType.AGREEMENTACCEPTANCE.code;
  if (Rel === RelType.APPLICATIONS.assigned) result = ObjType.APPLICATION.code;
  if (Rel === RelType.ATTACHMENTS.all) result = ObjType.ATTACHMENT.code;

  if (Rel === RelType.BOOKINGAPPOINTMENTS.all)
    result = ObjType.BOOKINGAPPOINTMENT.code;
  if (Rel === RelType.BOOKINGAPPOINTMENTS.coming)
    result = ObjType.BOOKINGAPPOINTMENT.code;
  if (Rel === RelType.BOOKINGAPPOINTMENTS.customerAll)
    result = ObjType.BOOKINGAPPOINTMENT.code;
  if (Rel === RelType.BOOKINGAPPOINTMENTS.serviceAll)
    result = ObjType.BOOKINGAPPOINTMENT.code;
  if (Rel === RelType.BOOKINGBUSINESSES.all)
    result = ObjType.BOOKINGBUSINESS.code;
  if (Rel === RelType.BOOKINGCUSTOMERS.all)
    result = ObjType.BOOKINGCUSTOMER.code;
  if (Rel === RelType.BOOKINGSERVICES.all) result = ObjType.BOOKINGSERVICE.code;
  if (Rel === RelType.BOOKINGSTAFFMEMBERS.all)
    result = ObjType.BOOKINGSTAFFMEMBER.code;
  if (Rel === RelType.BUCKETS.all) result = ObjType.BUCKET.code;

  if (Rel === RelType.CALENDARGROUPS.all) result = ObjType.CALENDARGROUP.code;
  if (Rel === RelType.CALENDARS.all) result = ObjType.CALENDAR.code;
  if (Rel === RelType.CALENDARS.assigned) result = ObjType.CALENDAR.code;
  if (Rel === RelType.CALENDARS.default) result = ObjType.CALENDAR.code;
  if (Rel === RelType.CALENDARS.single) result = ObjType.CALENDAR.code;
  if (Rel === RelType.CHANNELS.all) result = ObjType.CHANNEL.code;
  if (Rel === RelType.CHATMESSAGES.all) result = ObjType.CHATMESSAGE.code;
  if (Rel === RelType.CHATMESSAGES.root) result = ObjType.CHATMESSAGE.code;
  if (Rel === RelType.CHATTHREADS.all) result = ObjType.CHATTHREAD.code;
  if (Rel === RelType.CONTACTFOLDERS.all) result = ObjType.CONTACTFOLDER.code;
  if (Rel === RelType.CONTACTFOLDERS.children)
    result = ObjType.CONTACTFOLDER.code;
  if (Rel === RelType.CONTACTS.all) result = ObjType.CONTACT.code;
  if (Rel === RelType.CONTACTS.assigned) result = ObjType.CONTACT.code;
  if (Rel === RelType.CONTRACTS.assigned) result = ObjType.CONTRACT.code;
  if (Rel === RelType.CONVERSATIONS.all) result = ObjType.CONVERSATION.code;

  if (Rel === RelType.DEVICES.assigned) result = ObjType.DEVICE.code;
  if (Rel === RelType.DEVICES.owned) result = ObjType.DEVICE.code;
  if (Rel === RelType.DEVICES.registered) result = ObjType.DEVICE.code;
  if (Rel === RelType.DIRECTORYROLES.memberOf)
    result = ObjType.DIRECTORYROLE.code;
  if (Rel === RelType.DOMAINES.assigned) result = ObjType.DOMAIN.code;
  if (Rel === RelType.DRIVEITEMS.appRoot) result = ObjType.DRIVEITEM.code;
  if (Rel === RelType.DRIVEITEMS.cameraRoll) result = ObjType.DRIVEITEM.code;
  if (Rel === RelType.DRIVEITEMS.children) result = ObjType.DRIVEITEM.code;
  if (Rel === RelType.DRIVEITEMS.documents) result = ObjType.DRIVEITEM.code;
  if (Rel === RelType.DRIVEITEMS.following) result = ObjType.DRIVEITEM.code;
  if (Rel === RelType.DRIVEITEMS.music) result = ObjType.DRIVEITEM.code;
  if (Rel === RelType.DRIVEITEMS.photos) result = ObjType.DRIVEITEM.code;
  if (Rel === RelType.DRIVEITEMS.root) result = ObjType.DRIVEITEM.code;

  if (Rel === RelType.EVENTS.all) result = ObjType.EVENT.code;
  if (Rel === RelType.EVENTS.coming) result = ObjType.EVENT.code;
  if (Rel === RelType.EVENTS.instances) result = ObjType.EVENT.code;

  if (Rel === RelType.IDENTITYPROVIDERS.assigned)
    result = ObjType.IDENTITYPROVIDER.code;
  if (Rel === RelType.INSIGHTS.shared) result = ObjType.INSIGHT.code;
  if (Rel === RelType.INSIGHTS.trending) result = ObjType.INSIGHT.code;
  if (Rel === RelType.INSIGHTS.used) result = ObjType.INSIGHT.code;

  if (Rel === RelType.LICENSES.assigned) result = ObjType.LICENSE.code;
  if (Rel === RelType.LISTITEMS.all) result = ObjType.LISTITEM.code;
  if (Rel === RelType.LISTS.all) result = ObjType.LIST.code;

  if (Rel === RelType.MAILFOLDERS.all) result = ObjType.MAILFOLDER.code;
  if (Rel === RelType.MAILFOLDERS.children) result = ObjType.MAILFOLDER.code;
  if (Rel === RelType.MESSAGES.all) result = ObjType.MESSAGE.code;
  if (Rel === RelType.MESSAGES.unread) result = ObjType.MESSAGE.code;
  if (Rel === RelType.MESSAGES.inboxAll) result = ObjType.MESSAGE.code;
  if (Rel === RelType.MESSAGES.inboxUnread) result = ObjType.MESSAGE.code;

  if (Rel === RelType.NOTEBOOKS.all) result = ObjType.NOTEBOOK.code;
  if (Rel === RelType.NOTEBOOKS.assigned) result = ObjType.NOTEBOOK.code;

  if (Rel === RelType.ORGCONTACTS.assigned) result = ObjType.ORGCONTACT.code;
  if (Rel === RelType.ORGCONTACTS.directReports)
    result = ObjType.ORGCONTACT.code;
  if (Rel === RelType.ORGCONTACTS.manager) result = ObjType.ORGCONTACT.code;
  if (Rel === RelType.ORGCONTACTS.members) result = ObjType.ORGCONTACT.code;
  if (Rel === RelType.ORGCONTACTS.membersWithLicenseErrors)
    result = ObjType.ORGCONTACT.code;

  if (Rel === RelType.PAGES.all) result = ObjType.PAGE.code;
  if (Rel === RelType.PERSONS.trending) result = ObjType.PERSON.code;
  if (Rel === RelType.POSTS.all) result = ObjType.POST.code;
  if (Rel === RelType.POSTS.inReplyTo) result = ObjType.POST.code;

  if (Rel === RelType.RISKEVENTS.anonymousIp) result = ObjType.RISKEVENT.code;
  if (Rel === RelType.RISKEVENTS.identity) result = ObjType.RISKEVENT.code;
  if (Rel === RelType.RISKEVENTS.impossibleTravel)
    result = ObjType.RISKEVENT.code;
  if (Rel === RelType.RISKEVENTS.leakedCredentials)
    result = ObjType.RISKEVENT.code;
  if (Rel === RelType.RISKEVENTS.malware) result = ObjType.RISKEVENT.code;
  if (Rel === RelType.RISKEVENTS.suspiciousIp) result = ObjType.RISKEVENT.code;
  if (Rel === RelType.RISKEVENTS.unfamiliarLocation)
    result = ObjType.RISKEVENT.code;

  if (Rel === RelType.SECURITYALERTS.all) result = ObjType.SECURITYALERT.code;
  if (Rel === RelType.SERVICES.all) result = ObjType.SERVICE.code;
  if (Rel === RelType.SERVICEPLANS.assigned) result = ObjType.SERVICEPLAN.code;
  if (Rel === RelType.SERVICEPRINCIPALS.assigned)
    result = ObjType.SERVICEPRINCIPAL.code;

  if (Rel === RelType.TASKS.all) result = ObjType.TASK.code;
  if (Rel === RelType.TASKS.assigned) result = ObjType.TASK.code;
  if (Rel === RelType.TASKS.open) result = ObjType.TASK.code;
  if (Rel === RelType.TASKS.subAll) result = ObjType.TASK.code;
  if (Rel === RelType.TASKS.subAssigned) result = ObjType.TASK.code;
  if (Rel === RelType.TASKS.subOpen) result = ObjType.TASK.code;
  if (Rel === RelType.TEAMS.all) result = ObjType.TEAM.code;
  if (Rel === RelType.TEAMS.joined) result = ObjType.TEAM.code;
  if (Rel === RelType.TEAMSAPPINSTALLATIONS.all)
    result = ObjType.TEAMSAPPINSTALLATION.code;
  if (Rel === RelType.TEAMSTABS.all) result = ObjType.TEAMSTAB.code;
  if (Rel === RelType.THREADS.all) result = ObjType.THREAD.code;

  if (Rel === RelType.VERSIONS.all) result = ObjType.VERSION.code;

  return result;
};

export const GetHandle = Rel => {
  let result = '';

  //------------------------
  // Segments
  //------------------------

  // DRIVE
  if (Rel === RelType.DRIVES.all) result = 'drives';
  if (Rel === RelType.DRIVES.assigned) result = 'drives';
  if (Rel === RelType.DRIVES.default) result = 'drive';

  // GROUP
  if (Rel === RelType.GROUPS.acceptedSenders) result = 'acceptedSenders';
  if (Rel === RelType.GROUPS.all) result = 'groups?$orderby=displayName';
  if (Rel === RelType.GROUPS.assigned) result = 'groups?$orderby=displayName';
  if (Rel === RelType.GROUPS.joined) result = 'joinedGroups';
  if (Rel === RelType.GROUPS.memberOf) result = 'memberOf';
  if (Rel === RelType.GROUPS.members) result = 'members';
  if (Rel === RelType.GROUPS.membersWithLicenseErrors)
    result = 'membersWithLicenseErrors';
  if (Rel === RelType.GROUPS.rejectedSenders) result = 'rejectedSenders';

  // ORGANIZATION

  // PLAN
  if (Rel === RelType.PLANS.all) result = 'planner/plans?$orderby=title';
  if (Rel === RelType.PLANS.assigned) result = 'planner/plans?$orderby=title';
  if (Rel === RelType.PLANS.favorite)
    result = 'planner/favoritePlans?$orderby=title';
  if (Rel === RelType.PLANS.recent)
    result = 'planner/recentPlans?$orderby=title';

  // SITE
  if (Rel === RelType.SITES.children) result = 'sites';
  if (Rel === RelType.SITES.root) result = 'sites/root';

  // USER
  if (Rel === RelType.USERS.acceptedSenders) result = 'acceptedSenders';
  if (Rel === RelType.USERS.all) result = 'users?$orderby=displayName';
  if (Rel === RelType.USERS.assigned) result = 'users?$orderby=displayName';
  if (Rel === RelType.USERS.directReports) result = 'directReports';
  if (Rel === RelType.USERS.impacted) result = 'impactedUser';
  if (Rel === RelType.USERS.manager) result = 'manager';
  if (Rel === RelType.USERS.members) result = 'members';
  if (Rel === RelType.USERS.membersWithLicenseErrors)
    result = 'membersWithLicenseErrors';
  if (Rel === RelType.USERS.owners) result = 'owners';
  if (Rel === RelType.USERS.registeredOwners) result = 'registeredOwners';
  if (Rel === RelType.USERS.registeredUsers) result = 'registeredUsers';
  if (Rel === RelType.USERS.rejectedSenders) result = 'rejectedSenders';
  if (Rel === RelType.USERS.taskAssigned) result = '$STATIC';
  if (Rel === RelType.USERS.taskOwners) result = '$STATIC';

  //------------------------
  // Entities
  //------------------------

  if (Rel === RelType.ACTIVITIES.all) result = 'outlook/tasks';
  if (Rel === RelType.ACTIVITIES.subAll) result = 'tasks';
  if (Rel === RelType.ACTIVITIES.open)
    result = "outlook/tasks?$filter=status ne 'completed'";
  if (Rel === RelType.ACTIVITIES.subOpen)
    result = "tasks?$filter=status ne 'completed'";
  if (Rel === RelType.ACTIVITYGROUPS.all) result = 'outlook/taskGroups';
  if (Rel === RelType.ACTIVITYFOLDERS.all) result = 'outlook/taskFolders';
  if (Rel === RelType.ACTIVITYFOLDERS.subAll) result = 'taskFolders';
  if (Rel === RelType.ADMINISTRATIVEUNITS.memberOf) result = 'memberOf';
  if (Rel === RelType.AGREEMENTS.all) result = 'agreements';
  if (Rel === RelType.AGREEMENTACCEPTANCE.all) result = 'agreementAcceptances';
  if (Rel === RelType.APPLICATIONS.assigned) result = 'applications';
  if (Rel === RelType.ATTACHMENTS.all) result = 'attachments';

  if (Rel === RelType.BOOKINGAPPOINTMENTS.all) result = 'appointments';
  if (Rel === RelType.BOOKINGAPPOINTMENTS.coming) result = 'calendarView';
  if (Rel === RelType.BOOKINGAPPOINTMENTS.customerAll)
    result = 'appointments?$filter=customerId eq';
  if (Rel === RelType.BOOKINGAPPOINTMENTS.serviceAll)
    result = 'appointments?$filter=serviceId eq';
  if (Rel === RelType.BOOKINGBUSINESSES.all) result = 'bookingBusinesses';
  if (Rel === RelType.BOOKINGCUSTOMERS.all) result = 'customers';
  if (Rel === RelType.BOOKINGSERVICES.all) result = 'services';
  if (Rel === RelType.BOOKINGSTAFFMEMBERS.all) result = 'staffMembers';
  if (Rel === RelType.BUCKETS.all) result = 'buckets';

  if (Rel === RelType.CALENDARGROUPS.all) result = 'calendarGroups';
  if (Rel === RelType.CALENDARS.all) result = 'calendars';
  if (Rel === RelType.CALENDARS.assigned) result = 'calendars';
  if (Rel === RelType.CALENDARS.default) result = 'calendar';
  if (Rel === RelType.CALENDARS.single) result = 'calendar';
  if (Rel === RelType.CHANNELS.all) result = 'channels';
  if (Rel === RelType.CHATMESSAGES.all) result = 'messages';
  if (Rel === RelType.CHATMESSAGES.root) result = 'messages';
  if (Rel === RelType.CHATTHREADS.all) result = 'chatThreads';
  if (Rel === RelType.CONTACTFOLDERS.all) result = 'contactFolders';
  if (Rel === RelType.CONTACTFOLDERS.children) result = 'childFolders';
  if (Rel === RelType.CONTACTS.all) result = 'contacts?$orderby=displayName';
  if (Rel === RelType.CONTACTS.assigned)
    result = 'contacts?$orderby=displayName';
  if (Rel === RelType.CONTRACTS.assigned) result = 'contracts';
  if (Rel === RelType.CONVERSATIONS.all) result = 'conversations';

  if (Rel === RelType.DEVICES.assigned) result = 'devices';
  if (Rel === RelType.DEVICES.owned) result = 'ownedDevices';
  if (Rel === RelType.DEVICES.registered) result = 'registeredDevices';
  if (Rel === RelType.DIRECTORYROLES.memberOf) result = 'memberOf';
  if (Rel === RelType.DOMAINES.assigned) result = 'domains';
  if (Rel === RelType.DRIVEITEMS.appRoot) result = 'special/approot';
  if (Rel === RelType.DRIVEITEMS.cameraRoll) result = 'special/cameraroll';
  if (Rel === RelType.DRIVEITEMS.children) result = 'children';
  if (Rel === RelType.DRIVEITEMS.documents) result = 'special/documents';
  if (Rel === RelType.DRIVEITEMS.following) result = 'following';
  if (Rel === RelType.DRIVEITEMS.music) result = 'special/music';
  if (Rel === RelType.DRIVEITEMS.photos) result = 'special/photos';
  if (Rel === RelType.DRIVEITEMS.root) result = 'root';

  if (Rel === RelType.EVENTS.all) result = 'events';
  if (Rel === RelType.EVENTS.coming) result = 'calendarView';
  if (Rel === RelType.EVENTS.instances) result = 'instances';

  if (Rel === RelType.IDENTITYPROVIDERS.assigned) result = 'identityProviders';
  if (Rel === RelType.INSIGHTS.shared) result = 'insights/shared';
  if (Rel === RelType.INSIGHTS.trending) result = 'insights/trending';
  if (Rel === RelType.INSIGHTS.used) result = 'insights/used';

  if (Rel === RelType.LICENSES.assigned) result = 'licenseDetails';
  if (Rel === RelType.LICENSES.subscribed) result = 'subscribedSkus';
  if (Rel === RelType.LISTITEMS.all) result = 'items';
  if (Rel === RelType.LISTS.all) result = 'lists';

  if (Rel === RelType.MAILFOLDERS.all) result = 'mailFolders';
  if (Rel === RelType.MAILFOLDERS.children) result = 'childFolders';
  if (Rel === RelType.MESSAGES.all) result = 'messages';
  if (Rel === RelType.MESSAGES.unread)
    result = 'messages?$filter=isRead eq false';
  if (Rel === RelType.MESSAGES.inboxAll) result = 'mailFolders/inbox/messages';
  if (Rel === RelType.MESSAGES.inboxUnread)
    result = 'mailFolders/inbox/messages?$filter=isRead eq false';

  if (Rel === RelType.NOTEBOOKS.all) result = 'onenote/notebooks';
  if (Rel === RelType.NOTEBOOKS.assigned) result = 'onenote/notebooks';

  if (Rel === RelType.ORGCONTACTS.assigned) result = 'contacts';
  if (Rel === RelType.ORGCONTACTS.directReports) result = 'directReports';
  if (Rel === RelType.ORGCONTACTS.manager) result = 'manager';
  if (Rel === RelType.ORGCONTACTS.members) result = 'members';
  if (Rel === RelType.ORGCONTACTS.membersWithLicenseErrors)
    result = 'membersWithLicenseErrors';

  if (Rel === RelType.PAGES.all) result = 'pages';
  if (Rel === RelType.PERSONS.trending) result = 'people';
  if (Rel === RelType.POSTS.all) result = 'posts';
  if (Rel === RelType.POSTS.inReplyTo) result = 'inReplyTo';

  if (Rel === RelType.RISKEVENTS.anonymousIp) result = 'anonymousIpRiskEvents';
  if (Rel === RelType.RISKEVENTS.identity) result = 'identityRiskEvents';
  if (Rel === RelType.RISKEVENTS.impossibleTravel)
    result = 'impossibleTravelRiskEvents';
  if (Rel === RelType.RISKEVENTS.leakedCredentials)
    result = 'leakedCredentialsRiskEvents';
  if (Rel === RelType.RISKEVENTS.malware) result = 'malwareRiskEvents';
  if (Rel === RelType.RISKEVENTS.suspiciousIp)
    result = 'suspiciousIpRiskEvents';
  if (Rel === RelType.RISKEVENTS.unfamiliarLocation)
    result = 'unfamiliarLocationRiskEvents';

  if (Rel === RelType.SECURITYALERTS.all) result = 'security/alerts';
  if (Rel === RelType.SERVICES.all) result = '--';
  if (Rel === RelType.SERVICEPLANS.assigned) result = '';
  if (Rel === RelType.SERVICEPRINCIPALS.assigned) result = 'servicePrincipals';

  if (Rel === RelType.TASKS.all) result = 'planner/tasks';
  if (Rel === RelType.TASKS.assigned) result = 'planner/tasks';
  if (Rel === RelType.TASKS.open)
    result = 'planner/tasks?$filter=percentComplete lt 100';
  if (Rel === RelType.TASKS.subAll) result = 'tasks';
  if (Rel === RelType.TASKS.subAssigned) result = 'tasks';
  if (Rel === RelType.TASKS.subOpen)
    result = 'tasks?$filter=percentComplete lt 100';
  if (Rel === RelType.TEAMS.all) result = '$STATIC';
  if (Rel === RelType.TEAMS.joined) result = 'joinedTeams';
  if (Rel === RelType.TEAMSAPPINSTALLATIONS.all) result = 'apps';
  if (Rel === RelType.TEAMSTABS.all) result = 'tabs';
  if (Rel === RelType.THREADS.all) result = 'threads';

  if (Rel === RelType.VERSIONS.all) result = 'versions';

  return result;
};
