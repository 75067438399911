import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Button, Dropdown, Header } from 'semantic-ui-react';
import Slider from 'react-slick';
import classnames from 'classnames';
import PathfinderHeader from './Pathfinder.Header';
import Placeholder from '../../../common/components/controls/Placeholder';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as ApplicationConfig from '../../../app/config/settings/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Validation from '../../../common/utils/Validation';

class PathfinderCarousel extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
    if (this.slider) {
      this.slider.slickGoTo(this.props.dataPosition);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.dataPosition !== prevProps.dataPosition) {
      if (this.slider) {
        this.slider.slickGoTo(this.props.dataPosition);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  handleGoToFirst = () => {
    this.props.setCurrentStepPosition(1);
    //this.slider.slickGoTo(1);
  };
  handleGoToPrevious = () => {
    if (this.props.dataPosition > 1) {
      this.props.setCurrentStepPosition(this.props.dataPosition - 1);
      //this.slider.slickPrev();
    }
  };
  handleGoToNext = () => {
    if (this.props.dataPosition < this.props.dataCount) {
      this.props.setCurrentStepPosition(this.props.dataPosition + 1);
      //this.slider.slickNext();
    }
  };
  handleGoToLast = () => {
    this.props.setCurrentStepPosition(this.props.dataCount);
    //this.slider.slickGoTo(this.props.dataCount)
  };
  handleSliderGoTo = e => {
    this.props.setCurrentStepPosition(e.target.value);
    //this.slider.slickGoTo(e.target.value);
  };
  handleSliderSwipe = direction => {
    console.log(`Swipe to: ${direction}`);
    if (direction === 'right') {
      if (this.props.dataPosition > 1) {
        this.props.setCurrentStepPosition(this.props.dataPosition - 1);
      }
    }
    if (direction === 'left') {
      if (this.props.dataPosition < this.props.dataCount) {
        this.props.setCurrentStepPosition(this.props.dataPosition + 1);
      }
    }
  };
  handleSliderBeforeChange = (current, next) => {
    //this.props.setCurrentStepPosition(next);
  };
  handleSliderAfterChange = current => {
    //console.log(`Slider Changed to: ${current}`);
  };
  handleRefresh = () => {
    console.log('Refresh Step: ' + this.props.dataPosition);
    this.props.invalidateStep(this.props.dataPosition);
    this.props.refreshStepQuery(this.props.auth, this.props.dataPosition);
  };

  handleClearPathfinder = () => {
    if (window.confirm('All Steps in the Path will be removed. Continue?')) {
      this.props.clearAllSteps();
      this.props.addInitialStep();
      this.props.setCurrentStepPositionToLast();
    }
  };
  handleRemoveStep = () => {
    if (window.confirm('The current Step will be removed. Continue?')) {
      const currPosition = this.props.dataPosition;
      const maxPosition = this.props.dataCount;
      this.props.removeCurrentStep();
      if (currPosition >= maxPosition) {
        this.props.setCurrentStepPosition(currPosition - 1);
      }
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, history, params } = this.props;
    const { dataEntities, dataEntity, dataCount } = this.props;
    const { dataPosition } = this.props;
    const deviceMobile = ApplicationConst.DEVICE_SIZE.mobile;
    const sliderConfig = {
      initialSlide: dataPosition,
      centerMode: true,
      className: 'center',
      centerPadding: '0px',
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      onSwipe: this.handleSliderSwipe,
      beforeChange: this.handleSliderBeforeChange,
      afterChange: this.handleSliderAfterChange
    };
    const dataStep = dataEntity ? dataEntity.step : {};
    const dataQuery = dataStep ? dataStep.query : {};
    const dataQueryIndex = dataQuery ? dataQuery.index : '';
    const hasQuery = !Validation.isEmpty(dataQueryIndex);

    const navFirstActive =
      dataPosition > 1 && dataEntities && dataEntities.length > 0;
    const navPreviousActive =
      dataPosition > 1 && dataEntities && dataEntities.length > 0;
    const navNextActive =
      dataPosition < dataCount && dataEntities && dataEntities.length > 0;
    const navLastActive =
      dataPosition < dataCount && dataEntities && dataEntities.length > 0;
    const navRefreshActive =
      dataPosition >= 1 && dataPosition <= dataCount && hasQuery;

    const navSubmenuActive = dataPosition >= 1 && dataPosition <= dataCount;

    const navButtons = (
      <div>
        <Button
          active={false}
          disabled={!navFirstActive}
          circular
          icon={MenuConfig.ICONS_MENU_DEFAULTS.pagerFirst}
          onClick={this.handleGoToFirst}
        />
        <Button
          active={false}
          disabled={!navPreviousActive}
          circular
          icon={MenuConfig.ICONS_MENU_DEFAULTS.pagerPrevious}
          onClick={this.handleGoToPrevious}
        />
        <Button
          active={false}
          disabled={!navRefreshActive}
          circular
          icon={MenuConfig.ICONS_MENU_DEFAULTS.refresh}
          onClick={this.handleRefresh}
        />
        <Button
          active={false}
          disabled={!navNextActive}
          circular
          icon={MenuConfig.ICONS_MENU_DEFAULTS.pagerNext}
          onClick={this.handleGoToNext}
        />
        <Button
          active={false}
          disabled={!navLastActive}
          circular
          icon={MenuConfig.ICONS_MENU_DEFAULTS.pagerLast}
          onClick={this.handleGoToLast}
        />
      </div>
    );
    const menuButton = (
      <div className="app-pathfinder-navigator-submenu">
        <Dropdown
          item
          icon={MenuConfig.ICONS_MENU_DEFAULTS.toggle}
          simple
          disabled={!navSubmenuActive}
        >
          <Dropdown.Menu direction="left">
            <Dropdown.Item
              text="Clear all Steps from Path"
              onClick={this.handleClearPathfinder}
            />
            <Dropdown.Divider />
            <Dropdown.Item text="Remove Step" onClick={this.handleRemoveStep} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );

    return (
      <div>
        <div className="app-pathslider">
          {dataEntities && dataEntities.length > 0 ? (
            <Slider ref={slider => (this.slider = slider)} {...sliderConfig}>
              <div>
                <Segment basic>
                  <Header as="h3"> </Header>
                </Segment>
              </div>
              {dataEntities.map((stepContainer, index) => {
                return (
                  <div
                    key={stepContainer.index}
                    className={classnames('app-pathslider-slide', {
                      active: dataPosition === index + 1
                    })}
                  >
                    <Segment secondary={!(dataPosition === index + 1)}>
                      <PathfinderHeader
                        auth={auth}
                        location={location}
                        match={match}
                        history={history}
                        params={params}
                        dataStepContainer={stepContainer}
                        isCurrent={dataPosition === index + 1}
                        setCurrentNode={this.props.setCurrentNode}
                        setCurrentContext={this.props.setCurrentContext}
                      />
                    </Segment>
                  </div>
                );
              })}
              <div>
                <Segment basic>
                  <Header as="h3"> </Header>
                </Segment>
              </div>
            </Slider>
          ) : (
            <Placeholder
              icon={ApplicationConfig.SETTINGS_APP_ICONS.pathfinder}
              header="No data selected!"
              subHeader="To start the Pathfinder, please select an object first."
              showButton={true}
              buttonText="Go to Dashboard"
              buttonPath="/dashboard"
            />
          )}
        </div>
        <input
          className="app-slider"
          onChange={this.handleSliderGoTo}
          value={dataPosition}
          type="range"
          min={1}
          max={dataCount}
        />
        {this.state.windowWidth > deviceMobile ? (
          <div className="app-pathfinder-navigator">
            <div className="app-pathfinder-navigator-flex-fix" />
            <div className="app-pathfinder-navigator-flex-growing app-align-center">
              {navButtons}
            </div>
            <div className="app-pathfinder-navigator-flex-fix app-align-right">
              {menuButton}
            </div>
          </div>
        ) : (
          <div className="app-pathfinder-navigator">
            <div className="app-pathfinder-navigator-flex-growing">
              {navButtons}
            </div>
            <div className="app-pathfinder-navigator-flex-fix app-align-right">
              {menuButton}
            </div>
          </div>
        )}
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
PathfinderCarousel.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataEntities: PropTypes.array.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataCount: PropTypes.number.isRequired,
  dataPosition: PropTypes.number.isRequired,
  refreshStepQuery: PropTypes.func.isRequired,
  invalidateStep: PropTypes.func.isRequired,
  clearAllSteps: PropTypes.func.isRequired,
  removeCurrentStep: PropTypes.func.isRequired,
  addInitialStep: PropTypes.func.isRequired,
  setCurrentStepPosition: PropTypes.func.isRequired,
  setCurrentStepPositionToLast: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired
};

export default PathfinderCarousel;
