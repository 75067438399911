import * as Actions from '../types';
import * as AccountDataService from '../../../../data/admin/AccountDataService';
import * as Validation from '../../../../common/utils/Validation';

//-------------------------
// ADMIN ACCOUNT
// - invalidateAccount
// - loadAccountById
// - loadAccountByExternalId
// - receiveEntity
//-------------------------

export const invalidateAccount = () => {
  return {
    type: Actions.ADMIN_ACCOUNT_INVALIDATE,
    payload: {}
  };
};

export const loadAccountById = id => (dispatch, getState) => {
  if (shouldFetchEntity(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchAccountById(id));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const loadAccountByExternalId = externalId => (dispatch, getState) => {
  if (shouldFetchEntity(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchAccountByExternalId(externalId));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const receiveEntity = data => {
  return {
    type: Actions.ADMIN_ACCOUNT_RECEIVE,
    payload: {
      entityId: !Validation.isEmpty(data) ? data._id : '',
      entity: data,
      receivedAt: Date.now()
    }
  };
};

//-------------------------
// Helpers
//-------------------------

const requestEntity = () => {
  return {
    type: Actions.ADMIN_ACCOUNT_REQUEST,
    payload: {}
  };
};

const receiveError = err => {
  if (err.response.status === 404) {
    return {
      type: Actions.ADMIN_ACCOUNT_RECEIVE,
      payload: {
        entityId: '',
        entity: {},
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err.response
    }
  };
};

const shouldFetchEntity = state => {
  // Allways load from Database
  return true;
};

const fetchAccountById = id => dispatch => {
  dispatch(requestEntity());
  AccountDataService.GetById(id)
    .then(res => dispatch(receiveEntity(res)))
    .catch(err => dispatch(receiveError(err)));
};

const fetchAccountByExternalId = externalId => dispatch => {
  dispatch(requestEntity());
  AccountDataService.GetByExternalId(externalId)
    .then(res => dispatch(receiveEntity(res)))
    .catch(err => dispatch(receiveError(err)));
};
