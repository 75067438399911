import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as PathActions from '../../../app/store/actions/pathfinder/pathActions';
import * as StepActions from '../../../app/store/actions/pathfinder/stepActions';
import * as CurNodeActions from '../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import * as ObjectConf from '../../../app/config/entities/Objects';
import * as RelationTypes from '../../../app/config/entities/RelationTypes';
import * as RelationParams from '../../../app/config/entities/ParameterOfRelation';
import * as Hydration from '../../../common/utils/HydrationUtils';
import * as Validation from '../../../common/utils/Validation';
import BaseComponent from './Pathfinder.Canvas';

const mapStateToProps = state => {
  const stepCount = Selectors.getAllPathfinderStepsCount(state);
  let stepList = Selectors.getAllPathfinderSteps(state);

  // Deep load Header data
  stepList.forEach(stepCont => {
    if (stepCont && stepCont.step && stepCont.step.header) {
      const header = stepCont.step.header;
      const entityId = header.entityId;
      const type = header.entityType;
      const entityCont = Selectors.getEntityById(state, type, entityId);
      const entity = entityCont ? entityCont.entity : {};

      // Special Data Hydration
      const hydratedEntity = Hydration.EntityHydration(entity, type, state);

      // Return hydrated Entity
      stepCont.step.header.entity = hydratedEntity;
    }
  });

  // Deep load Relation data
  let stepPos = Selectors.getPathfinderCurrentStepPosition(state);
  if (stepPos > stepCount || (stepPos < 1 && stepCount > 0)) {
    stepPos = stepCount;
    PathActions.setCurrentStepPosition(stepPos);
  }

  const currStepObj = stepPos > 0 ? stepList[stepPos - 1] : {};
  let currentStep = {};
  if (currStepObj && currStepObj.step && currStepObj.step.query) {
    const stepHeader = currStepObj.step.header;
    const headerEntityType = stepHeader ? stepHeader.entityType : '';
    const stepQuery = currStepObj.step.query;
    const relationType = stepQuery ? stepQuery.relationType : '';
    const relationHandle = RelationParams.GetHandle(relationType);

    if (relationHandle === '$STATIC') {
      //-------------------------------------------------------------
      // Special Cases
      //-------------------------------------------------------------

      const entityType = RelationParams.GetObjectType(relationType);
      let entityIds = [];
      let entities = [];

      if (
        headerEntityType === ObjectConf.ORGANIZATION.code &&
        relationType === RelationTypes.TEAMS.all
      ) {
        entities = Selectors.getAllTeamEntities(state);
      }
      if (
        headerEntityType === ObjectConf.TASK.code &&
        relationType === RelationTypes.USERS.taskAssigned
      ) {
        const stepEntity = stepHeader ? stepHeader.entity : {};
        const stepAssignments = stepEntity ? stepEntity.assignments : {};
        for (const key in stepAssignments) {
          let assignment = stepAssignments[key];
          if (assignment) {
            const baseEntity = assignment.entity;
            const newEntity = {
              didInvalidate: false,
              entity: {
                ...baseEntity
              },
              id: baseEntity ? baseEntity.id : 0,
              isFetching: false
            };
            entities = entities.concat(newEntity);
          }
        }
      }
      if (
        headerEntityType === ObjectConf.TASK.code &&
        relationType === RelationTypes.USERS.taskOwners
      ) {
        const stepEntity = stepHeader ? stepHeader.entity : {};
        const stepCreatedBy = stepEntity ? stepEntity.createdBy : {};
        const stepOwner = stepCreatedBy ? stepCreatedBy.user : {};
        if (stepOwner) {
          const baseEntity = stepOwner.entity;
          const newEntity = {
            didInvalidate: false,
            entity: {
              ...baseEntity
            },
            id: baseEntity ? baseEntity.id : 0,
            isFetching: false
          };
          entities = entities.concat(newEntity);
        }
      }

      // Create Result
      currentStep = {
        ...currStepObj,
        step: {
          ...currStepObj.step,
          query: {
            ...currStepObj.step.query,
            entityIds: entityIds,
            entities: entities,
            moreAvailable: false,
            moreUrl: '',
            objectType: entityType,
            url: ''
          }
        }
      };
      //console.log("Selected Step:");
      //console.log(currentStep);
    } else {
      //-------------------------------------------------------------
      // Standard
      //-------------------------------------------------------------

      const queryIndex = currStepObj.step.query.index;
      if (!Validation.isEmpty(queryIndex)) {
        const queryCont = Selectors.getQueryByIndex(state, queryIndex);
        if (queryCont && queryCont.query) {
          const entityIds = queryCont.query.entities;
          const type = queryCont.query.objectType;
          const entities = Selectors.getEntitiesByIds(state, type, entityIds);

          // Special Hydration of Lists
          let hydratedEntities = [];

          entities.forEach(entityCont => {
            const baseEntity = entityCont ? entityCont.entity : {};
            const hydratedEntity = Hydration.EntityHydration(
              baseEntity,
              type,
              state
            );
            const hydratedEntityCont = {
              ...entityCont,
              entity: {
                ...hydratedEntity
              }
            };
            hydratedEntities = hydratedEntities.concat(hydratedEntityCont);
          });

          // Create Result
          currentStep = {
            ...currStepObj,
            step: {
              ...currStepObj.step,
              query: {
                ...currStepObj.step.query,
                entityIds: entityIds,
                entities: hydratedEntities,
                moreAvailable: queryCont.query.moreAvailable,
                moreUrl: queryCont.query.moreUrl,
                objectType: type,
                url: queryCont.query.url
              }
            }
          };
          //console.log("Selected Step:");
          //console.log(currentStep);
        }
      }
    }
  }
  return {
    dataEntities: stepList,
    dataEntity: currentStep,
    dataCount: stepCount,
    dataPosition: stepPos,
    dataLoading: currentStep.isFetching ? currentStep.isFetching : false
  };
};

const mapDispatchToProps = dispatch => ({
  refreshStepQuery: (auth, position) =>
    dispatch(StepActions.loadStepQuery(auth, position)),
  loadMoreStepQuery: (auth, position) =>
    dispatch(StepActions.loadMoreStepQuery(auth, position)),
  invalidateStep: position => dispatch(StepActions.invalidateStep(position)),
  clearAllSteps: () => dispatch(PathActions.clearPathfinder()),
  removeCurrentStep: () => dispatch(StepActions.removeCurrentStep()),
  setCurrentStepPosition: position =>
    dispatch(PathActions.setCurrentStepPosition(position)),
  setCurrentStepPositionToLast: () =>
    dispatch(PathActions.setCurrentStepPositionToLast()),
  addInitialStep: () => dispatch(StepActions.addInitialStep()),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
