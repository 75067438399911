import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

import ActivityCard from './card/Activity.Card.Container';
import ActivityFolderCard from './card/ActivityFolder.Card.Container';
import ActivityGroupCard from './card/ActivityGroup.Card.Container';
import AdministrativeUnitCard from './card/AdministrativeUnit.Card.Container';
import AgreementAcceptanceCard from './card/AgreementAcceptance.Card.Container';
import AgreementCard from './card/Agreement.Card.Container';
import ApplicationCard from './card/Application.Card.Container';
import AttachmentCard from './card/Attachment.Card.Container';
import BookingAppointmentCard from './card/BookingAppointment.Card.Container';
import BookingBusinessCard from './card/BookingBusiness.Card.Container';
import BookingCustomerCard from './card/BookingCustomer.Card.Container';
import BookingServiceCard from './card/BookingService.Card.Container';
import BookingStaffMemberCard from './card/BookingStaffMember.Card.Container';
import BucketCard from './card/Bucket.Card.Container';
import CalendarGroupCard from './card/CalendarGroup.Card.Container';
import CalendarCard from './card/Calendar.Card.Container';
import ChannelCard from './card/Channel.Card.Container';
import ChatMessageCard from './card/ChatMessage.Card.Container';
import ChatThreadCard from './card/ChatThread.Card.Container';
import ContactFolderCard from './card/ContactFolder.Card.Container';
import ContactCard from './card/Contact.Card.Container';
import ContractCard from './card/Contract.Card.Container';
import ConversationCard from './card/Conversation.Card.Container';
import DeviceCard from './card/Device.Card.Container';
import DirectoryRoleCard from './card/DirectoryRole.Card.Container';
import DomainCard from './card/Domain.Card.Container';
import DriveItemCard from './card/DriveItem.Card.Container';
import DriveCard from './card/Drive.Card.Container';
import EventCard from './card/Event.Card.Container';
import GroupCard from './card/Group.Card.Container';
import IdentityProviderCard from './card/IdentityProvider.Card.Container';
import InsightCard from './card/Insight.Card.Container';
import LicenseCard from './card/License.Card.Container';
import ListItemCard from './card/ListItem.Card.Container';
import ListCard from './card/List.Card.Container';
import MailFolderCard from './card/MailFolder.Card.Container';
import MessageCard from './card/Message.Card.Container';
import NotebookCard from './card/Notebook.Card.Container';
import OrganizationCard from './card/Organization.Card.Container';
import OrgContactCard from './card/OrgContact.Card.Container';
import PageCard from './card/Page.Card.Container';
import PersonCard from './card/Person.Card.Container';
import PlanCard from './card/Plan.Card.Container';
import PostCard from './card/Post.Card.Container';
import RiskEventCard from './card/RiskEvent.Card.Container';
import SecurityAlertCard from './card/SecurityAlert.Card.Container';
import ServicePlanCard from './card/ServicePlan.Card.Container';
import ServicePrincipalCard from './card/ServicePrincipal.Card.Container';
import ServiceCard from './card/Service.Card.Container';
import SiteCard from './card/Site.Card.Container';
import TaskCard from './card/Task.Card.Container';
import TeamCard from './card/Team.Card.Container';
import TeamsAppInstallationCard from './card/TeamsAppInstallation.Card.Container';
import TeamsTabCard from './card/TeamsTab.Card.Container';
import ThreadCard from './card/Thread.Card.Container';
import UserCard from './card/User.Card.Container';
import VersionCard from './card/Version.Card.Container';

import Placeholder from '../../../common/components/controls/Placeholder';
import * as AppConfig from '../../../app/config/settings/Application';
import * as ObjectConfig from '../../../app/config/entities/Objects';
import * as Validation from '../../../common/utils/Validation';

class NodeContent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { dataNode, smallContainer, showExtended } = this.props;
    const { showObjectLink } = this.props;

    if (!Validation.isEmpty(dataNode)) {
      if (dataNode.objectType === ObjectConfig.ACTIVITY.code) {
        return (
          <ActivityCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.ACTIVITYFOLDER.code) {
        return (
          <ActivityFolderCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.ACTIVITYGROUP.code) {
        return (
          <ActivityGroupCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.ADMINISTRATIVEUNIT.code) {
        return (
          <AdministrativeUnitCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.AGREEMENT.code) {
        return (
          <AgreementCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.AGREEMENTACCEPTANCE.code) {
        return (
          <AgreementAcceptanceCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.APPLICATION.code) {
        return (
          <ApplicationCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.ATTACHMENT.code) {
        return (
          <AttachmentCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.BOOKINGAPPOINTMENT.code) {
        return (
          <BookingAppointmentCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.BOOKINGBUSINESS.code) {
        return (
          <BookingBusinessCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.BOOKINGCUSTOMER.code) {
        return (
          <BookingCustomerCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.BOOKINGSERVICE.code) {
        return (
          <BookingServiceCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.BOOKINGSTAFFMEMBER.code) {
        return (
          <BookingStaffMemberCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.BUCKET.code) {
        return (
          <BucketCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CALENDAR.code) {
        return (
          <CalendarCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CALENDARGROUP.code) {
        return (
          <CalendarGroupCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CHANNEL.code) {
        return (
          <ChannelCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CHATMESSAGE.code) {
        return (
          <ChatMessageCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CHATTHREAD.code) {
        return (
          <ChatThreadCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CONTACT.code) {
        return (
          <ContactCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CONTACTFOLDER.code) {
        return (
          <ContactFolderCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CONTRACT.code) {
        return (
          <ContractCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.CONVERSATION.code) {
        return (
          <ConversationCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.DEVICE.code) {
        return (
          <DeviceCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.DIRECTORYROLE.code) {
        return (
          <DirectoryRoleCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.DOMAIN.code) {
        return (
          <DomainCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.DRIVE.code) {
        return (
          <DriveCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.DRIVEITEM.code) {
        return (
          <DriveItemCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }

      if (dataNode.objectType === ObjectConfig.EVENT.code) {
        return (
          <EventCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.GROUP.code) {
        return (
          <GroupCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.IDENTITYPROVIDER.code) {
        return (
          <IdentityProviderCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.INSIGHT.code) {
        return (
          <InsightCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.LICENSE.code) {
        return (
          <LicenseCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.LIST.code) {
        return (
          <ListCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.LISTITEM.code) {
        return (
          <ListItemCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.MAILFOLDER.code) {
        return (
          <MailFolderCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.MESSAGE.code) {
        return (
          <MessageCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.NOTEBOOK.code) {
        return (
          <NotebookCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.ORGANIZATION.code) {
        return (
          <OrganizationCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.ORGCONTACT.code) {
        return (
          <OrgContactCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.PAGE.code) {
        return (
          <PageCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.PERSON.code) {
        return (
          <PersonCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.PLAN.code) {
        return (
          <PlanCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.POST.code) {
        return (
          <PostCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.RISKEVENT.code) {
        return (
          <RiskEventCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.SECURITYALERT.code) {
        return (
          <SecurityAlertCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.SERVICE.code) {
        return (
          <ServiceCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.SERVICEPLAN.code) {
        return (
          <ServicePlanCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.SERVICEPRINCIPAL.code) {
        return (
          <ServicePrincipalCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.SITE.code) {
        return (
          <SiteCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.TASK.code) {
        return (
          <TaskCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.TEAM.code) {
        return (
          <TeamCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.TEAMSAPPINSTALLATION.code) {
        return (
          <TeamsAppInstallationCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.TEAMSTAB.code) {
        return (
          <TeamsTabCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.THREAD.code) {
        return (
          <ThreadCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.USER.code) {
        return (
          <UserCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      if (dataNode.objectType === ObjectConfig.VERSION.code) {
        return (
          <VersionCard
            auth={auth}
            location={location}
            match={match}
            params={params}
            smallContainer={smallContainer}
            showExtended={showExtended}
            showObjectLink={showObjectLink}
          />
        );
      }
      return (
        <Segment className="app-itemcard">
          <Placeholder
            icon={AppConfig.SETTINGS_APP_ICONS.construction}
            header="Under Construction"
            subHeader={
              'Work in progress. The View for entity ' +
              dataNode.objectType +
              ' is coming soon.'
            }
          />
        </Segment>
      );
    }
    return (
      <Segment className="app-itemcard">
        <Placeholder
          icon={AppConfig.SETTINGS_APP_ICONS.team}
          header="No data"
          subHeader={'No data to display'}
        />
      </Segment>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
NodeContent.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  smallContainer: PropTypes.bool,
  showExtended: PropTypes.bool,
  showObjectLink: PropTypes.bool
};

export default NodeContent;
