export const CONST_COLORS = {
  black: 'rgb(0,0,0)',
  blue: 'rgb(0,85,128)',
  darkGrey: 'rgb(105,105,105)',
  green: 'rgb(151,168,97)',
  lightBlue: 'rgb(174,199,232)',
  lightGrey: 'rgb(220,220,220)',
  orange: 'rgb(255,127,14)',
  red: 'rgb(218,48,64)'
};

export const COLOR_CODES = {
  black: 'black',
  blue: 'blue',
  brown: 'brown',
  green: 'green',
  grey: 'grey',
  olive: 'olive',
  orange: 'orange',
  pink: 'pink',
  purple: 'purple',
  red: 'red',
  teal: 'teal',
  violet: 'violet',
  yellow: 'yellow'
};

export const NAVIGATION_AREA = {
  dashboard: 'Dashboard',
  graph: 'Graph',
  pathfinder: 'Pathfinder'
};

// See definition: https://semantic-ui.com/elements/container.html
export const DEVICE_SIZE = {
  tiny: 449,
  mobile: 767,
  tablet: 991,
  computer: 1200,
  widescreen: 1201
};
