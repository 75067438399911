import React from 'react';
import { Container } from 'semantic-ui-react';

class PrivacyPage extends React.Component {
  render() {
    const emailAddress = 'privacy@appsolut.at';
    const emailLink = <a href={'mailto:' + emailAddress}>{emailAddress}</a>;

    return (
      <Container>
        <h1 id="top" className="app-info-title-h1">
          Privacy Policy
        </h1>
        <p>
          <em>Effective Date: January 1, 2019</em>
        </p>
        <p>
          {[
            `appsolut welcomes your interest in our company, products, and services. This document is our`,
            `"Privacy Statement". It applies to appsolut software gmbh, our subsidiaries and affiliated companies,`,
            `as well as all appsolut-owned and operated websites, domains, services, applications, and products`,
            `(collectively, "Site", "appsolut", "We", "Our", or "Us").`,
            `This Privacy Statement informs you of our privacy practices and the choices you can make about the`,
            `way we collect and use information about you, including information that may be collected from`,
            `your online activity, use of our products and services, and provided to us for purposes of recruitment`,
            `or employment consideration.`
          ].join(' ')}
        </p>
        <h2 className="app-info-title-h2">
          Use and Purpose of Collected Personal Data
        </h2>
        <p>
          {[
            `The term "Personal Data" means information relating to an identified or identifiable natural person.`,
            `That is, someone who can be directly or indirectly identified. This may include your full name,`,
            `physical address, email address, telephone number, IP address, or any other personal information`,
            `that, alone or in combination with other information, enables contact with you physically or online.`
          ].join(' ')}
        </p>
        <h2 className="app-info-title-h2">When We Collect Personal Data</h2>
        <p>
          {[
            `We collect Personal Data that you knowingly and voluntarily provide when you:`
          ].join(' ')}
        </p>
        <ul>
          <li>{[`Interact with our websites`].join(' ')}</li>
          <li>{[`Register for any of our services`].join(' ')}</li>
          <li>
            {[
              `Submit requests, suggestions, or other communications to us`
            ].join(' ')}
          </li>
          <li>
            {[
              `Choose to share usage information regarding appsolut products and services with appsolut`
            ].join(' ')}
          </li>
        </ul>
        <h2 className="app-info-title-h2">What Data We Collect</h2>
        <p>
          {[
            `We collect information that is directly provided by you, collected automatically, and gathered from third parties.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Information Directly Provided by You
        </h3>
        <p>
          {[
            `Contact Data: We may collect contact data including your first name, last name, mailing address,`,
            `telephone number, fax number, email address and other similar contact information.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Payment Data: We collect data necessary for processing payments and fraud prevention, including`,
            `credit/debit card numbers, security code numbers and other related billing information.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Account Data: We collect anything related to the account you create, this may include information`,
            `such as your username, how you purchased or signed up for appsolut products and services, or`,
            `events, your transaction, billing, and support history.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Location Data: We may collect geolocation data when you enable location-based services or when`,
            `you choose to provide location-related information during product, service, or event registration.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Security Credentials Data: We collect user IDs, passwords, password hints, and similar security`,
            `information required for authentication and access to appsolut accounts.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Demographic Data: We may collect certain demographic data including country, gender, age,`,
            `preferred language, general educational and employment background, and general job interest data.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Preferences: We collect information about your preferences and interests as they relate to our`,
            `products, services (both when you tell us what they are or when we deduce them from what we`,
            `know about you) and how you prefer to receive communications from us.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Social Media Data: We may provide social media features that enable you to share information with`,
            `your social networks and to interact with us on various social media sites. Your use of these features`,
            `may result in the collection or sharing of information about you, depending on the feature. We`,
            `encourage you to review the privacy policies and settings on the social media sites you use to make`,
            `sure you understand the information that may be collected, used, and shared by those sites.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Other Unique Identifying Information: Examples of other unique information that we may collect`,
            `from you include information you provide when you interact in-person, online or by phone or postal`,
            `mail with our service centers, help desks or other customer support channels, events, meetups and`,
            `conferences, your responses to customer surveys or contests, or additional information you have`,
            `provided to us to facilitate delivery of website content, services and to respond to your inquiries.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Information Collected Automatically
        </h3>
        <p>
          {[
            `We use technology integrated into our websites, products, and services that you can choose to`,
            `enable to provide us with automated data collection. Such information may include:`
          ].join(' ')}
        </p>
        <p>
          {[
            `Product Usage Data: If you elect to send product usage information, also known as telemetry`,
            `information, we collect details as IP addresses, License information or the geographic region`
          ].join(' ')}
        </p>
        <p>
          {[
            `appsolut Cloud Usage Data: When you use an appsolut Cloud trial or subscription account, we collect`,
            `information about your use of the appsolut Cloud service, as IP addresses, License information or the`,
            `geographic region`
          ].join(' ')}
        </p>
        <p>
          {[
            `Device Data: Data collected from Google Analytics may provide us with information regarding your`,
            `device type (mobile, tablet, desktop/laptop, etc.), operating system, and type of browser. This helps`,
            `us prioritize support for popular configurations.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Application Data: We may collect data regarding key performance metrics of appsolut applications in`,
            `order to understand if there are issues.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Website Browsing Data: We collect information about your visits and activity on our appsolut`,
            `websites, including the content and ads that you view and interact with, the address of the website`,
            `from which you were referred and other clickstream behavior (such as the pages you view, the links`,
            `you click or which items you've added to your shopping cart). Some of this information is collected`,
            `using our Automatic Data Collection Tools which include cookies, web beacons and embedded web`,
            `links. To learn more, please read the Cookies and Website Automatic Data Collection section in this`,
            `Privacy Statement.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Information Collected from Third-Party Sources
        </h3>
        <p>
          {[
            `We may also acquire data from third-party sources that we deem to be credible and that are either`,
            `publicly-available, or available on a commercial basis. Such information may include:`
          ].join(' ')}
        </p>
        <p>
          {[
            `Personal data: We may collect Personal Data such as your name, address, email address,`,
            `preferences, interests, and certain demographic data. For example, Personal Data may be collected`,
            `when you access our applications through social media logins (e.g. logging in to our applications`,
            `using your Facebook or other social media credentials). The details we receive may depend on your`,
            `social media account privacy settings.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Business contact data: In order to provide some of our products and services at an enterprise level,`,
            `your business contact data may be provided to us by a designated entity within your business or`,
            `enterprise, such as a member of your IT department. Where necessary, we may also use information`,
            `provided by you or your employer, together with information from publicly-available and other`,
            `online and offline sources, to conduct due diligence checks on business contacts as part of our anti-corruption`,
            `and export control compliance programs.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Combined data: In order to ensure data accuracy and offer personalized services, content, marketing`,
            `and ads, we may link or combine the information that we collect from the different sources outlined`,
            `above. For example, we may compare the geographic information acquired from commercial sources`,
            `with the IP address collected by our Automatic Data Collection Tools (see Cookies and Website`,
            `Automatic Data Collection) to derive your general geographic area. Information may also be linked`,
            `via a unique identifier such as a cookie or account number.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Other Information We Collect</h3>
        <p>
          {[
            `We may also collect information and data you choose to provide that is not Personal Data, such as`,
            `your occupation, affiliated companies, and general location. Additionally, we may collect technical`,
            `information relating to your mobile phone, mobile device or computer and the way you interact with`,
            `the Site, such as your IP address, browser type, and aggregate user data. This information is used to`,
            `identify your internet browser, store your user preferences, authenticate user sessions, provide`,
            `services, and determine whether you have installed the software necessary to access certain material`,
            `or applications on the Site.`
          ].join(' ')}
        </p>
        <h2 className="app-info-title-h2">How We Use Personal Data</h2>
        <p>
          {[`We use your Personal Data for the following purposes:`].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Transaction Processing and Account Administration
        </h3>
        <p>
          {[
            `To process transactions and orders of our products or services including payment processing, to`,
            `provide troubleshooting or consulting for our products or services, and for account administration.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Managing Your Customer Experience</h3>
        <p>
          {[
            `To maintain accurate contact and registration data, deliver customer support, and to offer products,`,
            `services, and features that may interest you. We also use your data to deliver personalized`,
            `communications and create recommendations based on your use of appsolut products, services,`,
            `features, and your visits on our websites.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Advertising</h3>
        <p>
          {[
            `To provide personalized advertising to you in accordance with your privacy preferences settings. We`,
            `might also share some of your information with marketing service providers and digital marketing`,
            `networks to present advertisements that might interest you. This may involve the use of Automatic`,
            `Data Collection Tools. To learn more about how appsolut uses Automated Data Collection Tools and`,
            `which ones, please see our Cookies and Website Automatic Data Collection section of this document.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Administrative Communications</h3>
        <p>
          {[
            `To answer questions or to provide information, support, or advice about existing and new products`,
            `or services.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Business Operations</h3>
        <p>
          {[
            `To conduct ordinary business operations, e.g. recruiting, conducting business research and analytics,`,
            `corporate reporting and management, staff training and quality assurance purposes which may`,
            `include monitoring or recording calls to our customer support team and outreach.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Research & Innovation</h3>
        <p>
          {[
            `To develop new products, features, and services using research and development tools, and`,
            `incorporating data analysis activities.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Security</h3>
        <p>
          {[
            `To maintain the integrity and security of our websites, products and services and preventing and`,
            `detecting security threats, fraud, or other criminal or malicious activity that might compromise your`,
            `information or the information of other customers and/or website visitors.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Compliance with Law</h3>
        <p>
          {[
            `We collect and store data as required to be compliant with applicable laws, regulations, court orders,`,
            `government, and law enforcement requests.`
          ].join(' ')}
        </p>
        <h2 className="app-info-title-h2">
          Third Parties with whom we share your Data
        </h2>
        <h3 className="app-info-title-h3">
          Sharing with appsolut affiliated Entities
        </h3>
        <p>
          {[
            `We may transfer your Personal Data to other appsolut entities within the European Union or`,
            `worldwide for the purposes outlined in this Privacy Statement. To ensure that your Personal Data is`,
            `private, appsolut entities are contractually bound to comply with our privacy requirements.`
          ].join(' ')}
        </p>
        <p>
          {[
            `By accessing our websites, registering a product or for services, creating an account, or otherwise`,
            `providing us with your Personal Data, you consent to the transfer of your Personal Data throughout`,
            `the global appsolut network of entities based on the international restrictions stated above.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Sharing Data with Third Party Service Providers
        </h3>
        <p>
          {[
            `We will not provide your Personal Data to third party advertisers without your permission, except as`,
            `provided in this privacy statement. We will not share Personal Data with third parties that would`,
            `directly contact you without your explicit consent to be contacted by a partner. However, we may`,
            `provide information and data that is not Personal Data, to our third-party providers for any purpose`,
            `including primarily customizing and targeting advertising messages.`
          ].join(' ')}
        </p>
        <p>
          {[
            `We work with external service providers to plan, manage and/or support certain aspects of our`,
            `business operations on our behalf.`
          ].join(' ')}
        </p>
        <p>
          {[
            `These service providers may be located in the EU or in other global locations and may provide`,
            `services such as credit card processing, customer support, advertising and marketing activities`,
            `(including digital and personalized advertising), email service, or data hosting. Our service providers`,
            `are required by contract to safeguard any Personal Data they receive from us and are prohibited`,
            `from using the Personal Data for any purpose other than to perform the services as instructed by appsolut.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Examples of our third-party service providers and the use of your data:`
          ].join(' ')}
        </p>
        <p>
          {[
            `Google Analytics: We use Google Analytics to understand visitor trends, page popularity, geographic`,
            `distribution of interest, time on pages, or issues with our site. We use this data to understand how to`,
            `invest in regions based on demand. For more information about Google Analytics, see `
          ].join(' ')}
          <a
            href="https://policies.google.com/privacy?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>
          {[
            ` and for more information about how Google uses data when you use a Google partner's sites or apps, see `
          ].join(' ')}
          <a
            href="https://www.google.com/policies/privacy/partners"
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>
        </p>
        <p>
          {[
            `Facebook: We service a growing community of followers on Facebook and use the platform to inform`,
            `this community of new content available. We use a Facebook cookie to match audiences and enable`,
            `us to ensure we can deliver paid (and unpaid) advertising to our Facebook appsolut followers and`,
            `additional audiences that we think would be interested in the content.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Google AdWords: We use AdWords to deliver advertising through Google's network of advertising sites.`
          ].join(' ')}
        </p>
        <p>
          {[
            `LinkedIn: We serve a community of followers on LinkedIn. We share content with this community`,
            `and use matching techniques to provide advertising on LinkedIn. The matching techniques include`,
            `the use of a cookie we execute on our websites and may include email matching (which is only used`,
            `for appsolut advertising).`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Sharing Data with Third Parties Who May Collect Information
        </h3>
        <p>
          {[
            `The following third parties may collect information through the Site, but except as set forth above,`,
            `such information shall not include Personal Data unless you post or publish such Personal Data in`,
            `areas of the Site accessible to other users or the public:`
          ].join(' ')}
        </p>
        <ul>
          <li>
            {[
              `third parties that help facilitate the Site may, from time to time, collect information through`,
              `the Site in the course of providing support`
            ].join(' ')}
          </li>
          <li>
            {[
              `we may share Site information with partners for marketing purposes`
            ].join(' ')}
          </li>
          <li>
            {[
              `third parties may collect other information that you voluntarily post or publish through the`,
              `Site in any way`
            ].join(' ')}
          </li>
          <li>
            {[
              `web-crawlers such as Google or Yahoo may collect information through the Site`
            ].join(' ')}
          </li>
        </ul>
        <p>
          {[
            `The Site may contain links or references to other websites, including third party advertisers and other`,
            `unaffiliated websites. We are not responsible for the privacy policies of those websites or of`,
            `advertisers on the Site, and you should read the privacy policies of each website you visit.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Sharing Financial Information</h3>
        <p>
          {[
            `appsolut uses third party payment processors to process payments made to us. In connection with`,
            `the processing of such payments, we do not retain any personally identifiable information or any`,
            `financial information such as credit card numbers. Rather, all such information is provided directly to`,
            `our third-party processors.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Sharing Data for Other Reasons</h3>
        <h4 className="app-info-title-h4">Corporate Transactions</h4>
        <p>
          {[
            `Circumstances may arise where, whether for strategic or other business reasons, appsolut decides to`,
            `sell, buy, merge or otherwise reorganize its businesses. In such transactions, we may disclose or`,
            `transfer your Personal Data to prospective or actual purchasers or receive your Personal Data from`,
            `sellers. Our practice is to seek appropriate protection for your Personal Data in these types of`,
            `transactions`
          ].join(' ')}
        </p>
        <h4 className="app-info-title-h4">Compliance with Law</h4>
        <p>
          {[
            `We may also share your Personal Data when we believe, in good faith, that we have an obligation to:`,
            `(i) respond to duly authorized information requests of law enforcement agencies, regulators, courts,`,
            `and other public authorities, including to meet national security or other law enforcement`,
            `requirements; (ii) comply with any law, regulation, subpoena, or court order; (iii) investigate and help`,
            `prevent security threats, fraud or other criminal or malicious activity; (iv) enforce/protect the rights`,
            `and properties of appsolut and/or our affiliates; or (v) protect the rights or personal safety of`,
            `appsolut's and our affiliates' employees, and third parties on or using appsolut property when`,
            `allowed and in line with the requirements of applicable law.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Online Advertising and Tracking</h3>
        <p>
          {[
            `appsolut engages with third-party advertising networks on the Site. Ad networks may include third-party`,
            `ad servers, ad agencies, ad technology vendors and research firms. We are not responsible for`,
            `the privacy practices of these third parties, and we encourage you to read their privacy policies,`,
            `which may apply to these third-party programs. Ad networks allow us to target our advertising to`,
            `users through demographic, behavioral and contextual means. After you visit our websites, these ad`,
            `networks may continue to track your online activities over time across third-party websites or online`,
            `services by collecting information through automated means, including through the use of cookies,`,
            `identifiers, web server logs, web beacons and other methods.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">How We Keep Your Data Secure</h3>
        <p>
          {[
            `To prevent unauthorized access or disclosure, and to ensure the appropriate use of your information,`,
            `we utilize reasonable and appropriate physical, technical, and administrative procedures to`,
            `safeguard the information we collect and process. We retain the data only as required or permitted`,
            `by law, and while the data continues to have a legitimate business purpose.`
          ].join(' ')}
        </p>
        <h2 className="app-info-title-h2">
          Cookies and Website Automatic Data Collection
        </h2>
        <p>
          {[
            `We use and allow certain other companies to use cookies, web beacons and other similar`,
            `technologies (collectively "Automatic Data Collection Tools") on our website and in the marketing,`,
            `sale and delivery of our products and services. We do this to understand your use of our website and`,
            `our products and services and to improve your user experience and to enable personalized features`,
            `and content, optimize our advertisements and marketing, and to enable third-party advertising`,
            `companies to assist us in serving ads specific to your interests across the internet.`
          ].join(' ')}
        </p>
        <p>
          {[
            `For information regarding advertising opt-out and cookie choices, please see Third Party Advertising`,
            `Cookies and Social Media Cookies below.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Our Use of Cookies</h3>
        <p>
          {[
            `Cookies are text files containing small amounts of information which are stored on your device when`,
            `you visit a site. The entity that places cookies on your browser can then read the information on that`,
            `cookie that it set. We categorize cookies as follows:`
          ].join(' ')}
        </p>
        <p>
          {[
            `Necessary cookies: These cookies are essential for our websites and their features to work properly,`,
            `and for us to properly provide services that depend on our websites and these features. (e.g. to`,
            `remember cookie permission settings, session logins, etc.)`
          ].join(' ')}
        </p>
        <p>
          {[
            `Convenience cookies: These enable us to improve the usability and performance of our websites and`,
            `services that depend on our websites (e.g. to remember selection of region and country to redirect`,
            `visitors to localized websites, or for altering or suggesting content that matches navigation patterns)`
          ].join(' ')}
        </p>
        <p>
          {[
            `Statistics cookies: Used to collect information about how you navigate to and from our websites and`,
            `how you use our website. The collection of this information is carried out as anonymous access`,
            `measurements. Statistics cookies may be classified as either session cookies or persistent cookies.`,
            `Session cookies expire when you close your browser session. Persistent cookies may remain on your`,
            `system for an extended period of time`
          ].join(' ')}
        </p>
        <p>
          {[
            `Targeting/Retargeting cookies: Used by our advertising services to provide you with appsolut-relevant`,
            `advertising content`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Third-Party Advertising Cookies and Social Media Cookies
        </h3>
        <p>
          {[
            `We use third party cookies on our websites, which will then be stored on your device (unless you`,
            `opt-out) to allow them to show you advertisements that may be relevant and useful to you. We may`,
            `use remarketing tags (e.g. Facebook, Outbrain, Google, LinkedIn) that align with these third-party`,
            `cookies to deliver advertisements about products that you have browsed on our site when you are`,
            `on third party websites. However, the remarketing tags that we use do not include any information`,
            `that directly identifies you. We would note, however, that these advertisers and other third parties`,
            `(including ad networks, ad-serving companies, and other service providers they may use) may`,
            `correlate the remarketing tag to their own third-party cookies and infer the user ID of users who`,
            `interact with or click on a personalized ad or content.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Cookies may also be used when you share information using a social media feature on our website or`,
            `with services made available via our website. The social media network may record your activities`,
            `which may be linked to targeted advertising. The types of cookies used by these third parties and`,
            `how they use the information generated by them will be governed by those companies' privacy policies.`
          ].join(' ')}
        </p>
        <p>
          {[
            `You can find out more about some of our social media, interest-based advertising partners and opt-out`,
            `mechanism by visiting these websites:`
          ].join(' ')}
        </p>
        <p>
          {[`Google Opt-out page: `].join(' ')}
          <a
            href="https://adssettings.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://adssettings.google.com
          </a>
        </p>
        <p>
          {[`Facebook Opt-out page: `].join(' ')}
          <a
            href="https://www.facebook.com/help/568137493302217"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/help/568137493302217
          </a>
        </p>
        <p>
          {[`LinkedIn Opt-out page: `].join(' ')}
          <a
            href="https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.linkedin.com/help/linkedin/answer/62931/manage-advertising-preferences
          </a>
        </p>
        <p>
          {[
            `If you do not want to allow cookies at all, or only want to allow use of certain cookies, please refer to`,
            `your browser settings. You can also use your browser settings to withdraw your consent to our use of`,
            `cookies at any time and delete cookies that have already been set. Your browser help menu contains`,
            `information about the process of opting out on different browsers.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Note that by disabling certain categories of cookies, you may be prevented from accessing some`,
            `features of our sites, and certain content or functionality may not be available.`
          ].join(' ')}
        </p>
        <p>
          {[
            `If you are in the EU and would like to opt out of third-party cookies relating to interest-based`,
            `advertising, please go to `
          ].join(' ')}
          <a
            href="https://www.youronlinechoices.eu"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.youronlinechoices.eu
          </a>
        </p>
        <h2 className="app-info-title-h2">
          Other Automatic Data Collection Tools
        </h2>
        <h3 className="app-info-title-h3">Web Beacons</h3>
        <p>
          {[
            `We may use and permit select third parties to use web beacons (usually in combination with cookies)`,
            `to compile information about your website usage and your interaction with email or other`,
            `communications, to measure performance and to provide content and ads that are more relevant to you.`
          ].join(' ')}
        </p>
        <p>
          {[
            `A web beacon (also known as a web bug or clear GIFs) is typically a transparent graphic image`,
            `(usually 1 pixel x 1 pixel) that can be embedded in online content, pictures, videos, and emails, and`,
            `can allow a server to read certain types of information from your device, know when you have`,
            `viewed particular content or a particular email message, determine the date and time when you`,
            `viewed the beacon, and the IP address of your device. For instance, we may include web beacons on`,
            `our websites, and in our promotional email messages or newsletters to determine whether our`,
            `messages have been opened or acted upon and whether our mailing tools are working correctly.`
          ].join(' ')}
        </p>
        <p>
          {[
            `Because web beacons are the same as any other content request initiated in a web page, you cannot`,
            `opt out or refuse them. However, you may be able to disable web beacons in email messages by not`,
            `downloading images contained in messages you receive (this feature varies depending on the email`,
            `software used on your personal computer). However, doing this may not always disable a web`,
            `beacon or other Automatic Data Collection Tools in the email message due to specific email software`,
            `capabilities. For more information, refer to the details provided by your email software or service`,
            `provider. Web beacons may also be rendered ineffective in some circumstances by opting out of`,
            `cookies or amending your cookie settings in your browser.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">Embedded Web Links</h3>
        <p>
          {[
            `Emails from us often use links designed to lead you to a relevant area on the web, after redirection`,
            `through our servers. The redirection system allows us to change the destination URL of these links, if`,
            `necessary, and to determine the effectiveness of our communications and marketing initiatives. In`,
            `emails, such web links may also allow us to determine whether you have clicked a link in an email,`,
            `and information about this interaction may be connected to your personal identity.`
          ].join(' ')}
        </p>
        <p>
          {[
            `If you do not want us to collect information about the links that you click, you can:`
          ].join(' ')}
        </p>
        <ul>
          <li>
            {[
              `Change your choice about how you receive communications from us (i.e. choose a text-based`,
              `version of the message where available) or choose not to click links in emails that we send`
            ].join(' ')}
          </li>
          <li>
            {[
              `Choose privacy settings in your browser and email client that will restrict this information`
            ].join(' ')}
          </li>
        </ul>
        <h2 className="app-info-title-h2">Your Rights</h2>
        <p>
          {[
            `At your request, we will: (i) inform you of what personal information we have on file for you; (ii)`,
            `amend or correct the personal information that we have on file for you or any previous privacy`,
            `preferences that you have selected; and/or (iii) erase personal information that you have provided to`,
            `us, or that we have collected. You may do so by contacting us via email at `
          ].join(' ')}
          {emailLink}
        </p>
        <p>
          {[
            `Provided, however, that we ask individuals to identify themselves and the information requested to`,
            `be accessed, corrected or erased before processing such requests, and, to the extent permitted by`,
            `applicable law, we may decline to process requests that are unreasonably repetitive or systematic,`,
            `require disproportionate technical effort, jeopardize the privacy of others, or would be extremely`,
            `impractical (for instance, requests concerning information residing on backup tapes).`
          ].join(' ')}
        </p>
        <p>
          {[
            `For information regarding advertising opt-out and cookie choices, please see Third Party Advertising`,
            `Cookies and Social Media Cookies above.`
          ].join(' ')}
        </p>
        <p>
          {[
            `If you ask us to erase your Personal Data from our records, we will use commercially reasonable`,
            `efforts to do so while retaining any record necessary to comply with a governmental authority or`,
            `applicable federal, state, or local law.`
          ].join(' ')}
        </p>
        <h2 className="app-info-title-h2">
          Your European Union Privacy Rights under GDPR
        </h2>
        <p>
          {[
            `If you are an EU person and would like to exercise your GDPR privacy rights, such as your right to`,
            `access, amendment, correction, or erasure of Personal Data, please email us at: `
          ].join(' ')}
          {emailLink}
          {[
            `, call us at +43.2173.20590 or send your request via postal mail to:`
          ].join(' ')}
        </p>
        <p>
          appsolut software gmbh
          <br />
          Attn: Privacy Team
          <br />
          Mühlgasse 4
          <br />
          7123 Mönchhof
          <br />
          Austria
        </p>
        <h2 className="app-info-title-h2">Your Privacy Preferences</h2>
        <p>
          {[
            `You have the option of subscribing to communications from us. You can change your choices at the`,
            `data collection point, or by using other methods, described below. Please note that these options do`,
            `not apply to communications primarily for the purpose of administering order completion, contracts,`,
            `support, updates, or other administrative and transactional notices, where the primary purpose of`,
            `these communications is not promotional in nature.`
          ].join(' ')}
        </p>
        <h3 className="app-info-title-h3">
          Subscription Communications and General Communications
        </h3>
        <p>
          {[
            `appsolut subscription communications include email newsletters, software updates, maintenance`,
            `notifications, etc. that may be expressly requested by you or which you consented to receive.`
          ].join(' ')}
        </p>
        <p>
          {[
            `After you consent to such communications, you may opt out of receiving them by using one of the`,
            `following methods:`
          ].join(' ')}
        </p>
        <ul>
          <li>
            {[
              `Select the email's "Opt out" or "Unsubscribe" link and follow the instructions included in`,
              `each email subscription communication`
            ].join(' ')}
          </li>
          <li>
            {[
              `To unsubscribe from messages delivered to mobile devices, reply to the message with the`,
              `words "STOP" or "END"`
            ].join(' ')}
          </li>
          <li>
            {[`Contact our Data Protection Officer at `].join(' ')}
            {emailLink}
            {[
              `. Please be sure to provide your`,
              `name, contact information, and specific relevant information about the appsolut`,
              `subscriptions or marketing that you no longer wish to receive`
            ].join(' ')}
          </li>
        </ul>
        <p>
          {[
            `Please note that unsubscribing from one email list does not automatically unsubscribe you from any`,
            `other email list that you may be on. Please read the email carefully to find out which list you are`,
            `unsubscribing from. To unsubscribe from all appsolut email lists, please contact us at `
          ].join(' ')}
          {emailLink}
        </p>
        <h2 className="app-info-title-h2">Contact Us</h2>
        <p>
          {[
            `If you have any questions or concerns about our Privacy Statement, our collection and use of your`,
            `data or a possible breach of local privacy laws, you can contact us via email to `
          ].join(' ')}
          {emailLink}
          {[` or write to us at: `].join(' ')}
        </p>
        <p>
          appsolut software gmbh
          <br />
          Attn: Privacy Team
          <br />
          Mühlgasse 4
          <br />
          7123 Mönchhof
          <br />
          Austria
        </p>
        <p>
          {[
            `All communications will be treated confidentially, and we will also take reasonable steps to verify`,
            `your identity, such as requiring a password and user ID or confirming personal information, in order`,
            `to validate any requests, questions or concerns. Upon receipt of your communication, our`,
            `representative will respond to you within a reasonable time to address your requests, questions or`,
            `concerns and ensure that they are resolved in a timely and appropriate manner.`
          ].join(' ')}
        </p>
      </Container>
    );
  }
}

export default PrivacyPage;
