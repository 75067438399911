import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image, Label } from 'semantic-ui-react';
import DefaultImage from '../../../app/assets/images/defaults/img-default-task.png';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as ObjectConfig from '../../../app/config/entities/Objects';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as Colors from '../../../app/config/settings/ColorOfState';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as Validation from '../../../common/utils/Validation';

class TaskCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, showObjectLink, labelContext } = this.props;
    const { smallContainer } = this.props;

    // Device size
    let gridColumnLeft = 4;
    let gridColumnRight = 12;
    let lengthMeta = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      lengthMeta = 25;
    } else if (smallContainer) {
      lengthMeta = 40;
    }

    // Image
    const entityImage =
      dataEntity && dataEntity.image ? dataEntity.image.url : DefaultImage;

    // Context
    const contextDefault = ObjectConfig.TASK.name;
    const contextValue = !Validation.isEmpty(labelContext)
      ? labelContext
      : contextDefault;

    // Title
    const titleString = dataEntity ? dataEntity.title : '--';

    // Label
    const percentComplete = !Validation.isEmpty(dataEntity)
      ? dataEntity.percentComplete
      : 0;
    let labelValue = '';
    if (!isNaN(percentComplete) && percentComplete >= 100) {
      labelValue = Enums.TASK_STATUS.completed;
    } else if (!isNaN(percentComplete) && percentComplete > 0) {
      labelValue = Enums.TASK_STATUS.inProgress;
    } else {
      labelValue = Enums.TASK_STATUS.notStarted;
    }
    let labelText = percentComplete + '%';
    const labelColor = Colors.GetColorOfTaskState(labelValue);

    // Meta
    const metaProject = StringUtil.MaxLength(
      FieldUtil.GetTitleFromPlannerPlan(dataEntity.plan),
      lengthMeta,
      true
    );
    const checklistItems = !Validation.isEmpty(dataEntity)
      ? dataEntity.checklistItemCount
      : 0;
    const checklistItemsOpen = !Validation.isEmpty(dataEntity)
      ? dataEntity.activeChecklistItemCount
      : 0;
    const checklistItemsClosed = checklistItems - checklistItemsOpen;

    const metaChecklist =
      checklistItems > 0
        ? checklistItemsClosed + '/' + checklistItems + ' Subtasks completed'
        : 'No Subtasks defined';

    const categoriesObject = dataEntity ? dataEntity.appliedCategories : {};
    let metaCategories = {};
    for (const key in categoriesObject) {
      let value = categoriesObject[key];
      if (key === 'category1' && value) {
        metaCategories.cat1 = true;
      }
      if (key === 'category2' && value) {
        metaCategories.cat2 = true;
      }
      if (key === 'category3' && value) {
        metaCategories.cat3 = true;
      }
      if (key === 'category4' && value) {
        metaCategories.cat4 = true;
      }
      if (key === 'category5' && value) {
        metaCategories.cat5 = true;
      }
      if (key === 'category6' && value) {
        metaCategories.cat6 = true;
      }
    }

    // WebLink
    const webLink = '';

    return (
      <Grid divided>
        <Grid.Column width={gridColumnLeft}>
          {showObjectLink ? (
            <Image
              src={entityImage}
              alt={titleString}
              as="a"
              onClick={e => this.handleTaskClick(e, dataEntity.id, webLink)}
              className="app-itemcard-image"
            />
          ) : (
            <Image src={entityImage} />
          )}
        </Grid.Column>
        <Grid.Column width={gridColumnRight}>
          <div className="app-itemcard-context">
            {contextValue}
            {showObjectLink ? (
              <Label
                as="a"
                className="app-itemcard-label"
                color={labelColor}
                horizontal
                onClick={e =>
                  this.props.onEntityClick(e, dataEntity.id, webLink)
                }
              >
                {labelText}
              </Label>
            ) : (
              <Label
                className="app-itemcard-label"
                color={labelColor}
                horizontal
              >
                {labelText}
              </Label>
            )}
          </div>
          {showObjectLink ? (
            <div
              onClick={e => this.props.onEntityClick(e, dataEntity.id, webLink)}
              className="app-itemcard-titlelink"
            >
              {titleString}
            </div>
          ) : (
            <div className="app-itemcard-title">{titleString}</div>
          )}
          <div className="app-itemcard-meta">
            <div>{metaProject}</div>
            <div>
              <div className="app-meta-item-description-categories">
                {metaCategories && metaCategories.cat1 && (
                  <span className="app-label-item">
                    <Label color="purple" />
                  </span>
                )}
                {metaCategories && metaCategories.cat2 && (
                  <span className="app-label-item">
                    <Label color="red" />
                  </span>
                )}
                {metaCategories && metaCategories.cat3 && (
                  <span className="app-label-item">
                    <Label color="yellow" />
                  </span>
                )}
                {metaCategories && metaCategories.cat4 && (
                  <span className="app-label-item">
                    <Label color="olive" />
                  </span>
                )}
                {metaCategories && metaCategories.cat5 && (
                  <span className="app-label-item">
                    <Label color="green" />
                  </span>
                )}
                {metaCategories && metaCategories.cat6 && (
                  <span className="app-label-item">
                    <Label color="blue" />
                  </span>
                )}
              </div>
              <div>{metaChecklist}</div>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
TaskCard.defaultProps = {
  smallContainer: true,
  showObjectLink: false
};

TaskCard.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  smallContainer: PropTypes.bool,
  labelContext: PropTypes.string,
  showObjectLink: PropTypes.bool,
  onEntityClick: PropTypes.func.isRequired
};

export default TaskCard;
