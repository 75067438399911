import React from 'react';
import PropTypes from 'prop-types';
import { List, Image, Label, Icon } from 'semantic-ui-react';
import DefaultImage from '../../../app/assets/images/defaults/img-default-message.png';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as Colors from '../../../app/config/settings/ColorOfState';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class MessageItemlistItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataKey } = this.props;

    // Device size
    let lengthMeta = 52;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      lengthMeta = 30;
    }

    // Image
    const entityImage =
      dataEntity && dataEntity.image ? dataEntity.image.url : DefaultImage;

    // Title
    const senderObject = dataEntity.sender
      ? dataEntity.sender.emailAddress
      : {};
    const titleString = StringUtil.MaxLength(
      FieldUtil.GetNameFromEmailAddress(senderObject),
      lengthMeta,
      true
    );

    // Label
    const isDraft = !Validation.isEmpty(dataEntity)
      ? dataEntity.isDraft
      : false;
    const isRead = !Validation.isEmpty(dataEntity) ? dataEntity.isRead : false;
    let labelText = '';
    if (isDraft) {
      labelText = Enums.MESSAGE_STATUS.draft;
    } else if (isRead) {
      labelText = Enums.MESSAGE_STATUS.read;
    } else {
      labelText = Enums.MESSAGE_STATUS.unread;
    }
    const labelColor = Colors.GetColorOfMessageState(labelText);

    // Meta
    const metaSender = StringUtil.MaxLength(
      FieldUtil.GetEmailFromEmailAddress(senderObject),
      lengthMeta,
      true
    );
    const metaSubject = StringUtil.MaxLength(
      StringUtil.NoEmptyString(dataEntity.subject),
      lengthMeta,
      true
    );
    const metaDate = Validation.isValidDate(dataEntity.receivedDateTime)
      ? DateUtil.formatMsGraphDateTime(dataEntity.receivedDateTime)
      : '';

    // WebLink
    let webLink = dataEntity.webLink;

    return (
      <List.Item
        key={dataKey}
        onClick={e => this.props.onEntityClick(e, dataEntity.id, webLink)}
      >
        <List.Content floated="right">
          <Label
            color={labelColor}
            circular
            empty
            className="app-itemlist-label"
          />
        </List.Content>
        <Image size="mini" src={entityImage} alt={titleString} />
        <List.Content className="app-meta-item-content">
          <List.Header>{titleString}</List.Header>
          <List.Description>{metaSender}</List.Description>
          <List.Description>
            {metaDate}
            <span className="app-meta-item-after">
              {dataEntity.importance === 'high' && (
                <span className="app-meta-item">
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.priorityhigh}
                    color="red"
                    className="app-listitem-meta-icon"
                  />
                </span>
              )}
              {dataEntity.importance === 'low' && (
                <span className="app-meta-item">
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.prioritylow}
                    color="blue"
                    className="app-listitem-meta-icon"
                  />
                </span>
              )}
              {FieldUtil.GetStringFromFollowupFlag(dataEntity.flag) ===
                'flagged' && (
                <span className="app-meta-item">
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.flag}
                    color="red"
                    className="app-listitem-meta-icon"
                  />
                </span>
              )}
              {FieldUtil.GetStringFromFollowupFlag(dataEntity.flag) ===
                'complete' && (
                <span className="app-meta-item">
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.flag}
                    color="blue"
                    className="app-listitem-meta-icon"
                  />
                </span>
              )}
              {dataEntity.hasAttachments && (
                <span className="app-meta-item">
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.attachment}
                    className="app-listitem-meta-icon"
                  />
                </span>
              )}
              {dataEntity.isDraft && (
                <span className="app-meta-item">
                  <Icon
                    name={MenuConfig.ICONS_MENU_DEFAULTS.edit}
                    className="app-listitem-meta-icon"
                  />
                </span>
              )}
            </span>
          </List.Description>
          <List.Description>{metaSubject}</List.Description>
        </List.Content>
      </List.Item>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
MessageItemlistItem.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  onEntityClick: PropTypes.func.isRequired
};

export default MessageItemlistItem;
