import moment from 'moment';
import * as Actions from '../types';
import * as Selectors from '../../reducers/rootReducer';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// INITIALPLAN
// - invalidateInitialPlans
// - loadInitialPlans
// - loadMoreInitialPlans
//-------------------------

export const invalidateInitialPlans = () => {
  return {
    type: Actions.INITIALPLANS_INVALIDATE
  };
};

export const loadInitialPlans = auth => (dispatch, getState) => {
  if (shouldFetchEntities(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchInitialPlans(auth));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const loadMoreInitialPlans = auth => (dispatch, getState) => {
  const currentState = getState();
  const entityContainer = Selectors.getMyInitialPlans(currentState);
  if (entityContainer && entityContainer.moreAvailable) {
    // Fetch the next entities from data api and append to current list
    return dispatch(fetchInitialPlansToAttach(auth, entityContainer.moreUrl));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

//-------------------------
// Helpers
//-------------------------

const requestEntities = () => {
  return {
    type: Actions.INITIALPLANS_REQUEST
  };
};

const receiveEntities = (data, attach = false) => {
  const entityList = !Validation.isEmpty(data) ? data.value : [];
  const moreLink = !Validation.isEmpty(data) ? data['@odata.nextLink'] : '';
  const actionType = attach
    ? Actions.INITIALPLANS_APPEND
    : Actions.INITIALPLANS_RECEIVE;

  let entityIds = [];
  let entities = [];
  entityList.forEach(entity => {
    if (entityIds.indexOf(entity.id) === -1) {
      entityIds = entityIds.concat(entity.id);
      entities = entities.concat(entity);
    }
  });
  return {
    type: actionType,
    payload: {
      entityIds: entityIds,
      entities: entities,
      moreAvailable: !Validation.isEmpty(moreLink),
      moreUrl: moreLink,
      receivedAt: Date.now()
    }
  };
};

const receiveError = err => {
  if (err.statusCode === 404) {
    return {
      type: Actions.INITIALPLANS_RECEIVE,
      payload: {
        entities: [],
        moreAvailable: false,
        moreUrl: '',
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const receiveErrorFromAttach = err => {
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const shouldFetchEntities = state => {
  const entityContainer = Selectors.getMyInitialPlans(state);
  const staleDate = moment().subtract(25, 'minutes');
  if (entityContainer) {
    if (!entityContainer.lastUpdated) {
      return true;
    } else if (
      entityContainer.lastUpdated &&
      entityContainer.lastUpdated < staleDate
    ) {
      return true;
    } else if (entityContainer.didInvalidate) {
      return true;
    } else if (entityContainer.isFetching) {
      return false;
    } else {
      return false;
    }
  } else return true;
};

const fetchInitialPlans = auth => dispatch => {
  dispatch(requestEntities());
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api('/me/planner/plans')
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => {
          dispatch(receiveEntities(res, false));
          const moreLink = !Validation.isEmpty(res)
            ? res['@odata.nextLink']
            : '';
          const moreAvailable = !Validation.isEmpty(moreLink);
          if (moreAvailable) {
            console.log('More Plans available!');
            dispatch(fetchInitialPlansToAttach(auth, moreLink));
          }
        })
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};

const fetchInitialPlansToAttach = (auth, url) => dispatch => {
  dispatch(requestEntities());
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(url)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => {
          dispatch(receiveEntities(res, true));
          const moreLink = !Validation.isEmpty(res)
            ? res['@odata.nextLink']
            : '';
          const moreAvailable = !Validation.isEmpty(moreLink);
          if (moreAvailable) {
            console.log('More Plans available!');
            dispatch(fetchInitialPlansToAttach(auth, moreLink));
          }
        })
        .catch(err => dispatch(receiveErrorFromAttach(err)));
    })
    .catch(err => console.log(err));
};
