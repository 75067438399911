import * as Actions from '../types';
import * as Selectors from '../../reducers/rootReducer';

//-------------------------
// PATHFINDER PATH
// - clearPathfinder
// - setCurrentStepPosition
// - receiveEntity
//-------------------------

export const clearPathfinder = () => {
  return {
    type: Actions.PATHFINDER_CLEAR
  };
};

export const setCurrentStepPosition = currentStepPosition => {
  return {
    type: Actions.PATHFINDER_PATH_STEPCURRENT,
    payload: {
      currentStepPosition: currentStepPosition
    }
  };
};

export const setCurrentStepPositionToLast = () => (dispatch, getState) => {
  const state = getState();
  const stepIndices = Selectors.getAllPathfinderSteps(state);
  const maxPosition = stepIndices ? stepIndices.length : 0;
  dispatch(setCurrentStepPosition(maxPosition));
};

export const receiveEntity = currentStepPosition => {
  return {
    type: Actions.PATHFINDER_PATH_RECEIVE,
    payload: {
      currentStepPosition: currentStepPosition,
      receivedAt: Date.now()
    }
  };
};

//-------------------------
// Helpers
//-------------------------
