import * as Actions from '../../../actions/types';
import * as ObjectConfig from '../../../../config/entities/Objects';

const initialState = [];

//-------------------------
// Helpers
//-------------------------

function addEntityId(state, action) {
  const { payload } = action;
  if (payload && payload.entityId) {
    const { entityId } = payload;
    if (state.indexOf(entityId) === -1) {
      return state.concat(entityId);
    }
    return state;
  }
  return state;
}

function addEntityIds(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds) {
    const { entityIds } = payload;
    let newState = [...state];
    entityIds.forEach(entityId => {
      if (newState.indexOf(entityId) === -1) {
        newState = newState.concat(entityId);
      }
    });
    return newState;
  }
  return state;
}

function addEntitiesFromGraph(state, action) {
  const { payload } = action;
  if (payload && payload.queryParams) {
    const objectType = payload.queryParams.objectType;
    if (objectType === ObjectConfig.BUCKET.code) {
      const newAction = {
        payload: {
          entityIds: payload.entityIds,
          receivedAt: payload.receivedAt
        }
      };
      const newState = addEntityIds(state, newAction);
      return newState;
    }
  }
  return state;
}

function addEntitiesFromPathfinder(state, action) {
  const { payload } = action;
  if (payload && payload.queryParams) {
    const objectType = payload.queryParams.objectType;
    if (objectType === ObjectConfig.BUCKET.code) {
      const newAction = {
        payload: {
          entityIds: payload.entityIds,
          receivedAt: payload.receivedAt
        }
      };
      const newState = addEntityIds(state, newAction);
      return newState;
    }
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.BUCKET_RECEIVE:
      return addEntityId(state, action);
    case Actions.BUCKETS_RECEIVE:
    case Actions.BUCKETS_APPEND:
      return addEntityIds(state, action);
    case Actions.GRAPH_QUERY_RECEIVE:
    case Actions.GRAPH_QUERY_APPEND:
      return addEntitiesFromGraph(state, action);
    case Actions.PATHFINDER_QUERY_RECEIVE:
    case Actions.PATHFINDER_QUERY_APPEND:
      return addEntitiesFromPathfinder(state, action);
    default:
      return state;
  }
}
