import * as Enums from '../constants/Enumerations';

export const GetColorOfActivityState = Status => {
  let color = 'grey';
  if (Status === Enums.ACTIVITY_STATUS.inProgress) color = 'blue';
  if (Status === Enums.ACTIVITY_STATUS.deferred) color = 'red';
  if (Status === Enums.ACTIVITY_STATUS.waitingOnOthers) color = 'yellow';
  if (Status === Enums.ACTIVITY_STATUS.completed) color = 'green';
  return color;
};

export const GetColorOfApplicationState = Status => {
  let color = 'grey';
  if (Status === Enums.APPLICATION_STATUS.active) color = 'blue';
  return color;
};

export const GetColorOfBookingState = Status => {
  let color = 'grey';
  if (Status === Enums.BOOKING_STATUS.published) color = 'blue';
  return color;
};

export const GetColorOfContractState = Status => {
  let color = 'grey';
  if (Status === Enums.CONTRACT_STATUS.active) color = 'blue';
  return color;
};

export const GetColorOfEventState = Status => {
  let color = 'grey';
  if (Status === Enums.EVENT_STATUS.active) color = 'blue';
  return color;
};

export const GetColorOfGroupState = Status => {
  let color = 'grey';
  if (Status === Enums.GROUP_STATUS.active) color = 'blue';
  return color;
};

export const GetColorOfProjectState = Status => {
  let color = 'grey';
  if (Status === Enums.PROJECT_STATUS.active) color = 'blue';
  if (Status === Enums.PROJECT_STATUS.pending) color = 'yellow';
  if (Status === Enums.PROJECT_STATUS.closed) color = 'green';
  if (Status === Enums.PROJECT_STATUS.removed) color = 'grey';
  return color;
};

export const GetColorOfProjectCategory = CategoryNumber => {
  let color = 'grey';
  if (CategoryNumber === 1) color = 'purple';
  if (CategoryNumber === 2) color = 'red';
  if (CategoryNumber === 3) color = 'yellow';
  if (CategoryNumber === 4) color = 'olive';
  if (CategoryNumber === 5) color = 'green';
  if (CategoryNumber === 6) color = 'blue';
  return color;
};

export const GetColorOfMessageState = Status => {
  let color = 'grey';
  if (Status === Enums.MESSAGE_STATUS.draft) color = 'yellow';
  if (Status === Enums.MESSAGE_STATUS.unread) color = 'blue';
  if (Status === Enums.MESSAGE_STATUS.read) color = 'green';
  return color;
};

export const GetColorOfTaskState = Status => {
  let color = 'grey';
  if (Status === Enums.TASK_STATUS.inProgress) color = 'blue';
  if (Status === Enums.TASK_STATUS.completed) color = 'green';
  return color;
};

export const GetColorOfUserState = Status => {
  let color = 'grey';
  if (Status === Enums.USER_STATUS.new) color = 'grey';
  if (Status === Enums.USER_STATUS.active) color = 'blue';
  if (Status === Enums.USER_STATUS.pending) color = 'yellow';
  if (Status === Enums.USER_STATUS.removed) color = 'grey';
  return color;
};
