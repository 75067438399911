import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as UserActions from '../../../../app/store/actions/dashboard/meActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import * as Validation from '../../../../common/utils/Validation';
import BaseComponent from '../../../components/user/User.Card';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  const meContainer = Selectors.getMyUser(state);
  if (!Validation.isEmpty(meContainer)) {
    const userContainer = Selectors.getUserEntityById(
      state,
      meContainer.userId
    );
    const user = !Validation.isEmpty(userContainer) ? userContainer.entity : {};
    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataEntity: user,
      dataLoading: meContainer.isFetching
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataEntity: {},
    dataLoading: false
  };
};

// Entity is Segment & ME
// > NO Segment, Parent or ID information needed to load the correct Entity (loadUser)
const mapDispatchToProps = dispatch => ({
  loadUser: auth => dispatch(UserActions.loadUser(auth)),
  invalidateUser: () => dispatch(UserActions.invalidateUser()),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
