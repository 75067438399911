import * as Selectors from '../../app/store/reducers/rootReducer';
import * as ObjectConf from '../../app/config/entities/Objects';
import * as MicrosoftSettings from '../../app/config/settings/Microsoft';

export const EntityHydration = (entity, entityType, state) => {
  if (entityType === ObjectConf.ACTIVITY.code) {
    return ActivityHydration(entity, state);
  }
  if (entityType === ObjectConf.ACTIVITYFOLDER.code) {
    return ActivityFolderHydration(entity, state);
  }
  if (entityType === ObjectConf.BOOKINGBUSINESS.code) {
    return BookingsHydration(entity, state);
  }
  if (entityType === ObjectConf.BOOKINGAPPOINTMENT.code) {
    return BookingsAppointmentHydration(entity, state);
  }
  if (entityType === ObjectConf.BOOKINGCUSTOMER.code) {
    return BookingsCustomerHydration(entity, state);
  }
  if (entityType === ObjectConf.BOOKINGSERVICE.code) {
    return BookingsServiceHydration(entity, state);
  }
  if (entityType === ObjectConf.BOOKINGSTAFFMEMBER.code) {
    return BookingsStaffMemberHydration(entity, state);
  }
  if (entityType === ObjectConf.CONTACT.code) {
    return ContactHydration(entity, state);
  }
  if (entityType === ObjectConf.CONTACTFOLDER.code) {
    return ContactFolderHydration(entity, state);
  }
  if (entityType === ObjectConf.PLAN.code) {
    return PlanHydration(entity, state);
  }
  if (entityType === ObjectConf.TASK.code) {
    return TaskHydration(entity, state);
  }
  return entity;
};

export const ActivityHydration = (entity, state) => {
  const activityId = entity ? entity.id : '';

  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.todos;
  const applicationUrl = `${baseUrl}/tasks/${activityId}/details`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    todoUrl: applicationUrl
  };
  return newEntity;
};

export const ActivityFolderHydration = (entity, state) => {
  const folderId = entity ? entity.id : '';

  // Create Activity Folder Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.todos;
  let applicationUrl = '';
  if (entity.isDefaultFolder) {
    applicationUrl = `${baseUrl}/lists/inbox`;
  } else {
    applicationUrl = `${baseUrl}/lists/${folderId}`;
  }

  // Hydrate Entity
  const newEntity = {
    ...entity,
    todoUrl: applicationUrl
  };
  return newEntity;
};

export const BookingsHydration = (entity, state) => {
  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.bookings;
  const applicationUrl = `${baseUrl}/homepage`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    ApplicationUrl: applicationUrl
  };
  return newEntity;
};

export const BookingsAppointmentHydration = (entity, state) => {
  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.bookings;
  const applicationUrl = `${baseUrl}/calendar`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    ApplicationUrl: applicationUrl
  };
  return newEntity;
};

export const BookingsCustomerHydration = (entity, state) => {
  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.bookings;
  const applicationUrl = `${baseUrl}/customers`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    ApplicationUrl: applicationUrl
  };
  return newEntity;
};

export const BookingsServiceHydration = (entity, state) => {
  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.bookings;
  const applicationUrl = `${baseUrl}/services`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    ApplicationUrl: applicationUrl
  };
  return newEntity;
};

export const BookingsStaffMemberHydration = (entity, state) => {
  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.bookings;
  const applicationUrl = `${baseUrl}/staff`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    ApplicationUrl: applicationUrl
  };
  return newEntity;
};

export const ContactHydration = (entity, state) => {
  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.outlookContact;
  const applicationUrl = `${baseUrl}`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    ApplicationUrl: applicationUrl
  };
  return newEntity;
};

export const ContactFolderHydration = (entity, state) => {
  // Create Activity Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.outlookContact;
  const applicationUrl = `${baseUrl}`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    ApplicationUrl: applicationUrl
  };
  return newEntity;
};

export const PlanHydration = (entity, state) => {
  const planId = entity ? entity.id : '';

  // Load related Group data
  const groupCont = Selectors.getGroupEntityById(state, entity.owner);
  const group = groupCont ? groupCont.entity : {};
  const groupId = group ? group.id : '';

  // Load related User data
  let newCreatedBy = { ...entity.createdBy };
  if (newCreatedBy && newCreatedBy.user) {
    const userId = newCreatedBy.user.id;
    const userContainer = Selectors.getUserEntityById(state, userId);
    const user = userContainer ? userContainer.entity : {};
    newCreatedBy.user.displayName = user.displayName;
    newCreatedBy.user.emailAddress = user.mail;
  }

  // Create Planner Url
  const baseUrl = MicrosoftSettings.ENDPOINTS.plannerPlan;
  const applicationUrl = `${baseUrl}?groupId=${groupId}&planId=${planId}`;

  // Hydrate Entity
  const newEntity = {
    ...entity,
    plannerUrl: applicationUrl,
    ownerGroup: {
      ...group
    },
    createdBy: {
      ...newCreatedBy
    }
  };
  return newEntity;
};

export const TaskHydration = (entity, state) => {
  // Load related Plan data
  const planCont = Selectors.getPlanEntityById(state, entity.planId);
  const plan = planCont ? planCont.entity : {};

  // Load User data of Owner
  let newCreatedBy = { ...entity.createdBy };
  if (newCreatedBy && newCreatedBy.user) {
    const userId = newCreatedBy.user.id;
    const userCont = Selectors.getUserEntityById(state, userId);
    const user = userCont ? userCont.entity : {};
    newCreatedBy.user.displayName = user.displayName;
    newCreatedBy.user.emailAddress = user.mail;
    newCreatedBy.user.entity = user;
  }

  // Load User data of Members
  let newAssignments = { ...entity.assignments };
  for (const key in newAssignments) {
    let assignment = newAssignments[key];
    if (assignment) {
      const assUserCont = Selectors.getUserEntityById(state, key);
      const assUser = assUserCont ? assUserCont.entity : {};
      assignment.displayName = assUser.displayName;
      assignment.emailAddress = assUser.mail;
      assignment.entity = assUser;
    }
  }

  // Hydrate Entity
  const newEntity = {
    ...entity,
    plan: {
      ...plan
    },
    createdBy: {
      ...newCreatedBy
    },
    assignments: {
      ...newAssignments
    }
  };
  return newEntity;
};
