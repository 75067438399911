import * as Actions from '../../actions/types';
import * as ObjectConfig from '../../../config/entities/Objects';

const initialState = {
  segmentType: ObjectConfig.ME.code,
  segmentId: '',
  parentType: '',
  parentId: ''
};

//-------------------------
// Helpers
//-------------------------

function setSegment(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      segmentType: payload.segmentType,
      segmentId: payload.segmentId
    };
  }
  return {
    ...state,
    segmentType: ObjectConfig.ME.code,
    segmentId: ''
  };
}

function setParent(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      parentType: payload.parentType,
      parentId: payload.parentId
    };
  }
  return {
    ...state,
    parentType: '',
    parentId: ''
  };
}

function setCurrentContext(state, action) {
  const { payload } = action;
  const segment = ObjectConfig.ME.code;
  if (payload) {
    const { segmentType, segmentId } = payload;
    const { parentType, parentId } = payload;
    const { receivedAt } = payload;
    return {
      ...state,
      segmentType: segmentType ? segmentType : segment,
      segmentId: segmentId,
      parentType: parentType,
      parentId: parentId,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    segmentType: ObjectConfig.ME.code,
    segmentId: '',
    parentType: '',
    parentId: ''
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.CURRENTCONTEXT_SEGMENT:
      return setSegment(state, action);
    case Actions.CURRENTCONTEXT_PARENT:
      return setParent(state, action);
    case Actions.CURRENTCONTEXT_RECEIVE:
      return setCurrentContext(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getCurrentContext = state => {
  return state;
};
