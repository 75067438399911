import { combineReducers } from 'redux';
import authReducer, * as authSelectors from './app/authReducer';
import onboardingReducer, * as onboardingSelectors from './app/onboardingReducer';
import errorReducer, * as errorSelectors from './app/errorReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  errors: errorReducer
});

//-------------------------
// Selectors
//-------------------------

export const getAuth = state => {
  return authSelectors.getAuth(state.auth);
};

export const getOnboarding = state => {
  return onboardingSelectors.getOnboarding(state.onboarding);
};

export const getError = state => {
  return errorSelectors.getError(state.errors);
};

export const getAppError = state => {
  return errorSelectors.getAppError(state.errors);
};

export const getFetchingError = state => {
  return errorSelectors.getFetchingError(state.errors);
};

export const getInputErrors = state => {
  return errorSelectors.getInputErrors(state.errors);
};
