import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as GroupActions from '../../../../app/store/actions/dashboard/joinedGroupActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import * as Validation from '../../../../common/utils/Validation';
import BaseComponent from '../../../components/group/Group.CardList';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  const joinedGroupsContainer = Selectors.getMyJoinedGroups(state);
  if (
    !Validation.isEmpty(joinedGroupsContainer) &&
    !Validation.isEmpty(joinedGroupsContainer.groups)
  ) {
    // Hydrate the group list
    const groupList = Selectors.getGroupEntitiesByIds(
      state,
      joinedGroupsContainer.groups
    );
    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataEntities: groupList,
      dataLoading: joinedGroupsContainer.isFetching,
      dataMoreAvailable: joinedGroupsContainer.moreAvailable,
      dataMoreUrl: joinedGroupsContainer.moreUrl
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataEntities: [],
    dataLoading: false,
    dataMoreAvailable: false,
    dataMoreUrl: ''
  };
};

const mapDispatchToProps = dispatch => ({
  loadGroups: auth => dispatch(GroupActions.loadJoinedGroups(auth)),
  loadMoreGroups: auth => dispatch(GroupActions.loadMoreJoinedGroups(auth)),
  invalidateGroups: () => dispatch(GroupActions.invalidateJoinedGroups()),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
