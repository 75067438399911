import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Video, Transformation } from 'cloudinary-react';
import { Button } from 'semantic-ui-react';
import logo from '../../../app/assets/images/logo-appsolut.png';
import * as AdminAuthService from '../../../common/auth/AdminAuthService';

class LandingPageLoggedin extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.actualWidth = 0;
    this.state = {
      videoSize: 'large'
    };

    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    this.props.loadInitialUsers(this.props.auth);
    this.props.loadInitialGroups(this.props.auth);
    this.props.loadInitialPlans(this.props.auth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  //-----------------------------------
  // Event Handler
  //-----------------------------------
  updateDimensions = () => {
    const previousSize = this.state.videoSize;
    this.actualWidth = window.innerWidth;
    const newDeviceSize = this.getDeviceSize(this.actualWidth);
    const newVideoSize = this.getVideoSize(newDeviceSize);
    if (newVideoSize !== previousSize) {
      this.setState({ videoSize: newVideoSize });
    }
  };
  handleLogout = e => {
    this.props.auth.logout();
    AdminAuthService.ClearAccessToken();
  };

  //-----------------------------------
  // Functions & Helpers
  //-----------------------------------
  getDeviceSize = width => {
    if (width < 768) return 'mobile';
    if (width < 992) return 'tablett';
    if (width < 1201) return 'desktop';
    return 'widescreen';
  };
  getVideoSize = deviceSize => {
    if (deviceSize === 'mobile') return 'small';
    if (deviceSize === 'tablett') return 'small';
    if (deviceSize === 'desktop') return 'middle';
    if (deviceSize === 'widescreen') return 'large';
    return 'middle';
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth } = this.props;
    const { videoSize } = this.state;
    const transformation = ['video-' + videoSize];
    const actualUser = auth.getUser();
    const authenticated = auth.authenticated;
    const userName = authenticated ? actualUser.name : '';

    return (
      <div className="app-pagewrapper">
        <div className="app-welcome">
          <div className="app-logo">
            <img src={logo} alt="appsolut 365" />
          </div>

          <div className="app-welcome-header">
            <p>appsolut graph</p>
          </div>
          <div className="app-welcome-info">
            <p>
              Welcome {userName}!
              <br />
              You successfully logged in to your personal Office 365 dashboard.
            </p>
          </div>

          <Link to="/dashboard">
            <div className="app-video">
              <Video
                cloudName="appsolut"
                publicId="website-home/template/social-network-852x480"
                loop
                autoPlay
                muted
              >
                <Transformation transformation={transformation} />
              </Video>
            </div>
          </Link>

          <div className="app-welcome-buttons">
            <div className="app-button-main">
              <Button className="app-hp-hero-button" as={Link} to="/dashboard">
                Start surfing Office 365
              </Button>
            </div>
            <div className="app-button-sub">
              <Button
                inverted
                className="app-hp-hero-button"
                onClick={this.handleLogout}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
LandingPageLoggedin.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  dataContext: PropTypes.object.isRequired,
  loadInitialGroups: PropTypes.func.isRequired,
  loadInitialPlans: PropTypes.func.isRequired,
  loadInitialUsers: PropTypes.func.isRequired
};

export default LandingPageLoggedin;
