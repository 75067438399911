import moment from 'moment';
import * as Actions from '../types';
import * as Selectors from '../../reducers/rootReducer';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// TENANT
// - invalidateOrganization
// - loadOrganization
//-------------------------
// NO Data Extension
//-------------------------

export const invalidateOrganization = () => (dispatch, getState) => {
  const tenantContainer = Selectors.getMyTenant(getState());
  return {
    type: Actions.TENANT_INVALIDATE,
    payload: {
      entityId: tenantContainer.organizationId
    }
  };
};

export const loadOrganization = auth => (dispatch, getState) => {
  if (shouldFetchEntity(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchOrganization(auth));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

//-------------------------
// Helpers
//-------------------------

const requestEntity = () => (dispatch, getState) => {
  const tenantContainer = Selectors.getMyTenant(getState());
  return {
    type: Actions.TENANT_REQUEST,
    payload: {
      entityId: tenantContainer.organizationId
    }
  };
};

const receiveEntity = data => {
  const entityList = !Validation.isEmpty(data) ? data.value : [];
  const entity = entityList && entityList.length > 0 ? data.value[0] : {};
  return {
    type: Actions.TENANT_RECEIVE,
    payload: {
      entityId: !Validation.isEmpty(entity) ? entity.id : '',
      entity: entity,
      receivedAt: Date.now()
    }
  };
};

const receiveError = err => {
  if (err.statusCode === 404) {
    return {
      type: Actions.TENANT_RECEIVE,
      payload: {
        entityId: '',
        entity: {},
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const shouldFetchEntity = state => {
  const entityContainer = Selectors.getMyTenant(state);
  const staleDate = moment().subtract(10, 'minutes');

  if (entityContainer) {
    if (!entityContainer.lastUpdated) {
      return true;
    } else if (
      entityContainer.lastUpdated &&
      entityContainer.lastUpdated < staleDate
    ) {
      return true;
    } else if (entityContainer.didInvalidate) {
      return true;
    } else if (entityContainer.isFetching) {
      return false;
    } else return false;
  } else return true;
};

const fetchOrganization = auth => dispatch => {
  dispatch(requestEntity());
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api('/organization')
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => dispatch(receiveEntity(res)))
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};
