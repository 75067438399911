import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import NodeRoot from '../../features/components/node/Node.Canvas.Container';
import SettingsRoot from '../../features/components/setting/Settings.Canvas';
import AccountRoot from '../../features/components/account/Account.Canvas.Container';
import RoutePrivate from '../../common/components/controls/RoutePrivateRedirect';

class AppRouter extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { auth, match } = this.props;

    const routeParams = {
      rootUrl: match.url
    };

    return (
      <Switch>
        <RoutePrivate
          path={`${match.url}/node`}
          auth={auth}
          params={routeParams}
          component={NodeRoot}
        />
        <RoutePrivate
          path={`${match.url}/settings`}
          auth={auth}
          params={routeParams}
          component={SettingsRoot}
        />
        <RoutePrivate
          path={`${match.url}/account`}
          auth={auth}
          params={routeParams}
          component={AccountRoot}
        />
      </Switch>
    );
  }
}

AppRouter.propTypes = {
  match: PropTypes.object.isRequired
};

export default AppRouter;
