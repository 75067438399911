import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image, Icon, Label } from 'semantic-ui-react';
import DefaultImage from '../../../app/assets/images/defaults/img-default-activity.png';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as ObjectConfig from '../../../app/config/entities/Objects';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as Colors from '../../../app/config/settings/ColorOfState';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as Validation from '../../../common/utils/Validation';

class ActivityCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, showObjectLink, labelContext } = this.props;
    const { smallContainer } = this.props;

    // Device size
    let gridColumnLeft = 4;
    let gridColumnRight = 12;
    let lengthMeta = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      lengthMeta = 25;
    } else if (smallContainer) {
      lengthMeta = 40;
    }

    // Image
    const entityImage =
      dataEntity && dataEntity.image ? dataEntity.image.url : DefaultImage;

    // Context
    const contextDefault = ObjectConfig.ACTIVITY.name;
    const contextValue = !Validation.isEmpty(labelContext)
      ? labelContext
      : contextDefault;

    // Title
    const titleString = dataEntity ? dataEntity.subject : '--';

    // Label
    const labelValue = dataEntity ? dataEntity.status : '';
    const labelText = !Validation.isEmpty(labelValue)
      ? Enums.ACTIVITY_STATUS[labelValue]
      : '--';
    const labelColor = Colors.GetColorOfActivityState(labelText);

    // Meta
    const metaAssigned = dataEntity ? dataEntity.assignedTo : '';
    const metaOwner = dataEntity ? dataEntity.owner : '';
    const metaResource = !Validation.isEmpty(metaAssigned)
      ? metaAssigned
      : metaOwner;
    const metaText = StringUtil.MaxLength(metaResource, lengthMeta, true);

    // WebLink
    const webLink = dataEntity ? dataEntity.todoUrl : '';

    return (
      <Grid divided>
        <Grid.Column width={gridColumnLeft}>
          {showObjectLink ? (
            <Image
              src={entityImage}
              alt={titleString}
              as="a"
              onClick={e => this.handleActivityClick(e, dataEntity.id, webLink)}
              className="app-itemcard-image"
            />
          ) : (
            <Image src={entityImage} />
          )}
        </Grid.Column>
        <Grid.Column width={gridColumnRight}>
          <div className="app-itemcard-context">
            {contextValue}
            {showObjectLink ? (
              <Label
                as="a"
                className="app-itemcard-label"
                color={labelColor}
                horizontal
                onClick={e =>
                  this.props.onEntityClick(e, dataEntity.id, webLink)
                }
              >
                {labelText}
              </Label>
            ) : (
              <Label
                className="app-itemcard-label"
                color={labelColor}
                horizontal
              >
                {labelText}
              </Label>
            )}
          </div>
          {showObjectLink ? (
            <div
              onClick={e => this.props.onEntityClick(e, dataEntity.id, webLink)}
              className="app-itemcard-titlelink"
            >
              {titleString}
            </div>
          ) : (
            <div className="app-itemcard-title">{titleString}</div>
          )}
          <div className="app-itemcard-meta">
            <div>
              {metaText}
              <span className="app-meta-item-after">
                {dataEntity.importance === 'high' && (
                  <span className="app-meta-item">
                    <Icon
                      name={MenuConfig.ICONS_MENU_DEFAULTS.priorityhigh}
                      color="red"
                      className="app-listitem-meta-icon"
                    />
                  </span>
                )}
                {dataEntity.importance === 'low' && (
                  <span className="app-meta-item">
                    <Icon
                      name={MenuConfig.ICONS_MENU_DEFAULTS.prioritylow}
                      color="blue"
                      className="app-listitem-meta-icon"
                    />
                  </span>
                )}
                {dataEntity.hasAttachments && (
                  <span className="app-meta-item">
                    <Icon
                      name={MenuConfig.ICONS_MENU_DEFAULTS.attachment}
                      className="app-listitem-meta-icon"
                    />
                  </span>
                )}
              </span>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
ActivityCard.defaultProps = {
  smallContainer: true,
  showObjectLink: false
};

ActivityCard.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  smallContainer: PropTypes.bool,
  labelContext: PropTypes.string,
  showObjectLink: PropTypes.bool,
  onEntityClick: PropTypes.func.isRequired
};

export default ActivityCard;
