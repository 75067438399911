import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Image, Menu } from 'semantic-ui-react';
import DefaultImage from '../../../app/assets/images/defaults/img-default-user.png';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as StringUtil from '../../../common/utils/StringUtils';

class UserCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, smallContainer, showLinks } = this.props;

    // Device size
    let gridColumnLeft = 4;
    let gridColumnRight = 12;
    let lengthMeta = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      lengthMeta = 25;
    } else if (smallContainer) {
      lengthMeta = 40;
    }

    // Image
    const entityImage =
      dataEntity && dataEntity.image ? dataEntity.image.url : DefaultImage;

    // Title
    const titleString = dataEntity ? dataEntity.displayName : '--';

    // Meta
    const metaTextLogin = StringUtil.MaxLength(
      dataEntity.userPrincipalName,
      lengthMeta,
      true
    );

    // Links
    let o365ProfileLink = 'https://delve.office.com';
    if (dataEntity && dataEntity.id) {
      o365ProfileLink += '/?u=' + dataEntity.id;
    }
    const o365AccountLink = 'https://portal.office.com/account/';
    const o365PortalLink = 'https://www.office.com/';
    const supportLink = 'https://www.appsolut-graph.com/';

    return (
      <Grid>
        <Grid.Column width={gridColumnLeft}>
          <Image circular src={entityImage} />
        </Grid.Column>
        <Grid.Column width={gridColumnRight}>
          <div className="app-account-title">{titleString}</div>
          <div className="app-account-meta">{metaTextLogin}</div>
          {showLinks && (
            <div className="app-account-link-wrapper">
              <Menu text vertical>
                <Menu.Item
                  name="o365profile"
                  content="My Office 365 Profile"
                  as="a"
                  href={o365ProfileLink}
                  target="_blank"
                  className="app-account-link"
                />
                <Menu.Item
                  name="o365account"
                  content="My Office 365 Account"
                  as="a"
                  href={o365AccountLink}
                  target="_blank"
                  className="app-account-link"
                />
                <Menu.Item
                  name="o365portal"
                  content="My Office 365"
                  as="a"
                  href={o365PortalLink}
                  target="_blank"
                  className="app-account-link"
                />
                <Menu.Item
                  name="support"
                  content="Get Support"
                  as="a"
                  href={supportLink}
                  target="_blank"
                  className="app-account-link"
                />
              </Menu>
            </div>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
UserCard.defaultProps = {
  smallContainer: true,
  showLinks: true
};

UserCard.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  smallContainer: PropTypes.bool,
  showLinks: PropTypes.bool,
  onLinkItemClick: PropTypes.func.isRequired
};

export default UserCard;
