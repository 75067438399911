export const MICROSOFT_GRAPH = {
  graphEndpoint: 'https://graph.microsoft.com/beta/me',
  EndpointVersion: 'beta'
};

export const ENDPOINTS = {
  plannerPlan: 'https://tasks.office.com/Home/Planner/#/plantaskboard',
  todos: 'https://to-do.office.com',
  outlookContact: 'https://outlook.office.com/people',
  outlookCalendar: 'https://outlook.office.com/calendar',
  bookings: 'https://outlook.office.com/owa/?path=/bookings'
};
