import * as Actions from '../../actions/types';

const initialState = {
  subscriptionIds: [],
  subscriptions: [],
  isFetching: false,
  didInvalidate: false
};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state) {
  return {
    ...state,
    didInvalidate: true
  };
}

function setFetching(state) {
  return {
    ...state,
    isFetching: true,
    didInvalidate: false
  };
}

function setSubscriptions(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds && payload.entities) {
    const { entityIds, entities, receivedAt } = payload;

    return {
      ...state,
      subscriptionIds: entityIds,
      subscriptions: entities,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    subscriptionIds: [],
    subscriptions: [],
    isFetching: false,
    didInvalidate: false
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.ADMIN_SUBSCRIPTIONS_INVALIDATE:
      return setInvalidate(state);
    case Actions.ADMIN_SUBSCRIPTIONS_REQUEST:
      return setFetching(state);
    case Actions.ADMIN_SUBSCRIPTIONS_RECEIVE:
      return setSubscriptions(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getAdminSubscriptions = state => {
  return state;
};
