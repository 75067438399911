import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import Carousel from './Pathfinder.Carousel';
import Relations from './Pathfinder.Relations';

class PathfinderCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    const dataCount = this.props.dataCount;
    if (!(dataCount > 0)) {
      this.props.addInitialStep();
      this.props.setCurrentStepPositionToLast();
    }
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, history, params } = this.props;
    const { dataEntities, dataEntity, dataLoading } = this.props;
    const { dataCount, dataPosition } = this.props;

    return (
      <div>
        <Carousel
          auth={auth}
          location={location}
          match={match}
          history={history}
          params={params}
          dataEntities={dataEntities}
          dataEntity={dataEntity}
          dataCount={dataCount}
          dataPosition={dataPosition}
          refreshStepQuery={this.props.refreshStepQuery}
          invalidateStep={this.props.invalidateStep}
          clearAllSteps={this.props.clearAllSteps}
          removeCurrentStep={this.props.removeCurrentStep}
          addInitialStep={this.props.addInitialStep}
          setCurrentStepPosition={this.props.setCurrentStepPosition}
          setCurrentStepPositionToLast={this.props.setCurrentStepPositionToLast}
          setCurrentNode={this.props.setCurrentNode}
          setCurrentContext={this.props.setCurrentContext}
        />
        <Container>
          <Relations
            auth={auth}
            location={location}
            match={match}
            history={history}
            params={params}
            dataStepContainer={dataEntity}
            dataLoading={dataLoading}
            dataPosition={dataPosition}
            loadMoreStepQuery={this.props.loadMoreStepQuery}
            setCurrentNode={this.props.setCurrentNode}
            setCurrentContext={this.props.setCurrentContext}
          />
        </Container>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
PathfinderCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataEntities: PropTypes.array.isRequired,
  dataEntity: PropTypes.object,
  dataLoading: PropTypes.bool.isRequired,
  dataCount: PropTypes.number.isRequired,
  dataPosition: PropTypes.number.isRequired,
  refreshStepQuery: PropTypes.func.isRequired,
  loadMoreStepQuery: PropTypes.func.isRequired,
  invalidateStep: PropTypes.func.isRequired,
  clearAllSteps: PropTypes.func.isRequired,
  removeCurrentStep: PropTypes.func.isRequired,
  addInitialStep: PropTypes.func.isRequired,
  setCurrentStepPosition: PropTypes.func.isRequired,
  setCurrentStepPositionToLast: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired
};

export default PathfinderCanvas;
