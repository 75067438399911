import moment from 'moment';
import * as Actions from '../types';
import * as Selectors from '../../reducers/rootReducer';
import * as NavUtil from '../../../../common/utils/NavigationUtils';
import * as ObjectParams from '../../../config/entities/ParameterOfObject';
import * as RelationParams from '../../../config/entities/ParameterOfRelation';
import * as ObjectConfig from '../../../config/entities/Objects';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// PATHFINDER STEP
// - invalidateStep
// - addInitialStep
// - addNewStep
// - removeCurrentStep
// - setStepHeader
// - receiveSteps
// - loadStepQuery
// - loadMoreStepQuery
//-------------------------
// NO Data Extension
//-------------------------

export const invalidateStep = position => (dispatch, getState) => {
  const state = getState();
  const index = getIndexFromPosition(state, position);
  const stepCont = Selectors.getPathfinderStepByIndex(state, index);
  const stepQuery = stepCont && stepCont.step ? stepCont.step.query : {};

  dispatch(invalidateStepHeader(index));
  dispatch(invalidateStepQuery(stepQuery.index));
};

export const addInitialStep = () => (dispatch, getState) => {
  const state = getState();
  const me = Selectors.getMyUser(state);
  const stepIndices = Selectors.getAllPathfinderSteps(state);
  const maxIndex =
    stepIndices.length > 0 ? stepIndices[stepIndices.length - 1].index : 0;
  const newIndex = maxIndex + 1;
  if (me && me.userId) {
    const newStep = {
      index: newIndex,
      header: {
        entityType: ObjectConfig.USER.code,
        entityId: me.userId,
        context: {
          segmentType: ObjectConfig.ME.code,
          segmentId: me.userId,
          parentType: '',
          parentId: ''
        }
      },
      query: {
        index: '',
        url: '',
        relationType: '',
        objectType: '',
        loadAll: false,
        moreAvailable: false,
        moreUrl: ''
      }
    };
    dispatch(receiveStep(newStep));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const addNewStep = auth => (dispatch, getState) => {
  const state = getState();
  const currentNode = Selectors.getCurrentNode(state);
  const currentContext = Selectors.getCurrentContext(state);
  const stepIndices = Selectors.getAllPathfinderSteps(state);
  const maxIndex =
    stepIndices.length > 0 ? stepIndices[stepIndices.length - 1].index : 0;
  const newIndex = maxIndex + 1;
  const newStep = {
    index: newIndex,
    header: {
      entityType: currentNode ? currentNode.objectType : '',
      entityId: currentNode ? currentNode.objectId : '',
      context: currentContext
    },
    query: {
      index: '',
      url: '',
      relationType: currentNode ? currentNode.relationType : '',
      objectType: '',
      loadAll: currentNode ? currentNode.loadAll : false,
      moreAvailable: !Validation.isEmpty(''),
      moreUrl: ''
    }
  };
  dispatch(receiveStep(newStep));
  dispatch(loadStepQueryByIndex(auth, newIndex));
};

export const removeCurrentStep = () => (dispatch, getState) => {
  const state = getState();
  const currentPosition = Selectors.getPathfinderCurrentStepPosition(state);
  const index = getIndexFromPosition(state, currentPosition);

  dispatch(removeStep(index));
};

export const setStepHeader = (position, header) => (dispatch, getState) => {
  const state = getState();
  const index = getIndexFromPosition(state, position);

  dispatch(setStepHeaderByIndex(index, header));
};

export const receiveSteps = stepList => {
  let stepIndices = [];
  let steps = [];
  stepList.forEach(step => {
    if (stepIndices.indexOf(step.index) === -1) {
      stepIndices = stepIndices.concat(step.index);
      steps = steps.concat(step);
    }
  });
  return {
    type: Actions.PATHFINDERS_STEP_RECEIVE,
    payload: {
      indices: stepIndices,
      steps: steps,
      receivedAt: Date.now()
    }
  };
};

export const loadStepQuery = (auth, position) => (dispatch, getState) => {
  const state = getState();
  const index = getIndexFromPosition(state, position);

  dispatch(loadStepQueryByIndex(auth, index));
};

export const loadMoreStepQuery = (auth, position) => (dispatch, getState) => {
  const state = getState();
  const index = getIndexFromPosition(state, position);

  dispatch(loadMoreStepQueryByIndex(auth, index));
};

//-------------------------
// Helpers
//-------------------------

const getIndexFromPosition = (state, position) => {
  return Selectors.getPathfinderStepIndexByPosition(state, position);
};

const invalidateStepHeader = index => {
  return {
    type: Actions.PATHFINDER_STEP_INVALIDATE,
    payload: {
      index: index
    }
  };
};

const invalidateStepQuery = index => {
  return {
    type: Actions.QUERY_INVALIDATE,
    payload: {
      index: index
    }
  };
};

const requestStep = index => (dispatch, getState) => {
  const state = getState();
  const stepCont = Selectors.getPathfinderStepByIndex(state, index);
  const stepQuery = stepCont && stepCont.step ? stepCont.step.query : {};

  dispatch(requestStepHeader(index));
  dispatch(requestStepQuery(stepQuery.index));
};

const requestStepHeader = index => {
  return {
    type: Actions.PATHFINDER_STEP_REQUEST,
    payload: {
      index: index
    }
  };
};

const requestStepQuery = index => {
  return {
    type: Actions.QUERY_REQUEST,
    payload: {
      index: index
    }
  };
};

const setStepHeaderByIndex = (index, header) => {
  const newStep = {
    index: index,
    header: header,
    query: {
      index: '',
      url: '',
      relationType: '',
      objectType: '',
      loadAll: false,
      moreAvailable: false,
      moreUrl: ''
    }
  };
  receiveStep(newStep);
};

const loadStepQueryByIndex = (auth, index) => (dispatch, getState) => {
  if (shouldFetchStep(getState(), index)) {
    // Fetch the entity from data api
    return dispatch(fetchStepRelations(auth, index));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

const loadMoreStepQueryByIndex = (auth, index) => (dispatch, getState) => {
  const stepCont = Selectors.getPathfinderStepByIndex(getState(), index);
  const query = stepCont && stepCont.step ? stepCont.step.query : {};
  if (query) {
    // Fetch the next entities from data api and append to current list
    return dispatch(fetchStepRelationsToAttach(auth, index));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

const receiveStep = data => {
  return {
    type: Actions.PATHFINDER_STEP_RECEIVE,
    payload: {
      index: !Validation.isEmpty(data) ? data.index : '',
      step: data,
      receivedAt: Date.now()
    }
  };
};

const receiveEntities = (
  data,
  stepIndex,
  queryParams,
  attach = false,
  moreParams = {}
) => {
  let entityList = [];
  if (data && Validation.isDefined(data, 'value')) {
    // List of datasets selected
    entityList = data.value;
  } else {
    // Single result dataset
    const singleEntity = data ? data : {};
    entityList = entityList.concat(singleEntity);
  }

  let actionType = Actions.PATHFINDER_QUERY_RECEIVE;
  let queryIndex = queryParams ? queryParams.url : '';
  const moreLink = !Validation.isEmpty(data) ? data['@odata.nextLink'] : '';
  if (attach) {
    actionType = Actions.PATHFINDER_QUERY_APPEND;
    queryIndex = moreParams ? moreParams.queryIndex : '';
  }
  // Prepare Entities & EntityIds for Payload
  let entityIds = [];
  let entities = [];
  entityList.forEach(entity => {
    let checkSuccess = true;
    const type = !Validation.isEmpty(entity) ? entity['@odata.type'] : '';

    // Check if correct Entity Type
    if (checkSuccess) {
      const apitype = ObjectParams.GetApiObjectType(queryParams.objectType);
      if (Array.isArray(apitype)) {
        if (apitype.indexOf(type) === -1) {
          checkSuccess = false;
        }
      } else {
        if (type && type !== apitype) {
          checkSuccess = false;
        }
      }
    }

    // Filter special types from result set
    if (checkSuccess) {
      if (queryParams.objectType === ObjectConfig.GROUP.code) {
        if (entity && Validation.isDefined(entity, 'groupTypes')) {
          const groupTypes = entity.groupTypes;
          if (groupTypes.indexOf('Unified') === -1) {
            checkSuccess = false;
          }
        }
      }
    }

    // Check if already selected
    if (checkSuccess) {
      if (entityIds && entityIds.length > 0) {
        if (entityIds.indexOf(entity.id) >= 0) {
          checkSuccess = false;
        }
      }
    }

    // Adjust selected Entity
    const hasContentBytes = ['#microsoft.graph.fileAttachment'];
    if (hasContentBytes.indexOf(type) !== -1) {
      // Document content is too large to be stored in App-State
      // >> remove contentBytes from entity
      delete entity['contentBytes'];
      console.log('Content Bytes removed from Entity ' + entity.id);
    }

    // Add Entity to Result, if checked successfully
    if (checkSuccess) {
      entityIds = entityIds.concat(entity.id);
      entities = entities.concat(entity);
    } else {
      console.log(
        'Excluded from Query: ' + queryParams.objectType + ' ' + entity.id
      );
    }
  });
  //console.log(actionType);
  //console.log(entityIds);
  //console.log(entities);
  return {
    type: actionType,
    payload: {
      stepIndex: stepIndex,
      queryIndex: queryIndex,
      queryParams: queryParams,
      entityIds: entityIds,
      entities: entities,
      moreAvailable: !Validation.isEmpty(moreLink),
      moreUrl: moreLink,
      receivedAt: Date.now()
    }
  };
};

const receiveError = (err, stepIndex = 0, queryParams = {}) => {
  if (err.statusCode === 404) {
    let actionType = Actions.PATHFINDER_QUERY_RECEIVE;
    let queryIndex = queryParams ? queryParams.url : '';
    return {
      type: actionType,
      payload: {
        stepIndex: stepIndex,
        queryIndex: queryIndex,
        queryParams: queryParams,
        entityIds: [],
        entities: [],
        moreAvailable: false,
        moreUrl: '',
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const receiveErrorFromAttach = err => {
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const shouldFetchStep = (state, index) => {
  const stepCont = Selectors.getPathfinderStepByIndex(state, index);
  const stepQuery = stepCont && stepCont.step ? stepCont.step.query : {};
  const queryCont = Selectors.getQueryByIndex(state, stepQuery.index);
  const staleDate = moment().subtract(10, 'minutes');

  const relationType = stepQuery ? stepQuery.relationType : '';
  const relationHandle = RelationParams.GetHandle(relationType);
  if (relationHandle === '' || relationHandle === '$STATIC') {
    // Data already loaded - don't fetch from DB
    return false;
  }

  if (queryCont && queryCont.index) {
    if (!queryCont.lastUpdated) {
      return true;
    } else if (queryCont.lastUpdated && queryCont.lastUpdated < staleDate) {
      return true;
    } else if (queryCont.didInvalidate) {
      return true;
    } else if (queryCont.isFetching) {
      return false;
    } else return false;
  } else return true;
};

const fetchStepRelations = (auth, index) => (dispatch, getState) => {
  dispatch(requestStep(index));

  const stepCont = Selectors.getPathfinderStepByIndex(getState(), index);
  const header = stepCont && stepCont.step ? stepCont.step.header : {};
  const stepQuery = stepCont && stepCont.step ? stepCont.step.query : {};

  const params = {
    relation: stepQuery ? stepQuery.relationType : '',
    objectType: stepQuery ? stepQuery.objectType : '',
    loadMore: false,
    moreUrl: ''
  };
  const queryParams = NavUtil.CreateRelationQuery(header, params);

  //console.log('Fetch Relation from API:');
  //console.log(header);
  //console.log(stepQuery);
  //console.log(queryParams);

  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(queryParams.url)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => dispatch(receiveEntities(res, index, queryParams, false)))
        .catch(err => dispatch(receiveError(err, index, queryParams)));
    })
    .catch(err => console.log(err));
};

const fetchStepRelationsToAttach = (auth, index) => (dispatch, getState) => {
  dispatch(requestStep(index));

  const stepCont = Selectors.getPathfinderStepByIndex(getState(), index);
  const header = stepCont && stepCont.step ? stepCont.step.header : {};
  const stepQuery = stepCont && stepCont.step ? stepCont.step.query : {};
  const queryCont = Selectors.getQueryByIndex(getState(), stepQuery.index);
  const query = queryCont ? queryCont.query : {};

  const queryIndex = query ? query.index : '';
  const moreLink = query ? query.moreUrl : '';
  const loadMore = !Validation.isEmpty(moreLink);
  const params = {
    relation: stepQuery ? stepQuery.relationType : '',
    objectType: stepQuery ? stepQuery.objectType : '',
    loadMore: loadMore,
    moreUrl: moreLink,
    queryIndex: queryIndex
  };
  const queryParams = NavUtil.CreateRelationQuery(header, params);

  //console.log("Query Parameters for Attach");
  //console.log(queryParams);

  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(queryParams.url)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res =>
          dispatch(receiveEntities(res, index, queryParams, true, params))
        )
        .catch(err => dispatch(receiveErrorFromAttach(err)));
    })
    .catch(err => console.log(err));
};

const removeStep = index => {
  return {
    type: Actions.PATHFINDER_STEP_REMOVE,
    payload: {
      index: index
    }
  };
};
