import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, GridColumn, List, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class ApplicationCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field Settings
    const appIdString = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.appId)
        ? 'App-ID: ' + dataEntity.appId
        : '',
      lengthColumn,
      true
    );
    const audienceString = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.signInAudience)
        ? 'Audience: ' + dataEntity.signInAudience
        : '',
      lengthColumn,
      true
    );

    // Field Links
    const web = dataEntity ? dataEntity.web : {};
    const linksApplication = !Validation.isEmpty(web.homePageUrl)
      ? web.homePageUrl
      : '';
    const info = dataEntity ? dataEntity.info : {};
    const linksTerms = !Validation.isEmpty(info.termsOfServiceUrl)
      ? info.termsOfServiceUrl
      : '';
    const linksSupport = !Validation.isEmpty(info.supportUrl)
      ? info.supportUrl
      : '';
    const linksPrivacy = !Validation.isEmpty(info.privacyStatementUrl)
      ? info.privacyStatementUrl
      : '';
    const linksMarketing = !Validation.isEmpty(info.marketingUrl)
      ? info.marketingUrl
      : '';

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );
    const systemDate = Validation.isValidDate(dataEntity.createdDateTime)
      ? 'Created: ' + DateUtil.formatMsGraphDateTime(dataEntity.createdDateTime)
      : '';

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Settings</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(appIdString)}</div>
                  {audienceString && <div>{audienceString}</div>}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Tags</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.tags) ? (
                    <List items={dataEntity.tags} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-website">
                  {linksApplication ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksApplication}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Application Link
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                  {linksSupport && (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksSupport}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Support Page
                      </a>
                    </div>
                  )}
                  {linksMarketing && (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksMarketing}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Marketing Page
                      </a>
                    </div>
                  )}
                  {linksTerms && (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksTerms}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Use
                      </a>
                    </div>
                  )}
                  {linksPrivacy && (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksPrivacy}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy Note
                      </a>
                    </div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                  {systemDate && <div>{systemDate}</div>}
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
ApplicationCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

ApplicationCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default ApplicationCardForm;
