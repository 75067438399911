export const ICONS_MENU_DEFAULTS = {
  account: 'user outline',
  add: 'add',
  alarm: 'alarm',
  attachment: 'paperclip',
  category: 'sitemap',
  checked: 'check',
  checklistChecked: 'check circle outline',
  checklistUnchecked: 'circle outline',
  close: 'close',
  comment: 'comments outline',
  contributor: 'user outline',
  dashboard: 'home',
  date: 'calendar outline',
  edit: 'edit',
  favorites: 'bookmark outline',
  fax: 'fax',
  feed: 'rss',
  file: 'file alternate outline',
  filter: 'filter',
  flag: 'flag outline',
  forks: 'fork',
  graph: 'arrows alternate',
  help: 'info',
  home: 'home',
  label: 'flag outline',
  landing: 'dot circle outline',
  link: 'linkify',
  linkextern: 'external',
  list: 'content',
  mail: 'mail',
  mine: 'user outline',
  mobile: 'mobile alternate',
  note: 'sticky note outline',
  pagerFirst: 'angle double left',
  pagerPrevious: 'angle left',
  pagerNext: 'angle right',
  pagerLast: 'angle double right',
  pathfinder: 'exchange',
  phone: 'phone',
  priorityhigh: 'exclamation',
  prioritylow: 'long arrow alternate down',
  project: 'folder outline',
  rated: 'empty star',
  refresh: 'refresh',
  request: 'idea',
  search: 'search',
  setting: 'wrench',
  showrows: 'list layout',
  showcards: 'block layout',
  signIn: 'sign in',
  signOut: 'sign out',
  sort: 'sort',
  tasks: 'tasks',
  team: 'users',
  toggle: 'bars',
  trending: 'lightning',
  user: 'user',
  view: 'eye',
  website: 'globe',
  write: 'write'
};

export const CODE_MENU_DEFAULTS = {
  none: '',
  account: 'account',
  add: 'add',
  category: 'category',
  close: 'close',
  comment: 'comments',
  contributor: 'users',
  contributions: 'contributions',
  dashboard: 'dashboard',
  edit: 'edit',
  favorites: 'audiences',
  fax: 'fax',
  feed: 'logs',
  filter: 'filter',
  forks: 'forks',
  graph: 'graph',
  home: 'home',
  link: 'link',
  linkextern: 'linkExtern',
  list: 'list',
  mine: 'mine',
  mobile: 'mobile',
  note: 'note',
  pagerFirst: 'pagerFirst',
  pagerPrevious: 'pagerPrevious',
  pagerNext: 'pagerNext',
  pagerLast: 'pagerLast',
  pathfinder: 'pathfinder',
  phone: 'phone',
  project: 'projects',
  rated: 'ratings',
  refresh: 'refresh',
  request: 'requests',
  search: 'search',
  setting: 'setting',
  showrows: 'showrows',
  showcards: 'showcards',
  sort: 'sort',
  tasks: 'tasks',
  team: 'team',
  toggle: 'toggle',
  toggleSidebar: 'sidebar-toggle',
  trending: 'trending',
  users: 'users',
  view: 'view',
  website: 'website'
};
