import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Video, Transformation } from 'cloudinary-react';
import { Button } from 'semantic-ui-react';
import logo from '../../../app/assets/images/logo-appsolut.png';

class LandingPageAnonym extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.actualWidth = 0;
    this.state = {
      videoSize: 'large'
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  //-----------------------------------
  // Event Handler
  //-----------------------------------
  updateDimensions = () => {
    const previousSize = this.state.videoSize;
    this.actualWidth = window.innerWidth;
    const newDeviceSize = this.getDeviceSize(this.actualWidth);
    const newVideoSize = this.getVideoSize(newDeviceSize);
    if (newVideoSize !== previousSize) {
      this.setState({ videoSize: newVideoSize });
    }
  };

  //-----------------------------------
  // Functions & Helpers
  //-----------------------------------
  getDeviceSize = width => {
    if (width < 768) return 'mobile';
    if (width < 992) return 'tablett';
    if (width < 1201) return 'desktop';
    return 'widescreen';
  };
  getVideoSize = deviceSize => {
    if (deviceSize === 'mobile') return 'small';
    if (deviceSize === 'tablett') return 'small';
    if (deviceSize === 'desktop') return 'middle';
    if (deviceSize === 'widescreen') return 'large';
    return 'middle';
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { videoSize } = this.state;
    const transformation = ['video-' + videoSize];

    return (
      <div className="app-pagewrapper">
        <div className="app-welcome">
          <div className="app-logo">
            <img src={logo} alt="appsolut 365" />
          </div>

          <div className="app-welcome-header">
            <p>appsolut graph</p>
          </div>
          <div className="app-welcome-info">
            <p>
              Your personal dashboard for Office 365
              <br />
              Sign in with Microsoft Business Account and start surfing your
              Office.
            </p>
          </div>

          <div className="app-video">
            <Video
              cloudName="appsolut"
              publicId="website-home/template/social-network-852x480"
              loop
              autoPlay
              muted
            >
              <Transformation transformation={transformation} />
            </Video>
          </div>

          <div className="app-welcome-buttons">
            <div className="app-button-main">
              <Button className="app-hp-hero-button" as={Link} to="/">
                Sign in
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
LandingPageAnonym.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default LandingPageAnonym;
