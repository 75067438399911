import * as Relation from './RelationTypes';
import * as RelationParams from './ParameterOfRelation';

// Segments

export const Drive = [
  {
    key: Relation.DRIVEITEMS.root,
    value: RelationParams.GetLabel(Relation.DRIVEITEMS.root)
  },
  {
    key: Relation.DRIVEITEMS.cameraRoll,
    value: RelationParams.GetLabel(Relation.DRIVEITEMS.cameraRoll)
  },
  {
    key: Relation.DRIVEITEMS.documents,
    value: RelationParams.GetLabel(Relation.DRIVEITEMS.documents)
  },
  {
    key: Relation.DRIVEITEMS.photos,
    value: RelationParams.GetLabel(Relation.DRIVEITEMS.photos)
  },
  {
    key: Relation.DRIVEITEMS.music,
    value: RelationParams.GetLabel(Relation.DRIVEITEMS.music)
  },
  {
    key: Relation.DRIVEITEMS.following,
    value: RelationParams.GetLabel(Relation.DRIVEITEMS.following)
  }
];

export const Group = [
  {
    key: Relation.CALENDARS.single,
    value: RelationParams.GetLabel(Relation.CALENDARS.single)
  },
  {
    key: Relation.ORGCONTACTS.members,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.members)
  },
  {
    key: Relation.ORGCONTACTS.membersWithLicenseErrors,
    value: RelationParams.GetLabel(
      Relation.ORGCONTACTS.membersWithLicenseErrors
    )
  },
  {
    key: Relation.CONVERSATIONS.all,
    value: RelationParams.GetLabel(Relation.CONVERSATIONS.all)
  },
  {
    key: Relation.THREADS.all,
    value: RelationParams.GetLabel(Relation.THREADS.all)
  },
  {
    key: Relation.DRIVES.all,
    value: RelationParams.GetLabel(Relation.DRIVES.all)
  },
  {
    key: Relation.DRIVES.default,
    value: RelationParams.GetLabel(Relation.DRIVES.default)
  },
  {
    key: Relation.EVENTS.all,
    value: RelationParams.GetLabel(Relation.EVENTS.all)
  },
  {
    key: Relation.EVENTS.coming,
    value: RelationParams.GetLabel(Relation.EVENTS.coming)
  },
  {
    key: Relation.GROUPS.members,
    value: RelationParams.GetLabel(Relation.GROUPS.members)
  },
  {
    key: Relation.GROUPS.memberOf,
    value: RelationParams.GetLabel(Relation.GROUPS.memberOf)
  },
  {
    key: Relation.GROUPS.membersWithLicenseErrors,
    value: RelationParams.GetLabel(Relation.GROUPS.membersWithLicenseErrors)
  },
  {
    key: Relation.GROUPS.acceptedSenders,
    value: RelationParams.GetLabel(Relation.GROUPS.acceptedSenders)
  },
  {
    key: Relation.GROUPS.rejectedSenders,
    value: RelationParams.GetLabel(Relation.GROUPS.rejectedSenders)
  },
  {
    key: Relation.NOTEBOOKS.assigned,
    value: RelationParams.GetLabel(Relation.NOTEBOOKS.assigned)
  },
  {
    key: Relation.PLANS.assigned,
    value: RelationParams.GetLabel(Relation.PLANS.assigned)
  },
  {
    key: Relation.SITES.root,
    value: RelationParams.GetLabel(Relation.SITES.root)
  },
  {
    key: Relation.USERS.owners,
    value: RelationParams.GetLabel(Relation.USERS.owners)
  },
  {
    key: Relation.USERS.members,
    value: RelationParams.GetLabel(Relation.USERS.members)
  },
  {
    key: Relation.USERS.membersWithLicenseErrors,
    value: RelationParams.GetLabel(Relation.USERS.membersWithLicenseErrors)
  },
  {
    key: Relation.USERS.acceptedSenders,
    value: RelationParams.GetLabel(Relation.USERS.acceptedSenders)
  },
  {
    key: Relation.USERS.rejectedSenders,
    value: RelationParams.GetLabel(Relation.USERS.rejectedSenders)
  }
];

export const Me = [
  {
    key: Relation.ACTIVITIES.all,
    value: RelationParams.GetLabel(Relation.ACTIVITIES.all)
  },
  {
    key: Relation.ACTIVITIES.open,
    value: RelationParams.GetLabel(Relation.ACTIVITIES.open)
  },
  {
    key: Relation.ACTIVITYFOLDERS.all,
    value: RelationParams.GetLabel(Relation.ACTIVITYFOLDERS.all)
  },
  {
    key: Relation.ACTIVITYGROUPS.all,
    value: RelationParams.GetLabel(Relation.ACTIVITYGROUPS.all)
  },
  {
    key: Relation.CALENDARS.all,
    value: RelationParams.GetLabel(Relation.CALENDARS.all)
  },
  {
    key: Relation.CALENDARS.default,
    value: RelationParams.GetLabel(Relation.CALENDARS.default)
  },
  {
    key: Relation.CALENDARGROUPS.all,
    value: RelationParams.GetLabel(Relation.CALENDARGROUPS.all)
  },
  {
    key: Relation.CONTACTS.all,
    value: RelationParams.GetLabel(Relation.CONTACTS.all)
  },
  {
    key: Relation.CONTACTFOLDERS.all,
    value: RelationParams.GetLabel(Relation.CONTACTFOLDERS.all)
  },
  {
    key: Relation.ORGCONTACTS.manager,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.manager)
  },
  {
    key: Relation.ORGCONTACTS.directReports,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.directReports)
  },
  {
    key: Relation.DEVICES.owned,
    value: RelationParams.GetLabel(Relation.DEVICES.owned)
  },
  {
    key: Relation.DEVICES.registered,
    value: RelationParams.GetLabel(Relation.DEVICES.registered)
  },
  {
    key: Relation.DIRECTORYROLES.memberOf,
    value: RelationParams.GetLabel(Relation.DIRECTORYROLES.memberOf)
  },
  {
    key: Relation.DRIVES.all,
    value: RelationParams.GetLabel(Relation.DRIVES.all)
  },
  {
    key: Relation.DRIVES.default,
    value: RelationParams.GetLabel(Relation.DRIVES.default)
  },
  {
    key: Relation.EVENTS.all,
    value: RelationParams.GetLabel(Relation.EVENTS.all)
  },
  {
    key: Relation.EVENTS.coming,
    value: RelationParams.GetLabel(Relation.EVENTS.coming)
  },
  {
    key: Relation.GROUPS.joined,
    value: RelationParams.GetLabel(Relation.GROUPS.joined)
  },
  {
    key: Relation.GROUPS.memberOf,
    value: RelationParams.GetLabel(Relation.GROUPS.memberOf)
  },
  {
    key: Relation.INSIGHTS.trending,
    value: RelationParams.GetLabel(Relation.INSIGHTS.trending)
  },
  {
    key: Relation.INSIGHTS.used,
    value: RelationParams.GetLabel(Relation.INSIGHTS.used)
  },
  {
    key: Relation.INSIGHTS.shared,
    value: RelationParams.GetLabel(Relation.INSIGHTS.shared)
  },
  {
    key: Relation.LICENSES.assigned,
    value: RelationParams.GetLabel(Relation.LICENSES.assigned)
  },
  {
    key: Relation.MAILFOLDERS.all,
    value: RelationParams.GetLabel(Relation.MAILFOLDERS.all)
  },
  {
    key: Relation.MESSAGES.inboxAll,
    value: RelationParams.GetLabel(Relation.MESSAGES.inboxAll)
  },
  {
    key: Relation.MESSAGES.inboxUnread,
    value: RelationParams.GetLabel(Relation.MESSAGES.inboxUnread)
  },
  {
    key: Relation.NOTEBOOKS.all,
    value: RelationParams.GetLabel(Relation.NOTEBOOKS.all)
  },
  {
    key: Relation.PERSONS.trending,
    value: RelationParams.GetLabel(Relation.PERSONS.trending)
  },
  {
    key: Relation.PLANS.all,
    value: RelationParams.GetLabel(Relation.PLANS.all)
  },
  {
    key: Relation.PLANS.favorite,
    value: RelationParams.GetLabel(Relation.PLANS.favorite)
  },
  {
    key: Relation.PLANS.recent,
    value: RelationParams.GetLabel(Relation.PLANS.recent)
  },
  {
    key: Relation.TASKS.all,
    value: RelationParams.GetLabel(Relation.TASKS.all)
  },
  {
    key: Relation.TASKS.open,
    value: RelationParams.GetLabel(Relation.TASKS.open)
  },
  {
    key: Relation.TEAMS.joined,
    value: RelationParams.GetLabel(Relation.TEAMS.joined)
  },
  {
    key: Relation.USERS.manager,
    value: RelationParams.GetLabel(Relation.USERS.manager)
  },
  {
    key: Relation.USERS.directReports,
    value: RelationParams.GetLabel(Relation.USERS.directReports)
  }
];

export const Organisation = [
  {
    key: Relation.APPLICATIONS.assigned,
    value: RelationParams.GetLabel(Relation.APPLICATIONS.assigned)
  },
  {
    key: Relation.BOOKINGBUSINESSES.all,
    value: RelationParams.GetLabel(Relation.BOOKINGBUSINESSES.all)
  },
  {
    key: Relation.ORGCONTACTS.assigned,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.assigned)
  },
  {
    key: Relation.CONTRACTS.assigned,
    value: RelationParams.GetLabel(Relation.CONTRACTS.assigned)
  },
  {
    key: Relation.DEVICES.assigned,
    value: RelationParams.GetLabel(Relation.DEVICES.assigned)
  },
  {
    key: Relation.DOMAINES.assigned,
    value: RelationParams.GetLabel(Relation.DOMAINES.assigned)
  },
  {
    key: Relation.DRIVES.all,
    value: RelationParams.GetLabel(Relation.DRIVES.all)
  },
  {
    key: Relation.GROUPS.all,
    value: RelationParams.GetLabel(Relation.GROUPS.all)
  },
  {
    key: Relation.LICENSES.subscribed,
    value: RelationParams.GetLabel(Relation.LICENSES.subscribed)
  },
  {
    key: Relation.RISKEVENTS.anonymousIp,
    value: RelationParams.GetLabel(Relation.RISKEVENTS.anonymousIp)
  },
  {
    key: Relation.RISKEVENTS.identity,
    value: RelationParams.GetLabel(Relation.RISKEVENTS.identity)
  },
  {
    key: Relation.RISKEVENTS.impossibleTravel,
    value: RelationParams.GetLabel(Relation.RISKEVENTS.impossibleTravel)
  },
  {
    key: Relation.RISKEVENTS.leakedCredentials,
    value: RelationParams.GetLabel(Relation.RISKEVENTS.leakedCredentials)
  },
  {
    key: Relation.RISKEVENTS.malware,
    value: RelationParams.GetLabel(Relation.RISKEVENTS.malware)
  },
  {
    key: Relation.RISKEVENTS.suspiciousIp,
    value: RelationParams.GetLabel(Relation.RISKEVENTS.suspiciousIp)
  },
  {
    key: Relation.RISKEVENTS.unfamiliarLocation,
    value: RelationParams.GetLabel(Relation.RISKEVENTS.unfamiliarLocation)
  },
  {
    key: Relation.SERVICEPRINCIPALS.assigned,
    value: RelationParams.GetLabel(Relation.SERVICEPRINCIPALS.assigned)
  },
  {
    key: Relation.SITES.root,
    value: RelationParams.GetLabel(Relation.SITES.root)
  },
  {
    key: Relation.TEAMS.all,
    value: RelationParams.GetLabel(Relation.TEAMS.all)
  },
  {
    key: Relation.USERS.all,
    value: RelationParams.GetLabel(Relation.USERS.all)
  }
];

export const Plan = [
  {
    key: Relation.BUCKETS.all,
    value: RelationParams.GetLabel(Relation.BUCKETS.all)
  },
  {
    key: Relation.TASKS.subAll,
    value: RelationParams.GetLabel(Relation.TASKS.subAll)
  },
  {
    key: Relation.TASKS.subOpen,
    value: RelationParams.GetLabel(Relation.TASKS.subOpen)
  }
];

export const Site = [
  {
    key: Relation.LISTS.all,
    value: RelationParams.GetLabel(Relation.LISTS.all)
  },
  {
    key: Relation.NOTEBOOKS.all,
    value: RelationParams.GetLabel(Relation.NOTEBOOKS.all)
  },
  {
    key: Relation.PAGES.all,
    value: RelationParams.GetLabel(Relation.PAGES.all)
  },
  {
    key: Relation.SITES.children,
    value: RelationParams.GetLabel(Relation.SITES.children)
  }
];

export const User = [
  {
    key: Relation.ORGCONTACTS.manager,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.manager)
  },
  {
    key: Relation.ORGCONTACTS.directReports,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.directReports)
  },
  {
    key: Relation.DEVICES.owned,
    value: RelationParams.GetLabel(Relation.DEVICES.owned)
  },
  {
    key: Relation.DEVICES.registered,
    value: RelationParams.GetLabel(Relation.DEVICES.registered)
  },
  {
    key: Relation.DIRECTORYROLES.memberOf,
    value: RelationParams.GetLabel(Relation.DIRECTORYROLES.memberOf)
  },
  {
    key: Relation.DRIVES.all,
    value: RelationParams.GetLabel(Relation.DRIVES.all)
  },
  {
    key: Relation.DRIVES.default,
    value: RelationParams.GetLabel(Relation.DRIVES.default)
  },
  {
    key: Relation.GROUPS.joined,
    value: RelationParams.GetLabel(Relation.GROUPS.joined)
  },
  {
    key: Relation.GROUPS.memberOf,
    value: RelationParams.GetLabel(Relation.GROUPS.memberOf)
  },
  {
    key: Relation.INSIGHTS.trending,
    value: RelationParams.GetLabel(Relation.INSIGHTS.trending)
  },
  {
    key: Relation.INSIGHTS.used,
    value: RelationParams.GetLabel(Relation.INSIGHTS.used)
  },
  {
    key: Relation.LICENSES.assigned,
    value: RelationParams.GetLabel(Relation.LICENSES.assigned)
  },
  {
    key: Relation.NOTEBOOKS.all,
    value: RelationParams.GetLabel(Relation.NOTEBOOKS.all)
  },
  {
    key: Relation.PERSONS.trending,
    value: RelationParams.GetLabel(Relation.PERSONS.trending)
  },
  {
    key: Relation.TEAMS.joined,
    value: RelationParams.GetLabel(Relation.TEAMS.joined)
  },
  {
    key: Relation.USERS.manager,
    value: RelationParams.GetLabel(Relation.USERS.manager)
  },
  {
    key: Relation.USERS.directReports,
    value: RelationParams.GetLabel(Relation.USERS.directReports)
  }
];

// Entities

export const Activity = [
  {
    key: Relation.ATTACHMENTS.all,
    value: RelationParams.GetLabel(Relation.ATTACHMENTS.all)
  }
];

export const ActivityFolder = [
  {
    key: Relation.ACTIVITIES.subAll,
    value: RelationParams.GetLabel(Relation.ACTIVITIES.subAll)
  },
  {
    key: Relation.ACTIVITIES.subOpen,
    value: RelationParams.GetLabel(Relation.ACTIVITIES.subOpen)
  }
];

export const ActivityGroup = [
  {
    key: Relation.ACTIVITYFOLDERS.subAll,
    value: RelationParams.GetLabel(Relation.ACTIVITYFOLDERS.subAll)
  }
];

export const AdministrativeUnit = [
  {
    key: Relation.GROUPS.members,
    value: RelationParams.GetLabel(Relation.GROUPS.members)
  },
  {
    key: Relation.USERS.members,
    value: RelationParams.GetLabel(Relation.USERS.members)
  }
];

export const Agreement = [
  {
    key: Relation.AGREEMENTACCEPTANCE.all,
    value: RelationParams.GetLabel(Relation.AGREEMENTACCEPTANCE.all)
  }
];

export const AgreementAcceptance = [];

export const Application = [
  {
    key: Relation.USERS.owners,
    value: RelationParams.GetLabel(Relation.USERS.owners)
  }
];

export const Attachment = [];

export const BookingAppointment = [];

export const BookingBusiness = [
  {
    key: Relation.BOOKINGAPPOINTMENTS.all,
    value: RelationParams.GetLabel(Relation.BOOKINGAPPOINTMENTS.all)
  },
  {
    key: Relation.BOOKINGAPPOINTMENTS.coming,
    value: RelationParams.GetLabel(Relation.BOOKINGAPPOINTMENTS.coming)
  },
  {
    key: Relation.BOOKINGCUSTOMERS.all,
    value: RelationParams.GetLabel(Relation.BOOKINGCUSTOMERS.all)
  },
  {
    key: Relation.BOOKINGSERVICES.all,
    value: RelationParams.GetLabel(Relation.BOOKINGSERVICES.all)
  },
  {
    key: Relation.BOOKINGSTAFFMEMBERS.all,
    value: RelationParams.GetLabel(Relation.BOOKINGSTAFFMEMBERS.all)
  }
];

export const BookingCustomer = [
  {
    key: Relation.BOOKINGAPPOINTMENTS.customerAll,
    value: RelationParams.GetLabel(Relation.BOOKINGAPPOINTMENTS.customerAll)
  }
];

export const BookingService = [
  {
    key: Relation.BOOKINGAPPOINTMENTS.serviceAll,
    value: RelationParams.GetLabel(Relation.BOOKINGAPPOINTMENTS.serviceAll)
  }
];

export const BookingStaffMember = [];

export const Bucket = [
  {
    key: Relation.TASKS.subAssigned,
    value: RelationParams.GetLabel(Relation.TASKS.subAssigned)
  }
];

export const Calendar = [
  {
    key: Relation.EVENTS.all,
    value: RelationParams.GetLabel(Relation.EVENTS.all)
  },
  {
    key: Relation.EVENTS.coming,
    value: RelationParams.GetLabel(Relation.EVENTS.coming)
  }
];

export const CalendarGroup = [
  {
    key: Relation.CALENDARS.all,
    value: RelationParams.GetLabel(Relation.CALENDARS.all)
  }
];

export const Channel = [
  {
    key: Relation.TEAMSTABS.all,
    value: RelationParams.GetLabel(Relation.TEAMSTABS.all)
  },
  {
    key: Relation.CHATTHREADS.all,
    value: RelationParams.GetLabel(Relation.CHATTHREADS.all)
  },
  {
    key: Relation.CHATMESSAGES.all,
    value: RelationParams.GetLabel(Relation.CHATMESSAGES.all)
  }
];

export const ChatMessage = [];

export const ChatThread = [
  {
    key: Relation.CHATMESSAGES.all,
    value: RelationParams.GetLabel(Relation.CHATMESSAGES.all)
  }
];

export const Contact = [];

export const ContactFolder = [
  {
    key: Relation.CONTACTFOLDERS.children,
    value: RelationParams.GetLabel(Relation.CONTACTFOLDERS.children)
  },
  {
    key: Relation.CONTACTS.assigned,
    value: RelationParams.GetLabel(Relation.CONTACTS.assigned)
  }
];

export const Contract = [];

export const Conversation = [
  {
    key: Relation.THREADS.all,
    value: RelationParams.GetLabel(Relation.THREADS.all)
  }
];

export const Device = [
  {
    key: Relation.USERS.registeredUsers,
    value: RelationParams.GetLabel(Relation.USERS.registeredUsers)
  },
  {
    key: Relation.USERS.registeredOwners,
    value: RelationParams.GetLabel(Relation.USERS.registeredOwners)
  }
];

export const DirectoryRole = [
  {
    key: Relation.USERS.members,
    value: RelationParams.GetLabel(Relation.USERS.members)
  }
];

export const Domain = [];

export const DriveItem = [
  {
    key: Relation.DRIVEITEMS.children,
    value: RelationParams.GetLabel(Relation.DRIVEITEMS.children)
  },
  {
    key: Relation.VERSIONS.all,
    value: RelationParams.GetLabel(Relation.VERSIONS.all)
  }
];

export const Event = [
  {
    key: Relation.ATTACHMENTS.all,
    value: RelationParams.GetLabel(Relation.ATTACHMENTS.all)
  },
  {
    key: Relation.CALENDARS.single,
    value: RelationParams.GetLabel(Relation.CALENDARS.single)
  },
  {
    key: Relation.EVENTS.instances,
    value: RelationParams.GetLabel(Relation.EVENTS.instances)
  }
];

export const IdentityProvider = [];

export const Insight = [];

export const License = [
  {
    key: Relation.SERVICEPLANS.assigned,
    value: RelationParams.GetLabel(Relation.SERVICEPLANS.assigned)
  }
];

export const List = [
  {
    key: Relation.LISTITEMS.all,
    value: RelationParams.GetLabel(Relation.LISTITEMS.all)
  }
];

export const ListItem = [
  {
    key: Relation.VERSIONS.all,
    value: RelationParams.GetLabel(Relation.VERSIONS.all)
  }
];

export const Mailfolder = [
  {
    key: Relation.MAILFOLDERS.children,
    value: RelationParams.GetLabel(Relation.MAILFOLDERS.children)
  },
  {
    key: Relation.MESSAGES.all,
    value: RelationParams.GetLabel(Relation.MESSAGES.all)
  },
  {
    key: Relation.MESSAGES.unread,
    value: RelationParams.GetLabel(Relation.MESSAGES.unread)
  }
];

export const Message = [
  {
    key: Relation.ATTACHMENTS.all,
    value: RelationParams.GetLabel(Relation.ATTACHMENTS.all)
  }
];

export const Notebook = [];

export const OrgContact = [
  {
    key: Relation.ORGCONTACTS.manager,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.manager)
  },
  {
    key: Relation.ORGCONTACTS.directReports,
    value: RelationParams.GetLabel(Relation.ORGCONTACTS.directReports)
  },
  {
    key: Relation.GROUPS.memberOf,
    value: RelationParams.GetLabel(Relation.GROUPS.memberOf)
  },
  {
    key: Relation.USERS.manager,
    value: RelationParams.GetLabel(Relation.USERS.manager)
  },
  {
    key: Relation.USERS.directReports,
    value: RelationParams.GetLabel(Relation.USERS.directReports)
  }
];

export const Page = [];

export const Person = [];

export const Post = [
  {
    key: Relation.ATTACHMENTS.all,
    value: RelationParams.GetLabel(Relation.ATTACHMENTS.all)
  },
  {
    key: Relation.POSTS.inReplyTo,
    value: RelationParams.GetLabel(Relation.POSTS.inReplyTo)
  }
];

export const RiskEvent = [
  {
    key: Relation.USERS.impacted,
    value: RelationParams.GetLabel(Relation.USERS.impacted)
  }
];

export const SecurityAlert = [];

export const ServicePlan = [];

export const ServicePrincipal = [
  {
    key: Relation.USERS.owners,
    value: RelationParams.GetLabel(Relation.USERS.owners)
  }
];

export const Task = [
  {
    key: Relation.USERS.taskAssigned,
    value: RelationParams.GetLabel(Relation.USERS.taskAssigned)
  },
  {
    key: Relation.USERS.taskOwners,
    value: RelationParams.GetLabel(Relation.USERS.taskOwners)
  }
];

export const Team = [
  {
    key: Relation.TEAMSAPPINSTALLATIONS.all,
    value: RelationParams.GetLabel(Relation.TEAMSAPPINSTALLATIONS.all)
  },
  {
    key: Relation.CHANNELS.all,
    value: RelationParams.GetLabel(Relation.CHANNELS.all)
  },
  {
    key: Relation.USERS.owners,
    value: RelationParams.GetLabel(Relation.USERS.owners)
  }
];

export const TeamsAppInstallation = [];

export const TeamsTab = [];

export const Thread = [
  {
    key: Relation.POSTS.all,
    value: RelationParams.GetLabel(Relation.POSTS.all)
  }
];

export const Version = [];
