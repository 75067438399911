import * as Actions from '../types';
import * as CompanyDataService from '../../../../data/admin/CompanyDataService';
import * as Validation from '../../../../common/utils/Validation';

//-------------------------
// ADMIN COMPANY
// - invalidateCompany
// - loadCompany
// - loadCompanyByExternalId
// - receiveEntity
//-------------------------

export const invalidateCompany = () => {
  return {
    type: Actions.ADMIN_COMPANY_INVALIDATE,
    payload: {}
  };
};

export const loadCompanyById = id => (dispatch, getState) => {
  if (shouldFetchEntity(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchCompanyById(id));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const loadCompanyByExternalId = externalId => (dispatch, getState) => {
  if (shouldFetchEntity(getState())) {
    // Fetch the entity from data api
    return dispatch(fetchCompanyByExternalId(externalId));
  } else {
    // Let the calling code know there's nothing to wait for.
    return Promise.resolve();
  }
};

export const receiveEntity = data => {
  return {
    type: Actions.ADMIN_COMPANY_RECEIVE,
    payload: {
      entityId: !Validation.isEmpty(data) ? data._id : '',
      entity: data,
      receivedAt: Date.now()
    }
  };
};

//-------------------------
// Helpers
//-------------------------

const requestEntity = () => {
  return {
    type: Actions.ADMIN_COMPANY_REQUEST,
    payload: {}
  };
};

const receiveError = err => {
  if (err.response.status === 404) {
    return {
      type: Actions.ADMIN_COMPANY_RECEIVE,
      payload: {
        entityId: '',
        entity: {},
        receivedAt: Date.now()
      }
    };
  }
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err.response
    }
  };
};

const shouldFetchEntity = state => {
  // Allways load from Database
  return true;
};

const fetchCompanyById = id => dispatch => {
  dispatch(requestEntity());
  CompanyDataService.GetById(id)
    .then(res => dispatch(receiveEntity(res)))
    .catch(err => dispatch(receiveError(err)));
};

const fetchCompanyByExternalId = externalId => dispatch => {
  dispatch(requestEntity());
  CompanyDataService.GetByExternalId(externalId)
    .then(res => dispatch(receiveEntity(res)))
    .catch(err => dispatch(receiveError(err)));
};
