import { combineReducers } from 'redux';

import activityReducer, * as activitySelectors from './entities/activitiesReducers';
import activityfolderReducer, * as activityfolderSelectors from './entities/activityfoldersReducer';
import activitygroupReducer, * as activitygroupSelectors from './entities/activitygroupsReducer';
import administrativeunitReducer, * as administrativeunitSelectors from './entities/administrativeunitsReducer';
import agreementacceptanceReducer, * as agreementacceptanceSelectors from './entities/agreementacceptancesReducer';
import agreementReducer, * as agreementSelectors from './entities/agreementsReducer';
import applicationReducer, * as applicationSelectors from './entities/applicationsReducer';
import attachmentReducer, * as attachmentSelectors from './entities/attachmentsReducer';
import bookingappointmentReducer, * as bookingappointmentSelectors from './entities/bookingappointmentsReducers';
import bookingbusinessReducer, * as bookingbusinessSelectors from './entities/bookingbusinessesReducer';
import bookingcustomerReducer, * as bookingcustomerSelectors from './entities/bookingcustomersReducer';
import bookingserviceReducer, * as bookingserviceSelectors from './entities/bookingservicesReducer';
import bookingstaffmemberReducer, * as bookingstaffmemberSelectors from './entities/bookingstaffmembersReducer';
import bucketReducer, * as bucketSelectors from './entities/bucketsReducer';
import calendargroupReducer, * as calendargroupSelectors from './entities/calendargroupsReducer';
import calendarReducer, * as calendarSelectors from './entities/calendarsReducer';
import channelReducer, * as channelSelectors from './entities/channelsReducer';
import chatmessageReducer, * as chatmessageSelectors from './entities/chatmessagesReducer';
import chatthreadReducer, * as chatthreadSelectors from './entities/chatthreadsReducer';
import contactfolderReducer, * as contactfolderSelectors from './entities/contactfoldersReducer';
import contactReducer, * as contactSelectors from './entities/contactsReducer';
import contractReducer, * as contractSelectors from './entities/contractsReducer';
import conversationReducer, * as conversationSelectors from './entities/conversationsReducer';
import deviceReducer, * as deviceSelectors from './entities/devicesReducer';
import directoryroleReducer, * as directoryroleSelectors from './entities/directoryrolesReducer';
import domainReducer, * as domainSelectors from './entities/domainsReducer';
import driveitemReducer, * as driveitemSelectors from './entities/driveitemsReducer';
import driveReducer, * as driveSelectors from './entities/drivesReducer';
import eventReducer, * as eventSelectors from './entities/eventsReducers';
import groupReducer, * as groupSelectors from './entities/groupsReducers';
import identityproviderReducer, * as identityproviderSelectors from './entities/identityprovidersReducer';
import insightReducer, * as insightSelectors from './entities/insightsReducer';
import licenseReducer, * as licenseSelectors from './entities/licensesReducer';
import listitemReducer, * as listitemSelectors from './entities/listitemsReducer';
import listReducer, * as listSelectors from './entities/listsReducer';
import mailfolderReducer, * as mailfolderSelectors from './entities/mailfoldersReducer';
import messageReducer, * as messageSelectors from './entities/messagesReducers';
import notebookReducer, * as notebookSelectors from './entities/notebooksReducer';
import organizationReducer, * as organizationSelectors from './entities/organizationsReducers';
import orgcontactReducer, * as orgcontactSelectors from './entities/orgcontactsReducer';
import pageReducer, * as pageSelectors from './entities/pagesReducer';
import personReducer, * as personSelectors from './entities/personsReducer';
import planReducer, * as planSelectors from './entities/plansReducers';
import postReducer, * as postSelectors from './entities/postsReducer';
import riskeventReducer, * as riskeventSelectors from './entities/riskeventsReducer';
import securityalertReducer, * as securityalertSelectors from './entities/securityalertsReducer';
import serviceplanReducer, * as serviceplanSelectors from './entities/serviceplansReducer';
import serviceprincipalReducer, * as serviceprincipalSelectors from './entities/serviceprincipalsReducer';
import serviceReducer, * as serviceSelectors from './entities/servicesReducer';
import siteReducer, * as siteSelectors from './entities/sitesReducer';
import taskReducer, * as taskSelectors from './entities/tasksReducers';
import teamsappinstallationReducer, * as teamsappinstallationSelectors from './entities/teamsappinstallationsReducer';
import teamstabReducer, * as teamstabSelectors from './entities/teamstabsReducer';
import threadReducer, * as threadSelectors from './entities/threadsReducer';
import userReducer, * as userSelectors from './entities/usersReducers';
import versionReducer, * as versionSelectors from './entities/versionsReducer';

import * as ObjectConfig from '../../config/entities/Objects';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  activities: activityReducer,
  activityfolders: activityfolderReducer,
  activitygroups: activitygroupReducer,
  administrativeunits: administrativeunitReducer,
  agreementacceptances: agreementacceptanceReducer,
  agreements: agreementReducer,
  applications: applicationReducer,
  attachments: attachmentReducer,
  bookingappointments: bookingappointmentReducer,
  bookingbusinesses: bookingbusinessReducer,
  bookingcustomers: bookingcustomerReducer,
  bookingservices: bookingserviceReducer,
  bookingstaffmembers: bookingstaffmemberReducer,
  buckets: bucketReducer,
  calendargroups: calendargroupReducer,
  calendars: calendarReducer,
  channels: channelReducer,
  chatmessages: chatmessageReducer,
  chatthreads: chatthreadReducer,
  contactfolders: contactfolderReducer,
  contacts: contactReducer,
  contracts: contractReducer,
  conversations: conversationReducer,
  devices: deviceReducer,
  directoryroles: directoryroleReducer,
  domains: domainReducer,
  driveitems: driveitemReducer,
  drives: driveReducer,
  events: eventReducer,
  groups: groupReducer,
  identityproviders: identityproviderReducer,
  insights: insightReducer,
  licenses: licenseReducer,
  listitems: listitemReducer,
  lists: listReducer,
  mailfolders: mailfolderReducer,
  messages: messageReducer,
  notebooks: notebookReducer,
  organizations: organizationReducer,
  orgcontacts: orgcontactReducer,
  pages: pageReducer,
  persons: personReducer,
  plans: planReducer,
  posts: postReducer,
  riskevents: riskeventReducer,
  securityalerts: securityalertReducer,
  serviceplans: serviceplanReducer,
  serviceprincipals: serviceprincipalReducer,
  services: serviceReducer,
  sites: siteReducer,
  tasks: taskReducer,
  teamsappinstallations: teamsappinstallationReducer,
  teamstabs: teamstabReducer,
  threads: threadReducer,
  users: userReducer,
  versions: versionReducer
});

//-------------------------
// Selectors
//-------------------------

// Generic
export const getEntityById = (state, type, id) => {
  if (type === ObjectConfig.ACTIVITY.code) {
    return activitySelectors.getEntityById(state.activities, id);
  }
  if (type === ObjectConfig.ACTIVITYFOLDER.code) {
    return activityfolderSelectors.getEntityById(state.activityfolders, id);
  }
  if (type === ObjectConfig.ACTIVITYGROUP.code) {
    return activitygroupSelectors.getEntityById(state.activitygroups, id);
  }
  if (type === ObjectConfig.ADMINISTRATIVEUNIT.code) {
    return administrativeunitSelectors.getEntityById(
      state.administrativeunits,
      id
    );
  }
  if (type === ObjectConfig.AGREEMENT.code) {
    return agreementSelectors.getEntityById(state.agreements, id);
  }
  if (type === ObjectConfig.AGREEMENTACCEPTANCE.code) {
    return agreementacceptanceSelectors.getEntityById(
      state.agreementacceptances,
      id
    );
  }
  if (type === ObjectConfig.APPLICATION.code) {
    return applicationSelectors.getEntityById(state.applications, id);
  }
  if (type === ObjectConfig.ATTACHMENT.code) {
    return attachmentSelectors.getEntityById(state.attachments, id);
  }
  if (type === ObjectConfig.BOOKINGAPPOINTMENT.code) {
    return bookingappointmentSelectors.getEntityById(
      state.bookingappointments,
      id
    );
  }
  if (type === ObjectConfig.BOOKINGBUSINESS.code) {
    return bookingbusinessSelectors.getEntityById(state.bookingbusinesses, id);
  }
  if (type === ObjectConfig.BOOKINGCUSTOMER.code) {
    return bookingcustomerSelectors.getEntityById(state.bookingcustomers, id);
  }
  if (type === ObjectConfig.BOOKINGSERVICE.code) {
    return bookingserviceSelectors.getEntityById(state.bookingservices, id);
  }
  if (type === ObjectConfig.BOOKINGSTAFFMEMBER.code) {
    return bookingstaffmemberSelectors.getEntityById(
      state.bookingstaffmembers,
      id
    );
  }
  if (type === ObjectConfig.BUCKET.code) {
    return bucketSelectors.getEntityById(state.buckets, id);
  }
  if (type === ObjectConfig.CALENDAR.code) {
    return calendarSelectors.getEntityById(state.calendars, id);
  }
  if (type === ObjectConfig.CALENDARGROUP.code) {
    return calendargroupSelectors.getEntityById(state.calendargroups, id);
  }
  if (type === ObjectConfig.CHANNEL.code) {
    return channelSelectors.getEntityById(state.channels, id);
  }
  if (type === ObjectConfig.CHATMESSAGE.code) {
    return chatmessageSelectors.getEntityById(state.chatmessages, id);
  }
  if (type === ObjectConfig.CHATTHREAD.code) {
    return chatthreadSelectors.getEntityById(state.chatthreads, id);
  }
  if (type === ObjectConfig.CONTACT.code) {
    return contactSelectors.getEntityById(state.contacts, id);
  }
  if (type === ObjectConfig.CONTACTFOLDER.code) {
    return contactfolderSelectors.getEntityById(state.contactfolders, id);
  }
  if (type === ObjectConfig.CONTRACT.code) {
    return contractSelectors.getEntityById(state.contracts, id);
  }
  if (type === ObjectConfig.CONVERSATION.code) {
    return conversationSelectors.getEntityById(state.conversations, id);
  }
  if (type === ObjectConfig.DEVICE.code) {
    return deviceSelectors.getEntityById(state.devices, id);
  }
  if (type === ObjectConfig.DIRECTORYROLE.code) {
    return directoryroleSelectors.getEntityById(state.directoryroles, id);
  }
  if (type === ObjectConfig.DOMAIN.code) {
    return domainSelectors.getEntityById(state.domains, id);
  }
  if (type === ObjectConfig.DRIVE.code) {
    return driveSelectors.getEntityById(state.drives, id);
  }
  if (type === ObjectConfig.DRIVEITEM.code) {
    return driveitemSelectors.getEntityById(state.driveitems, id);
  }
  if (type === ObjectConfig.EVENT.code) {
    return eventSelectors.getEntityById(state.events, id);
  }
  if (type === ObjectConfig.GROUP.code) {
    return groupSelectors.getEntityById(state.groups, id);
  }
  if (type === ObjectConfig.IDENTITYPROVIDER.code) {
    return identityproviderSelectors.getEntityById(state.identityproviders, id);
  }
  if (type === ObjectConfig.INSIGHT.code) {
    return insightSelectors.getEntityById(state.insights, id);
  }
  if (type === ObjectConfig.LICENSE.code) {
    return licenseSelectors.getEntityById(state.licenses, id);
  }
  if (type === ObjectConfig.LIST.code) {
    return listSelectors.getEntityById(state.lists, id);
  }
  if (type === ObjectConfig.LISTITEM.code) {
    return listitemSelectors.getEntityById(state.listitems, id);
  }
  if (type === ObjectConfig.MAILFOLDER.code) {
    return mailfolderSelectors.getEntityById(state.mailfolders, id);
  }
  if (type === ObjectConfig.MESSAGE.code) {
    return messageSelectors.getEntityById(state.messages, id);
  }
  if (type === ObjectConfig.NOTEBOOK.code) {
    return notebookSelectors.getEntityById(state.notebooks, id);
  }
  if (type === ObjectConfig.ORGANIZATION.code) {
    return organizationSelectors.getEntityById(state.organizations, id);
  }
  if (type === ObjectConfig.ORGCONTACT.code) {
    return orgcontactSelectors.getEntityById(state.orgcontacts, id);
  }
  if (type === ObjectConfig.PAGE.code) {
    return pageSelectors.getEntityById(state.pages, id);
  }
  if (type === ObjectConfig.PERSON.code) {
    return personSelectors.getEntityById(state.persons, id);
  }
  if (type === ObjectConfig.PLAN.code) {
    return planSelectors.getEntityById(state.plans, id);
  }
  if (type === ObjectConfig.POST.code) {
    return postSelectors.getEntityById(state.posts, id);
  }
  if (type === ObjectConfig.RISKEVENT.code) {
    return riskeventSelectors.getEntityById(state.riskevents, id);
  }
  if (type === ObjectConfig.SECURITYALERT.code) {
    return securityalertSelectors.getEntityById(state.securityalerts, id);
  }
  if (type === ObjectConfig.SERVICE.code) {
    return serviceSelectors.getEntityById(state.services, id);
  }
  if (type === ObjectConfig.SERVICEPLAN.code) {
    return serviceplanSelectors.getEntityById(state.serviceplans, id);
  }
  if (type === ObjectConfig.SERVICEPRINCIPAL.code) {
    return serviceprincipalSelectors.getEntityById(state.serviceprincipals, id);
  }
  if (type === ObjectConfig.SITE.code) {
    return siteSelectors.getEntityById(state.sites, id);
  }
  if (type === ObjectConfig.TASK.code) {
    return taskSelectors.getEntityById(state.tasks, id);
  }
  if (type === ObjectConfig.TEAM.code) {
    return groupSelectors.getEntityById(state.groups, id);
  }
  if (type === ObjectConfig.TEAMSAPPINSTALLATION.code) {
    return teamsappinstallationSelectors.getEntityById(
      state.teamsappinstallations,
      id
    );
  }
  if (type === ObjectConfig.TEAMSTAB.code) {
    return teamstabSelectors.getEntityById(state.teamstabs, id);
  }
  if (type === ObjectConfig.THREAD.code) {
    return threadSelectors.getEntityById(state.threads, id);
  }
  if (type === ObjectConfig.USER.code) {
    return userSelectors.getEntityById(state.users, id);
  }
  if (type === ObjectConfig.VERSION.code) {
    return versionSelectors.getEntityById(state.versions, id);
  }
  return {};
};
export const getEntitiesByIds = (state, type, ids) => {
  if (type === ObjectConfig.ACTIVITY.code) {
    return activitySelectors.getEntitiesByIds(state.activities, ids);
  }
  if (type === ObjectConfig.ACTIVITYFOLDER.code) {
    return activityfolderSelectors.getEntitiesByIds(state.activityfolders, ids);
  }
  if (type === ObjectConfig.ACTIVITYGROUP.code) {
    return activitygroupSelectors.getEntitiesByIds(state.activitygroups, ids);
  }
  if (type === ObjectConfig.ADMINISTRATIVEUNIT.code) {
    return administrativeunitSelectors.getEntitiesByIds(
      state.administrativeunits,
      ids
    );
  }
  if (type === ObjectConfig.AGREEMENT.code) {
    return agreementSelectors.getEntitiesByIds(state.agreements, ids);
  }
  if (type === ObjectConfig.AGREEMENTACCEPTANCE.code) {
    return agreementacceptanceSelectors.getEntitiesByIds(
      state.agreementacceptances,
      ids
    );
  }
  if (type === ObjectConfig.APPLICATION.code) {
    return applicationSelectors.getEntitiesByIds(state.applications, ids);
  }
  if (type === ObjectConfig.ATTACHMENT.code) {
    return attachmentSelectors.getEntitiesByIds(state.attachments, ids);
  }
  if (type === ObjectConfig.BOOKINGAPPOINTMENT.code) {
    return bookingappointmentSelectors.getEntitiesByIds(
      state.bookingappointments,
      ids
    );
  }
  if (type === ObjectConfig.BOOKINGBUSINESS.code) {
    return bookingbusinessSelectors.getEntitiesByIds(
      state.bookingbusinesses,
      ids
    );
  }
  if (type === ObjectConfig.BOOKINGCUSTOMER.code) {
    return bookingcustomerSelectors.getEntitiesByIds(
      state.bookingcustomers,
      ids
    );
  }
  if (type === ObjectConfig.BOOKINGSERVICE.code) {
    return bookingserviceSelectors.getEntitiesByIds(state.bookingservices, ids);
  }
  if (type === ObjectConfig.BOOKINGSTAFFMEMBER.code) {
    return bookingstaffmemberSelectors.getEntitiesByIds(
      state.bookingstaffmembers,
      ids
    );
  }
  if (type === ObjectConfig.BUCKET.code) {
    return bucketSelectors.getEntitiesByIds(state.buckets, ids);
  }
  if (type === ObjectConfig.CALENDAR.code) {
    return calendarSelectors.getEntitiesByIds(state.calendars, ids);
  }
  if (type === ObjectConfig.CALENDARGROUP.code) {
    return calendargroupSelectors.getEntitiesByIds(state.calendargroups, ids);
  }
  if (type === ObjectConfig.CHANNEL.code) {
    return channelSelectors.getEntitiesByIds(state.channels, ids);
  }
  if (type === ObjectConfig.CHATMESSAGE.code) {
    return chatmessageSelectors.getEntitiesByIds(state.chatmessages, ids);
  }
  if (type === ObjectConfig.CHATTHREAD.code) {
    return chatthreadSelectors.getEntitiesByIds(state.chatthreads, ids);
  }
  if (type === ObjectConfig.CONTACT.code) {
    return contactSelectors.getEntitiesByIds(state.contacts, ids);
  }
  if (type === ObjectConfig.CONTACTFOLDER.code) {
    return contactfolderSelectors.getEntitiesByIds(state.contactfolders, ids);
  }
  if (type === ObjectConfig.CONTRACT.code) {
    return contractSelectors.getEntitiesByIds(state.contracts, ids);
  }
  if (type === ObjectConfig.CONVERSATION.code) {
    return conversationSelectors.getEntitiesByIds(state.conversations, ids);
  }
  if (type === ObjectConfig.DEVICE.code) {
    return deviceSelectors.getEntitiesByIds(state.devices, ids);
  }
  if (type === ObjectConfig.DIRECTORYROLE.code) {
    return directoryroleSelectors.getEntitiesByIds(state.directoryroles, ids);
  }
  if (type === ObjectConfig.DOMAIN.code) {
    return domainSelectors.getEntitiesByIds(state.domains, ids);
  }
  if (type === ObjectConfig.DRIVE.code) {
    return driveSelectors.getEntitiesByIds(state.drives, ids);
  }
  if (type === ObjectConfig.DRIVEITEM.code) {
    return driveitemSelectors.getEntitiesByIds(state.driveitems, ids);
  }
  if (type === ObjectConfig.EVENT.code) {
    return eventSelectors.getEntitiesByIds(state.events, ids);
  }
  if (type === ObjectConfig.GROUP.code) {
    return groupSelectors.getEntitiesByIds(state.groups, ids);
  }
  if (type === ObjectConfig.IDENTITYPROVIDER.code) {
    return identityproviderSelectors.getEntitiesByIds(
      state.identityproviders,
      ids
    );
  }
  if (type === ObjectConfig.INSIGHT.code) {
    return insightSelectors.getEntitiesByIds(state.insights, ids);
  }
  if (type === ObjectConfig.LICENSE.code) {
    return licenseSelectors.getEntitiesByIds(state.licenses, ids);
  }
  if (type === ObjectConfig.LIST.code) {
    return listSelectors.getEntitiesByIds(state.lists, ids);
  }
  if (type === ObjectConfig.LISTITEM.code) {
    return listitemSelectors.getEntitiesByIds(state.listitems, ids);
  }
  if (type === ObjectConfig.MAILFOLDER.code) {
    return mailfolderSelectors.getEntitiesByIds(state.mailfolders, ids);
  }
  if (type === ObjectConfig.MESSAGE.code) {
    return messageSelectors.getEntitiesByIds(state.messages, ids);
  }
  if (type === ObjectConfig.NOTEBOOK.code) {
    return notebookSelectors.getEntitiesByIds(state.notebooks, ids);
  }
  if (type === ObjectConfig.ORGANIZATION.code) {
    return organizationSelectors.getEntitiesByIds(state.organizations, ids);
  }
  if (type === ObjectConfig.ORGCONTACT.code) {
    return orgcontactSelectors.getEntitiesByIds(state.orgcontacts, ids);
  }
  if (type === ObjectConfig.PAGE.code) {
    return pageSelectors.getEntitiesByIds(state.pages, ids);
  }
  if (type === ObjectConfig.PERSON.code) {
    return personSelectors.getEntitiesByIds(state.persons, ids);
  }
  if (type === ObjectConfig.PLAN.code) {
    return planSelectors.getEntitiesByIds(state.plans, ids);
  }
  if (type === ObjectConfig.POST.code) {
    return postSelectors.getEntitiesByIds(state.posts, ids);
  }
  if (type === ObjectConfig.RISKEVENT.code) {
    return riskeventSelectors.getEntitiesByIds(state.riskevents, ids);
  }
  if (type === ObjectConfig.SECURITYALERT.code) {
    return securityalertSelectors.getEntitiesByIds(state.securityalerts, ids);
  }
  if (type === ObjectConfig.SERVICE.code) {
    return serviceSelectors.getEntitiesByIds(state.services, ids);
  }
  if (type === ObjectConfig.SERVICEPLAN.code) {
    return serviceplanSelectors.getEntitiesByIds(state.serviceplans, ids);
  }
  if (type === ObjectConfig.SERVICEPRINCIPAL.code) {
    return serviceprincipalSelectors.getEntitiesByIds(
      state.serviceprincipals,
      ids
    );
  }
  if (type === ObjectConfig.SITE.code) {
    return siteSelectors.getEntitiesByIds(state.sites, ids);
  }
  if (type === ObjectConfig.TASK.code) {
    return taskSelectors.getEntitiesByIds(state.tasks, ids);
  }
  if (type === ObjectConfig.TEAM.code) {
    return groupSelectors.getEntitiesByIds(state.groups, ids);
  }
  if (type === ObjectConfig.TEAMSAPPINSTALLATION.code) {
    return teamsappinstallationSelectors.getEntitiesByIds(
      state.teamsappinstallations,
      ids
    );
  }
  if (type === ObjectConfig.TEAMSTAB.code) {
    return teamstabSelectors.getEntitiesByIds(state.teamstabs, ids);
  }
  if (type === ObjectConfig.THREAD.code) {
    return threadSelectors.getEntitiesByIds(state.threads, ids);
  }
  if (type === ObjectConfig.USER.code) {
    return userSelectors.getEntitiesByIds(state.users, ids);
  }
  if (type === ObjectConfig.VERSION.code) {
    return versionSelectors.getEntitiesByIds(state.versions, ids);
  }
  return {};
};

// Activity
export const getActivityEntityById = (state, id) => {
  return activitySelectors.getEntityById(state.activities, id);
};
export const getActivityEntitiesByIds = (state, ids) => {
  return activitySelectors.getEntitiesByIds(state.activities, ids);
};
export const getAllActivityEntities = state => {
  return activitySelectors.getAllEntities(state.activities);
};
export const getFilteredActivityEntities = (state, filter) => {
  return activitySelectors.getFilteredEntities(state.activities, filter);
};

// ActivityFolder
export const getActivityFolderEntityById = (state, id) => {
  return activityfolderSelectors.getEntityById(state.activityfolders, id);
};
export const getActivityFolderEntitiesByIds = (state, ids) => {
  return activityfolderSelectors.getEntitiesByIds(state.activityfolders, ids);
};
export const getAllActivityFolderEntities = state => {
  return activityfolderSelectors.getAllEntities(state.activityfolders);
};
export const getFilteredActivityFolderEntities = (state, filter) => {
  return activityfolderSelectors.getFilteredEntities(
    state.activityfolders,
    filter
  );
};

// ActivityGroup
export const getActivityGroupEntityById = (state, id) => {
  return activitygroupSelectors.getEntityById(state.activitygroups, id);
};
export const getActivityGroupEntitiesByIds = (state, ids) => {
  return activitygroupSelectors.getEntitiesByIds(state.activitygroups, ids);
};
export const getAllActivityGroupEntities = state => {
  return activitygroupSelectors.getAllEntities(state.activitygroups);
};
export const getFilteredActivityGroupEntities = (state, filter) => {
  return activitygroupSelectors.getFilteredEntities(
    state.activitygroups,
    filter
  );
};

// AdministrativeUnit
export const getAdministrativeUnitEntityById = (state, id) => {
  return administrativeunitSelectors.getEntityById(
    state.administrativeunits,
    id
  );
};
export const getAdministrativeUnitEntitiesByIds = (state, ids) => {
  return administrativeunitSelectors.getEntitiesByIds(
    state.administrativeunits,
    ids
  );
};
export const getAllAdministrativeUnitEntities = state => {
  return administrativeunitSelectors.getAllEntities(state.administrativeunits);
};
export const getFilteredAdministrativeUnitEntities = (state, filter) => {
  return administrativeunitSelectors.getFilteredEntities(
    state.administrativeunits,
    filter
  );
};

// AgreementAcceptance
export const getAgreementAcceptanceEntityById = (state, id) => {
  return agreementacceptanceSelectors.getEntityById(
    state.agreementacceptances,
    id
  );
};
export const getAgreementAcceptanceEntitiesByIds = (state, ids) => {
  return agreementacceptanceSelectors.getEntitiesByIds(
    state.agreementacceptances,
    ids
  );
};
export const getAllAgreementAcceptanceEntities = state => {
  return agreementacceptanceSelectors.getAllEntities(
    state.agreementacceptances
  );
};
export const getFilteredAgreementAcceptanceEntities = (state, filter) => {
  return agreementacceptanceSelectors.getFilteredEntities(
    state.agreementacceptances,
    filter
  );
};

// Agreement
export const getAgreementEntityById = (state, id) => {
  return agreementSelectors.getEntityById(state.agreements, id);
};
export const getAgreementEntitiesByIds = (state, ids) => {
  return agreementSelectors.getEntitiesByIds(state.agreements, ids);
};
export const getAllAgreementEntities = state => {
  return agreementSelectors.getAllEntities(state.agreements);
};
export const getFilteredAgreementEntities = (state, filter) => {
  return agreementSelectors.getFilteredEntities(state.agreements, filter);
};

// Application
export const getApplicationEntityById = (state, id) => {
  return applicationSelectors.getEntityById(state.applications, id);
};
export const getApplicationEntitiesByIds = (state, ids) => {
  return applicationSelectors.getEntitiesByIds(state.applications, ids);
};
export const getAllApplicationEntities = state => {
  return applicationSelectors.getAllEntities(state.applications);
};
export const getFilteredApplicationEntities = (state, filter) => {
  return applicationSelectors.getFilteredEntities(state.applications, filter);
};

// Attachment
export const getAttachmentEntityById = (state, id) => {
  return attachmentSelectors.getEntityById(state.attachments, id);
};
export const getAttachmentEntitiesByIds = (state, ids) => {
  return attachmentSelectors.getEntitiesByIds(state.attachments, ids);
};
export const getAllAttachmentEntities = state => {
  return attachmentSelectors.getAllEntities(state.attachments);
};
export const getFilteredAttachmentEntities = (state, filter) => {
  return attachmentSelectors.getFilteredEntities(state.attachments, filter);
};

// Booking Appointment
export const getBookingAppointmentEntityById = (state, id) => {
  return bookingappointmentSelectors.getEntityById(
    state.bookingappointments,
    id
  );
};
export const getBookingAppointmentEntitiesByIds = (state, ids) => {
  return bookingappointmentSelectors.getEntitiesByIds(
    state.bookingappointments,
    ids
  );
};
export const getAllBookingAppointmentEntities = state => {
  return bookingappointmentSelectors.getAllEntities(state.bookingappointments);
};
export const getFilteredBookingAppointmentEntities = (state, filter) => {
  return bookingappointmentSelectors.getFilteredEntities(
    state.bookingappointments,
    filter
  );
};

// BookingBusiness
export const getBookingBusinessEntityById = (state, id) => {
  return bookingbusinessSelectors.getEntityById(state.bookingbusinesses, id);
};
export const getBookingBusinessEntitiesByIds = (state, ids) => {
  return bookingbusinessSelectors.getEntitiesByIds(
    state.bookingbusinesses,
    ids
  );
};
export const getAllBookingBusinessEntities = state => {
  return bookingbusinessSelectors.getAllEntities(state.bookingbusinesses);
};
export const getFilteredBookingBusinessEntities = (state, filter) => {
  return bookingbusinessSelectors.getFilteredEntities(
    state.bookingbusinesses,
    filter
  );
};

// BookingCustomer
export const getBookingCustomerEntityById = (state, id) => {
  return bookingcustomerSelectors.getEntityById(state.bookingcustomers, id);
};
export const getBookingCustomerEntitiesByIds = (state, ids) => {
  return bookingcustomerSelectors.getEntitiesByIds(state.bookingcustomers, ids);
};
export const getAllBookingCustomerEntities = state => {
  return bookingcustomerSelectors.getAllEntities(state.bookingcustomers);
};
export const getFilteredBookingCustomerEntities = (state, filter) => {
  return bookingcustomerSelectors.getFilteredEntities(
    state.bookingcustomers,
    filter
  );
};

// BookingService
export const getBookingServiceEntityById = (state, id) => {
  return bookingserviceSelectors.getEntityById(state.bookingservices, id);
};
export const getBookingServiceEntitiesByIds = (state, ids) => {
  return bookingserviceSelectors.getEntitiesByIds(state.bookingservices, ids);
};
export const getAllBookingServiceEntities = state => {
  return bookingserviceSelectors.getAllEntities(state.bookingservices);
};
export const getFilteredBookingServiceEntities = (state, filter) => {
  return bookingserviceSelectors.getFilteredEntities(
    state.bookingservices,
    filter
  );
};

// BookingStaffMember
export const getBookingStaffMemberEntityById = (state, id) => {
  return bookingstaffmemberSelectors.getEntityById(
    state.bookingstaffmembers,
    id
  );
};
export const getBookingStaffMemberEntitiesByIds = (state, ids) => {
  return bookingstaffmemberSelectors.getEntitiesByIds(
    state.bookingstaffmembers,
    ids
  );
};
export const getAllBookingStaffMemberEntities = state => {
  return bookingstaffmemberSelectors.getAllEntities(state.bookingstaffmembers);
};
export const getFilteredBookingStaffMemberEntities = (state, filter) => {
  return bookingstaffmemberSelectors.getFilteredEntities(
    state.bookingstaffmembers,
    filter
  );
};

// Bucket
export const getBucketEntityById = (state, id) => {
  return bucketSelectors.getEntityById(state.buckets, id);
};
export const getBucketEntitiesByIds = (state, ids) => {
  return bucketSelectors.getEntitiesByIds(state.buckets, ids);
};
export const getAllBucketEntities = state => {
  return bucketSelectors.getAllEntities(state.buckets);
};
export const getFilteredBucketEntities = (state, filter) => {
  return bucketSelectors.getFilteredEntities(state.buckets, filter);
};

// CalendarGroup
export const getCalendarGroupEntityById = (state, id) => {
  return calendargroupSelectors.getEntityById(state.calendargroups, id);
};
export const getCalendarGroupEntitiesByIds = (state, ids) => {
  return calendargroupSelectors.getEntitiesByIds(state.calendargroups, ids);
};
export const getAllCalendarGroupEntities = state => {
  return calendargroupSelectors.getAllEntities(state.calendargroups);
};
export const getFilteredCalendarGroupEntities = (state, filter) => {
  return calendargroupSelectors.getFilteredEntities(
    state.calendargroups,
    filter
  );
};

// Calendar
export const getCalendarEntityById = (state, id) => {
  return calendarSelectors.getEntityById(state.calendars, id);
};
export const getCalendarEntitiesByIds = (state, ids) => {
  return calendarSelectors.getEntitiesByIds(state.calendars, ids);
};
export const getAllCalendarEntities = state => {
  return calendarSelectors.getAllEntities(state.calendars);
};
export const getFilteredCalendarEntities = (state, filter) => {
  return calendarSelectors.getFilteredEntities(state.calendars, filter);
};

// Channel
export const getChannelEntityById = (state, id) => {
  return channelSelectors.getEntityById(state.channels, id);
};
export const getChannelEntitiesByIds = (state, ids) => {
  return channelSelectors.getEntitiesByIds(state.channels, ids);
};
export const getAllChannelEntities = state => {
  return channelSelectors.getAllEntities(state.channels);
};
export const getFilteredChannelEntities = (state, filter) => {
  return channelSelectors.getFilteredEntities(state.channels, filter);
};

// ChatMessage
export const getChatMessageEntityById = (state, id) => {
  return chatmessageSelectors.getEntityById(state.chatmessages, id);
};
export const getChatMessageEntitiesByIds = (state, ids) => {
  return chatmessageSelectors.getEntitiesByIds(state.chatmessages, ids);
};
export const getAllChatMessageEntities = state => {
  return chatmessageSelectors.getAllEntities(state.chatmessages);
};
export const getFilteredChatMessageEntities = (state, filter) => {
  return chatmessageSelectors.getFilteredEntities(state.chatmessages, filter);
};

// ChatThread
export const getChatThreadEntityById = (state, id) => {
  return chatthreadSelectors.getEntityById(state.chatthreads, id);
};
export const getChatThreadEntitiesByIds = (state, ids) => {
  return chatthreadSelectors.getEntitiesByIds(state.chatthreads, ids);
};
export const getAllChatThreadEntities = state => {
  return chatthreadSelectors.getAllEntities(state.chatthreads);
};
export const getFilteredChatThreadEntities = (state, filter) => {
  return chatthreadSelectors.getFilteredEntities(state.chatthreads, filter);
};

// ContactFolder
export const getContactFolderEntityById = (state, id) => {
  return contactfolderSelectors.getEntityById(state.contactfolders, id);
};
export const getContactFolderEntitiesByIds = (state, ids) => {
  return contactfolderSelectors.getEntitiesByIds(state.contactfolders, ids);
};
export const getAllContactFolderEntities = state => {
  return contactfolderSelectors.getAllEntities(state.contactfolders);
};
export const getFilteredContactFolderEntities = (state, filter) => {
  return contactfolderSelectors.getFilteredEntities(
    state.contactfolders,
    filter
  );
};

// Contact
export const getContactEntityById = (state, id) => {
  return contactSelectors.getEntityById(state.contacts, id);
};
export const getContactEntitiesByIds = (state, ids) => {
  return contactSelectors.getEntitiesByIds(state.contacts, ids);
};
export const getAllContactEntities = state => {
  return contactSelectors.getAllEntities(state.contacts);
};
export const getFilteredContactEntities = (state, filter) => {
  return contactSelectors.getFilteredEntities(state.contacts, filter);
};

// Contract
export const getContractEntityById = (state, id) => {
  return contractSelectors.getEntityById(state.contracts, id);
};
export const getContractEntitiesByIds = (state, ids) => {
  return contractSelectors.getEntitiesByIds(state.contracts, ids);
};
export const getAllContractEntities = state => {
  return contractSelectors.getAllEntities(state.contracts);
};
export const getFilteredContractEntities = (state, filter) => {
  return contractSelectors.getFilteredEntities(state.contracts, filter);
};

// Conversation
export const getConversationEntityById = (state, id) => {
  return conversationSelectors.getEntityById(state.conversations, id);
};
export const getConversationEntitiesByIds = (state, ids) => {
  return conversationSelectors.getEntitiesByIds(state.conversations, ids);
};
export const getAllConversationEntities = state => {
  return conversationSelectors.getAllEntities(state.conversations);
};
export const getFilteredConversationEntities = (state, filter) => {
  return conversationSelectors.getFilteredEntities(state.conversations, filter);
};

// Device
export const getDeviceEntityById = (state, id) => {
  return deviceSelectors.getEntityById(state.devices, id);
};
export const getDeviceEntitiesByIds = (state, ids) => {
  return deviceSelectors.getEntitiesByIds(state.devices, ids);
};
export const getAllDeviceEntities = state => {
  return deviceSelectors.getAllEntities(state.devices);
};
export const getFilteredDeviceEntities = (state, filter) => {
  return deviceSelectors.getFilteredEntities(state.devices, filter);
};

// DirectoryRole
export const getDirectoryRoleEntityById = (state, id) => {
  return directoryroleSelectors.getEntityById(state.directoryroles, id);
};
export const getDirectoryRoleEntitiesByIds = (state, ids) => {
  return directoryroleSelectors.getEntitiesByIds(state.directoryroles, ids);
};
export const getAllDirectoryRoleEntities = state => {
  return directoryroleSelectors.getAllEntities(state.directoryroles);
};
export const getFilteredDirectoryRoleEntities = (state, filter) => {
  return directoryroleSelectors.getFilteredEntities(
    state.directoryroles,
    filter
  );
};

// Domain
export const getDomainEntityById = (state, id) => {
  return domainSelectors.getEntityById(state.domains, id);
};
export const getDomainEntitiesByIds = (state, ids) => {
  return domainSelectors.getEntitiesByIds(state.domains, ids);
};
export const getAllDomainEntities = state => {
  return domainSelectors.getAllEntities(state.domains);
};
export const getFilteredDomainEntities = (state, filter) => {
  return domainSelectors.getFilteredEntities(state.domains, filter);
};

// DriveItem
export const getDriveItemEntityById = (state, id) => {
  return driveitemSelectors.getEntityById(state.driveitems, id);
};
export const getDriveItemEntitiesByIds = (state, ids) => {
  return driveitemSelectors.getEntitiesByIds(state.driveitems, ids);
};
export const getAllDriveItemEntities = state => {
  return driveitemSelectors.getAllEntities(state.driveitems);
};
export const getFilteredDriveItemEntities = (state, filter) => {
  return driveitemSelectors.getFilteredEntities(state.driveitems, filter);
};

// Drive
export const getDriveEntityById = (state, id) => {
  return driveSelectors.getEntityById(state.drives, id);
};
export const getDriveEntitiesByIds = (state, ids) => {
  return driveSelectors.getEntitiesByIds(state.drives, ids);
};
export const getAllDriveEntities = state => {
  return driveSelectors.getAllEntities(state.drives);
};
export const getFilteredDriveEntities = (state, filter) => {
  return driveSelectors.getFilteredEntities(state.drives, filter);
};

// Event
export const getEventEntityById = (state, id) => {
  return eventSelectors.getEntityById(state.events, id);
};
export const getEventEntitiesByIds = (state, ids) => {
  return eventSelectors.getEntitiesByIds(state.events, ids);
};
export const getAllEventEntities = state => {
  return eventSelectors.getAllEntities(state.events);
};
export const getFilteredEventEntities = (state, filter) => {
  return eventSelectors.getFilteredEntities(state.events, filter);
};

// Group
export const getGroupEntityById = (state, id) => {
  return groupSelectors.getEntityById(state.groups, id);
};
export const getGroupEntitiesByIds = (state, ids) => {
  return groupSelectors.getEntitiesByIds(state.groups, ids);
};
export const getAllGroupEntities = state => {
  return groupSelectors.getAllEntities(state.groups);
};
export const getFilteredGroupEntities = (state, filter) => {
  return groupSelectors.getFilteredEntities(state.groups, filter);
};

// IdentityProvider
export const getIdentityProviderEntityById = (state, id) => {
  return identityproviderSelectors.getEntityById(state.identityproviders, id);
};
export const getIdentityProviderEntitiesByIds = (state, ids) => {
  return identityproviderSelectors.getEntitiesByIds(
    state.identityproviders,
    ids
  );
};
export const getAllIdentityProviderEntities = state => {
  return identityproviderSelectors.getAllEntities(state.identityproviders);
};
export const getFilteredIdentityProviderEntities = (state, filter) => {
  return identityproviderSelectors.getFilteredEntities(
    state.identityproviders,
    filter
  );
};

// Insight
export const getInsightEntityById = (state, id) => {
  return insightSelectors.getEntityById(state.insights, id);
};
export const getInsightEntitiesByIds = (state, ids) => {
  return insightSelectors.getEntitiesByIds(state.insights, ids);
};
export const getAllInsightEntities = state => {
  return insightSelectors.getAllEntities(state.insights);
};
export const getFilteredInsightEntities = (state, filter) => {
  return insightSelectors.getFilteredEntities(state.insights, filter);
};

// License
export const getLicenseEntityById = (state, id) => {
  return licenseSelectors.getEntityById(state.licenses, id);
};
export const getLicenseEntitiesByIds = (state, ids) => {
  return licenseSelectors.getEntitiesByIds(state.licenses, ids);
};
export const getAllLicenseEntities = state => {
  return licenseSelectors.getAllEntities(state.licenses);
};
export const getFilteredLicenseEntities = (state, filter) => {
  return licenseSelectors.getFilteredEntities(state.licenses, filter);
};

// ListItem
export const getListItemEntityById = (state, id) => {
  return listitemSelectors.getEntityById(state.listitems, id);
};
export const getListItemEntitiesByIds = (state, ids) => {
  return listitemSelectors.getEntitiesByIds(state.listitems, ids);
};
export const getAllListItemEntities = state => {
  return listitemSelectors.getAllEntities(state.listitems);
};
export const getFilteredListItemEntities = (state, filter) => {
  return listitemSelectors.getFilteredEntities(state.listitems, filter);
};

// List
export const getListEntityById = (state, id) => {
  return listSelectors.getEntityById(state.lists, id);
};
export const getListEntitiesByIds = (state, ids) => {
  return listSelectors.getEntitiesByIds(state.lists, ids);
};
export const getAllListEntities = state => {
  return listSelectors.getAllEntities(state.lists);
};
export const getFilteredListEntities = (state, filter) => {
  return listSelectors.getFilteredEntities(state.lists, filter);
};

// MailFolder
export const getMailFolderEntityById = (state, id) => {
  return mailfolderSelectors.getEntityById(state.mailfolders, id);
};
export const getMailFolderEntitiesByIds = (state, ids) => {
  return mailfolderSelectors.getEntitiesByIds(state.mailfolders, ids);
};
export const getAllMailFolderEntities = state => {
  return mailfolderSelectors.getAllEntities(state.mailfolders);
};
export const getFilteredMailFolderEntities = (state, filter) => {
  return mailfolderSelectors.getFilteredEntities(state.mailfolders, filter);
};

// Message
export const getMessageEntityById = (state, id) => {
  return messageSelectors.getEntityById(state.messages, id);
};
export const getMessageEntitiesByIds = (state, ids) => {
  return messageSelectors.getEntitiesByIds(state.messages, ids);
};
export const getAllMessageEntities = state => {
  return messageSelectors.getAllEntities(state.messages);
};
export const getFilteredMessageEntities = (state, filter) => {
  return messageSelectors.getFilteredEntities(state.messages, filter);
};

// Notebook
export const getNotebookEntityById = (state, id) => {
  return notebookSelectors.getEntityById(state.notebooks, id);
};
export const getNotebookEntitiesByIds = (state, ids) => {
  return notebookSelectors.getEntitiesByIds(state.notebooks, ids);
};
export const getAllNotebookEntities = state => {
  return notebookSelectors.getAllEntities(state.notebooks);
};
export const getFilteredNotebookEntities = (state, filter) => {
  return notebookSelectors.getFilteredEntities(state.notebooks, filter);
};

// Organization
export const getOrganizationEntityById = (state, id) => {
  return organizationSelectors.getEntityById(state.organizations, id);
};
export const getOrganizationEntitiesByIds = (state, ids) => {
  return organizationSelectors.getEntitiesByIds(state.organizations, ids);
};
export const getAllOrganizationEntities = state => {
  return organizationSelectors.getAllEntities(state.organizations);
};
export const getFilteredOrganizationEntities = (state, filter) => {
  return organizationSelectors.getFilteredEntities(state.organizations, filter);
};

// OrgContact
export const getOrgContactEntityById = (state, id) => {
  return orgcontactSelectors.getEntityById(state.orgcontacts, id);
};
export const getOrgContactEntitiesByIds = (state, ids) => {
  return orgcontactSelectors.getEntitiesByIds(state.orgcontacts, ids);
};
export const getAllOrgContactEntities = state => {
  return orgcontactSelectors.getAllEntities(state.orgcontacts);
};
export const getFilteredOrgContactEntities = (state, filter) => {
  return orgcontactSelectors.getFilteredEntities(state.orgcontacts, filter);
};

// Page
export const getPageEntityById = (state, id) => {
  return pageSelectors.getEntityById(state.pages, id);
};
export const getPageEntitiesByIds = (state, ids) => {
  return pageSelectors.getEntitiesByIds(state.pages, ids);
};
export const getAllPageEntities = state => {
  return pageSelectors.getAllEntities(state.pages);
};
export const getFilteredPageEntities = (state, filter) => {
  return pageSelectors.getFilteredEntities(state.pages, filter);
};

// Person
export const getPersonEntityById = (state, id) => {
  return personSelectors.getEntityById(state.persons, id);
};
export const getPersonEntitiesByIds = (state, ids) => {
  return personSelectors.getEntitiesByIds(state.persons, ids);
};
export const getAllPersonEntities = state => {
  return personSelectors.getAllEntities(state.persons);
};
export const getFilteredPersonEntities = (state, filter) => {
  return personSelectors.getFilteredEntities(state.persons, filter);
};

// Plan
export const getPlanEntityById = (state, id) => {
  return planSelectors.getEntityById(state.plans, id);
};
export const getPlanEntitiesByIds = (state, ids) => {
  return planSelectors.getEntitiesByIds(state.plans, ids);
};
export const getAllPlanEntities = state => {
  return planSelectors.getAllEntities(state.plans);
};
export const getFilteredPlanEntities = (state, filter) => {
  return planSelectors.getFilteredEntities(state.plans, filter);
};

// Post
export const getPostEntityById = (state, id) => {
  return postSelectors.getEntityById(state.posts, id);
};
export const getPostEntitiesByIds = (state, ids) => {
  return postSelectors.getEntitiesByIds(state.posts, ids);
};
export const getAllPostEntities = state => {
  return postSelectors.getAllEntities(state.posts);
};
export const getFilteredPostEntities = (state, filter) => {
  return postSelectors.getFilteredEntities(state.posts, filter);
};

// RiskEvent
export const getRiskEventEntityById = (state, id) => {
  return riskeventSelectors.getEntityById(state.riskevents, id);
};
export const getRiskEventEntitiesByIds = (state, ids) => {
  return riskeventSelectors.getEntitiesByIds(state.riskevents, ids);
};
export const getAllRiskEventEntities = state => {
  return riskeventSelectors.getAllEntities(state.riskevents);
};
export const getFilteredRiskEventEntities = (state, filter) => {
  return riskeventSelectors.getFilteredEntities(state.riskevents, filter);
};

// SecurityAlert
export const getSecurityAlertEntityById = (state, id) => {
  return securityalertSelectors.getEntityById(state.securityalerts, id);
};
export const getSecurityAlertEntitiesByIds = (state, ids) => {
  return securityalertSelectors.getEntitiesByIds(state.securityalerts, ids);
};
export const getAllSecurityAlertEntities = state => {
  return securityalertSelectors.getAllEntities(state.securityalerts);
};
export const getFilteredSecurityAlertEntities = (state, filter) => {
  return securityalertSelectors.getFilteredEntities(
    state.securityalerts,
    filter
  );
};

// ServicePlan
export const getServicePlanEntityById = (state, id) => {
  return serviceplanSelectors.getEntityById(state.serviceplans, id);
};
export const getServicePlanEntitiesByIds = (state, ids) => {
  return serviceplanSelectors.getEntitiesByIds(state.serviceplans, ids);
};
export const getAllServicePlanEntities = state => {
  return serviceplanSelectors.getAllEntities(state.serviceplans);
};
export const getFilteredServicePlanEntities = (state, filter) => {
  return serviceplanSelectors.getFilteredEntities(state.serviceplans, filter);
};

// ServicePrincipal
export const getServicePrincipalEntityById = (state, id) => {
  return serviceprincipalSelectors.getEntityById(state.serviceprincipals, id);
};
export const getServicePrincipalEntitiesByIds = (state, ids) => {
  return serviceprincipalSelectors.getEntitiesByIds(
    state.serviceprincipals,
    ids
  );
};
export const getAllServicePrincipalEntities = state => {
  return serviceprincipalSelectors.getAllEntities(state.serviceprincipals);
};
export const getFilteredServicePrincipalEntities = (state, filter) => {
  return serviceprincipalSelectors.getFilteredEntities(
    state.serviceprincipals,
    filter
  );
};

// Service
export const getServiceEntityById = (state, id) => {
  return serviceSelectors.getEntityById(state.services, id);
};
export const getServiceEntitiesByIds = (state, ids) => {
  return serviceSelectors.getEntitiesByIds(state.services, ids);
};
export const getAllServiceEntities = state => {
  return serviceSelectors.getAllEntities(state.services);
};
export const getFilteredServiceEntities = (state, filter) => {
  return serviceSelectors.getFilteredEntities(state.services, filter);
};

// Site
export const getSiteEntityById = (state, id) => {
  return siteSelectors.getEntityById(state.sites, id);
};
export const getSiteEntitiesByIds = (state, ids) => {
  return siteSelectors.getEntitiesByIds(state.sites, ids);
};
export const getAllSiteEntities = state => {
  return siteSelectors.getAllEntities(state.sites);
};
export const getFilteredSiteEntities = (state, filter) => {
  return siteSelectors.getFilteredEntities(state.sites, filter);
};

// Task
export const getTaskEntityById = (state, id) => {
  return taskSelectors.getEntityById(state.tasks, id);
};
export const getTaskEntitiesByIds = (state, ids) => {
  return taskSelectors.getEntitiesByIds(state.tasks, ids);
};
export const getAllTaskEntities = state => {
  return taskSelectors.getAllEntities(state.tasks);
};
export const getFilteredTaskEntities = (state, filter) => {
  return taskSelectors.getFilteredEntities(state.tasks, filter);
};

// Team
export const getTeamEntityById = (state, id) => {
  return groupSelectors.getEntityById(state.groups, id);
};
export const getTeamEntitiesByIds = (state, ids) => {
  return groupSelectors.getEntitiesByIds(state.groups, ids);
};
export const getAllTeamEntities = state => {
  return groupSelectors.getAllTeams(state.groups);
};
export const getFilteredTeamEntities = (state, filter) => {
  return groupSelectors.getFilteredTeams(state.groups, filter);
};

// TeamsAppInstallation
export const getTeamsAppInstallationEntityById = (state, id) => {
  return teamsappinstallationSelectors.getEntityById(
    state.teamsappinstallations,
    id
  );
};
export const getTeamsAppInstallationEntitiesByIds = (state, ids) => {
  return teamsappinstallationSelectors.getEntitiesByIds(
    state.teamsappinstallations,
    ids
  );
};
export const getAllTeamsAppInstallationEntities = state => {
  return teamsappinstallationSelectors.getAllEntities(
    state.teamsappinstallations
  );
};
export const getFilteredTeamsAppInstallationEntities = (state, filter) => {
  return teamsappinstallationSelectors.getFilteredEntities(
    state.teamsappinstallations,
    filter
  );
};

// TeamsTab
export const getTeamsTabEntityById = (state, id) => {
  return teamstabSelectors.getEntityById(state.teamstabs, id);
};
export const getTeamsTabEntitiesByIds = (state, ids) => {
  return teamstabSelectors.getEntitiesByIds(state.teamstabs, ids);
};
export const getAllTeamsTabEntities = state => {
  return teamstabSelectors.getAllEntities(state.teamstabs);
};
export const getFilteredTeamsTabEntities = (state, filter) => {
  return teamstabSelectors.getFilteredEntities(state.teamstabs, filter);
};

// Thread
export const getThreadEntityById = (state, id) => {
  return threadSelectors.getEntityById(state.threads, id);
};
export const getThreadEntitiesByIds = (state, ids) => {
  return threadSelectors.getEntitiesByIds(state.threads, ids);
};
export const getAllThreadEntities = state => {
  return threadSelectors.getAllEntities(state.threads);
};
export const getFilteredThreadEntities = (state, filter) => {
  return threadSelectors.getFilteredEntities(state.threads, filter);
};

// User
export const getUserEntityById = (state, id) => {
  return userSelectors.getEntityById(state.users, id);
};
export const getUserEntitiesByIds = (state, ids) => {
  return userSelectors.getEntitiesByIds(state.users, ids);
};
export const getAllUserEntities = state => {
  return userSelectors.getAllEntities(state.users);
};
export const getFilteredUserEntities = (state, filter) => {
  return userSelectors.getFilteredEntities(state.users, filter);
};

// Version
export const getVersionEntityById = (state, id) => {
  return versionSelectors.getEntityById(state.versions, id);
};
export const getVersionEntitiesByIds = (state, ids) => {
  return versionSelectors.getEntitiesByIds(state.versions, ids);
};
export const getAllVersionEntities = state => {
  return versionSelectors.getAllEntities(state.versions);
};
export const getFilteredVersionEntities = (state, filter) => {
  return versionSelectors.getFilteredEntities(state.versions, filter);
};
