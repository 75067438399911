import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Divider, Segment } from 'semantic-ui-react';
import classnames from 'classnames';
import Tenant from './Tenant/Tenant.Card.Container';
import User from './Me/Me.Card.Container';
import Manager from './Team/Manager.StickerList.Container';
import DirectReports from './Team/DirectReports.StickerList.Container';
import JoinedGroups from './Groups/JoinedGroups.CardList.Container';
import Statistics from './Statistics/Statistics.Canvas.Container';
import * as ApplicationConst from '../../../app/config/constants/Application';

class DashboardCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
    this.props.loadInitialUsers(this.props.auth);
    this.props.loadInitialGroups(this.props.auth);
    this.props.loadInitialPlans(this.props.auth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;

    // Device size
    let isMobile = false;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      isMobile = true;
    }

    return (
      <Container>
        <Tenant
          auth={auth}
          location={location}
          match={match}
          params={params}
          smallContainer={false}
          showExtended={true}
          showObjectLink={true}
        />
        <Divider
          fitted
          className={classnames('app-divider-margin', {
            mobile: isMobile === true
          })}
        />
        <User
          auth={auth}
          location={location}
          match={match}
          params={params}
          smallContainer={false}
          labelContext={'Dashboard of'}
          showExtended={true}
          showObjectLink={true}
        />
        <Grid>
          <Grid.Column
            mobile={16}
            tablet={4}
            computer={4}
            largeScreen={4}
            widescreen={4}
          >
            <Manager
              auth={auth}
              location={location}
              match={match}
              params={params}
              headerText={'Manager'}
            />
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={12}
            computer={12}
            largeScreen={12}
            widescreen={12}
          >
            <DirectReports
              auth={auth}
              location={location}
              match={match}
              params={params}
              headerText={'Direct Reports'}
            />
          </Grid.Column>
        </Grid>
        <Statistics
          auth={auth}
          location={location}
          match={match}
          params={params}
        />
        <Segment basic>
          <JoinedGroups
            auth={auth}
            location={location}
            match={match}
            params={params}
            headerText={'Joined Groups'}
          />
        </Segment>
      </Container>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
DashboardCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataNode: PropTypes.object.isRequired,
  dataContext: PropTypes.object.isRequired,
  dataInitialGroups: PropTypes.array.isRequired,
  dataInitialPlans: PropTypes.array.isRequired,
  dataInitialUsers: PropTypes.array.isRequired,
  loadInitialGroups: PropTypes.func.isRequired,
  loadInitialPlans: PropTypes.func.isRequired,
  loadInitialUsers: PropTypes.func.isRequired
};

export default DashboardCanvas;
