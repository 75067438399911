import axios from 'axios';
import * as AdminAuthService from '../../common/auth/AdminAuthService';

//-------------------------
// ADMIN COMPANY Data Service
// - GetAll
// - GetByStatus
// - GetById
// - GetByExternalId
// - Add
// - Sync
// - Update
// - Delete
//-------------------------

export const GetAll = () => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/companies`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByStatus = status => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/companies/status/${status}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetById = companyId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/companies/${companyId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByExternalId = externalId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/companies/extern/${externalId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Add = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/companies`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Sync = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/companies/sync`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Update = (companyId, payload) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .put(`/api/companies/${companyId}`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Delete = companyId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .delete(`/api/companies/${companyId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};
