import { combineReducers } from 'redux';
import byIndexReducer, * as byIndexSelectors from './steps/byIndexReducer';
import allIndicesReducer from './steps/allIndicesReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  byIndex: byIndexReducer,
  allIndices: allIndicesReducer
});

//-------------------------
// Selectors
//-------------------------

export const getStepIndexByPosition = (state, position) => {
  return state.allIndices[position - 1];
};
export const getStepByIndex = (state, index) => {
  return byIndexSelectors.getStepByIndex(state.byIndex, index);
};
export const getStepsByIndices = (state, indices = []) => {
  return indices.map(index => state.byIndex[index]);
};
export const getAllSteps = state => {
  return state.allIndices.map(index => state.byIndex[index]);
};
export const getAllStepsCount = state => {
  return state.allIndices.length;
};
export const getFilteredSteps = (state, filter = 'all') => {
  const allEntities = state.allIndices.map(index => state.byIndex[index]);
  switch (filter) {
    case 'all':
      return allEntities;
    default:
      throw new Error(`Unknown filter: ${filter}`);
  }
};
