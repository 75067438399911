import axios from 'axios';
import jwt_decode from 'jwt-decode';
import * as AuthConfig from '../../app/config/settings/Authorization';

//-------------------------
// ADMIN AUTH Data Service
// - Connect
// - Disconnect
// - GetToken
// - TokenIsValid
//-------------------------

// Connect to Admin Database
export const Connect = () => {
  return new Promise(function(resolve, reject) {
    const connectData = {
      key: AuthConfig.AUTHORISATION_CONFIG.appID,
      secret: AuthConfig.AUTHORISATION_CONFIG.appKey
    };
    axios
      .post('/api/applications/connect', connectData)
      .then(res => {
        const { token } = res.data;
        localStorage.setItem('ApiAccess', token);

        const decoded = jwt_decode(token);
        localStorage.setItem('ApiAccess-Id', decoded.id);
        localStorage.setItem('ApiAccess-App', decoded.name);
        localStorage.setItem('ApiAccess-Exp', decoded.exp);

        resolve(token);
      })
      .catch(err => reject(err));
  });
};

// Disconnect from the Admin Database
export const Disconnect = () => {
  localStorage.removeItem('ApiAccess');
  localStorage.removeItem('ApiAccess-Id');
  localStorage.removeItem('ApiAccess-App');
  localStorage.removeItem('ApiAccess-Exp');
};

// Check if Token has expired
export const GetToken = () => {
  return localStorage.getItem('ApiAccess');
};

// Check if Token has expired
export const TokenIsValid = () => {
  const exp = localStorage.getItem('ApiAccess-Exp');
  if (isNaN(exp)) {
    return false;
  }
  return !HasExpired(exp);
};

//-------------------------
// Helpers
//-------------------------

const HasExpired = exp => {
  const actDateValue = Date.now() / 1000;
  const actDateInt = Math.round(actDateValue);
  if (exp > actDateInt) {
    return false;
  }
  return true;
};
