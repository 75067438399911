import jwt_decode from 'jwt-decode';
import * as AccountDataService from '../../data/admin/AccountDataService';
import * as LoginDataService from '../../data/admin/LoginDataService';

export default function(idToken) {
  const decoded = jwt_decode(idToken);

  // TODO: load initial data each time a new ID Token has been received
  // e.g. Account/Admin profile to State (check also Home.Canvas.loadInitialAppData)
  // not Logins & State, they are loaded in own components

  AccountDataService.GetByExternalId(decoded.oid)
    .then(account => {
      // Add a new Login data set
      const newLogin = {
        account: account._id,
        name: decoded.name,
        code: decoded.preferred_username,
        channel: 'aad',
        service: decoded.iss
      };
      LoginDataService.Add(newLogin).catch(err => console.log(err));
    })
    .catch(err => console.log(err));
}
