import * as Actions from '../types';

//-------------------------
// CURRENT NODE
// - setRelation
// - receiveEntity
// - clearEntity
//-------------------------

export const setRelation = (relation, loadAll) => {
  return {
    type: Actions.CURRENTNODE_RELATION,
    payload: {
      relationType: relation,
      loadAll: loadAll
    }
  };
};

export const receiveEntity = (source, type, id, url) => {
  return {
    type: Actions.CURRENTNODE_RECEIVE,
    payload: {
      source: source,
      objectType: type,
      objectId: id,
      externalUrl: url,
      receivedAt: Date.now()
    }
  };
};

export const clearEntity = () => {
  return {
    type: Actions.CURRENTNODE_RECEIVE,
    payload: {}
  };
};

//-------------------------
// Helpers
//-------------------------
