import * as Actions from '../../../actions/types';

const initialState = [];

//-------------------------
// Helpers
//-------------------------

function clearSteps() {
  return initialState;
}

function addStepId(state, action) {
  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;
    if (state.indexOf(index) === -1) {
      return state.concat(index);
    }
    return state;
  }
  return state;
}

function addStepIds(state, action) {
  const { payload } = action;
  if (payload && payload.indices) {
    const { indices } = payload;
    let newState = [...state];
    indices.forEach(index => {
      if (newState.indexOf(index) === -1) {
        newState = newState.concat(index);
      }
    });
    return newState;
  }
  return state;
}

function removeStepId(state, action) {
  console.log('Remove Step ID');

  const { payload } = action;
  if (payload && payload.index) {
    const { index } = payload;
    const newState = state.filter(element => element !== index);
    return newState;
  }
  return state;
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.PATHFINDER_CLEAR:
      return clearSteps();
    case Actions.PATHFINDER_STEP_RECEIVE:
      return addStepId(state, action);
    case Actions.PATHFINDERS_STEP_RECEIVE:
      return addStepIds(state, action);
    case Actions.PATHFINDER_STEP_REMOVE:
      return removeStepId(state, action);
    default:
      return state;
  }
}
