export const API_MS_DRIVETYPE = {
  none: '',
  documentLibrary: 'SharePoint Document Library',
  business: 'OneDrive for Business',
  personal: 'OneDrive'
};

export const FORM_ACTION = {
  none: '',
  add: 'add',
  edit: 'edit',
  cancel: 'cancel',
  delete: 'delete',
  save: 'save',
  relations: 'relations'
};

export const FORM_MODE = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  view: 'view'
};

export const ACTIVITY_STATUS = {
  notStarted: 'Not Started',
  inProgress: 'Active',
  completed: 'Completed',
  waitingOnOthers: 'Waiting',
  deferred: 'Deferred'
};

export const APPLICATION_STATUS = {
  active: 'Active',
  deleted: 'Deleted'
};

export const BOOKING_LOCATIONTYPE = {
  default: '--',
  conferenceRoom: 'Conference Room',
  homeAddress: 'Home',
  businessAddress: 'Business Address',
  geoCoordinates: 'Geo Coordinates',
  streetAddress: 'Street',
  hotel: 'Hotel',
  restaurant: 'Restaurant',
  localBusiness: 'Local Business',
  postalAddress: 'Postal Address'
};

export const BOOKING_PRICETYPE = {
  notSet: '',
  undefined: '--',
  fixedPrice: 'fixed',
  startingAt: 'min.',
  hourly: 'per hour',
  free: 'free',
  priceVaries: 'variable',
  callUs: 'Call Us'
};

export const BOOKING_STATUS = {
  draft: 'Draft',
  published: 'Published'
};

export const CONTRACT_STATUS = {
  active: 'Active',
  deleted: 'Deleted'
};

export const EVENT_SHOWAS = {
  free: 'Free',
  tentative: 'Tentative',
  busy: 'Busy',
  oof: 'Out of Office',
  workingElsewhere: 'External',
  unknown: 'Unknown'
};

export const EVENT_STATUS = {
  active: 'Active',
  cancelled: 'Cancelled'
};

export const EVENT_TYPE = {
  singleInstance: 'Single Instance',
  seriesMaster: 'Master of Serie',
  occurrence: 'Occurrence',
  exception: 'Exception'
};

export const GROUP_STATUS = {
  expired: 'Expired',
  active: 'Active'
};

export const MESSAGE_STATUS = {
  draft: 'Draft',
  unread: 'Unread',
  read: 'Read'
};

export const PROJECT_STATUS = {
  new: 'New',
  active: 'Active',
  pending: 'Pending',
  closed: 'Closed',
  removed: 'Removed'
};

export const TASK_STATUS = {
  notStarted: 'Not Started',
  inProgress: 'Active',
  completed: 'Completed'
};

export const USER_STATUS = {
  new: 'New',
  active: 'Active',
  pending: 'Pending',
  closed: 'Closed',
  removed: 'Removed'
};

// TO BE UPDATED

export const ACCOUNT_STATUS = {
  new: 'New',
  active: 'Active',
  pending: 'Pending',
  closed: 'Closed',
  removed: 'Removed'
};

export const ACCOUNT_TYPE = {
  business: 'Business',
  education: 'Education',
  research: 'Research',
  analytics: 'Analytics',
  public: 'Public',
  personal: 'Personal'
};

export const COMMENT_STATUS = {
  open: 'Open',
  answerd: 'Answerd',
  pending: 'Pending',
  removed: 'Removed'
};

export const CONNECTION_PROVIDER = {
  none: 'None',
  graphcool: 'GraphCool'
};

export const CONTRIBUTION_STATUS = {
  requested: 'Requested',
  accepted: 'Accepted',
  rejected: 'Rejected',
  closed: 'Closed',
  removed: 'Removed'
};

export const GENDER = {
  male: 'Male',
  female: 'Female',
  other: 'Other'
};

export const GRAPH_ANALYSIS = {
  degree: 'Degree',
  closeness: 'Closeness',
  betweeness: 'Betweeness',
  pagerank: 'PageRank',
  eigencentrality: 'EigenCentrality',
  impact: 'Impact',
  kcors: 'KCors',
  findpath: 'FindPath',
  findhierarchy: 'FindHierarchy'
};

export const GRAPH_BORDERSTYLE = {
  solid: 'Solid',
  dashed: 'Dashed'
};

export const GRAPH_GLYPHSIZE = {
  circle: 'Circle',
  extend: 'Extend'
};

export const GRAPH_ITEMTYPE = {
  node: 'node',
  relation: 'link'
};

export const GRAPH_LAYOUT = {
  standard: 'standard',
  hierarchy: 'hierarchy',
  radial: 'radial',
  structural: 'structural',
  tweak: 'tweak',
  lens: 'lens'
};

export const GRAPH_LINKSTYLE = {
  solid: 'Solid',
  dashed: 'Dashed',
  dotted: 'Dotted'
};

export const GRAPH_MODE = {
  navigator: 'Navigator',
  analytics: 'Analytics'
};

export const GRAPH_POSITION = {
  nw: 'NW',
  ne: 'NE',
  sw: 'SW',
  se: 'SE'
};

export const GRAPH_SIZE = {
  small: 'Small',
  standard: 'Standard',
  double: 'Double',
  threeTimes: 'ThreeTimes',
  fourTimes: 'FourTimes'
};

export const ISSUE_STATUS = {
  new: 'New',
  active: 'Active',
  resolved: 'Resolved',
  closed: 'Closed',
  removed: 'Removed',
  critical: 'Critical'
};

export const LICENSE_TYPE = {
  free: 'Free',
  private: 'Private',
  pro: 'Pro'
};

export const LOG_CARDTYPE = {
  standard: 'Standard',
  image: 'Image'
};

export const LOG_STATUS = {
  info: 'Info',
  warning: 'Warning',
  error: 'Error'
};

export const PROJECT_CATEGORY = {
  business: 'Business',
  politics: 'Politics',
  socialmedia: 'SocialMedia',
  finance: 'Finance',
  fun: 'Fun',
  publicaffairs: 'PublicAffairs',
  life: 'Life',
  fraud: 'Fraud',
  systemmanagement: 'SystemManagement',
  threats: 'Threats',
  analytics: 'Analytics',
  science: 'Science'
};

export const PROJECT_LICENSE = {
  none: 'None',
  cc4int: 'CreativeCommon4Int'
};

export const PROJECT_LICENSE_COMMERCIAL = {
  yes: 'Yes',
  no: 'No'
};

export const PROJECT_LICENSE_MODIFY = {
  yes: 'Yes',
  no: 'No',
  same: 'Same'
};

export const PROJECT_SEEDTYPE = {
  scratch: 'Scratch',
  project: 'Project',
  files: 'Files'
};

export const RATING_STATUS = {
  active: 'Active',
  removed: 'Removed'
};

export const REQUEST_STATUS = {
  new: 'New',
  active: 'Active',
  rejected: 'Rejected',
  resolved: 'Resolved',
  closed: 'Closed',
  removed: 'Removed'
};

export const LIST_TYPES = {
  projects: 'Projects',
  contributions: 'Contributions',
  relations: 'Relations'
};

export const LIST_VIEWTYPES = {
  rows: 'showrows',
  cards: 'showcards'
};

export const LIST_ORDER = {
  desc: '_DESC',
  asc: '_ASC'
};

export const ITEM_MODE = {
  card: 'card',
  form: 'form',
  links: 'links',
  delete: 'delete'
};
