import * as Actions from '../../actions/types';

const initialState = {
  userId: '',
  isFetching: false,
  didInvalidate: false
};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state) {
  return {
    ...state,
    didInvalidate: true
  };
}

function setFetching(state) {
  return {
    ...state,
    isFetching: true,
    didInvalidate: false
  };
}

function setUser(state, action) {
  const { payload } = action;
  if (payload && payload.receivedAt) {
    const { entityId, receivedAt } = payload;
    return {
      ...state,
      userId: entityId,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    userId: '',
    isFetching: false,
    didInvalidate: false
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.MANAGER_INVALIDATE:
      return setInvalidate(state);
    case Actions.MANAGER_REQUEST:
      return setFetching(state);
    case Actions.MANAGER_RECEIVE:
      return setUser(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getMyManager = state => {
  return state;
};
