import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as BucketActions from '../../../../app/store/actions/entities/bucketActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import * as ObjectConfig from '../../../../app/config/entities/Objects';
import * as Hydration from '../../../../common/utils/HydrationUtils';
import * as Validation from '../../../../common/utils/Validation';
import BaseComponent from '../../bucket/Bucket.Card';

const type = ObjectConfig.BUCKET.code;

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  if (!Validation.isEmpty(nodeContainer) && nodeContainer.objectType === type) {
    const entityContainer = Selectors.getBucketEntityById(
      state,
      nodeContainer.objectId
    );
    //console.log('Card Container:');
    //console.log(entityContainer);
    const bucket = entityContainer ? entityContainer.entity : {};

    // Special Data Hydration
    const hydratedEntity = Hydration.EntityHydration(bucket, type, state);

    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataEntity: hydratedEntity,
      dataLoading: entityContainer.isFetching
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataEntity: {},
    dataLoading: false
  };
};

const mapDispatchToProps = dispatch => ({
  loadBucket: (auth, id, parent = {}, segment = {}) =>
    dispatch(BucketActions.loadBucket(auth, id, parent, segment)),
  invalidateBucket: id => dispatch(BucketActions.invalidateBucket(id)),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
