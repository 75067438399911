import * as Actions from '../../actions/types';

const initialState = {
  companyId: '',
  company: {},
  isFetching: false,
  didInvalidate: false
};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state) {
  return {
    ...state,
    didInvalidate: true
  };
}

function setFetching(state) {
  return {
    ...state,
    isFetching: true,
    didInvalidate: false
  };
}

function setCompany(state, action) {
  const { payload } = action;
  if (payload && payload.receivedAt) {
    const { entityId, entity, receivedAt } = payload;
    return {
      ...state,
      companyId: entityId,
      company: entity,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    companyId: '',
    company: {},
    isFetching: false,
    didInvalidate: false
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.ADMIN_COMPANY_INVALIDATE:
      return setInvalidate(state);
    case Actions.ADMIN_COMPANY_REQUEST:
      return setFetching(state);
    case Actions.ADMIN_COMPANY_RECEIVE:
      return setCompany(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getAdminCompany = state => {
  return state;
};
