import * as Actions from '../types';

//-------------------------
// APP ERROR
// - clearAppError
// - clearFetchingError
// - clearInputErrors
// - clearError
// - setAppError
// - setFetchingError
// - setInputErrors
// - setError
//-------------------------

export const clearAppError = () => {
  return {
    type: Actions.ERRORS_APP_CLEAR,
    payload: {}
  };
};

export const clearFetchingError = () => {
  return {
    type: Actions.ERRORS_FETCHING_CLEAR,
    payload: {}
  };
};

export const clearInputErrors = () => {
  return {
    type: Actions.ERRORS_INPUT_CLEAR,
    payload: {}
  };
};

export const clearError = () => {
  return {
    type: Actions.ERRORS_CLEAR,
    payload: {}
  };
};

export const setAppError = err => {
  return {
    type: Actions.ERRORS_APP_GET,
    payload: err
  };
};

export const setFetchingError = err => {
  return {
    type: Actions.ERRORS_FETCHING_GET,
    payload: err
  };
};

export const setInputErrors = err => {
  return {
    type: Actions.ERRORS_INPUT_GET,
    payload: err
  };
};

export const setError = err => {
  return {
    type: Actions.ERRORS_GET,
    payload: err
  };
};

//-------------------------
// Helpers
//-------------------------
