import React from 'react';
import { Route } from 'react-router-dom';
import { Sidebar } from 'semantic-ui-react';
import PageHeader from '../../../common/components/template/Header.App';
import SystemMessage from '../../../common/components/controls/SystemMessage.Container';
import AboutPage from './pages/About.Page';
import TermsPage from './pages/Terms.Page';
import PrivacyPage from './pages/Privacy.Pages';
import PageFooter from '../../../common/components/template/Footer.App';
import AppRouter from '../../../app/routing/App.Router';
import '../Page.css';
import '../Page.Sidebar.css';
import './Info.css';

class InfoPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sidebarSize: 'very wide'
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  //-----------------------------------
  // Event Handler
  //-----------------------------------
  updateDimensions = () => {
    const previousSize = this.state.sidebarSize;
    const newSize = this.getSidebarSize(window.innerWidth);
    if (newSize !== previousSize) {
      this.setState({ sidebarSize: newSize });
    }
  };

  //-----------------------------------
  // Functions & Helpers
  //-----------------------------------
  getSidebarSize = width => {
    if (width < 490) return 'wide';
    return 'very wide';
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { sidebarSize } = this.state;
    const { auth, location, match, params } = this.props;
    const showSidebar = !match.isExact;

    return (
      <div className="app-info">
        <PageHeader
          auth={auth}
          location={location}
          match={match}
          params={params}
        />

        <Sidebar.Pushable className="app-sidebar-wrapper">
          <Sidebar
            animation="overlay"
            width={sidebarSize}
            visible={showSidebar}
            direction="right"
          >
            <div className="app-sidebar">
              <Route
                path="/:page"
                render={props => <AppRouter auth={auth} {...props} />}
              />
            </div>
          </Sidebar>
          <Sidebar.Pusher dimmed={showSidebar} className="app-content">
            <div className="app-pagebody">
              <SystemMessage
                auth={auth}
                location={location}
                match={match}
                params={params}
              />
              <div className="app-info-page">
                {match.url.startsWith('/about') && <AboutPage />}
                {match.url.startsWith('/terms') && <TermsPage />}
                {match.url.startsWith('/privacy') && <PrivacyPage />}
              </div>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

        <PageFooter
          auth={auth}
          location={location}
          match={match}
          params={params}
        />
      </div>
    );
  }
}

export default InfoPage;
