import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Onboarding from './Home.Onboarding.Container';
import Callback from '../../../app/Callback';
import * as Validation from '../../../common/utils/Validation';

class HomeCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.actualWidth = 0;
    this.state = {
      userverified: false,
      onboarding: false
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    this.syncAccountDataStart(this.props.auth);
  }
  componentDidUpdate(prevProps) {
    const prevAccount = prevProps.adminAccount;
    const newAccount = this.props.adminAccount;
    const prevFetching = prevAccount ? prevAccount.isFetching : false;
    const prevId = prevAccount ? prevAccount.accountId : '';
    const newFetching = newAccount ? newAccount.isFetching : false;
    const newId = newAccount ? newAccount.accountId : '';

    if (newId !== prevId || newFetching !== prevFetching) {
      this.syncAccountData();
    }
  }

  //-----------------------------------
  // Event Handler
  //-----------------------------------
  syncAccountDataStart = authService => {
    const stateAuth = this.props.dataAuth;
    const stateUserId = !Validation.isEmpty(stateAuth) ? stateAuth.userId : '';
    const authUser = authService.getUser();
    const authUserId = authUser && authUser.idToken ? authUser.idToken.oid : '';

    if (authUser && authUserId) {
      if (authUserId !== stateUserId) {
        // new user >> reset auth
        this.props.setAppAuthentication(authUser);
      }
      // load Account from DB to check if onboarding needed (>> syncAccountData)
      this.props.loadAdminAccount(authUserId);
    }
  };
  syncAccountData = () => {
    const onboardedAccountContainer = this.props.adminAccount;

    if (onboardedAccountContainer && !onboardedAccountContainer.isFetching) {
      const onboardedAccount = onboardedAccountContainer.account;
      if (Validation.isEmpty(onboardedAccount)) {
        // NO Account >> onboarding required
        this.props.startOnboarding();
        this.setState({ userverified: true, onboarding: true });
      } else {
        if (!onboardedAccount.onboardingFinished) {
          this.props.startOnboarding();
          this.setState({ userverified: true, onboarding: true });
        } else {
          const onboardingStatus = this.props.onboardingStatus;
          if (onboardingStatus && onboardingStatus.needed) {
            this.setState({ userverified: true, onboarding: true });
          } else {
            this.loadInitialAppData();
            this.setState({ userverified: true, onboarding: false });
          }
        }
      }
    }
  };
  loadInitialAppData = () => {
    // TODO: load initial data each time the home page has been started without onboarding
    // e.g. Account/Admin profile to State (check also SyncUserLogin)
    // not Logins & State, they are loaded in own components
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { userverified, onboarding } = this.state;

    if (userverified) {
      if (onboarding) {
        return (
          <Onboarding
            auth={auth}
            match={match}
            location={location}
            params={params}
          />
        );
      }
      return (
        <Redirect
          to={{ pathname: '/dashboard', state: { from: this.props.location } }}
        />
      );
    }

    return <Callback {...this.props} />;
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
HomeCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataAuth: PropTypes.object.isRequired,
  onboardingStatus: PropTypes.object.isRequired,
  adminAccount: PropTypes.object.isRequired,
  loadAdminAccount: PropTypes.func.isRequired,
  setAppAuthentication: PropTypes.func.isRequired,
  startOnboarding: PropTypes.func.isRequired
};

export default HomeCanvas;
