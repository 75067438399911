import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as DirRepActions from '../../../../app/store/actions/dashboard/directReportActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import * as Validation from '../../../../common/utils/Validation';
import BaseComponent from '../../../components/user/User.StickerList';

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  const directReportsContainer = Selectors.getMyDirectReports(state);
  if (
    !Validation.isEmpty(directReportsContainer) &&
    !Validation.isEmpty(directReportsContainer.users)
  ) {
    // Hydrate the user list
    const userList = Selectors.getUserEntitiesByIds(
      state,
      directReportsContainer.users
    );
    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataEntities: userList,
      dataLoading: directReportsContainer.isFetching,
      dataMoreAvailable: directReportsContainer.moreAvailable,
      dataMoreUrl: directReportsContainer.moreUrl
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataEntities: [],
    dataLoading: false,
    dataMoreAvailable: false,
    dataMoreUrl: ''
  };
};

// Entity is Segment & ME
// > NO Segment, Parent or ID information needed to load the correct Entity (loadUsers)
const mapDispatchToProps = dispatch => ({
  loadUsers: auth => dispatch(DirRepActions.loadDirectReports(auth)),
  loadMoreUsers: auth => dispatch(DirRepActions.loadMoreDirectReports(auth)),
  invalidateUsers: () => dispatch(DirRepActions.invalidateDirectReports()),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
