import * as Actions from '../types';
import * as CompanyDataService from '../../../../data/admin/CompanyDataService';
import * as SubscriptionDataService from '../../../../data/admin/SubscriptionDataService';
import * as AccountDataService from '../../../../data/admin/AccountDataService';
import * as LicenseDataService from '../../../../data/admin/LicenseDataService';
import * as LoginDataService from '../../../../data/admin/LoginDataService';
import * as CompanyActions from '../admin/companyActions';
import * as SubscriptionActions from '../admin/subscriptionActions';
import * as AccountActions from '../admin/accountActions';
import * as LicenseActions from '../admin/licenseActions';
import * as LoginActions from '../admin/loginActions';
import * as StateActions from '../admin/stateActions';

//-------------------------
// APP ONBOARDING
// - runOnboarding
// - setNeeded
// - setRunning
// - setStep
// - setFinished
// - setOnboarding
// - setToInitial
//-------------------------

export const runOnboarding = aadUser => (dispatch, getState) => {
  const decoded = aadUser ? aadUser.idToken : {};
  const tenantId = decoded.tid;
  const userName = decoded.name;
  const userId = decoded.oid;
  const userCode = decoded.preferred_username;
  const loginService = decoded.iss;
  const isBusiness = !loginService.includes(
    '9188040d-6c67-4c5b-b112-36a304b66dad'
  );

  // Sync Company
  dispatch(setStep(1));
  const newCompany = {
    externalId: tenantId,
    name: userName
  };
  CompanyDataService.Sync(newCompany)
    .then(tenant => {
      dispatch(setStep(2));
      dispatch(CompanyActions.receiveEntity(tenant));

      // Sync Account
      const newAccount = {
        company: tenant._id,
        name: userName,
        externalId: userId,
        externalCode: userCode,
        isBusiness: isBusiness,
        status: 'active'
      };
      AccountDataService.Sync(newAccount)
        .then(account => {
          dispatch(setStep(4));
          dispatch(AccountActions.receiveEntity(account));

          // Sync Subscription
          const newSubscription = {
            company: tenant._id,
            subscriptionName: 'appsolut graph for Office 365',
            code: 'appGraph_O365',
            type: 'trial',
            externalId: '',
            externalName: '',
            offerId: '',
            planId: '',
            operationStatus: 'ready',
            status: 'valid'
          };
          SubscriptionDataService.Sync(newSubscription)
            .then(subscription => {
              dispatch(setStep(5));
              dispatch(
                SubscriptionActions.loadSubscriptionsByCompanyId(tenant._id)
              );

              // Sync License
              const newLicense = {
                account: account._id,
                subscription: subscription._id,
                externalId: '',
                externalSubscriptionId: '',
                externalSubscriptionName: '',
                operationStatus: 'ready',
                status: 'valid',
                service: loginService
              };
              LicenseDataService.Sync(newLicense)
                .then(license => {
                  dispatch(setStep(6));
                  dispatch(LicenseActions.loadLicensesByAccountId(account._id));

                  // Add a new Login data set
                  const newLogin = {
                    account: account._id,
                    name: userName,
                    code: userCode,
                    channel: 'aad',
                    service: loginService
                  };
                  LoginDataService.Add(newLogin)
                    .then(login => {
                      dispatch(setStep(8));
                      dispatch(LoginActions.loadLoginsByAccountId(account._id));

                      const updateAccount = {
                        onboardingFinished: true
                      };
                      AccountDataService.Update(account._id, updateAccount)
                        .then(account => {
                          dispatch(setFinished());
                          dispatch(AccountActions.loadAccountById(account._id));
                          dispatch(
                            StateActions.loadStatesByAccountId(account._id)
                          );
                        })
                        .catch(err => console.log(err));
                    })
                    .catch(err => console.log(err));
                })
                .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
};

export const setNeeded = (needed = true) => {
  return {
    type: Actions.ONBOARDING_NEEDED,
    payload: {
      needed: needed
    }
  };
};

export const setRunning = (step = 0) => {
  return {
    type: Actions.ONBOARDING_RUNNING,
    payload: {
      step: step
    }
  };
};

export const setStep = step => {
  return {
    type: Actions.ONBOARDING_STEP,
    payload: {
      step: step
    }
  };
};

export const setFinished = () => {
  return {
    type: Actions.ONBOARDING_FINISHED,
    payload: {}
  };
};

export const setOnboarding = data => {
  return {
    type: Actions.ONBOARDING_RECEIVE,
    payload: {
      needed: data ? data.needed : false,
      finished: data ? data.finished : false,
      step: data ? data.step : 0
    }
  };
};

export const setToInitial = () => {
  return {
    type: Actions.ONBOARDING_RECEIVE,
    payload: {
      needed: false,
      finished: false,
      step: 0
    }
  };
};

//-------------------------
// Helpers
//-------------------------
