import React from 'react';
import PropTypes from 'prop-types';
import { List, Button } from 'semantic-ui-react';
import ItemListItem from './ActivityFolder.ItemList.Item';

class ActivityFolderItemlistList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { auth, location, match, params } = this.props;
    const { dataEntities, dataMoreAvailable, dataMoreUrl } = this.props;

    return (
      <div>
        <List selection>
          {dataEntities.map((itemData, index) => (
            <ItemListItem
              key={itemData.entity ? itemData.entity.id : index.toString()}
              auth={auth}
              location={location}
              match={match}
              params={params}
              dataEntity={itemData.entity ? itemData.entity : {}}
              dataKey={itemData.entity ? itemData.entity.id : index.toString()}
              onEntityClick={this.props.onEntityClick}
            />
          ))}
        </List>
        {dataMoreAvailable && (
          <div className="app-itemlist-loadmore">
            <Button
              size="mini"
              onClick={e => this.props.onLoadMoreClick(e, dataMoreUrl)}
            >
              Load more ...
            </Button>
          </div>
        )}
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
ActivityFolderItemlistList.defaultProps = {
  dataMoreAvailable: false
};

ActivityFolderItemlistList.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntities: PropTypes.array.isRequired,
  dataMoreAvailable: PropTypes.bool,
  dataMoreUrl: PropTypes.string,
  onEntityClick: PropTypes.func.isRequired,
  onLoadMoreClick: PropTypes.func.isRequired
};

export default ActivityFolderItemlistList;
