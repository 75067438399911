import { combineReducers } from 'redux';
import pathReducer, * as pathSelectors from './pathfinder/pathReducer';
import stepsReducers, * as stepsSelectors from './pathfinder/stepsReducers';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  path: pathReducer,
  steps: stepsReducers
});

//-------------------------
// Selectors
//-------------------------

// Path
export const getCurrentStepPosition = state => {
  return pathSelectors.getCurrentStepPosition(state.path);
};
export const getPath = state => {
  return pathSelectors.getPath(state.path);
};

// Steps
export const getStepIndexByPosition = (state, position) => {
  return stepsSelectors.getStepIndexByPosition(state.steps, position);
};
export const getStepByIndex = (state, index) => {
  return stepsSelectors.getStepByIndex(state.steps, index);
};
export const getStepsByIndices = (state, indices) => {
  return stepsSelectors.getStepsByIndices(state.steps, indices);
};
export const getAllSteps = state => {
  return stepsSelectors.getAllSteps(state.steps);
};
export const getAllStepsCount = state => {
  return stepsSelectors.getAllStepsCount(state.steps);
};
export const getFilteredSteps = (state, filter) => {
  return stepsSelectors.getFilteredSteps(state.steps, filter);
};
