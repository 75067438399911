import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import * as Relations from '../../../app/config/entities/Relations';
import * as ObjectConfig from '../../../app/config/entities/Objects';

class RelationLookup extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { fieldName, placeholder, isLoading } = this.props;
    const { isDisabled, searchMode, fluidMode, hasError } = this.props;
    const { selectedValue, entityType, excluded } = this.props;

    let relations = [];
    // Load Relations of Segments
    if (entityType === ObjectConfig.DRIVE.code) {
      relations = Relations.Drive;
    } else if (entityType === ObjectConfig.GROUP.code) {
      relations = Relations.Group;
    } else if (entityType === ObjectConfig.ME.code) {
      relations = Relations.Me;
    } else if (entityType === ObjectConfig.ORGANIZATION.code) {
      relations = Relations.Organisation;
    } else if (entityType === ObjectConfig.PLAN.code) {
      relations = Relations.Plan;
    } else if (entityType === ObjectConfig.SITE.code) {
      relations = Relations.Site;
    } else if (entityType === ObjectConfig.USER.code) {
      relations = Relations.User;
    }
    // Load Relations of Entities
    if (entityType === ObjectConfig.ACTIVITY.code) {
      relations = Relations.Activity;
    } else if (entityType === ObjectConfig.ACTIVITYFOLDER.code) {
      relations = Relations.ActivityFolder;
    } else if (entityType === ObjectConfig.ACTIVITYGROUP.code) {
      relations = Relations.ActivityGroup;
    } else if (entityType === ObjectConfig.ADMINISTRATIVEUNIT.code) {
      relations = Relations.AdministrativeUnit;
    } else if (entityType === ObjectConfig.AGREEMENT.code) {
      relations = Relations.Agreement;
    } else if (entityType === ObjectConfig.AGREEMENTACCEPTANCE.code) {
      relations = Relations.AgreementAcceptance;
    } else if (entityType === ObjectConfig.APPLICATION.code) {
      relations = Relations.Application;
    } else if (entityType === ObjectConfig.ATTACHMENT.code) {
      relations = Relations.Attachment;
    } else if (entityType === ObjectConfig.BOOKINGAPPOINTMENT.code) {
      relations = Relations.BookingAppointment;
    } else if (entityType === ObjectConfig.BOOKINGBUSINESS.code) {
      relations = Relations.BookingBusiness;
    } else if (entityType === ObjectConfig.BOOKINGCUSTOMER.code) {
      relations = Relations.BookingCustomer;
    } else if (entityType === ObjectConfig.BOOKINGSERVICE.code) {
      relations = Relations.BookingService;
    } else if (entityType === ObjectConfig.BOOKINGSTAFFMEMBER.code) {
      relations = Relations.BookingStaffMember;
    } else if (entityType === ObjectConfig.BUCKET.code) {
      relations = Relations.Bucket;
    } else if (entityType === ObjectConfig.CALENDAR.code) {
      relations = Relations.Calendar;
    } else if (entityType === ObjectConfig.CALENDARGROUP.code) {
      relations = Relations.CalendarGroup;
    } else if (entityType === ObjectConfig.CHANNEL.code) {
      relations = Relations.Channel;
    } else if (entityType === ObjectConfig.CHATMESSAGE.code) {
      relations = Relations.ChatMessage;
    } else if (entityType === ObjectConfig.CHATTHREAD.code) {
      relations = Relations.ChatThread;
    } else if (entityType === ObjectConfig.CONTACT.code) {
      relations = Relations.Contact;
    } else if (entityType === ObjectConfig.CONTACTFOLDER.code) {
      relations = Relations.ContactFolder;
    } else if (entityType === ObjectConfig.CONTRACT.code) {
      relations = Relations.Contract;
    } else if (entityType === ObjectConfig.CONVERSATION.code) {
      relations = Relations.Conversation;
    } else if (entityType === ObjectConfig.DEVICE.code) {
      relations = Relations.Device;
    } else if (entityType === ObjectConfig.DIRECTORYROLE.code) {
      relations = Relations.DirectoryRole;
    } else if (entityType === ObjectConfig.DOMAIN.code) {
      relations = Relations.Domain;
    } else if (entityType === ObjectConfig.DRIVEITEM.code) {
      relations = Relations.DriveItem;
    } else if (entityType === ObjectConfig.EVENT.code) {
      relations = Relations.Event;
    } else if (entityType === ObjectConfig.IDENTITYPROVIDER.code) {
      relations = Relations.IdentityProvider;
    } else if (entityType === ObjectConfig.INSIGHT.code) {
      relations = Relations.Insight;
    } else if (entityType === ObjectConfig.LICENSE.code) {
      relations = Relations.License;
    } else if (entityType === ObjectConfig.LIST.code) {
      relations = Relations.List;
    } else if (entityType === ObjectConfig.LISTITEM.code) {
      relations = Relations.ListItem;
    } else if (entityType === ObjectConfig.MAILFOLDER.code) {
      relations = Relations.Mailfolder;
    } else if (entityType === ObjectConfig.MESSAGE.code) {
      relations = Relations.Message;
    } else if (entityType === ObjectConfig.NOTEBOOK.code) {
      relations = Relations.Notebook;
    } else if (entityType === ObjectConfig.ORGCONTACT.code) {
      relations = Relations.OrgContact;
    } else if (entityType === ObjectConfig.PAGE.code) {
      relations = Relations.Page;
    } else if (entityType === ObjectConfig.PERSON.code) {
      relations = Relations.Person;
    } else if (entityType === ObjectConfig.POST.code) {
      relations = Relations.Post;
    } else if (entityType === ObjectConfig.RISKEVENT.code) {
      relations = Relations.RiskEvent;
    } else if (entityType === ObjectConfig.SECURITYALERT.code) {
      relations = Relations.SecurityAlert;
    } else if (entityType === ObjectConfig.SERVICEPLAN.code) {
      relations = Relations.ServicePlan;
    } else if (entityType === ObjectConfig.SERVICEPRINCIPAL.code) {
      relations = Relations.ServicePrincipal;
    } else if (entityType === ObjectConfig.TASK.code) {
      relations = Relations.Task;
    } else if (entityType === ObjectConfig.TEAM.code) {
      relations = Relations.Team;
    } else if (entityType === ObjectConfig.TEAMSAPPINSTALLATION.code) {
      relations = Relations.TeamsAppInstallation;
    } else if (entityType === ObjectConfig.TEAMSTAB.code) {
      relations = Relations.TeamsTab;
    } else if (entityType === ObjectConfig.THREAD.code) {
      relations = Relations.Thread;
    } else if (entityType === ObjectConfig.VERSION.code) {
      relations = Relations.Version;
    }

    let nodeLookup = [];
    if (relations && relations.length > 0) {
      for (let i = 0; i < relations.length; i++) {
        const dataElement = relations[i];
        if (!excluded || !(excluded === dataElement.key)) {
          let dataItem = {};
          dataItem.key = dataElement.key;
          dataItem.value = dataElement.key;
          dataItem.text = dataElement.value;
          nodeLookup.push(dataItem);
        }
      }
    }

    return (
      <Dropdown
        selection
        deburr
        name={fieldName}
        placeholder={placeholder}
        loading={isLoading}
        disabled={isDisabled}
        search={searchMode}
        fluid={fluidMode}
        options={nodeLookup}
        error={hasError}
        value={selectedValue}
        onChange={this.props.onSelectionChange}
      />
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
RelationLookup.defaultProps = {
  isLoading: false,
  isDisabled: false,
  searchMode: false,
  fluidMode: false,
  hasError: false
};

RelationLookup.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  excluded: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  searchMode: PropTypes.bool,
  fluidMode: PropTypes.bool,
  hasError: PropTypes.bool,
  onSelectionChange: PropTypes.func.isRequired
};

export default RelationLookup;
