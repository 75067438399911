import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Header, Icon, Button } from 'semantic-ui-react';

const Placeholder = props => {
  //-----------------------------------
  // Component rendering
  //-----------------------------------
  return (
    <div className="app-placeholder">
      <Header as="h2" icon textAlign="center">
        <Icon name={props.icon} circular />
        <Header.Content>
          {props.header}
          {props.subHeader && (
            <Header.Subheader>{props.subHeader}</Header.Subheader>
          )}
        </Header.Content>
      </Header>
      {props.description && <div>{props.description}</div>}
      {props.showButton && (
        <div className="app-placeholder-button">
          <Button primary as={Link} to={props.buttonPath}>
            {props.buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};
export default Placeholder;

//-----------------------------------
// Component interface
//-----------------------------------
Placeholder.defaultProps = {
  icon: 'users',
  header: 'Placeholder',
  showButton: false,
  buttonText: 'Button'
};

Placeholder.propTypes = {
  icon: PropTypes.string,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  description: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonPath: PropTypes.string
};
