import * as Actions from '../../actions/types';

const initialState = {
  users: [],
  moreAvailable: false,
  moreUrl: '',
  isFetching: false,
  didInvalidate: false
};

//-------------------------
// Helpers
//-------------------------

function setInvalidate(state) {
  return {
    ...state,
    didInvalidate: true
  };
}

function setFetching(state) {
  return {
    ...state,
    isFetching: true,
    didInvalidate: false
  };
}

function setDirectReports(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds) {
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;

    return {
      ...state,
      users: entityIds,
      moreAvailable: moreAvailable,
      moreUrl: moreUrl,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    users: [],
    moreAvailable: false,
    moreUrl: '',
    isFetching: false,
    didInvalidate: false
  };
}

function appendDirectReports(state, action) {
  const { payload } = action;
  if (payload && payload.entityIds) {
    const { entityIds, moreAvailable, moreUrl, receivedAt } = payload;
    const newUsers =
      state && state.users ? state.users.concat(entityIds) : entityIds;
    return {
      ...state,
      users: newUsers,
      moreAvailable: moreAvailable,
      moreUrl: moreUrl,
      isFetching: false,
      didInvalidate: false,
      lastUpdated: receivedAt
    };
  }
  return {
    ...state,
    moreAvailable: false,
    moreUrl: '',
    isFetching: false,
    didInvalidate: false
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.DIRECTREPORTS_INVALIDATE:
      return setInvalidate(state);
    case Actions.DIRECTREPORTS_REQUEST:
      return setFetching(state);
    case Actions.DIRECTREPORTS_RECEIVE:
      return setDirectReports(state, action);
    case Actions.DIRECTREPORTS_APPEND:
      return appendDirectReports(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getMyDirectReports = state => {
  return state;
};
