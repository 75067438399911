import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

export default function DataLoader(props) {
  //-----------------------------------
  // Component rendering
  //-----------------------------------
  return (
    <div className="app-loader">
      <Loader
        active
        inline="centered"
        size={props.size}
        content={props.content}
      />
    </div>
  );
}

//-----------------------------------
// Component interface
//-----------------------------------
DataLoader.defaultProps = {
  size: 'huge',
  content: 'Loading'
};

DataLoader.propTypes = {
  size: PropTypes.string,
  content: PropTypes.string
};
