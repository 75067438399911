import axios from 'axios';
import * as AdminAuthService from '../../common/auth/AdminAuthService';

//-------------------------
// ADMIN STATE Data Service
// - GetAll
// - GetByAccount
// - GetById
// - Add
// - Update
// - Delete
//-------------------------

export const GetAll = () => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/states`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetByAccount = accountId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/states/account/${accountId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const GetById = stateId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .get(`/api/states/${stateId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Add = payload => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .post(`/api/states`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Update = (stateId, payload) => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .put(`/api/states/${stateId}`, payload)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};

export const Delete = stateId => {
  return new Promise(function(resolve, reject) {
    AdminAuthService.GetAccessToken().then(accessToken => {
      axios.defaults.headers.common['Authorization'] = accessToken;
      axios
        .delete(`/api/states/${stateId}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err));
    });
  });
};
