import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header } from 'semantic-ui-react';
import { Video, Transformation } from 'cloudinary-react';

class GraphCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      videoSize: 'middle'
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  //-----------------------------------
  // Event Handler
  //-----------------------------------
  updateDimensions = () => {
    const previousSize = this.state.videoSize;
    this.actualWidth = window.innerWidth;
    const newDeviceSize = this.getDeviceSize(this.actualWidth);
    const newVideoSize = this.getVideoSize(newDeviceSize);
    if (newVideoSize !== previousSize) {
      this.setState({ videoSize: newVideoSize });
    }
  };

  //-----------------------------------
  // Functions & Helpers
  //-----------------------------------
  getDeviceSize = width => {
    if (width < 768) return 'mobile';
    if (width < 992) return 'tablett';
    if (width < 1201) return 'desktop';
    return 'widescreen';
  };
  getVideoSize = deviceSize => {
    if (deviceSize === 'mobile') return 'small';
    if (deviceSize === 'tablett') return 'middle';
    if (deviceSize === 'desktop') return 'large';
    if (deviceSize === 'widescreen') return 'large';
    return 'middle';
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { videoSize } = this.state;
    const transformation = ['video-' + videoSize];

    const headerText = 'Coming: THE VISUAL GRAPH';
    const bodyText_Line1 =
      'The complete Navigation Path presented as visual Graph - with no additional effort!';
    const bodyText_Line2 =
      'The next generation of appsolut.graph is on the way.';

    return (
      <div>
        <Segment basic>
          <div className="app-video">
            <Video
              cloudName="appsolut"
              publicId="website-home/template/social-network-852x480"
              loop
              autoPlay
              muted
            >
              <Transformation transformation={transformation} />
            </Video>
          </div>
          <Header as="h2" icon textAlign="center">
            <Header.Content>
              {headerText}
              <div className="app-notify-subheader-wrapper">
                <Header.Subheader className="app-notify-subheader">
                  {bodyText_Line1}
                </Header.Subheader>
                <Header.Subheader className="app-notify-subheader">
                  {bodyText_Line2}
                </Header.Subheader>
              </div>
            </Header.Content>
          </Header>
        </Segment>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
GraphCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default GraphCanvas;
