import { combineReducers } from 'redux';
import byIdReducer, * as byIdSelectors from './users/byIdReducer';
import byIdExtensionReducer, * as byIdExtSelectors from './users/byIdExtensionReducer';
import allIdsReducer from './users/allIdsReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  byId: byIdReducer,
  byIdExtension: byIdExtensionReducer,
  allIds: allIdsReducer
});

//-------------------------
// Selectors
//-------------------------

export const getEntityById = (state, id) => {
  const entity = byIdSelectors.getEntityById(state.byId, id);
  const entityExt = byIdExtSelectors.getExtensionById(state.byIdExtension, id);
  if (entity && entityExt && entityExt.entity) {
    entity.entity = { ...entityExt.entity, ...entity.entity };
  }
  return entity;
};
export const getEntitiesByIds = (state, ids = []) => {
  return ids.map(id => getEntityById(state, id));
};
export const getAllEntities = state => {
  return state.allIds.map(id => getEntityById(state, id));
};
export const getFilteredEntities = (state, filter = 'all') => {
  const allEntities = state.allIds.map(id => getEntityById(state, id));
  switch (filter) {
    case 'all':
      return allEntities;
    default:
      throw new Error(`Unknown filter: ${filter}`);
  }
};
