import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import './Footer.css';

class Footer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    return (
      <footer className="app-footer">
        <Menu fixed="bottom" className="app-footer-navigation">
          <Menu.Item>
            <a
              href="https://www.appsolut-graph.com/docs"
              target="_blank"
              rel="noopener noreferrer"
            >
              About
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              href="https://www.appsolut-graph.com/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              href="https://www.appsolut-graph.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <a
                href="https://www.appsolut-graph.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                &copy; {new Date().getFullYear()} appsolut
              </a>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </footer>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
Footer.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Footer;
