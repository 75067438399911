import moment from 'moment';
import momentTZ from 'moment-timezone';
import * as Validation from './Validation';

export const formatMsGraphDateTime = (
  dateTime,
  format = 'D. MMM YYYY H:mm'
) => {
  // Helper function to format Graph date/time
  if (Validation.isValidDate(dateTime)) {
    return moment
      .utc(dateTime)
      .local()
      .format(format);
  } else {
    return '';
  }
};

export const formatMsGraphDateTimeWithTimeZone = (
  dateTime,
  timeZone,
  format = 'D. MMM YYYY H:mm'
) => {
  // Helper function to format Graph date/time
  if (Validation.isValidDate(dateTime)) {
    return momentTZ
      .tz(dateTime, timeZone)
      .local()
      .format(format);
  } else {
    return '';
  }
};
