import * as Actions from '../../actions/types';

const initialState = {
  currentStepPosition: -1
};

//-------------------------
// Helpers
//-------------------------

function clearPath() {
  return initialState;
}

function setCurrentStepPosition(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      currentStepPosition: payload.currentStepPosition
    };
  }
  return { ...state };
}

function setCurrentStepPositionFromStep(state, action) {
  const { payload } = action;
  if (payload) {
    return {
      ...state
    };
  }
  return { ...state };
}

function setPath(state, action) {
  const { payload } = action;
  if (payload) {
    const { currentStepPosition } = payload;
    return {
      ...state,
      currentStepPosition: currentStepPosition
    };
  }
  return {
    ...state,
    currentStepPosition: -1
  };
}

//-------------------------
// Reducer
//-------------------------

export default function(state = initialState, action) {
  switch (action.type) {
    case Actions.PATHFINDER_CLEAR:
      return clearPath();
    case Actions.PATHFINDER_PATH_STEPCURRENT:
      return setCurrentStepPosition(state, action);
    case Actions.PATHFINDER_PATH_RECEIVE:
      return setPath(state, action);
    case Actions.PATHFINDERS_STEP_RECEIVE:
      return setCurrentStepPositionFromStep(state, action);
    default:
      return state;
  }
}

//-------------------------
// Selectors
//-------------------------

export const getPath = state => {
  return state;
};
export const getCurrentStepPosition = state => {
  return state.currentStepPosition;
};
