import { UserAgentApplication } from 'msal';

export default class AuthService {
  constructor(clientId, authority, tokenReceivedCallBack, configOptions) {
    // authority: pass null for default authority (https://login.microsoftonline.com/common)
    // storeAuthStateInCookie: introduced in MSAL.js v0.2.2 as a fix for the authentication loop issues on IE and Edge
    // cacheLocation: sets browser storage to either 'localStorage' or sessionStorage'
    // More info: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/MSAL-basics
    this.msal = new UserAgentApplication(
      clientId,
      authority,
      tokenReceivedCallBack,
      configOptions
    );

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
  }

  get authenticated() {
    return this.msal.getUser() ? true : false;
  }

  login(scopes, extraQueryParameters) {
    this.msal.loginRedirect(scopes, extraQueryParameters);
  }

  logout() {
    this.msal.logout();
  }

  getUser() {
    return this.msal.getUser();
  }

  getAccessToken(scopes) {
    const auth = this.msal;
    return new Promise(function(resolve, reject) {
      if (!auth.getUser() || auth.isCallback(window.location.hash)) {
        auth.loginRedirect(scopes);
      }
      auth
        .acquireTokenSilent(scopes)
        .then(accessToken => resolve(accessToken))
        .catch(err => {
          if (
            err &&
            (err.indexOf('consent_required') !== -1 ||
              err.indexOf('interaction_required') !== -1 ||
              err.indexOf('login_required') !== -1)
          ) {
            auth.acquireTokenRedirect(scopes);
          } else {
            reject('Error acquiring token: ' + err);
          }
        });
    });
  }

  // acquireToken(scopes) {
  //   return this.msal.acquireTokenRedirect(scopes);
  // }

  // acquireTokenSilent(scopes) {
  //   const auth = this.msal;
  //   return new Promise(function(resolve, reject) {
  //     auth
  //       .acquireTokenSilent(scopes)
  //       .then(token => resolve(token))
  //       .catch(err => reject(err));
  //   });
  // }

  // testPromiseMethod(scopes) {
  //   return new Promise(function(resolve, reject) {
  //     if (scopes) {
  //       setTimeout(() => resolve("scope is TRUE"), 4000);
  //     } else {
  //       setTimeout(() => reject("scope is FALSE"), 4000);
  //     }
  //   });
  // }
}
