import React from 'react';
import PropTypes from 'prop-types';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';

class BookingServiceCardBody extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity } = this.props;

    // Body
    const bodyString = dataEntity.description;
    const bodyText = StringUtil.HtmlLinebreaks(bodyString);

    return (
      <div className="app-itemcard">
        <div
          className="app-itemcard-description"
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
BookingServiceCardBody.defaultProps = {
  mode: Enums.FORM_MODE.view
};

BookingServiceCardBody.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  mode: PropTypes.string
};

export default BookingServiceCardBody;
