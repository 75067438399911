import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, GridColumn } from 'semantic-ui-react';
import { List, Icon } from 'semantic-ui-react';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as MenuConfig from '../../../app/config/constants/Menu';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as FieldUtil from '../../../common/utils/FieldUtils';
import * as DateUtil from '../../../common/utils/DateUtils';
import * as Validation from '../../../common/utils/Validation';

class MessageCardForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataLoading, smallContainer, mode } = this.props;

    // Device size
    let gridWidth = 16;
    let lengthColumn = 100;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      gridWidth = 16;
      lengthColumn = 25;
    } else if (smallContainer) {
      gridWidth = 16;
      lengthColumn = 40;
    }

    // Field From
    const fromAddress = dataEntity.from ? dataEntity.from.emailAddress : {};
    const fromAddressEmail = FieldUtil.GetEmailFromEmailAddress(fromAddress);
    const fromAddressString = FieldUtil.GetStringFromEmailAddress(fromAddress);

    // Field Settings
    const hasSettings =
      dataEntity.importance !== 'normal' ||
      FieldUtil.GetStringFromFollowupFlag(dataEntity.flag) !== 'notFlagged' ||
      dataEntity.hasAttachments ||
      dataEntity.isDraft ||
      dataEntity.isDeliveryReceiptRequested ||
      dataEntity.isReadReceiptRequested;

    // Field Links
    const linksWeb = !Validation.isEmpty(dataEntity.webLink)
      ? dataEntity.webLink
      : '';

    // Field System
    const systemId = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id)
        ? 'ID: ' + dataEntity.id
        : dataEntity.id,
      lengthColumn,
      true
    );
    const systemDate = Validation.isValidDate(dataEntity.receivedDateTime)
      ? 'Created: ' +
        DateUtil.formatMsGraphDateTime(dataEntity.receivedDateTime)
      : '';

    return (
      <Form loading={dataLoading}>
        <Grid>
          <GridColumn width={gridWidth}>
            <Form.Field fluid="true">
              <label>Subject</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(dataEntity.subject)}</div>
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>From</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {!Validation.isEmpty(fromAddressEmail) ? (
                    <div className="app-itemcard-email">
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                        className="app-listitem-icon"
                      />
                      <a href={'mailto:' + fromAddressEmail}>
                        {fromAddressString}
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>To</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.toRecipients) ? (
                    <List>
                      {dataEntity.toRecipients.map((recipient, index) => (
                        <List.Item key={index}>
                          <div className="app-itemcard-email">
                            <Icon
                              name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                              className="app-listitem-icon"
                            />
                            <a
                              href={
                                'mailto:' +
                                FieldUtil.GetEmailFromEmailAddress(
                                  recipient.emailAddress
                                )
                              }
                            >
                              {FieldUtil.GetStringFromEmailAddress(
                                recipient.emailAddress
                              )}
                            </a>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>CC</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.ccRecipients) ? (
                    <List>
                      {dataEntity.ccRecipients.map((recipient, index) => (
                        <List.Item key={index}>
                          <div className="app-itemcard-email">
                            <Icon
                              name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                              className="app-listitem-icon"
                            />
                            <a
                              href={
                                'mailto:' +
                                FieldUtil.GetEmailFromEmailAddress(
                                  recipient.emailAddress
                                )
                              }
                            >
                              {FieldUtil.GetStringFromEmailAddress(
                                recipient.emailAddress
                              )}
                            </a>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>BCC</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.bccRecipients) ? (
                    <List>
                      {dataEntity.bccRecipients.map((recipient, index) => (
                        <List.Item key={index}>
                          <div className="app-itemcard-email">
                            <Icon
                              name={MenuConfig.ICONS_MENU_DEFAULTS.mail}
                              className="app-listitem-icon"
                            />
                            <a
                              href={
                                'mailto:' +
                                FieldUtil.GetEmailFromEmailAddress(
                                  recipient.emailAddress
                                )
                              }
                            >
                              {FieldUtil.GetStringFromEmailAddress(
                                recipient.emailAddress
                              )}
                            </a>
                          </div>
                        </List.Item>
                      ))}
                    </List>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Settings</label>
              {mode === Enums.FORM_MODE.view && (
                <div>
                  {hasSettings ? (
                    <div>
                      {dataEntity.importance === 'high' && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.priorityhigh}
                            color="red"
                            className="app-listitem-icon"
                          />
                          High Priority
                        </div>
                      )}
                      {dataEntity.importance === 'low' && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.prioritylow}
                            color="blue"
                            className="app-listitem-icon"
                          />
                          Low Priority
                        </div>
                      )}
                      {FieldUtil.GetStringFromFollowupFlag(dataEntity.flag) ===
                        'flagged' && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.flag}
                            color="red"
                            className="app-listitem-icon"
                          />
                          is flagged
                        </div>
                      )}
                      {FieldUtil.GetStringFromFollowupFlag(dataEntity.flag) ===
                        'complete' && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.flag}
                            color="blue"
                            className="app-listitem-icon"
                          />
                          is completed
                        </div>
                      )}
                      {dataEntity.hasAttachments && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.attachment}
                            className="app-listitem-icon"
                          />
                          has Attachments
                        </div>
                      )}
                      {dataEntity.isDraft && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.edit}
                            className="app-listitem-icon"
                          />
                          is Draft
                        </div>
                      )}
                      {dataEntity.isDeliveryReceiptRequested && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.checked}
                            className="app-listitem-icon"
                          />
                          Delivery Receipt requested
                        </div>
                      )}
                      {dataEntity.isReadReceiptRequested && (
                        <div className="app-itemcard-info">
                          <Icon
                            name={MenuConfig.ICONS_MENU_DEFAULTS.checked}
                            className="app-listitem-icon"
                          />
                          Read Receipt requested
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="app-itemcard-info">
                      {StringUtil.NoEmptyString('')}
                    </div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Categories</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  {!Validation.isEmpty(dataEntity.categories) ? (
                    <List items={dataEntity.categories} />
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>Links</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-website">
                  {linksWeb ? (
                    <div>
                      <Icon
                        name={MenuConfig.ICONS_MENU_DEFAULTS.website}
                        className="app-listitem-icon"
                      />
                      <a
                        href={linksWeb}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Weblink
                      </a>
                    </div>
                  ) : (
                    <div>{StringUtil.NoEmptyString('')}</div>
                  )}
                </div>
              )}
            </Form.Field>
            <Form.Field fluid="true">
              <label>System</label>
              {mode === Enums.FORM_MODE.view && (
                <div className="app-itemcard-info">
                  <div>{StringUtil.NoEmptyString(systemId)}</div>
                  {systemDate && <div>{systemDate}</div>}
                </div>
              )}
            </Form.Field>
          </GridColumn>
        </Grid>
      </Form>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
MessageCardForm.defaultProps = {
  dataLoading: false,
  smallContainer: true,
  mode: Enums.FORM_MODE.view
};

MessageCardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataLoading: PropTypes.bool,
  smallContainer: PropTypes.bool,
  mode: PropTypes.string
};

export default MessageCardForm;
