// Segments

export const DRIVE = {
  code: 'drive',
  apicode: '#microsoft.graph.drive',
  handle: 'drives',
  urlextension: '',
  name: 'Drive',
  namePlural: 'Drives',
  icon: ''
};

export const GROUP = {
  code: 'group',
  apicode: '#microsoft.graph.group',
  handle: 'groups',
  urlextension: '',
  name: 'Group',
  namePlural: 'Groups',
  icon: ''
};

export const ME = {
  code: 'me',
  apicode: '#microsoft.graph.user',
  handle: 'me',
  urlextension: '',
  name: 'Me',
  namePlural: 'Me',
  icon: ''
};

export const ORGANIZATION = {
  code: 'organization',
  apicode: '#microsoft.graph.organization',
  handle: 'organization',
  urlextension: '',
  name: 'Organization',
  namePlural: 'Organizations',
  icon: ''
};

export const PLAN = {
  code: 'plan',
  apicode: '#microsoft.graph.plan',
  handle: 'plans',
  urlextension: '',
  name: 'Plan',
  namePlural: 'Plans',
  icon: ''
};

export const SITE = {
  code: 'site',
  apicode: '#microsoft.graph.site',
  handle: 'sites',
  urlextension: '',
  name: 'Site',
  namePlural: 'Sites',
  icon: ''
};

export const USER = {
  code: 'user',
  apicode: '#microsoft.graph.user',
  handle: 'users',
  urlextension: '',
  name: 'User',
  namePlural: 'Users',
  icon: ''
};

// Entities

export const ACTIVITY = {
  code: 'activity',
  apicode: '#microsoft.graph.activity',
  handle: 'tasks',
  urlextension: '',
  name: 'Activity',
  namePlural: 'Activities',
  icon: ''
};

export const ACTIVITYFOLDER = {
  code: 'activityfolder',
  apicode: '#microsoft.graph.activityfolder',
  handle: 'taskFolders',
  urlextension: '',
  name: 'Activity Folder',
  namePlural: 'Activity Folders',
  icon: ''
};

export const ACTIVITYGROUP = {
  code: 'activitygroup',
  apicode: '#microsoft.graph.activityGroup',
  handle: 'taskGroups',
  urlextension: '',
  name: 'Activity Group',
  namePlural: 'Activity Groups',
  icon: ''
};

export const ADMINISTRATIVEUNIT = {
  code: 'administrative unit',
  apicode: '#microsoft.graph.administrativeUnit',
  handle: '',
  urlextension: '',
  name: 'Administrative Unit',
  namePlural: 'Administrative Units',
  icon: ''
};

export const AGREEMENT = {
  code: 'agreement',
  apicode: '#microsoft.graph.agreement',
  handle: 'agreements',
  urlextension: '',
  name: 'Agreement',
  namePlural: 'Agreements',
  icon: ''
};

export const AGREEMENTACCEPTANCE = {
  code: 'agreementacceptance',
  apicode: '#microsoft.graph.agreementAcceptance',
  handle: 'agreementAcceptances',
  urlextension: '',
  name: 'Agreement Acceptance',
  namePlural: 'Agreement Acceptances',
  icon: ''
};

export const APPLICATION = {
  code: 'application',
  apicode: '#microsoft.graph.application',
  handle: 'applications',
  urlextension: '',
  name: 'Application',
  namePlural: 'Applications',
  icon: ''
};

export const ATTACHMENT = {
  code: 'attachment',
  apicode: [
    '#microsoft.graph.fileAttachment',
    '#microsoft.graph.itemAttachment',
    '#microsoft.graph.referenceAttachment'
  ],
  handle: 'attachments',
  urlextension: '',
  name: 'Attachment',
  namePlural: 'Attachments',
  icon: ''
};

export const BOOKINGAPPOINTMENT = {
  code: 'bookingappointment',
  apicode: '#microsoft.graph.bookingAppointment',
  handle: 'appointments',
  urlextension: '',
  name: 'Booking Appointment',
  namePlural: 'Booking Appointments',
  icon: ''
};

export const BOOKINGBUSINESS = {
  code: 'bookingbusiness',
  apicode: '#microsoft.graph.bookingBusiness',
  handle: 'bookingBusinesses',
  urlextension: '',
  name: 'Booking Business',
  namePlural: 'Booking Businesses',
  icon: ''
};

export const BOOKINGCUSTOMER = {
  code: 'bookingcustomer',
  apicode: '#microsoft.graph.bookingCustomer',
  handle: 'customers',
  urlextension: '',
  name: 'Booking Customer',
  namePlural: 'Booking Customers',
  icon: ''
};

export const BOOKINGSERVICE = {
  code: 'bookingservice',
  apicode: '#microsoft.graph.bookingService',
  handle: 'services',
  urlextension: '',
  name: 'Booking Service',
  namePlural: 'Booking Services',
  icon: ''
};

export const BOOKINGSTAFFMEMBER = {
  code: 'bookingstaffmember',
  apicode: '#microsoft.graph.bookingStaffMember',
  handle: 'staffMembers',
  urlextension: '',
  name: 'Booking Staff Member',
  namePlural: 'Booking Staff Members',
  icon: ''
};

export const BUCKET = {
  code: 'bucket',
  apicode: '#microsoft.graph.bucket',
  handle: 'buckets',
  urlextension: '',
  name: 'Bucket',
  namePlural: 'Buckets',
  icon: ''
};

export const CALENDAR = {
  code: 'calendar',
  apicode: '#microsoft.graph.calendar',
  handle: 'calendars',
  urlextension: '',
  name: 'Calendar',
  namePlural: 'Calendars',
  icon: ''
};

export const CALENDARGROUP = {
  code: 'calendargroup',
  apicode: '#microsoft.graph.calendarGroup',
  handle: 'calendarGroups',
  urlextension: '',
  name: 'Calendar Group',
  namePlural: 'Calendar Groups',
  icon: ''
};

export const CHANNEL = {
  code: 'channel',
  apicode: '#microsoft.graph.channel',
  handle: 'channels',
  urlextension: '',
  name: 'Channel',
  namePlural: 'Channels',
  icon: ''
};

export const CHATMESSAGE = {
  code: 'chatmessage',
  apicode: '#microsoft.graph.chatMessage',
  handle: 'messages',
  urlextension: '',
  name: 'Chat Message',
  namePlural: 'Chat Messages',
  icon: ''
};

export const CHATTHREAD = {
  code: 'chatthread',
  apicode: '#microsoft.graph.chatThread',
  handle: 'chatThreads',
  urlextension: '',
  name: 'Chat Thread',
  namePlural: 'Chat Threads',
  icon: ''
};

export const CONTACT = {
  code: 'contact',
  apicode: '#microsoft.graph.contact',
  handle: 'contacts',
  urlextension: '',
  name: 'Contact',
  namePlural: 'Contacts',
  icon: ''
};

export const CONTACTFOLDER = {
  code: 'contactfolder',
  apicode: '#microsoft.graph.contactFolder',
  handle: 'contactFolders',
  urlextension: '',
  name: 'Contact Folder',
  namePlural: 'Contact Folders',
  icon: ''
};

export const CONTRACT = {
  code: 'contract',
  apicode: '#microsoft.graph.contract',
  handle: 'contracts',
  urlextension: '',
  name: 'Contract',
  namePlural: 'Contracts',
  icon: ''
};

export const CONVERSATION = {
  code: 'conversation',
  apicode: '#microsoft.graph.conversation',
  handle: 'conversations',
  urlextension: '',
  name: 'Conversation',
  namePlural: 'Conversations',
  icon: ''
};

export const DEVICE = {
  code: 'device',
  apicode: '#microsoft.graph.device',
  handle: 'devices',
  urlextension: '',
  name: 'Device',
  namePlural: 'Devices',
  icon: ''
};

export const DIRECTORYROLE = {
  code: 'directoryrole',
  apicode: '#microsoft.graph.directoryRole',
  handle: 'directoryRoles',
  urlextension: '',
  name: 'Directory Role',
  namePlural: 'Directory Roles',
  icon: ''
};

export const DOMAIN = {
  code: 'domain',
  apicode: '#microsoft.graph.domain',
  handle: 'domains',
  urlextension: '',
  name: 'Domain',
  namePlural: 'Domains',
  icon: ''
};

export const DRIVEITEM = {
  code: 'driveitem',
  apicode: '#microsoft.graph.driveItem',
  handle: 'items',
  urlextension: '',
  name: 'Drive Item',
  namePlural: 'Drive Items',
  icon: ''
};

export const EVENT = {
  code: 'event',
  apicode: '#microsoft.graph.event',
  handle: 'events',
  urlextension: '',
  name: 'Event',
  namePlural: 'Events',
  icon: ''
};

export const IDENTITYPROVIDER = {
  code: 'identityprovider',
  apicode: '#microsoft.graph.identityProvider',
  handle: 'identityProviders',
  urlextension: '',
  name: 'Identity Provider',
  namePlural: 'Identity Providers',
  icon: ''
};

export const INSIGHT = {
  code: 'insight',
  apicode: '#microsoft.graph.insight',
  handle: '',
  urlextension: '',
  name: 'Insight',
  namePlural: 'Insights',
  icon: ''
};

export const LICENSE = {
  code: 'license',
  apicode: '#microsoft.graph.license',
  handle: 'licenseDetails',
  urlextension: '',
  name: 'License',
  namePlural: 'Licenses',
  icon: ''
};

export const LIST = {
  code: 'list',
  apicode: '#microsoft.graph.list',
  handle: 'lists',
  urlextension: '',
  name: 'List',
  namePlural: 'Lists',
  icon: ''
};

export const LISTITEM = {
  code: 'listitem',
  apicode: '#microsoft.graph.listItem',
  handle: 'items',
  urlextension: '',
  name: 'ListItem',
  namePlural: 'List Items',
  icon: ''
};

export const MAILFOLDER = {
  code: 'mailfolder',
  apicode: '#microsoft.graph.mailFolder',
  handle: 'mailFolders',
  urlextension: '',
  name: 'Mail Folder',
  namePlural: 'Mail Folders',
  icon: ''
};

export const MESSAGE = {
  code: 'message',
  apicode: '#microsoft.graph.message',
  handle: 'messages',
  urlextension: '',
  name: 'Message',
  namePlural: 'Messages',
  icon: ''
};

export const NOTEBOOK = {
  code: 'notebook',
  apicode: '#microsoft.graph.notebook',
  handle: 'notebooks',
  urlextension: '',
  name: 'Notebook',
  namePlural: 'Notebooks',
  icon: ''
};

export const ORGCONTACT = {
  code: 'orgcontact',
  apicode: '#microsoft.graph.orgContact',
  handle: 'contacts',
  urlextension: '',
  name: 'Organization Contact',
  namePlural: 'Organization Contacts',
  icon: ''
};

export const PAGE = {
  code: 'page',
  apicode: '#microsoft.graph.page',
  handle: 'pages',
  urlextension: '',
  name: 'Page',
  namePlural: 'Pages',
  icon: ''
};

export const PERSON = {
  code: 'person',
  apicode: '#microsoft.graph.person',
  handle: '',
  urlextension: '',
  name: 'Person',
  namePlural: 'Persons',
  icon: ''
};

export const POST = {
  code: 'post',
  apicode: '#microsoft.graph.post',
  handle: 'posts',
  urlextension: '',
  name: 'Post',
  namePlural: 'Posts',
  icon: ''
};

export const RISKEVENT = {
  code: 'riskevent',
  apicode: '#microsoft.graph.riskEvent',
  handle: '',
  urlextension: '',
  name: 'Risk Event',
  namePlural: 'Risk Events',
  icon: ''
};

export const SECURITYALERT = {
  code: 'securityalert',
  apicode: '#microsoft.graph.securityAlert',
  handle: 'security/alerts',
  urlextension: '',
  name: 'Security Alert',
  namePlural: 'Security Alerts',
  icon: ''
};

export const SERVICE = {
  code: 'sku',
  apicode: '#microsoft.graph.sku',
  handle: '',
  urlextension: '',
  name: 'Service',
  namePlural: 'Services',
  icon: ''
};

export const SERVICEPLAN = {
  code: 'serviceplan',
  apicode: '',
  handle: '',
  urlextension: '',
  name: 'Service Plan',
  namePlural: 'Service Plans',
  icon: ''
};

export const SERVICEPRINCIPAL = {
  code: 'serviceprincipal',
  apicode: '#microsoft.graph.servicePrincipal',
  handle: 'servicePrincipals',
  urlextension: '',
  name: 'Service Principal',
  namePlural: 'Service Principals',
  icon: ''
};

export const TASK = {
  code: 'task',
  apicode: '#microsoft.graph.task',
  handle: 'tasks',
  urlextension: '?$expand=details',
  name: 'Task',
  namePlural: 'Tasks',
  icon: ''
};

export const TEAM = {
  code: 'team',
  apicode: '#microsoft.graph.team',
  handle: 'teams',
  urlextension: '',
  name: 'Team',
  namePlural: 'Teams',
  icon: ''
};

export const TEAMSAPPINSTALLATION = {
  code: 'teamsappinstallation',
  apicode: '#microsoft.graph.app',
  handle: 'apps',
  urlextension: '',
  name: 'Teams App Installation',
  namePlural: 'Teams App Installations',
  icon: ''
};

export const TEAMSTAB = {
  code: 'teamstab',
  apicode: '#microsoft.graph.teamsTab',
  handle: 'tabs',
  urlextension: '',
  name: 'Teams Tab',
  namePlural: 'Teams Tabs',
  icon: ''
};

export const THREAD = {
  code: 'thread',
  apicode: '#microsoft.graph.thread',
  handle: 'threads',
  urlextension: '',
  name: 'Thread',
  namePlural: 'Threads',
  icon: ''
};

export const VERSION = {
  code: 'version',
  apicode: '#microsoft.graph.version',
  handle: 'versions',
  urlextension: '',
  name: 'Version',
  namePlural: 'Versions',
  icon: ''
};
