import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as adminAccountActions from '../../../app/store/actions/admin/accountActions';
import * as authActions from '../../../app/store/actions/app/authActions';
import * as onboardingActions from '../../../app/store/actions/app/onboardingActions';
import * as Selectors from '../../../app/store/reducers/rootReducer';
import BaseComponent from './Home.Canvas';

const mapStateToProps = state => {
  const authContainer = Selectors.getAuth(state);
  const onboardingStatus = Selectors.getOnboarding(state);
  const adminAccountContainer = Selectors.getAdminAccount(state);

  return {
    dataAuth: authContainer,
    onboardingStatus: onboardingStatus,
    adminAccount: adminAccountContainer
  };
};

const mapDispatchToProps = dispatch => ({
  loadAdminAccount: aadId =>
    dispatch(adminAccountActions.loadAccountByExternalId(aadId)),
  setAppAuthentication: aadUser => dispatch(authActions.setAuth(aadUser)),
  startOnboarding: () => dispatch(onboardingActions.setRunning())
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
