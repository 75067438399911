import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
//import throttle from 'lodash/throttle';
//import { loadState, saveState } from './persist';

//const initialState = loadState();
const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

// store.subscribe(
//   throttle(() => {
//     saveState(store.getState());
//   }, 1000)
// );

export default store;
