import * as Actions from '../types';
import * as Validation from '../../../../common/utils/Validation';

//-------------------------
// APP AUTH
// - setAuth
//-------------------------

export const setAuth = data => {
  return {
    type: Actions.AUTH_USER_RECEIVE,
    payload: {
      entityId:
        !Validation.isEmpty(data) && data.idToken ? data.idToken.oid : '',
      entity: data,
      receivedAt: Date.now()
    }
  };
};

//-------------------------
// Helpers
//-------------------------
