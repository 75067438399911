import React from 'react';
import PropTypes from 'prop-types';
import * as Enums from '../../../app/config/constants/Enumerations';
import * as StringUtil from '../../../common/utils/StringUtils';

class MessageCardBody extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity } = this.props;

    // Body
    const bodyPreview = StringUtil.HtmlLinebreaks(dataEntity.bodyPreview);
    //const bodyType = dataEntity.body ? dataEntity.body.contentType : "";
    //const bodyContent = dataEntity.body ? dataEntity.body.content : "";
    //const isHTML = bodyType.toLowerCase() === "html";

    // Remarks: HTML version is not used, as this can cause many problems in the
    // App rendering
    // {isHTML ? (
    //   <div
    //     className="app-itemcard-description"
    //     dangerouslySetInnerHTML={{ __html: bodyContent }}
    //   />
    // ) : (
    //   <div className="app-itemcard-description">{bodyPreview}</div>
    // )}

    return (
      <div className="app-itemcard">
        <div
          className="app-itemcard-description"
          dangerouslySetInnerHTML={{ __html: bodyPreview }}
        />
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
MessageCardBody.defaultProps = {
  mode: Enums.FORM_MODE.view
};

MessageCardBody.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  mode: PropTypes.string
};

export default MessageCardBody;
