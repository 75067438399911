import moment from 'moment';
import * as Actions from '../types';
import * as AuthConfig from '../../../config/settings/Authorization';
import * as ApiConfig from '../../../config/settings/Api';
import * as Validation from '../../../../common/utils/Validation';

const MicrosoftGraph = require('@microsoft/microsoft-graph-client');

//-------------------------
// TENANT
// - loadStatistics
//-------------------------
// NO Data Extension
//-------------------------

export const loadStatistics = auth => dispatch => {
  dispatch(fetchOpenActivities(auth));
  dispatch(fetchComingEvents(auth));
  dispatch(fetchUnreadMessages(auth));
  dispatch(fetchOpenTasks(auth));
};

//-------------------------
// Helpers
//-------------------------

const receiveOpenActivities = data => {
  const countValue = !Validation.isEmpty(data) ? data['@odata.count'] : 0;
  return {
    type: Actions.STATISTICS_ACTIVITIES_OPEN,
    payload: {
      value: countValue
    }
  };
};

const receiveComingEvents = data => {
  const countValue = !Validation.isEmpty(data) ? data['@odata.count'] : 0;
  return {
    type: Actions.STATISTICS_EVENTS_COMING,
    payload: {
      value: countValue
    }
  };
};

const receiveUnreadMessages = data => {
  const countValue = !Validation.isEmpty(data) ? data['@odata.count'] : 0;
  return {
    type: Actions.STATISTICS_MESSAGES_UNREAD,
    payload: {
      value: countValue
    }
  };
};

const receiveOpenTasks = data => {
  const countValue = !Validation.isEmpty(data) ? data['@odata.count'] : 0;
  return {
    type: Actions.STATISTICS_TASKS_OPEN,
    payload: {
      value: countValue
    }
  };
};

const receiveError = err => {
  return {
    type: Actions.ERRORS_GET,
    payload: {
      fetching: err
    }
  };
};

const fetchOpenActivities = auth => dispatch => {
  const query = "/me/outlook/tasks?$filter=status ne 'completed'&$count=true";
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(query)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => dispatch(receiveOpenActivities(res)))
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};

const fetchComingEvents = auth => dispatch => {
  const startDateISO = moment().toISOString();
  const endDateISO = moment()
    .add(1, 'months')
    .toISOString();
  const query =
    '/me/calendar/calendarView?startDateTime=' +
    startDateISO +
    '&endDateTime=' +
    endDateISO +
    '&$count=true';
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(query)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => dispatch(receiveComingEvents(res)))
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};

const fetchUnreadMessages = auth => dispatch => {
  const query = '/me/messages?$filter=isRead eq false&$count=true';
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(query)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => dispatch(receiveUnreadMessages(res)))
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};

const fetchOpenTasks = auth => dispatch => {
  const query =
    '/me/planner/tasks?$expand=details&$filter=percentComplete lt 100&$count=true';
  auth
    .getAccessToken(AuthConfig.AUTHORISATION_CONFIG.graphScopes)
    .then(accessToken => {
      const client = MicrosoftGraph.Client.init({
        authProvider: done => done(null, accessToken)
      });
      client
        .api(query)
        .version(ApiConfig.MICROSOFT_GRAPH.EndpointVersion)
        .get()
        .then(res => dispatch(receiveOpenTasks(res)))
        .catch(err => dispatch(receiveError(err)));
    })
    .catch(err => console.log(err));
};
