import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';

import ActivityHeader from '../../components/activity/Activity.Card.Header';
import ActivityFolderHeader from '../../components/activityfolder/ActivityFolder.Card.Header';
import ActivityGroupHeader from '../../components/activitygroup/ActivityGroup.Card.Header';
import AdministrativeUnitHeader from '../../components/administrativeunit/AdministrativeUnit.Card.Header';
import AgreementAcceptanceHeader from '../../components/agreementacceptance/AgreementAcceptance.Card.Header';
import AgreementHeader from '../../components/agreement/Agreement.Card.Header';
import ApplicationHeader from '../../components/application/Application.Card.Header';
import AttachmentHeader from '../../components/attachment/Attachment.Card.Header';
import BookingAppointmentHeader from '../../components/bookingappointment/BookingAppointment.Card.Header';
import BookingBusinessHeader from '../../components/bookingbusiness/BookingBusiness.Card.Header';
import BookingCustomerHeader from '../../components/bookingcustomer/BookingCustomer.Card.Header';
import BookingServiceHeader from '../../components/bookingservice/BookingService.Card.Header';
import BookingStaffMemberHeader from '../../components/bookingstaffmember/BookingStaffMember.Card.Header';
import BucketHeader from '../../components/bucket/Bucket.Card.Header';
import CalendarGroupHeader from '../../components/calendargroup/CalendarGroup.Card.Header';
import CalendarHeader from '../../components/calendar/Calendar.Card.Header';
import ChannelHeader from '../../components/channel/Channel.Card.Header';
import ChatMessageHeader from '../../components/chatmessage/ChatMessage.Card.Header';
import ChatThreadHeader from '../../components/chatthread/ChatThread.Card.Header';
import ContactFolderHeader from '../../components/contactfolder/ContactFolder.Card.Header';
import ContactHeader from '../../components/contact/Contact.Card.Header';
import ContractHeader from '../../components/contract/Contract.Card.Header';
import ConversationHeader from '../../components/conversation/Conversation.Card.Header';
import DeviceHeader from '../../components/device/Device.Card.Header';
import DirectoryRoleHeader from '../../components/directoryrole/DirectoryRole.Card.Header';
import DomainHeader from '../../components/domain/Domain.Card.Header';
import DriveItemHeader from '../../components/driveitem/DriveItem.Card.Header';
import DriveHeader from '../../components/drive/Drive.Card.Header';
import EventHeader from '../../components/event/Event.Card.Header';
import GroupHeader from '../../components/group/Group.Card.Header';
import IdentityProviderHeader from '../../components/identityprovider/IdentityProvider.Card.Header';
import InsightHeader from '../../components/insight/Insight.Card.Header';
import LicenseHeader from '../../components/license/License.Card.Header';
import ListItemHeader from '../../components/listitem/ListItem.Card.Header';
import ListHeader from '../../components/list/List.Card.Header';
import MailFolderHeader from '../../components/mailfolder/MailFolder.Card.Header';
import MessageHeader from '../../components/message/Message.Card.Header';
import NotebookHeader from '../../components/notebook/Notebook.Card.Header';
import OrganizationHeader from '../../components/organization/Organization.Card.Header';
import OrgContactHeader from '../../components/orgcontact/OrgContact.Card.Header';
import PageHeader from '../../components/page/Page.Card.Header';
import PersonHeader from '../../components/person/Person.Card.Header';
import PlanHeader from '../../components/plan/Plan.Card.Header';
import PostHeader from '../../components/post/Post.Card.Header';
import RiskEventHeader from '../../components/riskevent/RiskEvent.Card.Header';
import SecurityAlertHeader from '../../components/securityalert/SecurityAlert.Card.Header';
import ServicePlanHeader from '../../components/serviceplan/ServicePlan.Card.Header';
import ServicePrincipalHeader from '../../components/serviceprincipal/ServicePrincipal.Card.Header';
import ServiceHeader from '../../components/service/Service.Card.Header';
import SiteHeader from '../../components/site/Site.Card.Header';
import TaskHeader from '../../components/task/Task.Card.Header';
import TeamHeader from '../../components/team/Team.Card.Header';
import TeamsAppInstallationHeader from '../../components/teamsappinstallation/TeamsAppInstallation.Card.Header';
import TeamsTabHeader from '../../components/teamstab/TeamsTab.Card.Header';
import ThreadHeader from '../../components/thread/Thread.Card.Header';
import UserHeader from '../../components/user/User.Card.Header';
import VersionHeader from '../../components/version/Version.Card.Header';

import * as ObjectConfig from '../../../app/config/entities/Objects';
import * as ApplicationConfig from '../../../app/config/constants/Application';
import * as Validation from '../../../common/utils/Validation';

class PathfinderHeader extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleEntityClick = (e, key, url) => {
    e.preventDefault();
    const dataStep = this.props.dataStepContainer
      ? this.props.dataStepContainer.step
      : {};
    const dataHeader = dataStep ? dataStep.header : {};
    const dataContext = dataHeader ? dataHeader.context : {};
    const area = ApplicationConfig.NAVIGATION_AREA.pathfinder;
    const type = dataHeader ? dataHeader.entityType : '';

    let parent = {};
    let segment = {};
    if (dataContext) {
      segment.type = dataContext.segmentType;
      segment.id = dataContext.segmentId;
      parent.type = dataContext.parentType;
      parent.id = dataContext.parentId;
    }

    //console.log(segment);
    //console.log(parent);
    //console.log({ type: type, id: key });

    this.props.setCurrentContext(type, key, parent, segment);
    this.props.setCurrentNode(area, type, key, url);
    this.props.history.push(`${this.props.match.url}/node`);
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { dataStepContainer, isCurrent } = this.props;

    const dataStep = dataStepContainer ? dataStepContainer.step : {};
    const dataHeader = dataStep ? dataStep.header : {};

    if (!Validation.isEmpty(dataHeader)) {
      if (dataHeader.entityType === ObjectConfig.ACTIVITY.code) {
        return (
          <ActivityHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.ACTIVITYFOLDER.code) {
        return (
          <ActivityFolderHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.ACTIVITYGROUP.code) {
        return (
          <ActivityGroupHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.ADMINISTRATIVEUNIT.code) {
        return (
          <AdministrativeUnitHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.AGREEMENT.code) {
        return (
          <AgreementHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.AGREEMENTACCEPTANCE.code) {
        return (
          <AgreementAcceptanceHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.APPLICATION.code) {
        return (
          <ApplicationHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.ATTACHMENT.code) {
        return (
          <AttachmentHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.BOOKINGAPPOINTMENT.code) {
        return (
          <BookingAppointmentHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.BOOKINGBUSINESS.code) {
        return (
          <BookingBusinessHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.BOOKINGCUSTOMER.code) {
        return (
          <BookingCustomerHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.BOOKINGSERVICE.code) {
        return (
          <BookingServiceHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.BOOKINGSTAFFMEMBER.code) {
        return (
          <BookingStaffMemberHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.BUCKET.code) {
        return (
          <BucketHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CALENDAR.code) {
        return (
          <CalendarHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CALENDARGROUP.code) {
        return (
          <CalendarGroupHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CHANNEL.code) {
        return (
          <ChannelHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CHATMESSAGE.code) {
        return (
          <ChatMessageHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CHATTHREAD.code) {
        return (
          <ChatThreadHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CONTACT.code) {
        return (
          <ContactHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CONTACTFOLDER.code) {
        return (
          <ContactFolderHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CONTRACT.code) {
        return (
          <ContractHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.CONVERSATION.code) {
        return (
          <ConversationHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.DEVICE.code) {
        return (
          <DeviceHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.DIRECTORYROLE.code) {
        return (
          <DirectoryRoleHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.DOMAIN.code) {
        return (
          <DomainHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.DRIVE.code) {
        return (
          <DriveHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.DRIVEITEM.code) {
        return (
          <DriveItemHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.EVENT.code) {
        return (
          <EventHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.GROUP.code) {
        return (
          <GroupHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.IDENTITYPROVIDER.code) {
        return (
          <IdentityProviderHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.INSIGHT.code) {
        return (
          <InsightHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.LICENSE.code) {
        return (
          <LicenseHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.LIST.code) {
        return (
          <ListHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.LISTITEM.code) {
        return (
          <ListItemHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.MAILFOLDER.code) {
        return (
          <MailFolderHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.MESSAGE.code) {
        return (
          <MessageHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.NOTEBOOK.code) {
        return (
          <NotebookHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.ORGANIZATION.code) {
        return (
          <OrganizationHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.ORGCONTACT.code) {
        return (
          <OrgContactHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.PAGE.code) {
        return (
          <PageHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.PERSON.code) {
        return (
          <PersonHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.PLAN.code) {
        return (
          <PlanHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.POST.code) {
        return (
          <PostHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.RISKEVENT.code) {
        return (
          <RiskEventHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.SECURITYALERT.code) {
        return (
          <SecurityAlertHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.SERVICE.code) {
        return (
          <ServiceHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.SERVICEPLAN.code) {
        return (
          <ServicePlanHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.SERVICEPRINCIPAL.code) {
        return (
          <ServicePrincipalHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.SITE.code) {
        return (
          <SiteHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.TASK.code) {
        return (
          <TaskHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.TEAM.code) {
        return (
          <TeamHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.TEAMSAPPINSTALLATION.code) {
        return (
          <TeamsAppInstallationHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.TEAMSTAB.code) {
        return (
          <TeamsTabHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.THREAD.code) {
        return (
          <ThreadHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.USER.code) {
        return (
          <UserHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }
      if (dataHeader.entityType === ObjectConfig.VERSION.code) {
        return (
          <VersionHeader
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntity={dataHeader.entity}
            smallContainer={true}
            showObjectLink={isCurrent}
            onEntityClick={this.handleEntityClick}
          />
        );
      }

      return (
        <Header as="h4" textAlign="center">
          Object View coming soon
        </Header>
      );
    }
    return (
      <Header as="h4" textAlign="center">
        No Relation data selected
      </Header>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
PathfinderHeader.defaultProps = {
  isCurrent: false
};

PathfinderHeader.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataStepContainer: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool,
  setCurrentContext: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired
};

export default PathfinderHeader;
