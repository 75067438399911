import React from 'react';
import PropTypes from 'prop-types';
import { List, Image, Label } from 'semantic-ui-react';
import DefaultImage from '../../../app/assets/images/defaults/img-default-driveitem.png';
import * as ApplicationConst from '../../../app/config/constants/Application';
import * as StringUtil from '../../../common/utils/StringUtils';
import * as Validation from '../../../common/utils/Validation';

class DriveItemItemlistItem extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      windowWidth: 0
    };
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentWillMount() {
    this.setState({ windowWidth: window.innerWidth });
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  updateWindowWidth = () => {
    const previousSize = this.state.windowWidth;
    const newSize = window.innerWidth;
    if (newSize !== previousSize) {
      this.setState({ windowWidth: newSize });
    }
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { dataEntity, dataKey } = this.props;

    // Device size
    let lengthMeta = 52;
    if (this.state.windowWidth < ApplicationConst.DEVICE_SIZE.tiny) {
      lengthMeta = 30;
    }

    // Image
    const entityImage =
      dataEntity && dataEntity.image ? dataEntity.image.url : DefaultImage;

    // Title
    const titleString = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.name) ? dataEntity.name : '--',
      lengthMeta,
      true
    );

    // Label
    const labelColor = 'blue';

    // Meta
    const metaText = StringUtil.MaxLength(
      !Validation.isEmpty(dataEntity.id) ? dataEntity.id : '',
      lengthMeta,
      true
    );

    // WebLink
    let webLink = dataEntity.webUrl;

    return (
      <List.Item
        key={dataKey}
        onClick={e => this.props.onEntityClick(e, dataEntity.id, webLink)}
      >
        <List.Content floated="right">
          <Label
            color={labelColor}
            circular
            empty
            className="app-itemlist-label"
          />
        </List.Content>
        <Image size="mini" src={entityImage} alt={titleString} />
        <List.Content className="app-meta-item-content">
          <List.Header>{titleString}</List.Header>
          <List.Description>{metaText}</List.Description>
        </List.Content>
      </List.Item>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
DriveItemItemlistItem.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  dataEntity: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
  onEntityClick: PropTypes.func.isRequired
};

export default DriveItemItemlistItem;
