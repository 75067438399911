import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment, Statistic, Icon } from 'semantic-ui-react';
import * as Relation from '../../../../app/config/entities/RelationTypes';
import * as ObjectConfig from '../../../../app/config/entities/Objects';
import * as AppConfig from '../../../../app/config/constants/Application';
import * as AppSettings from '../../../../app/config/settings/Application';
import * as DataUtils from '../../../../common/utils/DataUtils';

class StatisticsCanvas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    this.props.loadStatistics(this.props.auth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleStatisticsClick = query => {
    let area = AppConfig.NAVIGATION_AREA.dashboard;
    let type = ObjectConfig.USER.code;
    let key = this.props.dataMe ? this.props.dataMe.userId : '';
    let url = '';
    let loadAll = false;
    if (query === Relation.BOOKINGAPPOINTMENTS.coming) {
      type = ObjectConfig.ORGANIZATION.code;
      key = this.props.dataTenant ? this.props.dataTenant.organizationId : '';
    }

    // set Current node & context
    this.props.setCurrentContext(type, key);
    this.props.setCurrentNode(area, type, key, url);
    this.props.setRelation(query, loadAll);

    // check Object & Relations if already available as Step
    const datasetParams = {
      segmentType: ObjectConfig.ME.code,
      segmentId: this.props.dataMe ? this.props.dataMe.userId : '',
      parentType: '',
      parentId: '',
      entityType: ObjectConfig.USER.code,
      entityId: this.props.dataMe ? this.props.dataMe.userId : '',
      relationType: query
    };
    const newPosition = DataUtils.getPathPositionOfDataSet(
      this.props.dataSteps,
      datasetParams
    );
    if (newPosition > 0) {
      // navigate to already open Step in Path
      this.props.setCurrentStepPosition(newPosition);
    } else {
      // create new Step in Path
      this.props.addNewPathfinderStep(this.props.auth);
      this.props.setCurrentStepPositionToLast();
    }
    // open the Path
    this.props.history.push('/pathfinder');
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    // const { auth, location, match, params } = this.props;
    // const { dataStatistics } = this.props;
    // const numberActivities =
    //   dataStatistics && dataStatistics.openActivities
    //     ? dataStatistics.openActivities
    //     : 0;
    // const numberEvents =
    //   dataStatistics && dataStatistics.comingEvents
    //     ? dataStatistics.comingEvents
    //     : 0;
    // const numberMessages =
    //   dataStatistics && dataStatistics.unreadMessages
    //     ? dataStatistics.unreadMessages
    //     : 0;
    // const numberTasks =
    //   dataStatistics && dataStatistics.openTasks ? dataStatistics.openTasks : 0;

    return (
      <Segment basic>
        <Grid>
          <Grid.Column
            mobile={8}
            tablet={4}
            computer={4}
            largeScreen={4}
            widescreen={4}
          >
            <div
              className="app-statistics-container"
              onClick={e => this.handleStatisticsClick(Relation.TASKS.open)}
            >
              <Segment
                color="olive"
                textAlign="center"
                className="app-statistics"
              >
                <Statistic color="olive">
                  <Statistic.Value>
                    <Icon name={AppSettings.SETTINGS_APP_ICONS.task} />
                  </Statistic.Value>
                  <Statistic.Label className="app-statistics-label">
                    Open Tasks
                  </Statistic.Label>
                </Statistic>
              </Segment>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={8}
            tablet={4}
            computer={4}
            largeScreen={4}
            widescreen={4}
          >
            <div
              className="app-statistics-container"
              onClick={e =>
                this.handleStatisticsClick(Relation.ACTIVITIES.open)
              }
            >
              <Segment
                color="teal"
                textAlign="center"
                className="app-statistics"
              >
                <Statistic color="teal">
                  <Statistic.Value>
                    <Icon name={AppSettings.SETTINGS_APP_ICONS.activity} />
                  </Statistic.Value>
                  <Statistic.Label className="app-statistics-label">
                    To-Dos
                  </Statistic.Label>
                </Statistic>
              </Segment>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={8}
            tablet={4}
            computer={4}
            largeScreen={4}
            widescreen={4}
          >
            <div
              className="app-statistics-container"
              onClick={e =>
                this.handleStatisticsClick(Relation.MESSAGES.inboxUnread)
              }
            >
              <Segment
                color="blue"
                textAlign="center"
                className="app-statistics"
              >
                <Statistic color="blue">
                  <Statistic.Value>
                    <Icon name={AppSettings.SETTINGS_APP_ICONS.email} />
                  </Statistic.Value>
                  <Statistic.Label className="app-statistics-label">
                    Unread Mails
                  </Statistic.Label>
                </Statistic>
              </Segment>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={8}
            tablet={4}
            computer={4}
            largeScreen={4}
            widescreen={4}
          >
            <div
              className="app-statistics-container"
              onClick={e => this.handleStatisticsClick(Relation.EVENTS.coming)}
            >
              <Segment
                color="violet"
                textAlign="center"
                className="app-statistics"
              >
                <Statistic color="violet">
                  <Statistic.Value>
                    <Icon name={AppSettings.SETTINGS_APP_ICONS.appointment} />
                  </Statistic.Value>
                  <Statistic.Label className="app-statistics-label">
                    Coming Events
                  </Statistic.Label>
                </Statistic>
              </Segment>
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
StatisticsCanvas.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataStatistics: PropTypes.object.isRequired,
  dataSteps: PropTypes.array.isRequired,
  dataMe: PropTypes.object.isRequired,
  dataTenant: PropTypes.object.isRequired,
  loadStatistics: PropTypes.func.isRequired,
  addNewPathfinderStep: PropTypes.func.isRequired,
  setCurrentStepPosition: PropTypes.func.isRequired,
  setCurrentStepPositionToLast: PropTypes.func.isRequired,
  setRelation: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired
};

export default StatisticsCanvas;
