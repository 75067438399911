import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as BookingServiceActions from '../../../../app/store/actions/entities/bookingserviceActions';
import * as CurNodeActions from '../../../../app/store/actions/current/nodeActions';
import * as CurContextActions from '../../../../app/store/actions/current/contextActions';
import * as Selectors from '../../../../app/store/reducers/rootReducer';
import * as ObjectConfig from '../../../../app/config/entities/Objects';
import * as Hydration from '../../../../common/utils/HydrationUtils';
import * as Validation from '../../../../common/utils/Validation';
import BaseComponent from '../../bookingservice/BookingService.Card';

const type = ObjectConfig.BOOKINGSERVICE.code;

const mapStateToProps = state => {
  const contextContainer = Selectors.getCurrentContext(state);
  const nodeContainer = Selectors.getCurrentNode(state);
  if (!Validation.isEmpty(nodeContainer) && nodeContainer.objectType === type) {
    const entityContainer = Selectors.getBookingServiceEntityById(
      state,
      nodeContainer.objectId
    );
    //console.log('Card Container:');
    //console.log(entityContainer);
    const bookingservice = entityContainer ? entityContainer.entity : {};

    // Special Data Hydration
    const hydratedEntity = Hydration.EntityHydration(
      bookingservice,
      type,
      state
    );

    return {
      dataNode: nodeContainer,
      dataContext: contextContainer,
      dataEntity: hydratedEntity,
      dataLoading: entityContainer.isFetching
    };
  }
  return {
    dataNode: {},
    dataContext: {},
    dataEntity: {},
    dataLoading: false
  };
};

const mapDispatchToProps = dispatch => ({
  loadBookingService: (auth, id, parent = {}, segment = {}) =>
    dispatch(
      BookingServiceActions.loadBookingService(auth, id, parent, segment)
    ),
  invalidateBookingService: id =>
    dispatch(BookingServiceActions.invalidateBookingService(id)),
  setCurrentNode: (area, type, id, url = '') =>
    dispatch(CurNodeActions.receiveEntity(area, type, id, url)),
  setCurrentContext: (type, id, parent = {}, segment = {}) =>
    dispatch(CurContextActions.setContext(type, id, parent, segment))
});

const ContainerComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseComponent)
);

export default ContainerComponent;
