import { combineReducers } from 'redux';
import byIdReducer, * as byIdSelectors from './groups/byIdReducer';
import byIdExtensionReducer, * as byIdExtSelectors from './groups/byIdExtensionReducer';
import byIdTeamReducer, * as byIdTeamSelectors from './groups/byIdTeamReducer';
import allIdsReducer from './groups/allIdsReducer';
import allIdsTeamReducer from './groups/allIdsTeamReducer';

//-------------------------
// Reducers
//-------------------------

export default combineReducers({
  byId: byIdReducer,
  byIdExtension: byIdExtensionReducer,
  byIdTeam: byIdTeamReducer,
  allIds: allIdsReducer,
  allIdsTeam: allIdsTeamReducer
});

//-------------------------
// Selectors
//-------------------------

export const getEntityById = (state, id) => {
  const entity = byIdSelectors.getEntityById(state.byId, id);
  const entityExt = byIdExtSelectors.getExtensionById(state.byIdExtension, id);
  const entityTeam = byIdTeamSelectors.getTeamById(state.byIdTeam, id);
  if (entity && entityExt && entityExt.entity) {
    entity.entity = {
      ...entityExt.entity,
      ...entity.entity
    };
  }
  if (entity && entityTeam && entityTeam.entity) {
    entity.entity = {
      ...entity.entity,
      teamParameters: {
        ...entityTeam.entity
      }
    };
  }
  return entity;
};
export const getEntitiesByIds = (state, ids = []) => {
  return ids.map(id => getEntityById(state, id));
};
export const getAllEntities = state => {
  return state.allIds.map(id => getEntityById(state, id));
};
export const getFilteredEntities = (state, filter = 'all') => {
  const allEntities = state.allIds.map(id => getEntityById(state, id));
  switch (filter) {
    case 'all':
      return allEntities;
    default:
      throw new Error(`Unknown filter: ${filter}`);
  }
};

// Special Team Selectors

export const getAllTeams = state => {
  return state.allIdsTeam.map(id => getEntityById(state, id));
};
export const getFilteredTeams = (state, filter = 'all') => {
  const allEntities = state.allIdsTeam.map(id => getEntityById(state, id));
  switch (filter) {
    case 'all':
      return allEntities;
    default:
      throw new Error(`Unknown filter: ${filter}`);
  }
};
