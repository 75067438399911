import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import CardList from './Group.CardList.List';
import DataLoader from '../../../common/components/controls/DataLoader';
import * as ApplicationConfig from '../../../app/config/constants/Application';
import * as ObjectConfig from '../../../app/config/entities/Objects';

class GroupCardList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  //-----------------------------------
  // Life cycle management
  //-----------------------------------
  componentDidMount() {
    this.props.loadGroups(this.props.auth);
  }

  //-----------------------------------
  // Event & Callback Handler
  //-----------------------------------
  handleNodeClick = (e, key, url) => {
    this.props.setCurrentContext(ObjectConfig.GROUP.code, key);
    this.props.setCurrentNode(
      this.props.area,
      ObjectConfig.GROUP.code,
      key,
      url
    );
    this.props.history.push(`${this.props.match.url}/node`);
  };
  handleLoadMoreClick = (e, url) => {
    console.log('Load more data ...');
  };

  //-----------------------------------
  // Component rendering
  //-----------------------------------
  render() {
    const { auth, location, match, params } = this.props;
    const { dataEntities, dataLoading, headerText } = this.props;
    const { dataMoreAvailable, dataMoreUrl } = this.props;

    if (dataLoading) {
      return <DataLoader />;
    }

    return (
      <div>
        {headerText && (
          <Header sub className="app-itemcard-subheader">
            {headerText}
          </Header>
        )}
        <div className="app-itemlist">
          <CardList
            auth={auth}
            location={location}
            match={match}
            params={params}
            dataEntities={dataEntities}
            dataMoreAvailable={dataMoreAvailable}
            dataMoreUrl={dataMoreUrl}
            onEntityClick={this.handleNodeClick}
            onLoadMoreClick={this.handleLoadMoreClick}
          />
        </div>
      </div>
    );
  }
}

//-----------------------------------
// Component interface
//-----------------------------------
GroupCardList.defaultProps = {
  area: ApplicationConfig.NAVIGATION_AREA.dashboard
};

GroupCardList.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dataEntities: PropTypes.array.isRequired,
  dataLoading: PropTypes.bool.isRequired,
  area: PropTypes.string,
  dataMoreAvailable: PropTypes.bool.isRequired,
  dataMoreUrl: PropTypes.string,
  headerText: PropTypes.string,
  loadGroups: PropTypes.func.isRequired,
  loadMoreGroups: PropTypes.func,
  invalidateGroups: PropTypes.func.isRequired,
  setCurrentNode: PropTypes.func.isRequired,
  setCurrentContext: PropTypes.func.isRequired
};

export default GroupCardList;
